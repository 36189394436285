import Cookies from 'js-cookie'
import { useQuery } from '@tanstack/react-query'
import { COOKIE_KEYS, TIME_COOKIE_TOKEN_EXPIRATION } from '~/constants/auth'
import authApi from '~/modules/auth/services'
import { SSO_COOKIE_DOMAIN } from '~/constants/environment'

const callRefreshToken = async () => {
  const rt = Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || ''
  if (rt) {
    try {
      const req = await authApi.fetchRefreshToken(rt)
      if (req.has_app) {
        Cookies.set(COOKIE_KEYS.CUSTOM_TOKEN, req.custom_token, { expires: TIME_COOKIE_TOKEN_EXPIRATION, domain: SSO_COOKIE_DOMAIN })
        Cookies.set(COOKIE_KEYS.REFRESH_TOKEN, req.refresh_token, { expires: TIME_COOKIE_TOKEN_EXPIRATION, domain: SSO_COOKIE_DOMAIN })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}

export const useRefreshCustomToken = (enabled) => useQuery({
  queryKey: ['fetchRefreshCustomToken', enabled],
  queryFn: callRefreshToken,
  refetchInterval: 50 * 60 * 1000,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
  enabled,
})
