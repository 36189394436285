import {
  all,
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'

import skuApi from '~/services/apis/sku'

import Actions, { SKU_ACTIONS } from '~/redux/actions/sku'

export function* createSku(action) {
  try {
    const resp = yield call(skuApi.create, action.payload)
    yield put(Actions.createSkuOk(resp))
    action.onSuccess(resp)
  } catch (e) {
    yield put(Actions.createSkuFail(e))
    action.onFail(e)
  }
}
export function* updateSku(action) {
  try {
    const resp = yield call(skuApi.updateOne, action.payload)
    yield put(Actions.updateSkuOk(resp))
    action.onSuccess(resp)
  } catch (e) {
    yield put(Actions.updateSkuFail(e))
    action.onFail(e)
  }
}

export function* getSKUList(action) {
  try {
    const resp = yield call(skuApi.getList, action.payload)
    yield put(Actions.getSKUListSuccess(resp))
  } catch (e) {
    yield put(Actions.getSKUListFail(e))
  }
}

export function* getSKUDetail(action) {
  try {
    const resp = yield call(skuApi.findOne, action.payload)
    yield put(Actions.getSKUDetailSuccess(resp))
  } catch (error) {
    yield put(Actions.getSKUDetailFail(error))
  }
}

export function* watchSKU() {
  yield all([
    takeLatest(SKU_ACTIONS.GET_SKU_LIST, getSKUList),
    takeLatest(SKU_ACTIONS.GET_SKU_DETAIL, getSKUDetail),
    takeLatest(SKU_ACTIONS.CREATE_SKU, createSku),
    takeLatest(SKU_ACTIONS.UPDATE_SKU, updateSku),
  ])
}

export default function* sku() {
  yield fork(watchSKU)
}
