import { AuthStorageKey } from '../localStorage/localStorageKey'

const { ORGANIZATION, STORE } = AuthStorageKey

// We refer AuthStorageKey here
// because I want sessionKey sync with localStorageKey
// so that even the localStorageKey value changes
// this sessionKey still works out of the box
export const AuthSessionKey = {
  ORGANIZATION,
  STORE,
}

export const ListSettingKey = {
  PRODUCT: 'PRODUCT_SETTINGS',
  PERFORMANCE_PRODUCTS: 'PERFORMANCE_PRODUCTS',
  COUPON: 'COUPON_SETTINGS',
  COVA_COUPON: 'COVA_COUPON_SETTINGS',
  GREENLINE_COUPON: 'GREENLINE_COUPON_SETTINGS',
  CATEGORY: 'CATEGORY_SETTINGS',
  SHOPIFY_CATEGORY: 'SHOPIFY_CATEGORY_SETTINGS',
  TAG: 'TAG_SETTINGS',
  SKU: 'SKU-management',
  PROMOTION: 'PROMOTION_SETTINGS',
  ATTRIBUTE: 'ATTRIBUTE_SETTINGS',
  COMMENT: 'COMMENT_SETTINGS',
  ORDER: 'ORDER_SETTINGS',
  SHOPIFY_ORDER: 'SHOPIFY_ORDER_SETTINGS',
  CUSTOMER: 'CUSTOMER_SETTINGS',
  INVENTORY: 'INVENTORY_SETTINGS',
  MANAGE_POINT: 'MANAGE_POINT',
  POINT_LOG: 'POINT_LOG',
  INVENTORY_ADJUSTMENT: 'INVENTORY_ADJUSTMENT',
  COVA_ORDER: 'COVA_ORDER_SETTINGS',
  COVA_CUSTOMER: 'COVA_CUSTOMER_SETTINGS',
  COVA_PRODUCT: 'COVA_PRODUCT_SETTINGS',
  GREENLINE_PRODUCT: 'GREENLINE_PRODUCT_SETTINGS',
  GREENLINE_ORDER: 'GREENLINE_ORDER_SETTINGS',
  GREENLINE_CUSTOMER: 'GREENLINE_CUSTOMER_SETTINGS',
  SHOPIFY_CUSTOMER: 'SHOPIFY_CUSTOMER_SETTINGS',
  SHOPIFY_PRODUCT: 'SHOPIFY_PRODUCT_SETTINGS',
}

export const Notification = {
  APP_UPDATED: 'APP_UPDATED',
}
