import React from 'react'

export const WrapSSOContext = React.createContext()

export const useWrapSSOContext = () => {
  const context = React.useContext(WrapSSOContext)
  if (context === undefined) {
    throw new Error('Component should be in Context ')
  }
  return context
}
