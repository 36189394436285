import {
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'

import {
  DASHBOARD_ACTION,
  getDashboardDataRejected,
  getDashboardDataResolved,
} from '~/redux/actions/dashboard'
import dashboardApi from '~/services/apis/dashboard'

export function* getDashboardData({ payload }) {
  try {
    const data = yield call(dashboardApi.get, payload.startDate, payload.endDate)
    const parsedData = {
      grossSales: data?.gross_sales,
      netSales: data?.net_sales,
      totalOrders: data?.total_orders,
      newUsers: data?.new_registrations,
      topCategories: data?.top_5_categories,
      customerTypes: data?.new_vs_returning,
      topCoupons: data?.top_5_coupons,
      recentReviews: data?.recent_reviews,
      bestSellers: data?.top_5_bestsellers,
    }
    yield put(getDashboardDataResolved(parsedData))
  } catch (e) {
    yield put(getDashboardDataRejected(e))
  }
}

export function* watchGetDashboardData() {
  yield takeLatest(DASHBOARD_ACTION.GET_DATA, getDashboardData)
}

export default function* dashboard() {
  yield fork(watchGetDashboardData)
}
