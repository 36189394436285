import httpClient from '~/modules/core/httpClient'

const ACTIONS = {
  PAGINATE_FILES: 'paginate_files',
  CREATE_FILE: 'create_file',
  NOTIFY_UPLOADED: 'notify_uploaded',
  DELETE_FILE: 'delete_file',
  CREATE_FOLDER: 'create_folder',
  READ_FOLDER: 'read_folder',
  DELETE_FOLDER: 'delete_folder',
  UPDATE_FILE: 'update_file',
  UPDATE_FOLDER: 'update_folder',
  GET_FOLDERS_STRUCTURE: 'get_folders_structure',
  MOVE_MEDIA: 'move_media',
}
const url = {
  READ: '/stores/media/',
}

const getFiles = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.PAGINATE_FILES,
    parameters,
  },
)

const getFolders = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.GET_FOLDERS_STRUCTURE,
    parameters,
  },
)

const createFile = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.CREATE_FILE,
    parameters,
  },
)

const notifyUploaded = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.NOTIFY_UPLOADED,
    parameters,
  },
)

const deleteFile = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.DELETE_FILE,
    parameters,
  },
)

const createFolder = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.CREATE_FOLDER,
    parameters,
  },
)

const deleteFolder = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.DELETE_FOLDER,
    parameters,
  },
)

const editFolder = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.UPDATE_FOLDER,
    parameters,
  },
)
const updateFileMetadata = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.UPDATE_FILE,
    parameters,
  },
)

const moveFileToFolder = (parameters) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.MOVE_MEDIA,
    parameters,
  },
)

const mediaApi = {
  getFiles,
  createFile,
  notifyUploaded,
  deleteFile,
  createFolder,
  getFolders,
  deleteFolder,
  updateFileMetadata,
  editFolder,
  moveFileToFolder,
}

export default mediaApi
