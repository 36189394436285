import React, {
  useMemo, useLayoutEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { set, get } from '~/utils/storage/memoryStorage'
import Guard from '~/modules/auth/components/Guard'
import { useGetStore, useAllStores } from '~/redux/hooks/store'
import HttpConstants from '~/modules/core/httpClient/constants'
import { useUser } from '~/redux/hooks/user'

const StoreGuard = ({
  children,
}) => {
  const stores = useAllStores()
  const activeStore = useGetStore()
  const { user } = useUser()

  const isExactStore = useMemo(() => !isEmpty(activeStore), [activeStore])

  useLayoutEffect(() => {
    if (isExactStore) {
      if (get(HttpConstants.ORGANIZATION_ID) !== activeStore.orgId
        || get(HttpConstants.STORE_ID) !== activeStore.storeId) {
        set(HttpConstants.ORGANIZATION_ID, activeStore.orgId)
        set(HttpConstants.STORE_ID, activeStore.storeId)
        set(HttpConstants.WORKSPACE_ID, activeStore.csWsId)
      }
    }
    // TODO: Get store_id from useUser().user_settings
    // TODO: clear ORGANIZATION_ID, STORE_ID
    // else {
    //   clear(HttpConstants.ORGANIZATION_ID)
    //   clear(HttpConstants.STORE_ID)z
    // }
  }, [activeStore, isExactStore])

  const defaultStoreId = useMemo(() => {
    if (user?.user_settings?.default_store) {
      const tempActiveStore = (stores || []).find((s) => s.storeId === user?.user_settings?.default_store)
      return tempActiveStore ? user?.user_settings?.default_store : stores?.[0]?.storeId
    }

    return stores?.[0]?.storeId || null
  }, [user?.user_settings?.default_store, stores])

  return (
    <Guard accessWhen={() => isExactStore} fallbackComp={<Navigate to={`/s/${defaultStoreId}/home`} />}>
      {children}
    </Guard>
  )
}

StoreGuard.defaultProps = {
}

StoreGuard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
}

export default StoreGuard
