import moment from 'moment'

export const convertPublishDate = (date, hh, mm) => `${moment(date).format('YYYY-MM-DD')}T${hh}:${mm}:00`

export const mapCouponToCreate = (couponData) => {
  const {
    discount_type,
    products,
    excluded_products,
    product_categories,
    excluded_product_categories,
    date_expires,
    email_restrictions,
    limit_usage_to_x_items,
    expiry_date,
    ...rest
  } = couponData

  const itemData = {
    ...rest,
    discount_type,
    date_expires: date_expires
      ? moment(date_expires, 'MM/DD/YYYY').endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
      : '',
    product_ids: products.map((p) => p.value),
    excluded_product_ids: excluded_products.map((p) => p.value),
    product_categories: product_categories.map((p) => p.value),
    excluded_product_categories: excluded_product_categories.map((p) => p.value),
    email_restrictions,
    limit_usage_to_x_items: discount_type !== 'fixed_cart' ? limit_usage_to_x_items : null,
  }

  return itemData
}

export const mapCouponStatusToTag = (orderStatus) => {
  switch (orderStatus) {
    case 'active': {
      return 'active'
    }
    case 'expired': {
      return 'expired'
    }
    default: {
      return orderStatus
    }
  }
}

export const transformTypeToTitle = (text) => {
  if (!text) return ''
  const str = text.split('-')
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
  }
  return str.join(' ')
}
