import httpClient from '~/modules/core/httpClient'
import { defaultStoreParams } from '~/constants/store'
import { ACTIONS } from './common'

const url = {
  COVA_GET_TASK_ID: '/cova/onboarding/',
  COVA_STORE: '/cova/store/',
  COVA_GET_WAREHOUSE: (task_id) => `/cova/onboarding/task/?task_id=${task_id}`,
  STORE_CREATE: '/stores/woocommerce/create/',
  WAREHOUSES_LIST: 'cova/warehouses/list',
  COVA_GET_CLASSIFICATIONS: '/cova/pre-onboarding/classifications/',
  UPDATE_CLASSIFICATIONS: 'cova/pre-onboarding/classifications/update',
  COVA_GET_PREDEFINED_CATEGORIES: (company_id) => `/cova/pre-onboarding/predefined_categories/${company_id}`,
  COVA_UPDATE_CLASSIFICATION_MAPPING: '/cova/pre-onboarding/classifications/update',
  COVA_GET_CATEGORIES_MATCHING: '/stores/woocommerce/products/categories/',
  COVA_LOGIN: '/cova/auth/',
  COVA_LOCATIONS: '/cova/locations/',
  COVA_CATEGORIES_MAPPING_SUGGESTION: (store_id) => `/cova/stores/${store_id}/category_mapping/suggestion`,
}

const getTaskId = ({
  username, password, client_id, client_secret, company_id, organization_id, organization_name, store_name,
}) => httpClient.post(
  url.COVA_GET_TASK_ID,
  () => ({
    username, password, client_id, client_secret, company_id, organization_id, organization_name, store_name,
  }),
)

const linkCovaStore = ({
  username, password, client_id, client_secret, company_id, organization_id, organization_name, store_name,
}) => httpClient.post(
  url.COVA_STORE,
  () => ({
    username, password, client_id, client_secret, company_id, organization_id, organization_name, store_name,
  }),
)

const getInforStoreCova = (param) => httpClient.create()
  .ejectRes('defaultResponseHandler')
  .get(url.COVA_GET_WAREHOUSE(param))
  .then((res) => res.data)

const create = (data) => {
  const { redirect_url } = defaultStoreParams
  return httpClient.json.post(
    url.STORE_CREATE,
    () => ({
      redirect_url, ...data,
    }),
  )
}

const getWarehouseList = ({ organization_id, store_id }) => httpClient.json.post(
  url.WAREHOUSES_LIST,
  {
    organization_id,
    store_id,
  },
)

const getClassifications = (payload) => httpClient.json.post(url.COVA_GET_CLASSIFICATIONS, payload)

const getPredefinedCategories = (company_id) => httpClient.json.get(url.COVA_GET_PREDEFINED_CATEGORIES(company_id))

const updateClassification = (payload) => httpClient.json.post(url.UPDATE_CLASSIFICATIONS, payload)

const getCategoriesList = (params) => httpClient.post(url.COVA_GET_CATEGORIES_MATCHING, {
  action: ACTIONS.PAGINATE,
  ...params,
})

const getCategoriesListDirectFromWoo = (params) => httpClient.post(url.COVA_GET_CATEGORIES_MATCHING, {
  action: ACTIONS.READ_DIRECT_ALL,
  ...params,
})

const covaLogin = ({
  store_id, username, password,
}) => httpClient.json.post(url.COVA_LOGIN, {
  store_id,
  username,
  password,
})

const getCovaLocations = (id) => httpClient.json.get(`${url.COVA_LOCATIONS}?store_id=${id}&is_new=true`)

const updateCovaLocations = ({ store_id, new_locations }) => httpClient.json.post(url.COVA_LOCATIONS, {
  action: ACTIONS.ADD_COVA_LOCATIONS,
  store_id,
  parameters: {
    new_locations,
  },
})

const covaCategoryMappingSuggestion = ({ store_id }) => httpClient.json.get(url.COVA_CATEGORIES_MAPPING_SUGGESTION(store_id))

const covaApi = {
  getTaskId,
  getInforStoreCova,
  create,
  updateClassification,
  linkCovaStore,
  getWarehouseList,
  getClassifications,
  getPredefinedCategories,
  getCategoriesList,
  getCategoriesListDirectFromWoo,
  covaLogin,
  getCovaLocations,
  updateCovaLocations,
  covaCategoryMappingSuggestion,
}

export default covaApi
