import httpClient from '~/modules/core/httpClient'
import * as Common from './common'
import {
  checkMissingRequireFields,
  mapNotifyDriver,
} from '~/utils/fulfillment'
import { deepCleanObject } from '~/utils/utility'
import cantecClient from './cantec'

const ACTIONS = {
  ...Common.ACTIONS,
  CANTEC_PENDING: 'cantec_pending',
  CANTEC_PICKED_UP: 'cantec_picked_up',
  COUNT_CANTEC_ORDERS: 'count_cantec_orders',
  GET_CANTEC_INFO: 'get_cantec_info',
  UPDATE_PICKING_STATUS: 'update_picking_status',
  NOTIFY_PRINTED: 'notify_printed',
  PREVIEW: 'preview',
  NOTIFY_CUSTOMERS: 'notify_customers_local_pickup',
  NOTIFY_PICKED: 'notify_picked',
}
const url = {
  ORDERS: '/stores/woocommerce/orders/',
  ORDER_PICKING_NOTIFY_DRIVER: '/tasks/batch',
  LINK_WAREHOUSE: '/admin/pickup_spot/driver_pools/link-warehouse/',
  ONLINE_DRIVERS: '/admin/pickup_spot/driver_pools/online-drivers/',
  SPECIFY_ASSIGNEE: '/tasks/specify-assignee',
  LINKED_POOLS: '/tasks/batch/linked-pools',
}

const getPendingOrders = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.CANTEC_PENDING,
  parameters,
})

const getPickedUpOrders = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.CANTEC_PICKED_UP,
  parameters,
})

const countOrders = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.COUNT_CANTEC_ORDERS,
  parameters,
})

const updatePickingStatus = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.UPDATE_PICKING_STATUS,
  parameters,
})

const readOrdersDetail = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.PREVIEW,
  parameters,
})

const getCantecInfo = (ids) => httpClient.post(url.ORDERS, {
  action: ACTIONS.GET_CANTEC_INFO,
  parameters: { ids },
})

const getOnlineDrivers = (payload, queryParams) => cantecClient.post(url.ONLINE_DRIVERS, () => payload, { params: queryParams })

const getLinkedWarehouses = (params) => cantecClient.post(url.LINK_WAREHOUSE, () => params)

const notifyDriver = (tasks) => {
  const cleanTasks = tasks.map((task) => deepCleanObject(task))
  checkMissingRequireFields(cleanTasks)
  const params = mapNotifyDriver(cleanTasks)
  return cantecClient.post(url.ORDER_PICKING_NOTIFY_DRIVER, () => params)
}

const notifyDriverInLinkWarehouses = async (tasks, pool_info) => {
  const cleanTasks = tasks.map((task) => deepCleanObject(task))
  checkMissingRequireFields(cleanTasks)
  const mappedTasks = mapNotifyDriver(cleanTasks)
  const params = mappedTasks.map((task) => ({
    ...task,
    pool_info,
  }))
  return cantecClient.post(url.LINKED_POOLS, () => params)
}

const notifySpecificDrivers = (tasks, dedicated_assigned_user_id) => {
  const cleanTasks = tasks.map((task) => deepCleanObject(task))
  checkMissingRequireFields(cleanTasks)
  const mappedTasks = mapNotifyDriver(cleanTasks)

  const params = mappedTasks.map((task) => ({
    ...task,
    dedicated_assigned_user_id,
  }))
  return cantecClient.post(url.SPECIFY_ASSIGNEE, () => params)
}

const notifyCustomers = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.NOTIFY_CUSTOMERS,
  parameters,
})

const notifyPicked = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.NOTIFY_PICKED,
  parameters,
})

const print = (ids) => httpClient.post(url.ORDERS, {
  action: ACTIONS.NOTIFY_PRINTED,
  parameters: {
    ids,
  },
})

export default {
  getPendingOrders,
  getPickedUpOrders,
  countOrders,
  getCantecInfo,
  updatePickingStatus,
  getLinkedWarehouses,
  getOnlineDrivers,
  notifyDriver,
  notifyDriverInLinkWarehouses,
  notifySpecificDrivers,
  readOrdersDetail,
  notifyCustomers,
  notifyPicked,
  print,
}
