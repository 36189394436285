export const FLAGS = Object.freeze({
  ACD_11119_HIDDEN_PRODUCT_TRASH_TAB_COVA_GREENLINE: 'acd-11119-hidden-product-tab-cova-greenline',
  BS_FULFILLMENT_POLLING: 'bs_fulfillment_polling',
  BS_POINT_REWARD: 'bs-point-reward',
  BS_TIME_SLOT_PHASE_3: 'bs-time-slot-phase3',
  BS_SETTING_UI_FACELIFTING: 'ubp-setting-ui-facelifting',
  BS_CUSTOMER_UI_FACELIFTING: 'bs-customer-ui-facelifting',
  BS_ENABLE_COVA_PRODUCT: 'bs-enable-cova-product',
  ACD_11071_COOKIE_ATTRIBUTES_NOT_SET_OPTIMAL_SECURITY: 'acd-11071-cookie-attributes-not-set-optimal-security',
  BS_11731: 'bs_11731_third_party_integration',
  BS_LOCATION_FACELIFTING: 'bs_10983_location_setting_ui_facelifting',
  BS_FILTER_DATE_DEFAULT_YESTERDAY: 'bs_filter_date_default_yesterday',
  BS_PRODUCT_VARIATION_UPDATE: 'bs-product-variation-update',
  BS_UPDATE_RECEIPT_FORMAT: 'bs-update-receipt-format',
  BS_11808: 'bs_11808_greenline_blazepay_integration',
  CS_7743: 'cs-7743-warning-redirect-out',
  BS_ACD_11589: 'bs_11589_asap_cut_off_time',
  BS_ACD_11305: 'bs_11305_cova_edit_unavailable_pos_fields',
  BS_ACD_11142: 'bs_11142_cova_product_sale_price_edit',
  BS_ENABLE_2FA: 'acd-11172-two-factor-authentication',
  BS_ACD_11301: 'bs_11301_enable_edit_greenline_product',
  BS_ACD_11298: 'bs_11298_greenline_product_sale_price_edit',
  BS_EMAIL_VERIFY: 'acd-10754-email-verification-during-signup-flow',
  BS_11461: 'bs_11461_retain_location_and_period_for_user_session',
  BS_11801: 'bs_11801_set_order_note_to_public',
  BS_11554: 'bs_11554_promotion_banner_integration',
  CS_CSP_7619: 'cs-7589-custom-audio-notification',
  BS_11569: 'bs_11569_bundle_discount_promotion',
  BS_DEBUG_MODE: 'bs-debug-mode',
})
