import { useEffect, useCallback, useState } from 'react'
import get from 'lodash/get'
import sw from '~/utils/sw'
import webpushApi from '~/services/apis/webpush'
import { useAuth } from '~/modules/auth/redux/hook'
import pubsub from '~/utils/pushNotification/pubsub'

const NotificationSubscription = () => {
  const [browserSubscription, setBrowserSubscription] = useState(null)
  const { authState: { data: { userId } } } = useAuth()

  useEffect(() => {
    const getSub = async () => {
      const existedSub = await sw.pushManager.getSubscription()

      if (existedSub) {
        setBrowserSubscription(existedSub)
      } else {
        const newSub = await sw.pushManager.createSubscription()
        setBrowserSubscription(newSub)
      }
    }

    getSub().catch(() => true)
    sw.pushManager.askPermission().catch(() => true)
  }, [])

  const readAndUpdateSub = useCallback(async () => {
    if (!userId || !browserSubscription) return
    try {
      const resp = await webpushApi.read()

      const respSubscription = (resp && resp.find((s) => s.user_id === userId)) || []
      const respSubscriptionInfo = get(respSubscription, 'subscription_info', [])

      if (respSubscriptionInfo.every((sub) => sub?.endpoint !== browserSubscription?.endpoint)) {
        webpushApi.subscribe({
          subscription_info: [...respSubscriptionInfo, browserSubscription],
          subscription_topics: ['woocommerce.order.created'],
          subscription_settings: get(respSubscription, 'subscription_settings', []),
        })
      }
      pubsub.emit('subscribeSuccessfully')
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }, [browserSubscription, userId])

  useEffect(() => {
    readAndUpdateSub().catch(() => true)
  }, [readAndUpdateSub])

  return null
}

export default NotificationSubscription
