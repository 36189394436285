/* eslint-disable no-underscore-dangle */
import produce from 'immer'
import { mediaAction } from '../actions/media'
import { handleTransformListFolder } from '~/utils/helpers/media'

const initialState = {
  file: {
    list: [],
    limit: 72,
    page: 1,
    total_items: 0,
    total_pages: 0,
    error: {},
  },
  folder: {
    list: [],
    unsortedFileCount: 0,
    error: {},
  },
  loading: {
    getListFolder: false,
    getListFile: false,
    deleteFile: false,
    addNewFolder: false,
    deleteFolder: false,
    updateMetadata: false,
    editFolder: false,
    uploadFile: false,
    moveFile: 'idle',
  },
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action

  // get list folder
  if (type === mediaAction.GET_LIST_FOLDER_REQUEST) {
    draft.loading.getListFolder = true
  }

  if (type === mediaAction.GET_LIST_FOLDER_SUCCESS) {
    draft.loading.getListFolder = false
    draft.folder.unsortedFileCount = payload.unsortedFileCount
    draft.folder.list = handleTransformListFolder(payload.folders)
  }

  if (type === mediaAction.GET_LIST_FOLDER_FAILURE) {
    draft.loading.getListFolder = false
    draft.folder.error = payload.error
  }

  // get list file
  if (type === mediaAction.GET_LIST_FILE_REQUEST) {
    draft.loading.getListFile = true
  }

  if (type === mediaAction.GET_LIST_FILE_SUCCESS) {
    draft.loading.getListFile = false
    if (draft.file.page === 1) {
      draft.file.list = payload.files
    } else {
      draft.file.list = draft.file.list.concat(payload.files)
    }
    draft.file.limit = payload.limit
    draft.file.page = payload.page
    draft.file.total_items = payload.total_items
    draft.file.total_pages = payload.total_pages
  }

  if (type === mediaAction.GET_LIST_FILE_FAILURE) {
    draft.loading.getListFile = false
    draft.file.error = payload.error
  }

  // Delete file
  if (type === mediaAction.DELETE_FILE_REQUEST) {
    draft.loading.deleteFile = true
  }
  if (type === mediaAction.DELETE_FILE_SUCCESS) {
    draft.loading.deleteFile = false
    draft.file.list = draft.file.list.filter((file) => file.id !== payload.fileId)
  }
  if (type === mediaAction.DELETE_FILE_FAILURE) {
    draft.loading.deleteFile = false
    draft.error = payload.error
  }
  // Add new folder
  if (type === mediaAction.ADD_NEW_FOLDER_REQUEST) {
    draft.loading.addNewFolder = true
  }
  if (type === mediaAction.ADD_NEW_FOLDER_SUCCESS) {
    draft.loading.addNewFolder = false
  }
  if (type === mediaAction.ADD_NEW_FOLDER_FAILURE) {
    draft.loading.addNewFolder = false
    draft.error = payload.error
  }
  // Delete Folder
  if (type === mediaAction.DELETE_FOLDER_REQUEST) {
    draft.loading.deleteFolder = true
  }
  if (type === mediaAction.DELETE_FOLDER_SUCCESS) {
    draft.folder.list = draft.folder.list.filter((folder) => folder.id !== payload.folderId)
    draft.loading.deleteFolder = false
  }
  if (type === mediaAction.DELETE_FOLDER_FAILURE) {
    draft.loading.deleteFolder = false
    draft.error = payload.error
  }

  // Edit Folder
  if (type === mediaAction.EDIT_FOLDER_REQUEST) {
    draft.loading.editFolder = true
  }
  if (type === mediaAction.EDIT_FOLDER_SUCCESS) {
    draft.folder.list = draft.folder.list.map((folder) => folder.id === payload.folderId
      ? { ...folder, ...{ name: payload.folderName } }
      : folder)
    draft.loading.editFolder = false
  }
  if (type === mediaAction.EDIT_FOLDER_FAILURE) {
    draft.loading.editFolder = false
    draft.error = payload.error
  }

  if (type === mediaAction.SET_LIST_FILE_FOLDER_DATA) {
    draft.file.list = payload?.files || draft.file.list
    draft.file.page = payload?.page || draft.file.page
    draft.file.total_pages = payload.total_pages
    draft.file.total_items = payload.total_items || draft.file.total_items

    draft.folder.list = payload?.folders || draft.folder.list
    draft.loading.updateMetadata = payload.loading?.updateMetadata
    draft.loading.uploadFile = payload.loading?.uploadFile
  }

  if (type === mediaAction.MOVE_FILE_TO_FOLDER_REQUEST) {
    draft.loading.moveFile = 'loading'
  }
  if (type === mediaAction.MOVE_FILE_TO_FOLDER_SUCCESS) {
    draft.loading.moveFile = 'success'
  }

  if (type === mediaAction.MOVE_FILE_TO_FOLDER_FAILURE) {
    draft.loading.moveFile = 'failure'
  }
})

export default reducer
