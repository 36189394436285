export const DEFAULT_ATTRIBUTE_LIST_DATA = {
  items: [],
  total_items: 0,
  total_pages: 0,
}

export const DEFAULT_ATTRIBUTE_DETAIL = {
  id: '',
  name: '',
  slug: '',
  order_by: 'menu_order',
  has_archives: false,
  termList: [],
  oldTermList: [],
  type: 'select',
}
