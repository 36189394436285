import HttpJson from './json'
import HttpFormData from './formData'

const httpJson = HttpJson.create()
const httpFormData = HttpFormData.create()

const csClient = {
  json: httpJson,
  formData: httpFormData,
}

export default csClient
