import { useMemo } from 'react'
import {
  matchPath, useLocation, useNavigate, useParams,
} from 'react-router-dom'
import { useStoreHandler } from '~/modules/Prompt'
import { convertTopbarStoreOptions } from '../user'
import { useAuthenticatedStores, useGetMapUserStores, useOrganizations } from '~/redux/hooks/store'
import { ADMIN_ROUTES, FULFILLMENT_ROUTES } from '~/constants/router'
import { ADMIN_OPTIONS_TYPE } from '~/constants/adminOptions'

const useHandleChangeStore = () => {
  const location = useLocation()
  const { storeId } = useParams()
  const navigate = useNavigate()

  const storeHandler = useStoreHandler()
  const stores = useAuthenticatedStores()
  const organizations = useOrganizations()
  const mapUserStores = useGetMapUserStores()

  const storeValues = useMemo(() => convertTopbarStoreOptions(organizations, stores, mapUserStores), [organizations, stores])

  const handleChangeStore = (nextStoreId) => {
    if (storeHandler?.onChangeStoreHandler) {
      const orgIdSelected = storeValues.find((i) => i.storeId === nextStoreId).orgId
      storeHandler.onChangeStoreHandler(nextStoreId, orgIdSelected)
      return
    }

    const match = matchPath({
      path: ADMIN_ROUTES.STORE,
      end: false,
    }, location.pathname)

    // keep search param
    let search = ''
    if (matchPath({
      path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ADMIN_OPTIONS}/${ADMIN_OPTIONS_TYPE.SKU}/edit`,
      exact: true,
    }, location.pathname)) {
      search = location.search
    }

    if (matchPath({
      path: `${ADMIN_ROUTES.STORE}${FULFILLMENT_ROUTES.FULFILLMENT}`,
      exact: true,
    }, location.pathname)) {
      search = location.search
    }

    // keep location search on the same org
    if (matchPath({
      path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ADMIN_OPTIONS}/${ADMIN_OPTIONS_TYPE.SKU}`,
      exact: true,
    }, location.pathname)) {
      if (stores.find((s) => s.storeId === storeId).orgId === stores.find((s) => s.storeId === nextStoreId).orgId) {
        search = location.search
      } else {
        search = ''
      }
    }

    if (match) {
      let newURL = location.pathname
      const urlArray = location.pathname.split('/')
      newURL = `/s/${nextStoreId}/${urlArray.slice(3).join('/')}`
      navigate({
        pathname: newURL,
        search,
      })
    }
  }

  return { handleChangeStore }
}

export default useHandleChangeStore
