export const MEDIA_FILE_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
}

export const MEDIA_FOLDER_STATIC = {
  ALL_FILE: 'all_file',
  UNSORTED: 'un_sorted',
}

export const MEDIA_FILE_MODE = {
  LIST_VIEW: 'list',
  GRID_VIEW: 'grid',
}

export const FILE_INFO_MODE = {
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  GALLERY_DIALOG: 'GALLERY_DIALOG',
}

export const PARENT_MODE = {
  MEDIA: 'MEDIA',
  GALLERY: 'GALLERY',
}

export const INITIAL_FILTERS_DATA = {
  sort: {},
  search_parameter: '',
  content_type: '',
  created_from: '',
  created_to: '',
}
