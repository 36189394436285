import { PLUGIN_BASE_URL } from '~/constants/environment'
import { CANTEC_TYPE, defaultStoreParams } from '~/constants/store'
import httpClient from '~/modules/core/httpClient'
import { basePath, addHeader, jwtHeader } from '~/modules/core/httpClient/interceptors'
import { convertStoreData } from '~/utils/store'

const url = {
  STORE_CREATE: '/stores/woocommerce/create/',
  STORE_READ: '/stores/woocommerce/read/',
  STORE_DELETE: '/stores/woocommerce/delete/',
  STORE_UPDATE: '/stores/woocommerce/update/',
  [CANTEC_TYPE.CANTEC_DELIVERY]: 'wp-json/v2/mirror/cantec-delivery',
  [CANTEC_TYPE.CANTEC_LOCAL_PICKUP]: 'wp-json/v2/mirror/cantec-local-pickup',
  PLUGIN_BASE_URL: (PLUGIN_BASE_URL || '').replace(/\/+$/, ''),
  STORES: '/stores',
}

const ACTIONS = {
  READ_SS_CREDENTIALS: 'read_ss_credentials',
  BATCH_SS_CREDENTIALS: 'batch_ss_credentials',
}

const CANTEC_STATUS = {
  [CANTEC_TYPE.CANTEC_DELIVERY]: 'cantec_delivery_status',
  [CANTEC_TYPE.CANTEC_LOCAL_PICKUP]: 'cantec_local_pickup_status',
}

const CANTEC_CHANGE_STATUS = {
  [CANTEC_TYPE.CANTEC_DELIVERY]: 'enabled_disabled_cantec_delivery',
  [CANTEC_TYPE.CANTEC_LOCAL_PICKUP]: 'enabled_disabled_cantec_local_pickup',
}

const create = (data) => httpClient.post(
  url.STORE_CREATE,
  () => convertStoreData({ ...defaultStoreParams, ...data }),
)

const update = (data) => httpClient.json.post(
  url.STORE_UPDATE,
  () => convertStoreData(data),
)

const get = ({ stores_list, organization_id }) => httpClient.post(
  url.STORE_READ,
  (params) => ({
    organization_id: organization_id || params.organization_id,
    stores_list,
  }),
)

const remove = (store_id) => httpClient.post(
  url.STORE_DELETE,
  (params) => ({
    organization_id: params.organization_id,
    store_id,
  }),
)

const checkCantecStatus = ({ storeURL, type }) => httpClient.create()
  .ejectReq('basePath').useReq('basePath', basePath(storeURL))
  .ejectRes('defaultResponseHandler')
  .ejectReq('defaultHeader')
  .useReq('defaultHeader', addHeader(
    () => ({ 'Content-Type': 'multipart/form-data' }),
    jwtHeader,
  ))
  .post(url[type], () => ({ action: CANTEC_STATUS[type] }))
  .then((res) => res.data)

const changeCantecStatus = ({ storeURL, type, status }) => httpClient.create()
  .ejectReq('basePath').useReq('basePath', basePath(storeURL))
  .ejectRes('defaultResponseHandler')
  .ejectReq('defaultHeader')
  .useReq('defaultHeader', addHeader(
    () => ({ 'Content-Type': 'multipart/form-data' }),
    jwtHeader,
  ))
  .post(url[type], () => ({ action: CANTEC_CHANGE_STATUS[type], enabled: status }))
  .then((res) => res.data)

const getCantecPluginInfo = (slug) => httpClient.create()
  .ejectReq('basePath').useReq('basePath', basePath(url.PLUGIN_BASE_URL))
  .ejectRes('defaultResponseHandler')
  .ejectReq('defaultHeader')
  .useReq('defaultHeader', addHeader(
    () => ({ 'Content-Type': 'multipart/form-data' }),
    jwtHeader,
  ))
  .get(`tracker/plugins?plugin=${slug}`, () => null)
  .then((res) => res.data)
const readSSCredentials = (store_id) => httpClient.post(
  url.STORES,
  ({ organization_id }) => ({
    organization_id, store_id, action: ACTIONS.READ_SS_CREDENTIALS, parameters: {},
  }),
)

const batchSSCredentials = ({ store_id, params }) => httpClient.post(
  url.STORES,
  ({ organization_id }) => ({
    organization_id, store_id, action: ACTIONS.BATCH_SS_CREDENTIALS, parameters: params,
  }),
)

const storeApi = {
  create,
  update,
  get,
  remove,
  checkCantecStatus,
  changeCantecStatus,
  getCantecPluginInfo,
  readSSCredentials,
  batchSSCredentials,
}

export default storeApi
