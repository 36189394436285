import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useGetStore } from '~/redux/hooks/store'
import { useAuthPlugins, useAuthState } from '~/modules/auth/redux/hook'
import { STORE_TYPES } from '~/constants/store'
import RouteProvider from '~/providers/RouteProvider'

const RouteWithStore = ({ routeConfig, ...props }) => {
  const activeStore = useGetStore()
  const authState = useAuthState()
  const authPlugins = useAuthPlugins()

  const readOnly = routeConfig.editRoles && activeStore?.storeId
    && !routeConfig.editRoles(activeStore?.storeId, authPlugins)(authState.data)

  const renderComponent = useCallback((routeProps) => {
    const rProps = {
      ...routeProps.match?.params,
      ...routeConfig.xProps,
      url: routeProps.location?.pathname,
      path: routeProps.match?.path,
      store: activeStore,
      readOnly,
    }
    switch (activeStore?.storeType) {
      case STORE_TYPES.COVA:
        if (routeConfig.covaComponent) {
          return (
            <RouteProvider value={rProps}>
              <routeConfig.covaComponent {...rProps} {...routeProps} />
            </RouteProvider>
          )
        }
      // eslint-disable-next-line no-fallthrough
      case STORE_TYPES.GREENLINE:
        if (routeConfig.greenlineComponent) {
          return (
            <RouteProvider value={rProps}>
              <routeConfig.greenlineComponent {...rProps} {...routeProps} />
            </RouteProvider>
          )
        }
      // eslint-disable-next-line no-fallthrough
      case STORE_TYPES.SHOPIFY:
        if (routeConfig.shopifyComponent) {
          return (
            <RouteProvider value={rProps}>
              <routeConfig.shopifyComponent {...rProps} {...routeProps} />
            </RouteProvider>
          )
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return (
          <RouteProvider value={rProps}>
            <routeConfig.component {...rProps} {...routeProps} />
          </RouteProvider>
        )
    }
  }, [activeStore, routeConfig, readOnly])

  return (
    <>
      {renderComponent(props)}
    </>
  )
}

RouteWithStore.propTypes = {
  routeConfig: PropTypes.shape().isRequired,
}

export default RouteWithStore
