import { lazyRetry } from '~/utils/lazyRetry'
import {
  ADMIN_ROUTES,
  TAG_ROUTES,
} from '~/constants/router'

import { productRolesEditable } from '~/modules/auth/conditions'

const TagList = lazyRetry(() => import('~/pages/Tag/TagList'))
const TagForm = lazyRetry(() => import('~/pages/Tag/TagForm'))

const TagRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${TAG_ROUTES.LIST}`,
    component: TagList,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    routeName: 'Tags List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${TAG_ROUTES.EDIT}`,
    component: TagForm,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    xProps: {
      isEditing: true,
    },
    routeName: 'Tag Edit',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${TAG_ROUTES.CREATE}`,
    component: TagForm,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    routeName: 'Tag Create',
  },
]

export default TagRouters
