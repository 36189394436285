import { htmlDecode } from './utility'

/* eslint-disable no-param-reassign */
export const netLevelCategoryList = (inputList) => {
  const list = JSON.parse(JSON.stringify(inputList))

  list.swap = function swap(index1, index2) {
    const temp = list[index1]
    list[index1] = list[index2]
    list[index2] = temp
  }
  let flag = 0
  const convertArray = (parentId = 0, level = 1) => {
    for (let i = flag; i < list.length; i += 1) {
      if (list[i].parent === parentId) {
        const currentId = list[i].id
        list[i].level = level
        if (flag > 0 && list[flag - 1].id === list[i].parent) list[flag - 1].expandable = true

        list.swap(flag, i)

        if (i < flag) i = flag
        flag += 1
        convertArray(currentId, level + 1)
      }
    }
  }

  convertArray()
  delete list.swap

  return list
}

export const sortCategoryList = (list, sortInfo) => {
  const { sortId, desc } = sortInfo
  let orderedList = [...list]
  if (desc === -1) { // desc === -1 => z-a
    orderedList = orderedList.sort((a, b) => {
      const result = `${b[sortId]}`.localeCompare(`${a[sortId]}`)
      return result !== 0 || sortId === 'name' ? result : `${b.name}`.localeCompare(`${a.name}`)
    })
  } else { // desc !== -1 => a-z
    orderedList = orderedList.sort((a, b) => {
      const result = `${a[sortId]}`.localeCompare(`${b[sortId]}`)
      return result !== 0 || sortId === 'name' ? result : `${a.name}`.localeCompare(`${b.name}`)
    })
  }
  return orderedList
}

export const categoryListBuilder = (list) => {
  const tree = (list || []).reduce((acc, item) => {
    // eslint-disable-next-line no-param-reassign
    acc[item.parent] = (acc[item.parent] || []).concat(item)
    return acc
  }, {})

  const builder = (parent = 0, p = 0) => {
    if (!tree[parent]) return []
    const sortedList = sortCategoryList(tree[parent], { sortId: 'name', desc: 1 })
    return sortedList.reduce((acc, item) => acc.concat([{ ...item, p }]).concat(builder(item.id, p + 1)), [])
  }

  return builder().map((item) => ({
    value: item.id,
    label: htmlDecode((Array(item.p).fill('—')).join('') + item.name.replace('&amp;', '&')),
  }))
}

// Logic for multiple levels sorting

const nesten = (arr, parent = 0, level = 1) => arr.reduce((acc, cur) => {
  if (cur.parent === parent) {
    const children = nesten(arr, cur.id, level + 1)

    if (children.length) {
      acc.push({ ...cur, level, children })
    } else {
      acc.push({ ...cur, level })
    }
  }
  return acc
}, [])

const flatten = (arr) => arr.reduce((acc, cur) => {
  if (cur.children) {
    return [...acc, { ...cur, expandable: true }, ...flatten(cur.children)]
  }
  return [...acc, cur]
}, [])

const sortOneLevel = (arr, sortId, desc) => {
  const output = [...arr]

  output.sort((a, b) => {
    let aVal = a[sortId]
    let bVal = b[sortId]

    if (typeof aVal === 'string') {
      aVal = aVal.toLowerCase()
      bVal = bVal.toLowerCase()
    }

    if (aVal === bVal) return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
    return (aVal < bVal ? -desc : desc)
  })

  return output
}

const sortMultipleLevels = (arr, sortId, desc) => {
  const output = sortOneLevel(arr, sortId, desc)

  output.forEach((el) => {
    if (el.children) {
      output[output.findIndex((x) => x.id === el.id)] = { ...el, children: sortMultipleLevels(el.children, sortId, desc) }
    }
  })

  return output
}

export const sortDeepCategoryList = (list, sortInfo) => {
  const { sortId, desc } = sortInfo
  const nestedList = nesten(JSON.parse(JSON.stringify(list)))
  const sortedList = sortMultipleLevels(nestedList, sortId, desc)
  const flattedList = flatten(sortedList)

  return flattedList
}

export const makeAtlas = (items) => (items || []).reduce((acc, item) => {
  if (acc[item.parent]) {
    acc[item.parent] = {
      ...acc[item.parent],
      childs: [...acc[item.parent].childs, item.id],
      children: [...acc[item.parent].children, item],
    }
  } else {
    acc[item.parent] = {
      ...acc[item.parent],
      childs: [item.id],
      children: [item],
    }
  }
  acc[item.id] = {
    ...acc[item.id],
    ...item,
    ...(acc[item.id]?.childs ? {} : { childs: [] }),
    ...(acc[item.id]?.children ? {} : { children: [] }),
  }
  return acc
}, {})

export const nestCategories = (atlas, options) => {
  const {
    startId, level, sortId, desc, keyword,
  } = options
  // handle sort
  let sortedChildsList = (atlas[startId]?.childs || []).map((cId) => atlas[cId])
  if (sortId && desc) {
    sortedChildsList = sortOneLevel(sortedChildsList, sortId, desc)
  }

  // handle parent case
  const parentCate = (startId !== 0) && {
    ...atlas[startId],
    level,
    ...(atlas[startId]?.childs?.length ? { expandable: true } : { expandable: false }),
  }

  const matchedCondition = !keyword || (startId > 0 && parentCate?.name && parentCate.name.toLowerCase().includes((keyword || '').toLowerCase()))

  // handle child case
  let childrenCases = []
  if (atlas[startId]?.childs?.length) {
    childrenCases = sortedChildsList.reduce((cates, c) => cates.concat(nestCategories(atlas, {
      startId: c.id,
      level: level + 1,
      sortId,
      desc,
      keyword: !matchedCondition ? keyword : undefined,
    })), [])
  }
  if (startId === 0) return childrenCases
  return matchedCondition || childrenCases.length > 0 ? (parentCate ? [parentCate] : []).concat(childrenCases) : []
}
