import produce from 'immer'
import { ONFLEET_ACTION } from '~/redux/actions/onfleet'
import { DEFAULT_ONFLEET_STORE_DETAIL } from '../default/onfleet'

const initialState = {
  loadingOrg: false,
  loadingStore: false,
  onfleetFulfillmentLoading: false,
  error: '',
  data: [],
  onfleetProcessingData: [],
  updatingOrg: false,

  detail: DEFAULT_ONFLEET_STORE_DETAIL,
  loadingDetail: false,
  errorDetail: null,
  loadingForm: false,
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ONFLEET_ACTION.FETCH_LIST: {
      draft.loadingOrg = true
      draft.loadingStore = true
      draft.error = ''
      break
    }
    case ONFLEET_ACTION.SET_LIST_DATA: {
      draft.loadingOrg = false
      draft.loadingStore = false
      draft.data = action.payload
      draft.error = ''
      break
    }
    case ONFLEET_ACTION.SET_LIST_ERROR: {
      draft.loadingOrg = false
      draft.loadingStore = false
      draft.error = action.payload
      break
    }
    case ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL:
      draft.loadingDetail = true
      draft.errorDetail = null
      break
    case ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL_OK:
      draft.detail = action.payload
      draft.loadingDetail = false
      break
    case ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL_ERR:
      draft.loadingDetail = false
      draft.errorDetail = action.payload
      break
    case ONFLEET_ACTION.CLEAR_ONFLEET_STORE_DETAIL:
      draft.loadingForm = false
      draft.detail = action.payload
      break

    case ONFLEET_ACTION.UPDATE_ONFLEET_STORE:
      draft.loadingForm = true
      draft.loadingStore = true
      draft.errorDetail = ''
      break
    case ONFLEET_ACTION.UPDATE_ONFLEET_STORE_OK:
      draft.loadingForm = false
      draft.loadingStore = false
      draft.data = draft.data.map((item) => {
        if (item._id === action.payload._id) { return { ...item, ...action.payload } }
        return item
      })
      break
    case ONFLEET_ACTION.UPDATE_ONFLEET_STORE_FAIL:
      draft.loadingForm = false
      draft.loadingStore = false
      break

    case ONFLEET_ACTION.FETCH_ONFLEET_PROCESSING: {
      draft.onfleetFulfillmentLoading = true
      draft.error = ''
      break
    }
    case ONFLEET_ACTION.REFETCH_ONFLEET_PROCESSING: {
      draft.onfleetFulfillmentLoading = false
      break
    }
    case ONFLEET_ACTION.SET_ONFLEET_PROCESSING_DATA: {
      draft.onfleetFulfillmentLoading = false
      draft.onfleetProcessingData = action.payload
      break
    }
    case ONFLEET_ACTION.SET_ONFLEET_PROCESSING_ERROR: {
      draft.onfleetProcessingData = []
      draft.onfleetFulfillmentLoading = false
      break
    }
    default:
      break
  }
})

export default reducer
