export const DEFAULT_ZONE = 'Locations not covered by your other zones'

export const handleLocationsData = (locations, locationList) => {
  if (locations && locations.length > 0) {
    return locations.map((location) => {
      switch (location.type) {
        case 'country':
          const countryItem = locationList && locationList.find((e) => e.value === location.code)
          return countryItem && countryItem.label
        case 'state':
          const listLocationCode = location.code.split(':')
          if (listLocationCode.length === 2) {
            const countryObj = locationList && locationList.find((e) => e.value === listLocationCode[0])
            const stateObj = (countryObj?.states || []).find((x) => x.value === listLocationCode[1]) || {}
            return stateObj.label
          }
          return ''
        case 'postcode':
          return location.code
        default:
          return ''
      }
    }).join(', ')
  }
  return '-'
}

const handleShippingMethods = (methods) => {
  if (methods && methods.length) {
    return methods
      .sort((a, b) => a.order - b.order)
      .map((method) => method.settings?.title?.value || method.title || method.method_title).join(', ')
  }
  return '-'
}

export const handleShippingZonesData = (data) => {
  const newData = data
    .sort((a, b) => a.order - b.order)
    .map((item) => (
      {
        id: item.id,
        name: item.name,
        locations: item.locations,
        methods: handleShippingMethods(item.shipping_methods),
        priority: item.order,
      }
    ))

  const defaultZone = newData.filter((e) => e.name === DEFAULT_ZONE)
  return newData.filter((e) => e.name !== DEFAULT_ZONE).concat(defaultZone)
}

export const getStateCode = (countryCode, stateLabel, locationList) => {
  const countryObj = locationList.find((c) => c.value === countryCode)
  if (countryObj) {
    const stateObj = countryObj.states.find((s) => s.label === stateLabel)
    return stateObj.value
  }
  return ''
}

export const getStateLabel = (codes, locationList) => {
  const countryCode = codes.split(':')[0] || ''
  const stateCode = codes.split(':')[1] || ''
  const stateObj = locationList.find((c) => c.value === countryCode)
  if (stateObj) {
    return stateObj.states.find((s) => s.value === stateCode)?.label
  }
  return ''
}

export const getCountryLabel = (countryCode, locationList) => {
  const countryObj = locationList.find((c) => c.value === countryCode)
  if (countryObj) {
    return countryObj.label
  }
  return ''
}

export const convertMethodToUpdate = ({ settings }) => {
  const { type, ...otherSettings } = settings
  const { title } = settings
  const convertedSettings = {}
  Object.keys(otherSettings).forEach((key) => {
    convertedSettings[key] = otherSettings[key].value
  })
  return ({
    title: title.value,
    settings: convertedSettings,
    ...(type && type.value ? { type: type.value } : {}),
  })
}
