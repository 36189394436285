import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  TAGS: '/stores/woocommerce/products/tags/',
  ORGANIZATION_TAGS: 'organizations/tags',
}

const create = (tag) => httpClient.create()
  .ejectRes('defaultResponseErrorHandler')
  .useRes('defaultResponseErrorHandler', undefined, (error) => {
    if (error.response && error.response.data) {
      throw error.response.data
    }
    throw error.response.data.message
  })
  .post(url.TAGS, {
    action: ACTIONS.CREATE,
    parameters: { item_data: tag },
  })
  .catch((e) => {
    throw e
  })

const findOne = (id) => httpClient.post(url.TAGS, {
  action: ACTIONS.READ,
  parameters: { id },
})

const getList = (params) => httpClient.post(url.TAGS, {
  action: ACTIONS.PAGINATE,
  parameters: params,
})

const getShopifyList = (storeId, keyword) => httpClient.get(`shopify/stores/${storeId}/product_tags?search_parameter=${keyword}`)

const getAllList = (params = {}) => httpClient.post(url.TAGS, {
  action: ACTIONS.LIST_ALL,
  parameters: params,
})

const removeOne = (id) => httpClient.post(url.TAGS, {
  action: ACTIONS.DELETE,
  parameters: { id },
})

const updateOne = (tagParams) => httpClient.post(url.TAGS, {
  action: ACTIONS.UPDATE,
  parameters: {
    item_data: tagParams,
  },
})

const batchUpdate = (params) => httpClient.post(url.TAGS, {
  action: ACTIONS.BATCH_UPDATE,
  parameters: params,
})

const getOrganizationTags = (params) => httpClient.post(url.ORGANIZATION_TAGS, {
  action: ACTIONS.GET_ALL,
  parameters: params,
})

const tagApi = {
  create,
  findOne,
  updateOne,
  getList,
  getShopifyList,
  removeOne,
  batchUpdate,
  getAllList,
  getOrganizationTags,
}

export default tagApi
