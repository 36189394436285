/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { focusManager } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { useAuth } from '~/modules/auth/redux/hook'
import authApi from '~/modules/auth/services'
import logger from '~/utils/logger'
import LoadingLinear from '~/components/common/LoadingLinear'
import { useCheckSession } from './hooks'
import { get as getMemoryStorage, set as setMemoryStorage, clear as clearMemoryStorage } from '~/utils/storage/memoryStorage'
import { FLAGS } from '~/constants/flags'
import { AUTH_TYPE, COOKIE_KEYS } from '~/constants/auth'
import { useWrapSSOContext } from '../WrapSSOProvider/hook'
import { ADMIN_ROUTES } from '~/constants/router'
import { useUser } from '~/redux/hooks/user'
import { SSO_COOKIE_DOMAIN } from '~/constants/environment'
import { AUTH_STATUS } from '~/modules/auth/redux/reducer'

const SSOProvider = ({ children }) => {
  const { actions, authState } = useAuth()
  const { loading: userLoading } = useUser()
  const isSSO = useFeatureIsOn(FLAGS.ACD_11071_COOKIE_ATTRIBUTES_NOT_SET_OPTIMAL_SECURITY)
  const { setIsHandlingSSO, isHandlingSSO } = useWrapSSOContext()

  const [firstVerifying, setFirstVerifying] = useState(true)
  const reduxFirebaseUserEmail = useMemo(() => authState?.data?.userEmail, [authState?.data?.userEmail])
  const isEnabledCheckSession = authState.status !== AUTH_STATUS.PROCESSING && !userLoading

  const handleSSO = useCallback(() => {
    const dataToken = getMemoryStorage(COOKIE_KEYS.DATA_TOKEN)
    const whiteList = [...Object.values(AUTH_TYPE), '/invitation']
    const isNotInWhiteList = whiteList.every((url) => url !== window.location.pathname)
    const firebaseUserId = authApi.getUserFirebaseId()

    if (!dataToken && !firebaseUserId) {
      logger.info('sso case 1')
      setFirstVerifying(false)
      if (isNotInWhiteList) {
        actions.signOut({ isSSO })
      }
      return
    }

    if (!dataToken) {
      logger.info('sso case 2')
      setFirstVerifying(false)
      if (isNotInWhiteList) {
        window.location.replace(ADMIN_ROUTES.LOGOUT)
      }
      return
    }

    if (!dataToken?.customClaims?.is_breadstack) {
      logger.info('sso case 3')
      actions.ssoRedirectSignUp({ email: dataToken?.email, onFinal: () => setFirstVerifying(false) })
      return
    }

    const isSignInWithToken = () => {
      const isException = [].includes(window.location.pathname)
      if (isException) return false
      if (!firebaseUserId) return true // chua login bs
      if (dataToken?.isIdTokenValid === false) return true // token invalid do change password va login lai luon
      if (dataToken?.uid !== firebaseUserId) return true // token login uid !== bs
      if (dataToken?.uid === firebaseUserId && !authState?.data) return true // da login bs, open new tab => login firebase nhung auth redux chua co
      if (!!reduxFirebaseUserEmail && dataToken?.email !== reduxFirebaseUserEmail) return true // 2tab, 1tab da login bs moi, redux tab cu van luu cache uid
      return false
    }

    if (isSignInWithToken()) {
      logger.info('sso case 4')
      actions.ssoSignInWithToken({ customToken: dataToken?.customToken, onFinal: () => setFirstVerifying(false) })
      return
    }

    logger.info('sso case 5')
    setFirstVerifying(false)
  }, [reduxFirebaseUserEmail, isSSO, authState?.data])

  const handleRemoveOldSSO = () => {
    const customKey = Cookies.get(COOKIE_KEYS.CUSTOM_TOKEN) || ''
    const refreshKey = Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || ''

    if (customKey || refreshKey) {
      Cookies.remove(COOKIE_KEYS.CUSTOM_TOKEN, {
        domain: SSO_COOKIE_DOMAIN,
      })
      Cookies.remove(COOKIE_KEYS.REFRESH_TOKEN, {
        domain: SSO_COOKIE_DOMAIN,
      })
    }
  }

  useCheckSession({
    onStart: () => setIsHandlingSSO(true),
    onSuccess: (data) => {
      if (isEnabledCheckSession) {
        setMemoryStorage(COOKIE_KEYS.DATA_TOKEN, data)
        handleSSO()
      }
    },
    onError: (e) => {
      clearMemoryStorage(COOKIE_KEYS.DATA_TOKEN)
      if (e === 'Request aborted') {
        setFirstVerifying(false)
      } else if (isEnabledCheckSession) {
        handleSSO()
      }
    },
    onSettled: () => setIsHandlingSSO(false),
    enabled: ![ADMIN_ROUTES.LOGOUT, AUTH_TYPE.REDIRECT_USER].includes(window.location.pathname),
  })

  useEffect(() => {
    /* The reason is because logout page, redirect user page not call check session
      => firstVerifying = true
      => when page focus will loading linear one time
    */
    if ([ADMIN_ROUTES.LOGOUT, AUTH_TYPE.REDIRECT_USER].includes(window.location.pathname) && firstVerifying) {
      setFirstVerifying(false)
    }

    handleRemoveOldSSO()

    const onFocus = async () => {
      focusManager.setFocused(true)
      setIsHandlingSSO(true)
    }

    window.addEventListener('focus', onFocus)
    return () => {
      focusManager.setFocused(undefined)
      window.removeEventListener('focus', onFocus)
    }
  }, [firstVerifying, isHandlingSSO])

  logger.info({ firstVerifying, authStatus: authState.status, userLoading }, window.location.pathname)

  return firstVerifying && window.location.pathname !== ADMIN_ROUTES.LOGOUT ? <LoadingLinear /> : (<>{children}</>)
}

export default SSOProvider
