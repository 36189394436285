import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {
  ANALYTIC_ROUTES, ADMIN_ROUTES, COVA_INSIGHTS_ROUTES, GREENLINE_INSIGHTS_ROUTES,
} from '~/constants/router'
import { analyticRoles } from '~/modules/auth/conditions'
import { urlWithStoreID } from '~/utils/common'
import { lazyRetry } from '~/utils/lazyRetry'

const Board = lazyRetry(() => import('~/pages/Analytic/Board'))
const CustomReportBuilder = lazyRetry(() => import('~/pages/Analytic/CustomReportBuilder'))
const DefaultReport = lazyRetry(() => import('~/pages/Analytic/DefaultReport/DefaultReport'))
const CustomReportEdit = lazyRetry(() => import('~/pages/Analytic/CustomReportEdit'))
const CustomReport = lazyRetry(() => import('~/pages/Analytic/CustomReport'))
const ExportPDF = lazyRetry(() => import('~/pages/Analytic/ExportPDF'))
const GreenlineDefaultReport = lazyRetry(() => import('~/pages/Analytic/DefaultReport/GreenlineDefaultReport'))
const CovaDefaultReport = lazyRetry(() => import('~/pages/Analytic/DefaultReport/CovaDefaultReport'))

const RedirectInsight = () => {
  const { storeId } = useParams()
  return <Navigate to={urlWithStoreID('/insights/report/inventory-report', storeId)} />
}
const RedirectCovaInsight = () => {
  const { storeId } = useParams()
  return <Navigate to={urlWithStoreID('/insights/sales', storeId)} />
}
const RedirectGreenlineInsight = () => {
  const { storeId } = useParams()
  return <Navigate to={urlWithStoreID('/insights/greenline/sales', storeId)} />
}

const AnalyticRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${ANALYTIC_ROUTES.REPORT_DASHBOARD}`,
    component: Board,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Analytics',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ANALYTIC_ROUTES.REPORT_CREATING}`,
    component: CustomReportBuilder,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Analytic Creating',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ANALYTIC_ROUTES.REPORT_DEFAULT}`,
    component: DefaultReport,
    covaComponent: RedirectCovaInsight,
    greenlineComponent: RedirectGreenlineInsight,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Default Report',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ANALYTIC_ROUTES.REPORT_VIEW}`,
    component: CustomReport,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Custom Report View',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ANALYTIC_ROUTES.REPORT_EDIT}`,
    component: CustomReportEdit,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Custom Report View',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ANALYTIC_ROUTES.EXPORT_PDF}`,
    component: ExportPDF,
    layout: 'admin',
    exact: true,
    roles: true,
    routeName: 'Export PDF',
  },
  // Cova Insights routes
  // Navigate with orthers store types
  {
    path: `${ADMIN_ROUTES.STORE}${COVA_INSIGHTS_ROUTES.REPORT_DEFAULT}`,
    component: RedirectInsight,
    covaComponent: CovaDefaultReport,
    greenlineComponent: RedirectGreenlineInsight,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Cova Default Report',
  },
  // Greenline Insights routes
  // Navigate with orthers store types
  {
    path: `${ADMIN_ROUTES.STORE}${GREENLINE_INSIGHTS_ROUTES.REPORT_DEFAULT}`,
    component: RedirectInsight,
    covaComponent: RedirectCovaInsight,
    greenlineComponent: GreenlineDefaultReport,
    layout: 'admin',
    exact: true,
    roles: analyticRoles,
    routeName: 'Greenline Default Report',
  },
]

export default AnalyticRouters
