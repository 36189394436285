import {
  takeLatest,
  put,
  fork,
  call,
  takeEvery,
} from 'redux-saga/effects'
import inventoryWarehousesApis from '~/services/apis/inventoryWarehouses'
import { inventoryWarehousesAction, getInventoryWarehouseCarryingDetail } from '~/redux/actions/inventoryWarehouses'
import { ACTIONS } from '~/services/apis/common'
import tagApi from '~/services/apis/tag'
import { convertInventoryTags } from '~/utils/inventoryWarehouses'

export function* getInventoryWarehouses(action) {
  try {
    const response = yield call(inventoryWarehousesApis.get, action.payload.params)
    yield put({
      type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES_OK,
      payload: response,
      tab: action.payload.tab,
    })
    action.callbacks.onSuccess()
  } catch (error) {
    action.callbacks.onError(error)
    yield put({
      type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES_ERR,
      payload: error,
      tab: action.payload.tab,
    })
  }
}

export function* getCarryStoresData(action) {
  const { payload = {}, callbacks = {} } = action
  const { onError } = callbacks
  try {
    const carryingStoreRes = yield call(inventoryWarehousesApis.getDetail, {
      ...payload.params,
      action: ACTIONS.READ_CARRY_STORES,
      params: {
        // eslint-disable-next-line no-underscore-dangle
        _id: payload.params._id,
      },
    })
    yield put({
      type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE_OK,
      payload: carryingStoreRes,
    })
  } catch (error) {
    yield put({
      type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE_ERR,
      payload: error,
    })
    onError(error)
  }
}

export function* getInventoryWarehouseDetail(action) {
  const { payload = {}, callbacks = {} } = action
  const { onError } = callbacks
  try {
    const response = yield call(inventoryWarehousesApis.getDetail, payload.params)
    const { _id } = response
    yield put({
      type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL_OK,
      payload: response,
    })
    yield put(getInventoryWarehouseCarryingDetail({ ...payload.params, _id }, {
      onSuccess: () => {},
      onError: () => {},
    }))
  } catch (error) {
    yield put({
      type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL_ERR,
      payload: error,
    })
    onError(error)
  }
}

export function* addNewInventoryWarehouses(action) {
  try {
    const response = yield call(inventoryWarehousesApis.create, action.payload.params)
    yield put({
      type: inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT_ERR,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* updateInventoryWarehouses(action) {
  const { payload = {}, callbacks = {} } = action
  const { onSuccess = () => {}, onError = () => {} } = callbacks
  try {
    const response = yield call(inventoryWarehousesApis.update, payload.params)
    yield put({
      type: inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT_OK,
      payload: response,
    })
    onSuccess(response)
  } catch (error) {
    yield put({
      type: inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT_ERR,
      payload: error,
    })
    onError(error)
  }
}

export function* deleteInventoryWarehousesItem(action) {
  const { payload = {}, callbacks = {} } = action
  const { onSuccess = () => {}, onError = () => {} } = callbacks
  try {
    const response = yield call(inventoryWarehousesApis.deleteDetail, payload.params)
    yield put({
      type: inventoryWarehousesAction.DELETE_INVENTORY_WAREHOUSE_DETAIL_OK,
      payload: response,
    })
    onSuccess(response)
  } catch (error) {
    yield put({
      type: inventoryWarehousesAction.DELETE_INVENTORY_WAREHOUSE_DETAIL_ERR,
      payload: error,
    })
    onError(error)
  }
}

export function* getInventoryTags(action) {
  try {
    const response = yield call(tagApi.getOrganizationTags, action.payload.params)
    yield put({
      type: inventoryWarehousesAction.GET_ALL_TAGS_OK,
      payload: convertInventoryTags(response),
    })
    action.callbacks.onSuccess()
  } catch (error) {
    action.callbacks.onError(error)
    yield put({
      type: inventoryWarehousesAction.GET_ALL_TAGS_ERR,
      payload: error,
    })
  }
}

export function* watchGetInventoryWarehousesData() {
  yield takeEvery(inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES, getInventoryWarehouses)
}

export function* watchGetInventoryWarehouseDetail() {
  yield takeLatest(inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL, getInventoryWarehouseDetail)
}

export function* watchGetCarryingStore() {
  yield takeLatest(inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE, getCarryStoresData)
}

export function* watchUpdateInventoryWarehouseDetail() {
  yield takeLatest(inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT, updateInventoryWarehouses)
}

export function* watchAddNewInventoryWarehouses() {
  yield takeLatest(inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT, addNewInventoryWarehouses)
}

export function* watchDeleteInventoryWarehousesItem() {
  yield takeLatest(inventoryWarehousesAction.DELETE_INVENTORY_WAREHOUSE_DETAIL, deleteInventoryWarehousesItem)
}

export function* watchGetInventoryTag() {
  yield takeLatest(inventoryWarehousesAction.GET_ALL_TAGS, getInventoryTags)
}

export default function* inventoryWarehouses() {
  yield fork(watchGetInventoryWarehousesData)
  yield fork(watchAddNewInventoryWarehouses)
  yield fork(watchGetInventoryWarehouseDetail)
  yield fork(watchGetCarryingStore)
  yield fork(watchUpdateInventoryWarehouseDetail)
  yield fork(watchDeleteInventoryWarehousesItem)
  yield fork(watchGetInventoryTag)
}
