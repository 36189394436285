export const USER_ACTION = {
  SIGN_OUT: 'user.SIGN_OUT',
  UPDATE_SETTINGS: 'user.UPDATE_SETTINGS',
  UPDATE_SETTINGS_OK: 'user.UPDATE_SETTINGS_OK',
  UPDATE_SETTINGS_ERR: 'user.UPDATE_SETTINGS_ERR',

  UPDATE_ORGANIZATION: 'user.UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_SUCCESS: 'user.UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILED: 'user.UPDATE_ORGANIZATION_FAILED',

  GET_ORG: 'user.GET_ORG',
  GET_ORG_SUCCESS: 'user.GET_ORG_SUCCESS',
  GET_ORG_FAILED: 'user.GET_ORG_FAILED',
  GET_STORES: 'user.GET_STORES',
  GET_STORES_SUCCESS: 'user.GET_STORES_SUCCESS',
  GET_STORES_FAILED: 'user.GET_STORES_FAILED',

  REMOVE_STORE: 'user.REMOVE_STORE',
  REMOVE_STORE_SUCCESS: 'user.REMOVE_STORE_SUCCESS',
  REMOVE_STORE_FAILED: 'user.REMOVE_STORE_FAILED',

  LINK_STORE: 'user.LINK_STORE',
  LINK_WOO_STORE: 'user.LINK_WOO_STORE',
  LINK_STORE_SUCCESS: 'user.LINK_STORE_SUCCESS',
  LINK_STORE_FAILED: 'user.LINK_STORE_FAILED',

  LINK_COVA_STORE: 'user.LINK_COVA_STORE',
  LINK_COVA_STORE_SUCCESS: 'user.LINK_COVA_STORE_SUCCESS',
  LINK_COVA_STORE_FAILED: 'user.LINK_COVA_STORE_FAILED',

  UPDATE_STORE: 'user.UPDATE_STORE',
  UPDATE_STORE_SUCCESS: 'user.UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILED: 'user.UPDATE_STORE_FAILED',

  REMOVE_WAREHOUSE: 'user.REMOVE_WAREHOUSE',
  REMOVE_WAREHOUSE_SUCCESS: 'user.REMOVE_WAREHOUSE_SUCCESS',
  REMOVE_WAREHOUSE_FAILED: 'user.REMOVE_WAREHOUSE_FAILED',
  UPDATE_WAREHOUSE: 'user.UPDATE_WAREHOUSE',
  UPDATE_WAREHOUSE_SUCCESS: 'user.UPDATE_WAREHOUSE_SUCCESS',
  UPDATE_WAREHOUSE_FAILED: 'user.UPDATE_WAREHOUSE_FAILED',
  UPDATE_WAREHOUSE_ZONE_COUNTING: 'user.UPDATE_WAREHOUSE_ZONE_COUNTING',
  CREATE_WAREHOUSE: 'user.CREATE_WAREHOUSE',
  CREATE_WAREHOUSE_SUCCESS: 'user.CREATE_WAREHOUSE_SUCCESS',
  CREATE_WAREHOUSE_FAILED: 'user.CREATE_WAREHOUSE_FAILED',

}

export const updateSetting = (payload) => ({
  type: USER_ACTION.UPDATE_SETTINGS,
  payload,
})

export const updateSettingSuccess = (payload) => ({
  type: USER_ACTION.UPDATE_SETTINGS_OK,
  payload,
})

export const updateSettingFail = (payload) => ({
  type: USER_ACTION.UPDATE_SETTINGS_ERR,
  payload,
})

export const updateOrganization = (payload) => ({
  type: USER_ACTION.UPDATE_ORGANIZATION,
  payload,
})

export const updateOrganizationSuccess = (payload) => ({
  type: USER_ACTION.UPDATE_ORGANIZATION_SUCCESS,
  payload,
})

export const updateOrganizationFailed = (payload) => ({
  type: USER_ACTION.UPDATE_ORGANIZATION_FAILED,
  payload,
})

export const getOrg = (payload) => ({
  type: USER_ACTION.GET_ORG,
  payload,
})

export const getOrgSuccess = (payload) => ({
  type: USER_ACTION.GET_ORG_SUCCESS,
  payload,
})

export const getOrgFailed = (payload) => ({
  type: USER_ACTION.GET_ORG_FAILED,
  payload,
})

export const getStores = (payload) => ({
  type: USER_ACTION.GET_STORES,
  payload,
})

export const getStoresSuccess = (payload) => ({
  type: USER_ACTION.GET_STORES_SUCCESS,
  payload,
})

export const getStoresFailed = (payload) => ({
  type: USER_ACTION.GET_STORES_FAILED,
  payload,
})

export const removeStore = (payload) => ({
  type: USER_ACTION.REMOVE_STORE,
  payload,
})

export const removeStoreSuccess = (payload) => ({
  type: USER_ACTION.REMOVE_STORE_SUCCESS,
  payload,
})

export const removeStoreFailed = (payload) => ({
  type: USER_ACTION.REMOVE_STORE_FAILED,
  payload,
})

export const linkStore = (payload, onSuccess) => ({
  type: USER_ACTION.LINK_STORE,
  payload,
  onSuccess,
})

export const linkWooStore = (payload, onSuccess) => ({
  type: USER_ACTION.LINK_WOO_STORE,
  payload,
  onSuccess,
})

export const linkStoreSuccess = (payload) => ({
  type: USER_ACTION.LINK_STORE_SUCCESS,
  payload,
})

export const linkStoreFailed = (payload) => ({
  type: USER_ACTION.LINK_STORE_FAILED,
  payload,
})

export const updateStore = (payload, onSuccess, onError) => ({
  type: USER_ACTION.UPDATE_STORE,
  payload,
  onSuccess,
  onError,
})

export const updateStoreSuccess = (payload) => ({
  type: USER_ACTION.UPDATE_STORE_SUCCESS,
  payload,
})

export const updateStoreFailed = (payload) => ({
  type: USER_ACTION.UPDATE_STORE_FAILED,
  payload,
})

export const removeWarehouse = (payload) => ({
  type: USER_ACTION.REMOVE_WAREHOUSE,
  payload,
})

export const removeWarehouseSuccess = (payload) => ({
  type: USER_ACTION.REMOVE_WAREHOUSE_SUCCESS,
  payload,
})

export const removeWarehouseFailed = (payload) => ({
  type: USER_ACTION.REMOVE_WAREHOUSE_FAILED,
  payload,
})

export const updateWarehouse = (payload) => ({
  type: USER_ACTION.UPDATE_WAREHOUSE,
  payload,
})

export const updateWarehouseZoneCounting = (warehouseId, areaType, deliveryAreaCounting) => ({
  type: USER_ACTION.UPDATE_WAREHOUSE_ZONE_COUNTING,
  payload: {
    warehouse_id: warehouseId,
    area_type: areaType,
    delivery_area_counting: deliveryAreaCounting,
  },
})

export const updateWarehouseSuccess = (payload) => ({
  type: USER_ACTION.UPDATE_WAREHOUSE_SUCCESS,
  payload,
})

export const updateWarehouseFailed = (payload) => ({
  type: USER_ACTION.UPDATE_WAREHOUSE_FAILED,
  payload,
})

export const createWarehouse = (payload) => ({
  type: USER_ACTION.CREATE_WAREHOUSE,
  payload,
})

export const createWarehouseSuccess = (payload) => ({
  type: USER_ACTION.CREATE_WAREHOUSE_SUCCESS,
  payload,
})

export const createWarehouseFailed = (payload) => ({
  type: USER_ACTION.CREATE_WAREHOUSE_FAILED,
  payload,
})

export const linkCovaStore = (payload, onSuccess) => ({
  type: USER_ACTION.LINK_COVA_STORE,
  payload,
  onSuccess,
})

export const linkCovaStoreSuccess = (payload) => ({
  type: USER_ACTION.LINK_COVA_STORE_SUCCESS,
  payload,
})

export const linkCovaStoreFailed = (payload) => ({
  type: USER_ACTION.LINK_COVA_STORE_FAILED,
  payload,
})

export default {
  updateSetting,
  updateOrganization,
  getOrg,
  getStores,
  removeStore,
  removeStoreSuccess,
  linkStore,
  linkWooStore,
  updateStore,
  removeWarehouse,
  updateWarehouse,
  createWarehouse,
  linkCovaStore,
  updateWarehouseZoneCounting,
}
