export const LOCATION_ACTION = {
  FETCH_LIST: 'location.GET_LIST',
  SET_LIST_DATA: 'location.SET_LIST_DATA',
  SET_LIST_ERROR: 'location.SET_LIST_ERROR',
}

export const fetchLocationList = (params) => ({
  type: LOCATION_ACTION.FETCH_LIST,
  payload: params,
})

export const setLocationListData = (listData) => ({
  type: LOCATION_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setLocationListError = (error) => ({
  type: LOCATION_ACTION.SET_LIST_ERROR,
  payload: error,
})

export default {
  fetchLocationList,
  setLocationListData,
  setLocationListError,
}
