import httpClient from '~/modules/core/httpClient'
import { convertGetListTransferParams, mapTransferInDetailForm } from '~/utils/inventoryWarehouses'
import { ACTIONS } from './common'

const url = {
  WAREHOUSE_TRANSFER: 'warehouses/transfers/',
  WAREHOUSE_INVENTORY_PRODUCTS: 'warehouses/inventory_products/',
}

const CUSTOM_ACTIONS = {
  CHECK_IN: 'check_in',
  LOCK_UNLOCK: 'lock_unlock',
}

const getList = async ({ warehouseId, params }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: ACTIONS.PAGINATE,
    warehouse_id: warehouseId,
    parameters: convertGetListTransferParams(params),
  },
)

const searchProduct = (warehouseId, keyword, limit = 25) => httpClient.post(url.WAREHOUSE_INVENTORY_PRODUCTS, (params) => ({
  ...params,
  action: ACTIONS.PAGINATE,
  warehouse_id: warehouseId,
  parameters: {
    search_parameter: keyword,
    limit,
    filters: {},
    page: 1,
  },
}))

const create = ({ params, warehouseId }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: ACTIONS.CREATE,
    warehouse_id: warehouseId,
    parameters: params,
  },
)

const getTransferOutInfo = ({ params, warehouseId }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: ACTIONS.READ,
    warehouse_id: warehouseId,
    parameters: params,
  },
)

const getDetail = ({ warehouseId, ...parameters }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: ACTIONS.READ,
    warehouse_id: warehouseId,
    parameters,
  },
)

const checkIn = ({ warehouseId, ...parameters }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: CUSTOM_ACTIONS.CHECK_IN,
    warehouse_id: warehouseId,
    parameters: mapTransferInDetailForm(parameters),
  },
)

const lockCheckIn = ({ warehouseId, ...parameters }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: CUSTOM_ACTIONS.LOCK_UNLOCK,
    warehouse_id: warehouseId,
    parameters,
  },
)

const remove = ({ warehouseId, ...parameters }) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: ACTIONS.DELETE,
    warehouse_id: warehouseId,
    parameters,
  },
)

const exportCSV = ({
  warehouseId, ...parameters
}) => httpClient.post(
  url.WAREHOUSE_TRANSFER,
  {
    action: ACTIONS.EXPORT,

    warehouse_id: warehouseId,
    parameters,
  },
)

const warehouseTransferApi = {
  getList,
  create,
  getTransferOutInfo,
  searchProduct,
  getDetail,
  checkIn,
  lockCheckIn,
  remove,
  exportCSV,
}

export default warehouseTransferApi
