import { combineReducers } from 'redux'

import auth from '~/modules/auth/redux/reducer'
import dashboard from './dashboard'
import product from './product'
import media from './media'
import user from './user'
import category from './category'
import order from './order'
import reward from './reward'
import coupon from './coupon'
import tag from './tag'
import customer from './customer'
import attribute from './attribute'
import comment from './comment'
import location from './location'
import calendar from './calendar'
import cantecFulfillment from './cantecFulfillment'
import regularShippingFulfillment from './regularShipping'
import settings from './settings'
import shipping from './shipping'
import payment from './payment'
import inventoryWarehouses from './inventoryWarehouses'
import warehouseTransfer from './warehouseTransfer'
import share from './share'
import warehouseAdjustments from './warehouseAdjustments'
import bill from './bill'
import notificationCenter from './notificationCenter'
import sku from './sku'
import skuTags from './skuTags'
import credential from './credential'
import onfleet from './onfleet'
import analytic from './analytic'
import { USER_ACTION } from '../actions/user'

const rootReducer = combineReducers({
  dashboard,
  product,
  auth,
  media,
  share,
  user,
  category,
  order,
  reward,
  coupon,
  tag,
  customer,
  attribute,
  location,
  calendar,
  cantecFulfillment,
  comment,
  settings,
  shipping,
  inventoryWarehouses,
  warehouseTransfer,
  regularShippingFulfillment,
  payment,
  warehouseAdjustments,
  bill,
  sku,
  skuTags,
  notificationCenter,
  credential,
  onfleet,
  analytic,
})

const reducer = (state, action) => rootReducer(action.type === USER_ACTION.SIGN_OUT && state ? {} : state, action)

export default reducer
