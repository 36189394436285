import moment from 'moment'
import httpClient from '~/modules/core/httpClient'

const url = {
  GET_LINK: '/export_results',
}

const getLink = (exportId) => httpClient.get(
  `${url.GET_LINK}/${exportId}`,
  { params: { current_time: moment().utc().format('YYYY-MM-DDTHH:mm:ss') } },
)

const exportApi = {
  getLink,
}

export default exportApi
