export const BILL_ACTIONS = {
  GET_BILL_LIST: 'bill.GET_BILL_LIST',
  GET_BILL_LIST_OK: 'bill.GET_BILL_LIST_OK',
  GET_BILL_LIST_ERR: 'bill.GET_BILL_LIST_ERR',
  LOCAL_PAGINATE: 'bill.LOCAL_PAGINATE',

  GET_BILL_DETAIL: 'bill.GET_BILL_DETAIL',
  GET_BILL_DETAIL_OK: 'bill.GET_BILL_DETAIL_OK',
  GET_BILL_DETAIL_ERR: 'bill.GET_BILL_DETAIL_ERR',

  GET_BILL_STORES: 'bill.GET_BILL_STORES',
  GET_BILL_STORES_OK: 'bill.GET_BILL_STORES_OK',
  GET_BILL_STORES_ERR: 'bill.GET_BILL_STORES_ERR',
}

export const getBillList = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_LIST,
  payload,
})

export const getBillListSuccess = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_LIST_OK,
  payload,
})

export const getBillListFail = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_LIST_ERR,
  payload,
})

export const localPaginateBillList = (payload) => ({
  type: BILL_ACTIONS.LOCAL_PAGINATE,
  payload,
})

export const getBillDetail = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_DETAIL,
  payload,
})

export const getBillDetailSuccess = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_DETAIL_OK,
  payload,
})

export const getBillDetailFail = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_DETAIL_ERR,
  payload,
})

export const getBillStores = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_STORES,
  payload,
})

export const getBillStoresSuccess = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_STORES_OK,
  payload,
})

export const getBillStoresFail = (payload) => ({
  type: BILL_ACTIONS.GET_BILL_STORES_ERR,
  payload,
})

export default {
  getBillList,
  getBillListSuccess,
  getBillListFail,
  localPaginateBillList,

  getBillDetail,
  getBillDetailSuccess,
  getBillDetailFail,

  getBillStores,
  getBillStoresSuccess,
  getBillStoresFail,
}
