import { AUTH_STORAGE_KEYS, IS_NOT_SHOW_TUTORIAL_PANEL_HOME_AGAIN } from '~/constants/localStorage'

// const isLocalStorageSupported = typeof (Storage) !== 'undefined'

// TODO: fallback to memory storage
export function set(key, val) {
  try {
    window.localStorage.setItem(key, JSON.stringify(val))
    return true
  } catch (e) {
    // This might be falled in safari private mode
    return false
  }
}

export function get(key) {
  try {
    // in case the value stored in localStorage is not valid JSON string.
    const val = JSON.parse(window.localStorage.getItem(key))
    return val
  } catch (e) {
    return null
  }
}

export function clear(key) {
  window.localStorage.removeItem(key)
}

export function clearAll(isAll) {
  if (isAll) {
    window.localStorage.clear()
    return
  }

  // By default, clear all but invitation
  const invitationCode = get(AUTH_STORAGE_KEYS.INVITATION_CODE)
  const invitationEmail = get(AUTH_STORAGE_KEYS.INVITATION_EMAIL)
  const notShowTutorialPanelWorkspaces = get(IS_NOT_SHOW_TUTORIAL_PANEL_HOME_AGAIN)

  window.localStorage.clear()

  set(AUTH_STORAGE_KEYS.INVITATION_CODE, invitationCode)
  set(AUTH_STORAGE_KEYS.INVITATION_EMAIL, invitationEmail)
  set(IS_NOT_SHOW_TUTORIAL_PANEL_HOME_AGAIN, notShowTutorialPanelWorkspaces)
}
