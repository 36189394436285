export const CALENDAR_ACTION = {
  FETCH_DATA: 'calendar.FETCH_DATA',
  SET_CALENDAR_DATA_ERROR: 'calendar.SET_CALENDAR_DATA_ERROR',
  SET_CALENDAR_DATA: 'calendar.SET_CALENDAR_DATA',
}

export const fetchCalendarData = (params) => ({
  type: CALENDAR_ACTION.FETCH_DATA,
  payload: params,
})

export const setCalendarData = (calendar) => ({
  type: CALENDAR_ACTION.SET_CALENDAR_DATA,
  payload: calendar,
})

export const setCalendarDataError = (error) => ({
  type: CALENDAR_ACTION.SET_CALENDAR_DATA_ERROR,
  payload: error,
})

export const clearCalendarData = () => ({
  type: CALENDAR_ACTION.SET_CALENDAR_DATA,
  payload: {},
})

export default {
  fetchCalendarData,
  setCalendarData,
  setCalendarDataError,
  clearCalendarData,
}
