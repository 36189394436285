export const DEFAULT_TAG_LIST_DATA = {
  items: [],
  total_items: 0,
  total_pages: 0,
}

export const DEFAULT_TAG_DETAIL = {
  name: '',
  slug: '',
  description: '',
}
