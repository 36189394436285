import { DEFAULT_COMMENT_DETAIL } from '~/redux/default/comment'

export const COMMENT_ACTION = {
  FETCH_LIST: 'comment.GET_LIST',
  SET_LIST_DATA: 'comment.SET_LIST_DATA',
  SET_LIST_ERROR: 'comment.SET_LIST_ERROR',
  FETCH_DETAIL: 'comment.FETCH_DETAIL',
  SET_DETAIL_DATA_ERROR: 'comment.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'comment.SET_DETAIL_DATA',
}

export const fetchCommentList = (params) => ({
  type: COMMENT_ACTION.FETCH_LIST,
  payload: params,
})

export const setCommentListData = (listData) => ({
  type: COMMENT_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setCommentListError = (error) => ({
  type: COMMENT_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const fetchCommentDetail = (commentId) => ({
  type: COMMENT_ACTION.FETCH_DETAIL,
  payload: commentId,
})

export const setCommentDetail = (comment) => ({
  type: COMMENT_ACTION.SET_DETAIL_DATA,
  payload: { ...comment, review: (comment.review || '').replace(/\\"/g, '"') },
})

export const setCommentDetailError = (error) => ({
  type: COMMENT_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const clearCommentDetail = () => ({
  type: COMMENT_ACTION.SET_DETAIL_DATA,
  payload: DEFAULT_COMMENT_DETAIL,
})

export default {
  fetchCommentList,
  setCommentListData,
  setCommentListError,
  fetchCommentDetail,
  setCommentDetail,
  setCommentDetailError,
  clearCommentDetail,
}
