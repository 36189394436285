export const mapLocationIds = (locationIds = []) => {
  if (!locationIds.length) {
    return ''
  }
  const allLocation = locationIds.map((id) => (`location_ids=${id}`))
  return `?${allLocation.join('&')}`
}

export const productCountMapper = (data) => {
  if (!data) {
    return {
      totalProduct: 0,
      totalInventory: 0,
    }
  }
  return {
    totalProduct: data.total_product,
    totalInventory: data.total_inventory,
  }
}
