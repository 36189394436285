import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
}
const url = {
  CUSTOMER_NOTES: '/stores/woocommerce/customers/notes',
}

const getList = (customerId, id) => httpClient.post(url.CUSTOMER_NOTES, {
  action: ACTIONS.PAGINATE,
  parameters: {
    limit: 25,
    customer_id: customerId,
    note_id_lt: id,
  },
})

const create = (customerId, noteDescription) => {
  const params = {
    customer_id: customerId,
    description: noteDescription,
  }
  return httpClient.post(url.CUSTOMER_NOTES, {
    action: ACTIONS.CREATE,
    parameters: params,
  })
}

const remove = async (customerId, noteId) => {
  const params = {
    customer_id: customerId,
    note_id: noteId,
  }
  return httpClient.post(url.CUSTOMER_NOTES, {
    action: ACTIONS.DELETE,
    parameters: params,
  })
}

export default {
  getList,
  create,
  remove,
}
