import { COLORS } from '~/themes/common'

export default {
  dialogTitle: {
    borderBottom: `1px solid ${COLORS.gray.disableGray1}`,
    color: COLORS.palette.primaryBlue,
    fontSize: 16,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    '& h3': {
      fontSize: 18,
      fontWeight: 500,
      margin: '0 0 10px',
    },
    '& p': {
      fontSize: 14,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  gridContainer: {
    maxWidth: 880,
    margin: 'auto',
    padding: '40px 0',
    justifyContent: 'center',
  },
  mt: {
    marginTop: 40,
  },
  closeBtn: {
    color: COLORS.gray.darkGray,
    cursor: 'pointer',
  },
  errorMessage: {
    color: COLORS.accent.rubyRed1,
  },
}
