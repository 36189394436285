export const jsonToFormData = (json) => {
  const formData = new FormData()
  Object.keys(json).forEach((key) => {
    if (key) {
      if (typeof json[key] === 'object') {
        formData.append(key, JSON.stringify(json[key]))
      } else {
        formData.append(key, json[key])
      }
    }
  })

  return formData
}
