/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import Cookies from 'js-cookie'
import { captureException } from '@sentry/react'
import { useRefreshCustomToken } from '~/providers/CheckingValidProvider/hooks'
import { AUTH_TYPE, COOKIE_KEYS, TIME_COOKIE_TOKEN_EXPIRATION } from '~/constants/auth'
import { useAuth } from '~/modules/auth/redux/hook'
import { ADMIN_ROUTES } from '~/constants/router'
import authApi from '~/modules/auth/services'
import logger from '~/utils/logger'
import LoadingLinear from '~/components/common/LoadingLinear'
import { SSO_COOKIE_DOMAIN } from '~/constants/environment'

const CheckingValidProvider = ({ children }) => {
  useRefreshCustomToken(true)

  const { actions, authState } = useAuth()
  const [firstVerifying, setFirstVerifying] = useState(true)

  const _cachedFirebaseUserId = useMemo(() => authState?.data?.userFirebaseId, [authState?.data?.userFirebaseId])

  const decodeJwt = useCallback((token) => {
    try {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))

      return JSON.parse(jsonPayload)
    } catch (e) {
      return null
    }
  }, [])

  const checkValidFirebaseUser = useCallback(async () => {
    const whiteList = Object.values(AUTH_TYPE)

    try {
      const customKey = Cookies.get(COOKIE_KEYS.CUSTOM_TOKEN) || ''
      const decoded = decodeJwt(customKey)
      const firebaseUserId = authApi.getUserFirebaseId() || _cachedFirebaseUserId
      switch (true) {
        case !decoded?.uid && !!firebaseUserId:
          logger.info('case 1')
          setFirstVerifying(false)
          if (!whiteList.concat('/logout').some((path) => path === window.location.pathname)) window.location.replace(ADMIN_ROUTES.LOGOUT)
          break
        case !!decoded?.uid && !firebaseUserId:
          logger.info('case 2')
          if (decoded?.claims?.is_breadstack) {
            actions.signInWithToken({
              token: customKey,
              refreshToken: Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || '',
              onFinal: () => {
                logger.info('final case 2')
                setFirstVerifying(false)
              },
            })
          } else setFirstVerifying(false)
          break

        case !!decoded?.uid && !!firebaseUserId && decoded?.uid !== firebaseUserId:
          logger.info('case 3')
          if (window.location.pathname !== AUTH_TYPE.SIGN_UP) {
            /**
             *  Check if the user does not have a breadstack account
             *  call signInWithToken function to check has_app breadstack
             *  if no has_app => redirect to signup by email
            */
            if (!decoded?.claims?.is_breadstack) {
              actions.signInWithToken({
                token: customKey,
                refreshToken: Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || '',
                onFinal: () => {
                  logger.info('final case 3-1')
                  setFirstVerifying(false)
                },
              })
            } else {
              actions.signInAgain({
                token: customKey,
                refreshToken: Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || '',
                onFinal: () => {
                  logger.info('final case 3-2')
                  setFirstVerifying(false)
                },
              })
            }
          } else setFirstVerifying(false)
          break

        case firebaseUserId === decoded?.uid:
        default:
          logger.info('case 4')
          const refreshToken = Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || ''
          if (refreshToken) {
            try {
              if (whiteList.some((path) => path === window.location.pathname)) {
                actions.signInWithToken({
                  token: customKey,
                  refreshToken: Cookies.get(COOKIE_KEYS.REFRESH_TOKEN) || '',
                  onFinal: () => {
                    logger.info('final 4-2')
                    setFirstVerifying(false)
                  },
                })
                logger.info('final 1')
              } else {
                if (firstVerifying) {
                  const req = await authApi.fetchRefreshToken(refreshToken)
                  if (req.has_app) {
                    Cookies.set(COOKIE_KEYS.CUSTOM_TOKEN, req.custom_token, { expires: TIME_COOKIE_TOKEN_EXPIRATION, domain: SSO_COOKIE_DOMAIN })
                    Cookies.set(COOKIE_KEYS.REFRESH_TOKEN, req.refresh_token, { expires: TIME_COOKIE_TOKEN_EXPIRATION, domain: SSO_COOKIE_DOMAIN })
                    await authApi.signInWithCustomToken(req.custom_token || '')
                    logger.info('final 4-1')
                  }
                }
                setFirstVerifying(false)
              }
            } catch (e) {
              logger.info('final case 42', e)
              setFirstVerifying(false)
            }
          } else {
            logger.info('final case 43')
            setFirstVerifying(false)
          }
          break
      }
    } catch (e) {
      setFirstVerifying(false)
      captureException(e)
    }
  }, [_cachedFirebaseUserId, setFirstVerifying])

  useEffect(() => {
    if (firstVerifying) {
      logger.info('Start verifing')
      checkValidFirebaseUser()
    }

    window.addEventListener('focus', checkValidFirebaseUser)

    return () => {
      window.removeEventListener('focus', checkValidFirebaseUser)
    }
  }, [firstVerifying, _cachedFirebaseUserId, setFirstVerifying])

  logger.info({ firstVerifying }, window.location.pathname)

  return firstVerifying && window.location.pathname !== '/logout' ? <LoadingLinear /> : (<>{children}</>)
}

export default CheckingValidProvider
