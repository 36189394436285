import { transformTypeOptions } from '~/utils/common'
import { VITE_GREENLINE_URL } from './environment'

export const COVA_HUB_PRODUCT_URL = 'https://hub.covasoft.net/#CatalogManager/products'

export const GREENLINE_PRODUCT_URL = VITE_GREENLINE_URL

export const PROMOTION_STATUS = {
  DRAFT: -1,
  WAITING: 0,
  ACTIVE: 1,
  ENDING: 2,
  ENDED: 3,
}

export const PRODUCT_TYPE = {
  SIMPLE: 'simple',
  VARIABLE: 'variable',
  VARIATION: 'variation',
  COMPOSITE: 'composite',
}

export const PRODUCT_TYPE_LIST = [{
  value: PRODUCT_TYPE.SIMPLE,
  label: 'Simple product',
}, {
  value: PRODUCT_TYPE.VARIABLE,
  label: 'Variable product',
}, {
  value: PRODUCT_TYPE.COMPOSITE,
  label: 'Composite product',
}]

export const STOCK_STATUS_OPTIONS = [
  { value: 'inventory_quantity', label: 'Enable stock management at product level' },
  { value: 'instock', label: 'In stock' },
  { value: 'outofstock', label: 'Out of stock' },
  { value: 'onbackorder', label: 'On backorder' },
]

export const STOCK_STATUS_OPTIONS_WITHOUT_MANAGE_STOCK = [
  { value: 'instock', label: 'In stock' },
  { value: 'outofstock', label: 'Out of stock' },
  { value: 'onbackorder', label: 'On backorder' },
]

export const BACKORDER_OPTIONS = [
  { value: 'no', label: 'No' },
  { value: 'notify', label: 'Allow, but notify customer' },
  { value: 'yes', label: 'Allow' },
]

export const GALLERY_STEP = {
  SELECT: 0,
  PREVIEW: 1,
  UPLOAD_FILE: 2,
}

export const PRODUCT_STATUS = {
  ALL_PRODUCT: 'all-products',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled',
  DRAFTS: 'drafts',
  PRIVATE: 'private',
  TRASH: 'trash',
}
export const PRODUCT_HIDDEN_STATUS = {
  ALL_PRODUCT: 'all-products',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled',
  DRAFTS: 'drafts',
  PRIVATE: 'private',
  HIDDEN: 'hidden',
  TRASH: 'trash',
}

export const PRODUCTS_TAB = transformTypeOptions(PRODUCT_STATUS)
export const PRODUCTS_HIDDEN_TAB = transformTypeOptions(PRODUCT_HIDDEN_STATUS)

export const PRODUCT_STATUS_FILTER = {
  ALL_PRODUCT: undefined,
  PUBLISHED: 'publish',
  SCHEDULED: 'future',
  DRAFTS: 'draft',
  PRIVATE: 'private',
  TRASH: 'trash',
}
export const PRODUCT_HIDDEN_STATUS_FILTER = {
  ALL_PRODUCT: undefined,
  PUBLISHED: 'publish',
  SCHEDULED: 'future',
  DRAFTS: 'draft',
  PRIVATE: 'private',
  HIDDEN: 'hidden',
  TRASH: 'trash',
}

export const PRODUCT_TYPE_MAPPING_WITH_BACKEND_FIELD = {
  [PRODUCT_STATUS.ALL_PRODUCT]: 'all',
  [PRODUCT_STATUS.PUBLISHED]: 'publish',
  [PRODUCT_STATUS.SCHEDULED]: 'future',
  [PRODUCT_STATUS.DRAFTS]: 'draft',
  [PRODUCT_STATUS.PRIVATE]: 'private',
  [PRODUCT_STATUS.TRASH]: 'trash',
}
export const PRODUCT_HIDDEN_TYPE_MAPPING_WITH_BACKEND_FIELD = {
  [PRODUCT_HIDDEN_STATUS.ALL_PRODUCT]: 'all',
  [PRODUCT_HIDDEN_STATUS.PUBLISHED]: 'publish',
  [PRODUCT_HIDDEN_STATUS.SCHEDULED]: 'future',
  [PRODUCT_HIDDEN_STATUS.DRAFTS]: 'draft',
  [PRODUCT_HIDDEN_STATUS.PRIVATE]: 'private',
  [PRODUCT_HIDDEN_STATUS.HIDDEN]: 'hidden',
  [PRODUCT_HIDDEN_STATUS.TRASH]: 'trash',
}

// status use in filters
export const mapTabToStatus = (tab) => {
  switch (tab) {
    case PRODUCT_STATUS.ALL_PRODUCT:
      return PRODUCT_STATUS_FILTER.ALL_PRODUCT
    case PRODUCT_STATUS.PUBLISHED:
      return PRODUCT_STATUS_FILTER.PUBLISHED
    case PRODUCT_STATUS.SCHEDULED:
      return PRODUCT_STATUS_FILTER.SCHEDULED
    case PRODUCT_STATUS.DRAFTS:
      return PRODUCT_STATUS_FILTER.DRAFTS
    case PRODUCT_STATUS.PRIVATE:
      return PRODUCT_STATUS_FILTER.PRIVATE
    case PRODUCT_STATUS.TRASH:
      return PRODUCT_STATUS_FILTER.TRASH
    default:
      return PRODUCT_STATUS_FILTER.ALL_PRODUCT
  }
}
export const mapHiddenTabToStatus = (tab) => {
  switch (tab) {
    case PRODUCT_HIDDEN_STATUS.ALL_PRODUCT:
      return PRODUCT_HIDDEN_STATUS_FILTER.ALL_PRODUCT
    case PRODUCT_HIDDEN_STATUS.PUBLISHED:
      return PRODUCT_HIDDEN_STATUS_FILTER.PUBLISHED
    case PRODUCT_HIDDEN_STATUS.SCHEDULED:
      return PRODUCT_HIDDEN_STATUS_FILTER.SCHEDULED
    case PRODUCT_HIDDEN_STATUS.DRAFTS:
      return PRODUCT_HIDDEN_STATUS_FILTER.DRAFTS
    case PRODUCT_HIDDEN_STATUS.PRIVATE:
      return PRODUCT_HIDDEN_STATUS_FILTER.PRIVATE
    case PRODUCT_HIDDEN_STATUS.HIDDEN:
      return PRODUCT_HIDDEN_STATUS_FILTER.HIDDEN
    case PRODUCT_HIDDEN_STATUS.TRASH:
      return PRODUCT_HIDDEN_STATUS_FILTER.TRASH
    default:
      return PRODUCT_HIDDEN_STATUS_FILTER.ALL_PRODUCT
  }
}

export const mapStatusToTab = (status) => {
  switch (status) {
    case (Boolean(status) === false):
      return PRODUCT_STATUS.ALL_PRODUCT
    case PRODUCT_STATUS_FILTER.PUBLISHED:
      return PRODUCT_STATUS.PUBLISHED
    case PRODUCT_STATUS_FILTER.SCHEDULED:
      return PRODUCT_STATUS.SCHEDULED
    case PRODUCT_STATUS_FILTER.DRAFTS:
      return PRODUCT_STATUS.DRAFTS
    case PRODUCT_STATUS_FILTER.PRIVATE:
      return PRODUCT_STATUS.PRIVATE
    case PRODUCT_STATUS_FILTER.TRASH:
      return PRODUCT_STATUS.TRASH
    default:
      return PRODUCT_STATUS.ALL_PRODUCT
  }
}
export const mapHiddenStatusToTab = (status) => {
  switch (status) {
    case (Boolean(status) === false):
      return PRODUCT_HIDDEN_STATUS.ALL_PRODUCT
    case PRODUCT_HIDDEN_STATUS_FILTER.PUBLISHED:
      return PRODUCT_HIDDEN_STATUS.PUBLISHED
    case PRODUCT_HIDDEN_STATUS_FILTER.SCHEDULED:
      return PRODUCT_HIDDEN_STATUS.SCHEDULED
    case PRODUCT_HIDDEN_STATUS_FILTER.DRAFTS:
      return PRODUCT_HIDDEN_STATUS.DRAFTS
    case PRODUCT_HIDDEN_STATUS_FILTER.PRIVATE:
      return PRODUCT_HIDDEN_STATUS.PRIVATE
    case PRODUCT_HIDDEN_STATUS_FILTER.HIDDEN:
      return PRODUCT_HIDDEN_STATUS.HIDDEN
    case PRODUCT_HIDDEN_STATUS_FILTER.TRASH:
      return PRODUCT_HIDDEN_STATUS.TRASH
    default:
      return PRODUCT_HIDDEN_STATUS.ALL_PRODUCT
  }
}

export const COMPOSITE_LAYOUT = {
  STACKED: 'single',
  PROGRESSIVE: 'progressive',
  STEPPED: 'paged',
  COMPONENTIZED: 'paged-componentized',
}

export const COMPOSITE_LAYOUT_LIST = [
  { label: 'Stacked', value: COMPOSITE_LAYOUT.STACKED, icon: 'stacked' },
  { label: 'Progressive', value: COMPOSITE_LAYOUT.PROGRESSIVE, icon: 'progressive' },
  { label: 'Stepped', value: COMPOSITE_LAYOUT.STEPPED, icon: 'stepped' },
  { label: 'Componentized', value: COMPOSITE_LAYOUT.COMPONENTIZED, icon: 'componentized' },
]

export const COMPOSITE_LOCATIONS = [
  { label: 'Default', value: 'default' },
  { label: 'Before Tabs', value: 'after_summary' },
]

export const COMPOSITE_CATALOG_PRICE = [
  { label: 'Use defaults', value: 'defaults' },
  { label: 'Calculate from/to', value: 'min_max' },
  { label: 'Hide', value: 'hidden' },
]

export const ASSEMBLED_WEIGHT = [
  { label: 'Ignore', value: 'ignore' },
  { label: 'Preserve', value: 'preserve' },
]

export const COMPOSITE_SHIPPINGS = {
  UNASSIGNED: 'unassembled',
  ASSEMBLED: 'assembled',
}

export const COMPOSITE_SHIPPINGS_LIST = [
  { label: 'Unassembled', value: COMPOSITE_SHIPPINGS.UNASSIGNED, icon: 'unassembled' },
  { label: 'Assembled', value: COMPOSITE_SHIPPINGS.ASSEMBLED, icon: 'assembled' },
]

export const COMPOSITE_PLUGIN_NAME = 'WooCommerce Composite Products'

export const COMPOSITE_QUERY_TYPE = [
  { label: 'Select Products', value: 'product_ids' },
  { label: 'Select Categories', value: 'category_ids' },
]

export const COMPOSITE_OPTIONS_STYLE = [
  { label: 'Dropdown', value: 'dropdowns' },
  { label: 'Thumbnails', value: 'thumbnails' },
  { label: 'Radio Buttons', value: 'radios' },
]

export const COMPOSITE_PRICE_OPTIONS = [
  { label: 'Absolute', value: 'absolute' },
  { label: 'Relative', value: 'relative' },
  { label: 'Hidden', value: 'hidden' },
]

export const COMPOSITE_SCENARIO_CONFIGURATION_OPTIONS = [
  { value: 'in', label: 'is' },
  { value: 'not-in', label: 'is not' },
  { value: 'any', label: 'is any' },
]

export const COMPOSITE_SCENARIO_HIDDEN_OPTIONS = [
  { value: 'in', label: 'hide' },
  { value: 'not-in', label: 'hide all except' },
]

export const DEFAULT_EDIT_VARITAION_FORM = {
  cbxTrack: true,
  allow_backorders: 'Allow',
  cbxCustomer: true,
}

export const COVA_PRODUCT_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  HIDDEN: 'hidden',
  SCHEDULED: 'scheduled',
}

export const COVA_PRODUCT_STATUS_OPTION = [
  {
    label: 'Published',
    value: COVA_PRODUCT_STATUS.PUBLISHED,
  },
  {
    label: 'Hidden',
    value: COVA_PRODUCT_STATUS.HIDDEN,
  },
  {
    label: 'Draft',
    value: COVA_PRODUCT_STATUS.DRAFT,
  },
  {
    label: 'Scheduled',
    value: COVA_PRODUCT_STATUS.SCHEDULED,
  },
]
