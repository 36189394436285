// import pick from 'lodash/pick'
// import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { convertGmtToLocalTime } from '~/utils/datetime'
import { isNullOrUndefined } from '~/utils/utility'
// import { ORDER_DETAIL_UPDATED } from '~/redux/default/order'

export const mapOrderDetail = (order) => ({
  ...order,
  originalData: order,
  shipping: order.shipping_lines ? order.shipping_lines.map((s) => s.method_title).join(', ')
    : (order.shipping_method ? order.shipping_method.join(', ') : ''),
  shipment_tracking: order.shipment_tracking && order.shipment_tracking.length > 0
    ? order.shipment_tracking
    : (order.meta_data ? order.meta_data.filter(({ key }) => key === '_wc_shipment_tracking_items') : []),
})

export const mapOrderList = (orders) => {
  if (!orders) {
    return []
  }
  return orders.map((order) => mapOrderDetail(order))
}

export const mapOrderStatusToTag = (orderStatus) => {
  switch (orderStatus) {
    case 'on-hold': {
      return 'on_hold'
    }
    case 'Completed': {
      return 'completed'
    }
    case 'Refunded': {
      return 'refunded'
    }
    case 'Cancelled': {
      return 'cancelled'
    }
    case 'Confirmed': {
      return 'confirmed'
    }
    case 'PaidAndAwaitingCompletion': {
      return 'paid'
    }
    case 'ready for pickup': {
      return 'ready_for_pickup'
    }
    case 'ready for delivery': {
      return 'ready_for_delivery'
    }
    case 'out for delivery': {
      return 'out_for_delivery'
    }
    default: {
      return orderStatus
    }
  }
}

export const mapCovaOrderStatusToTag = (orderStatus) => {
  switch (orderStatus) {
    case 'New Order': {
      return 'cova_new_orders'
    }
    case 'Completed': {
      return 'cova_completed'
    }
    case 'Cancelled': {
      return 'cova_cancelled'
    }
    default: {
      return orderStatus
    }
  }
}

export const transformShopifyTypeToTitle = (text) => {
  if (!text) return ''
  const convertText = text.toLowerCase()?.split('_')?.join(' ')
  const str = convertText.split('-')
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
  }
  return str.join(' ')
}

export const mapShopifyOrderStatusToTag = (orderStatus) => {
  switch (orderStatus) {
    case 'PENDING': {
      return 'shopify_pending'
    }
    case 'PAID': {
      return 'shopify_paid'
    }
    case 'PARTIALLY_REFUNDED': {
      return 'shopify_partially_refunded'
    }
    case 'REFUNDED': {
      return 'shopify_refunded'
    }
    default: {
      return orderStatus
    }
  }
}

export const mapShippingSection = (status, task) => {
  // TODO: CHECK RESHIP FOR EACH ITEMS
  const trackingProgress = []
  const {
    task_status_type,
    activities,
    is_completed,
    current_total_ship,
    history_ship_fail,
  } = !task || !task.length ? {} : task[0]
  const delivery_attempt_count = (activities || []).filter(({ activity_type }) => activity_type === 2).length
  let steps = []
  let state = []
  let desc = []
  switch (status) {
    case 'on-hold':
      steps = ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered']
      state = (history_ship_fail && history_ship_fail.length) ? ['active'] : []
      trackingProgress.push({
        steps,
        state,
        desc,
        bottomLine: history_ship_fail && history_ship_fail.length,
        order_status: status,
      })
      if (history_ship_fail && history_ship_fail.length) {
        history_ship_fail.forEach((history, index) => {
          trackingProgress.push({
            steps: ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered'],
            state: ['completed', 'completed', 'failWarning', 'warning'],
            desc: [{}, {}, {}, { title: 'Please contact seller' }],
            date: history.created_at,
            reship: index !== history_ship_fail.length - 1,
            collapse: true,
            bottomLine: true,
            order_status: status,
          })
        })
      }
      break
    case 'cancelled':
      steps = ['Order cancelled']
      state = ['error']
      trackingProgress.push({
        steps,
        state,
        desc,
        order_status: status,
      })
      break
    case 'processing':
    case 'completed':
      if (!task || !task.length) {
        steps = ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered']
        state = (status === 'processing') ? ['active'] : ['completed', 'completed', 'completed', 'completed']
        trackingProgress.push({
          steps,
          state,
          desc,
          order_status: status,
        })
        break
      }
      if (current_total_ship > (history_ship_fail || []).length) {
        steps = ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered']
        state = ['active']
        desc = []
        if (task_status_type === 1 && !is_completed) {
          state = ['completed', 'active']
        } else if (task_status_type === 2) {
          if (delivery_attempt_count === 1 && !is_completed) {
            state = ['completed', 'completed', 'active']
          } else if (delivery_attempt_count === 2 && !is_completed) {
            state = ['completed', 'completed', 'warning']
            desc = [{}, {}, { title: 'First Attempt Failed', note: 'We’ll try again' }, {}]
          } else if (is_completed) {
            steps = ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered']
            state = ['completed', 'completed', 'completed', 'completed']
          }
        } else if (task_status_type === 3) {
          steps = ['Preparing order', 'Ready to ship', 'Out for delivery', 'Deliver Failed']
          state = ['completed', 'completed', 'failWarning', 'warning']
          desc = [{}, {}, {}, { title: 'Please contact seller' }]
        }
        trackingProgress.push({
          steps,
          state,
          desc,
          reship: (history_ship_fail || []).length,
          bottomLine: history_ship_fail && history_ship_fail.length,
          order_status: status,
        })
      }
      (history_ship_fail || []).forEach((history, index) => {
        trackingProgress.push({
          steps: ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered'],
          state: ['completed', 'completed', 'failWarning', 'warning'],
          desc: [{}, {}, {}, { title: 'Please contact seller' }],
          date: history.created_at,
          reship: index !== history_ship_fail.length - 1,
          collapse: !!trackingProgress.length,
          bottomLine: !!trackingProgress.length,
          order_status: status,
        })
      })
      break
    default:
      steps = ['Preparing order', 'Ready to ship', 'Out for delivery', 'Delivered']
      state = ['active']
      trackingProgress.push({
        steps,
        state,
        desc,
        order_status: status,
      })
  }
  return trackingProgress
}

export const mapOrderFilters = (params) => {
  const filters = { ...params }
  if (!filters.status || filters.status === 'all') {
    filters.status = 'all'
  }
  const {
    order_id, customer_id, shipping_zone_method_title, shipping_method, tracking_id, payment_method_id,
    product_ids, variation_ids, category_ids, order_total, total_items,
    username, first_name, last_name, address_line_1, address_line_2, country, state, city, postcode, phone, email, clv,
    start_date, end_date, coupon_codes, customer_note, coupon_exists, location_ids, search, payment_status,
  } = filters
  return ({
    order_status: filters.status,
    order_id: order_id && (Array.isArray(order_id) ? order_id : [order_id]),
    customer_id,
    shipping_zone_method_title,
    shipping_method,
    tracking_id,
    payment_method_id,
    product_ids,
    variation_ids,
    category_ids,
    order_total,
    total_items,
    username,
    first_name,
    last_name,
    address_line_1,
    address_line_2,
    country,
    state,
    city,
    postcode,
    phone,
    email,
    clv,
    start_date,
    end_date,
    coupon_codes,
    customer_note,
    coupon_exists,
    location_ids,
    search,
    payment_status,
  })
}

const extractDataByChar = (str, char1, char2) => {
  if (typeof str === 'string') {
    const splitted = str.split(char1)
    const result = splitted.map((e) => {
      const [key, value] = e.trim().split(char2)
      return {
        key: key.trim(),
        value: value.trim(),
      }
    })
    return result
  }
  return str
}

export const mapExtraOrderProducts = (lineItem) => {
  const { meta_data } = lineItem
  if (meta_data) {
    return {
      ...lineItem,
      meta_data: meta_data.map((field) => {
        if (['add extras'].includes(field.key.trim().toLowerCase())) {
          return {
            ...field,
            value: extractDataByChar(field.value, ',', '$'),
          }
        }
        return field
      }),
    }
  }
  return null
}

export const mapOrdersToExport = (orders) => orders.map(({
  id,
  customer,
  customer_email,
  status,
  item_count,
  total,
  shipping_lines,
  meta_data,
  date_created_gmt,
  line_items_text,
  discount_total,
  date_paid,
  date_paid_gmt,
  billing,
  shipping,
}) => {
  const shipmentTrackingMetaData = meta_data?.find(({ key }) => key === '_wc_shipment_tracking_items')
  let shipmentTrackingValues = shipmentTrackingMetaData?.value && Array.isArray(shipmentTrackingMetaData?.value) ? shipmentTrackingMetaData.value : []
  if (shipmentTrackingMetaData?.value && typeof shipmentTrackingMetaData?.value === 'object' && !Array.isArray(shipmentTrackingMetaData?.value)) {
    shipmentTrackingValues = Object.values(shipmentTrackingMetaData?.value)
  }
  return ({
    id,
    customer,
    customer_email,
    status,
    item_count,
    total,
    shipping_method: shipping_lines[0]?.method_title,
    tracking_ids: shipmentTrackingValues.map((tracking) => tracking.tracking_number).join(' ,'),
    date_created: convertGmtToLocalTime(date_created_gmt, 'YYYY/MM/DD HH:mm'),
    line_items_text: (line_items_text || '').replaceAll('.,', '.'),
    discount_total,
    date_paid,
    date_paid_gmt,
    billing,
    shipping,
  }
  )
})

export const mapHeaderCSVOrder = (showColumns, hasShipmentTrackingPlugin) => {
  const csvHeader = ['Order ID']
  if (showColumns.customer) csvHeader.push('Customer')
  csvHeader.push('Customer Email')
  if (showColumns.order_status) csvHeader.push('Order Status')
  if (showColumns.item_count) csvHeader.push('No. of Items')
  if (showColumns.total) csvHeader.push('Order Total ($)')
  csvHeader.push('Billing')
  csvHeader.push('Shipping')
  if (showColumns.shipping_method) csvHeader.push('Shipping Method')
  if (showColumns.tracking_id && hasShipmentTrackingPlugin) csvHeader.push('Tracking ID')
  if (showColumns.date_created_gmt) csvHeader.push('Time of Order')
  csvHeader.push('Line Items')
  csvHeader.push('Total Discount')
  csvHeader.push('Date Paid')
  csvHeader.push('Date Paid GMT')
  return csvHeader
}

// Array data to export
export const mapExportData = (items, showColumns, hasShipmentTrackingPlugin) => items.map(
  ({
    id,
    customer,
    customer_email,
    status,
    item_count,
    total,
    shipping_method,
    tracking_ids,
    date_created,
    line_items_text,
    discount_total,
    date_paid,
    date_paid_gmt,
    billing,
    shipping,
  }) => {
    const contentCSV = [id]
    if (showColumns.customer) contentCSV.push(customer)
    contentCSV.push(customer_email)
    if (showColumns.order_status) contentCSV.push(status)
    if (showColumns.item_count) contentCSV.push(item_count)
    if (showColumns.total) contentCSV.push(total)
    contentCSV.push(billing)
    contentCSV.push(shipping)
    if (showColumns.shipping_method) contentCSV.push(shipping_method)
    if (showColumns.tracking_id && hasShipmentTrackingPlugin) contentCSV.push(tracking_ids)
    if (showColumns.date_created_gmt) contentCSV.push(date_created)
    contentCSV.push(line_items_text)
    contentCSV.push(discount_total)
    contentCSV.push(date_paid)
    contentCSV.push(date_paid_gmt)
    return contentCSV
  },
)

export const stripHtmlTags = (str) => {
  if (isNullOrUndefined(str)) return false
  const strTemp = str.toString()

  return strTemp.replace(/<[^>]*>/g, '')
}

export const displayExistValues = (sign = ', ', ...args) => args.filter((s) => !isEmpty(s)).join(sign)

export const isExistValues = (...args) => args.some((s) => !isEmpty(s))

export const displayCovaTax = (tax, fix, taxType) => taxType === 'Percentage' ? `${Number.parseFloat(tax * 100).toFixed(fix || 2)}%` : `$${Number.parseFloat(tax).toFixed(fix || 2)}`

export const forwardOrderToForm = (data) => data

export const revertOrderToForm = (data) => data

export const calculatedOrder = (data, xData) => {
  const updatingOrderData = { ...data, ...xData }

  // Mapping to line_items
  if (!isNullOrUndefined(xData.line_items) && xData.line_items.length > 0) {
    const currentProductIds = data.line_items.map((item) => item.product_id ? item.product_id : -1)
    const newProducts = xData.line_items.map((item) => {
      const findIndex = currentProductIds.indexOf(item.product_id)
      if (findIndex >= 0) {
        return { ...item, product: data.line_items[findIndex].product }
      }
      return item
    })
    updatingOrderData.line_items = newProducts
  }

  // Mapping to shipping_lines
  if (!isNullOrUndefined(xData.shipping_lines)) {
    updatingOrderData.shipping_lines = xData.shipping_lines
  }

  // Mapping to tax_lines
  if (!isNullOrUndefined(xData.tax_lines)) {
    updatingOrderData.tax_lines = xData.tax_lines
  }

  // Mapping to fee_lines
  if (!isNullOrUndefined(xData.fee_lines)) {
    updatingOrderData.fee_lines = xData.fee_lines
  }

  // Mapping to coupon_lines
  if (!isNullOrUndefined(xData.coupon_lines)) {
    updatingOrderData.coupon_lines = xData.coupon_lines
  }

  // Mapping to meta_xData
  if (!isNullOrUndefined(xData.meta_data)) {
    updatingOrderData.custom_fields = xData.meta_data.map((item) => {
      const mutableItem = { ...item }
      mutableItem.name = item.key
      return mutableItem
    })
  }

  return updatingOrderData
}

export const calculatedDraftOrder = (data, xData) => {
  const updatingOrderData = { ...data, ...xData }
  // Mapping to line_items
  if (!isNullOrUndefined(xData.line_items) && xData.line_items.length > 0) {
    const currentProductIds = data.line_items.map((item) => item.product_id ? item.product_id : -1)
    const newProducts = xData.line_items.map(({ id, ...item }) => {
      const findIndex = currentProductIds.indexOf(item.product_id)
      if (findIndex >= 0) {
        return { ...item, product: data.line_items[findIndex].product, id: data.line_items[findIndex].id }
      }
      return item
    })
    updatingOrderData.line_items = newProducts
  }
  // Mapping to shipping_lines
  if (!isNullOrUndefined(xData.shipping_lines)) {
    updatingOrderData.shipping_lines = (xData.shipping_lines || []).map(({ id, ...s }) => s)
  }

  // Mapping to tax_lines
  if (!isNullOrUndefined(xData.tax_lines)) {
    updatingOrderData.tax_lines = (xData.tax_lines || []).map(({ id, ...s }) => s)
  }

  // Mapping to fee_lines
  if (!isNullOrUndefined(xData.fee_lines)) {
    updatingOrderData.fee_lines = (xData.fee_lines || []).map(({ id, ...s }) => s)
  }

  // Mapping to coupon_lines
  if (!isNullOrUndefined(xData.coupon_lines)) {
    updatingOrderData.coupon_lines = (xData.coupon_lines || []).map(({ id, ...s }) => s)
  }

  delete updatingOrderData.id
  delete updatingOrderData.date_created
  delete updatingOrderData.date_created_gmt
  delete updatingOrderData.date_completed
  delete updatingOrderData.date_completed_gmt
  delete updatingOrderData.date_modified
  delete updatingOrderData.date_modified_gmt
  delete updatingOrderData.date_paid
  delete updatingOrderData.date_paid_gmt

  return updatingOrderData
}
