import { makeDefaultProduct } from '~/redux/default/product'

export const PRODUCT_ACTION = {
  FETCH_FULL_DETAIL: 'product.FETCH_FULL_DETAIL',
  FETCH_DETAIL: 'product.FETCH_DETAIL',
  FETCH_DUPLICATE_DATA: 'product.FETCH_DUPLICATE_DATA',
  SET_DETAIL_DATA_ERROR: 'product.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'product.SET_DETAIL_DATA',
  FETCH_PRODUCT_LIST: 'product.GET_LIST',
  SET_LIST_DATA: 'product.SET_LIST_DATA',
  SET_LIST_ERROR: 'product.SET_LIST_ERROR',
  FETCH_CACHED_PRODUCTS: 'product.FETCH_CACHED_PRODUCTS',
  FETCH_CACHED_PRODUCTS_ERROR: 'product.FETCH_CACHED_PRODUCTS_ERROR',
  SET_CACHED_PRODUCTS: 'product.SET_CACHED_PRODUCTS',
}

export const fetchProductList = (params) => ({
  type: PRODUCT_ACTION.FETCH_PRODUCT_LIST,
  payload: params,
})

export const fetchDuplicateData = (params) => ({
  type: PRODUCT_ACTION.FETCH_DUPLICATE_DATA,
  payload: params,
})

export const setProductListData = (listData) => ({
  type: PRODUCT_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setProductListError = (error) => ({
  type: PRODUCT_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const fetchProductDetail = (productId) => ({
  type: PRODUCT_ACTION.FETCH_DETAIL,
  payload: productId,
})

export const fetchProductFullDetail = (productId) => ({
  type: PRODUCT_ACTION.FETCH_FULL_DETAIL,
  payload: productId,
})

export const setProductDetail = (product) => ({
  type: PRODUCT_ACTION.SET_DETAIL_DATA,
  payload: product,
})

export const setProductDetailError = (error) => ({
  type: PRODUCT_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const clearProductDetail = () => ({
  type: PRODUCT_ACTION.SET_DETAIL_DATA,
  payload: makeDefaultProduct(),
})

export const setCachedProducts = (data = {}) => ({
  type: PRODUCT_ACTION.SET_CACHED_PRODUCTS,
  payload: data,
})

export default {
  fetchProductList,
  setProductListData,
  setProductListError,
  fetchProductDetail,
  setProductDetail,
  setProductDetailError,
  clearProductDetail,
  fetchProductFullDetail,
  fetchDuplicateData,
  setCachedProducts,
}
