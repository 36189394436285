import {
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'
import customerApi from '~/services/apis/customer'
import { customerAction } from '~/redux/actions/customer'
import { mapCovaCustomerList, mapCustomerList } from '~/utils/customer'
import noteCustomerApi from '~/services/apis/noteCustomer'

export function* getCustomerData(action) {
  try {
    const response = yield call(customerApi.get, action.payload.customerId, action.payload.timeZone)
    yield put({
      type: customerAction.GET_CUSTOMER_DATA_SUCCESS,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: customerAction.GET_CUSTOMER_DATA_FAILURE,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* resetPassword(action) {
  try {
    const response = yield call(customerApi.updateCustomerInfo, action.payload.params)
    action.callbacks.onSuccess(response)
  } catch (error) {
    action.callbacks.onError(error)
  }
}

export function* updateCustomerStatus(action) {
  try {
    const response = yield call(customerApi.updateCustomerInfo, action.payload.params)
    action.callbacks.onSuccess(response)
    yield put({
      type: customerAction.UPDATE_CUSTOMER_STATUS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    action.callbacks.onError(error)
  }
}

export function* updateCustomerInfo(action) {
  try {
    const response = yield call(customerApi.updateCustomerInfo, action.payload.params)
    action.callbacks.onSuccess(response)
    yield put({
      type: customerAction.UPDATE_CUSTOMER_INFO_SUCCESS,
      payload: response,
    })
  } catch (error) {
    action.callbacks.onError(error)
  }
}

export function* removeCustomer(action) {
  try {
    const response = yield call(customerApi.removeOne, action.payload.customerId)
    action.callbacks.onSuccess(response)
  } catch (error) {
    action.callbacks.onError(error)
  }
}

export function* getCustomerList(action) {
  try {
    const response = yield call(customerApi.getList, action.payload.params)
    yield put({
      type: customerAction.GET_CUSTOMER_LIST_DATA_REQUEST_SUCCESS,
      payload: { ...response, items: mapCustomerList(response.items) },
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: customerAction.GET_CUSTOMER_LIST_DATA_REQUEST_FAILURE,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* approveCustomer(action) {
  try {
    const response = yield call(customerApi.updateCustomerInfo, action.payload.params.data)
    yield put({
      type: customerAction.APPROVE_CUSTOMER_SUCCESS,
      payload: { response, index: action.payload.params.index },

    })
    action.callbacks.onSuccess()
  } catch (error) {
    yield put({
      type: customerAction.APPROVE_CUSTOMER_FAILURE,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* denyCustomer(action) {
  try {
    const response = yield call(
      customerApi.updateCustomerInfo,
      action.payload.params.data,
    )
    yield put({
      type: customerAction.DENY_CUSTOMER_SUCCESS,
      payload: { response, index: action.payload.params.index },
    })
    action.callbacks.onSuccess()
  } catch (error) {
    yield put({
      type: customerAction.DENY_CUSTOMER_FAILURE,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* getCovaCustomerData(action) {
  try {
    const response = yield call(customerApi.getCovaCustomer, action.payload.customerId)
    yield put({
      type: customerAction.GET_COVA_CUSTOMER_DATA_SUCCESS,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: customerAction.GET_COVA_CUSTOMER_DATA_FAILED,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* getCovaCustomerList(action) {
  try {
    const response = yield call(customerApi.getCovaList, action.payload.params)
    yield put({
      type: customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST_SUCCESS,
      payload: { ...response, items: mapCovaCustomerList(response.items) },
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST_FAILURE,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* fetchCustomerNotes(action) {
  try {
    const resp = yield call(noteCustomerApi.getList, action.payload)
    yield put({
      type: customerAction.SET_CUSTOMER_NOTES,
      payload: resp,
    })
    if (action.callbacks?.onSuccess) action.callbacks?.onSuccess(resp)
  } catch (e) {
    yield put({
      type: customerAction.SET_CUSTOMER_NOTES_ERROR,
      payload: e,
    })
    if (action.callbacks?.onError) action.callbacks?.onError(resp)
  }
}

export function* watchGetCustomerData() {
  yield takeLatest(customerAction.GET_CUSTOMER_DATA_REQUEST, getCustomerData)
}

export function* watchResetPassword() {
  yield takeLatest(customerAction.RESET_PASSWORD_REQUEST, resetPassword)
}

export function* watchRemoveCustomer() {
  yield takeLatest(customerAction.REMOVE_CUSTOMER_REQUEST, removeCustomer)
}

export function* watchUpdateCustomerStatus() {
  yield takeLatest(customerAction.UPDATE_CUSTOMER_STATUS, updateCustomerStatus)
}

export function* watchUpdateCustomerInfo() {
  yield takeLatest(customerAction.UPDATE_CUSTOMER_INFO, updateCustomerInfo)
}

export function* watchGetCustomerList() {
  yield takeLatest(customerAction.GET_CUSTOMER_LIST_DATA_REQUEST, getCustomerList)
}

export function* watchApproveCustomerInfo() {
  yield takeLatest(
    customerAction.APPROVE_CUSTOMER,
    approveCustomer,
  )
}

export function* watchDenyCustomerInfo() {
  yield takeLatest(customerAction.DENY_CUSTOMER, denyCustomer)
}

export function* watchGetCovaCustomerData() {
  yield takeLatest(customerAction.GET_COVA_CUSTOMER_DATA_REQUEST, getCovaCustomerData)
}

export function* watchGetCovaCustomerList() {
  yield takeLatest(customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST, getCovaCustomerList)
}

export function* watchFetchCustomerNotes() {
  yield takeLatest(customerAction.FETCH_CUSTOMER_NOTES, fetchCustomerNotes)
}

export default function* media() {
  yield fork(watchGetCustomerData)
  yield fork(watchResetPassword)
  yield fork(watchRemoveCustomer)
  yield fork(watchUpdateCustomerStatus)
  yield fork(watchUpdateCustomerInfo)
  yield fork(watchGetCustomerList)
  yield fork(watchApproveCustomerInfo)
  yield fork(watchDenyCustomerInfo)

  yield fork(watchGetCovaCustomerData)
  yield fork(watchGetCovaCustomerList)
  yield fork(watchFetchCustomerNotes)
}
