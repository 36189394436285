export const DEFAULT_SKU_LIST_DATA = {
  items: [],
  total_items: 0,
  total_pages: 0,
}

export const DEFAULT_SKU_DETAIL = {
  cost: null,
  weight: null,
  name: '',
  length: null,
  width: null,
  height: null,
}

export const DEFAULT_SKU_TAGS_LIST_DATA = {
  items: [],
  total_items: 0,
  total_pages: 0,
}

export const DEFAULT_SKU_TAGS_DETAIL = {
  name: '',
  description: '',
}
