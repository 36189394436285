import ProductRouters from './ProductRouters'
import AdminRouters from './AdminRouters'
import CustomerRouters from './CustomerRouters'
import MediaRouters from './MediaRouters'
import OrderRouters from './OrderRouters'
import CouponRouters from './CouponRouters'
import TagRouters from './TagRouters'
import CategoryRouters from './CategoryRouters'
import AttributeRouters from './AttributeRouters'
import FulfillmentRouters from './FulfillmentRouters'
import CommentRouters from './CommentRouters'
import InventoryRouters from './InventoryRouters'
import AnalyticRouters from './AnalyticRouters'
import PointRewardRouters from './PointRewardRouters'
import PromotionRouters from './PromotionRouters'
import PerformanceRouters from './PerformanceRouters'

const SystemRouter = [
  ...ProductRouters,
  ...CustomerRouters,
  ...MediaRouters,
  ...OrderRouters,
  ...CouponRouters,
  ...PointRewardRouters,
  ...TagRouters,
  ...CategoryRouters,
  ...AttributeRouters,
  ...FulfillmentRouters,
  ...CommentRouters,
  ...InventoryRouters,
  ...AnalyticRouters,
  ...PromotionRouters,
  ...PerformanceRouters,
  ...AdminRouters,
]

export default SystemRouter
