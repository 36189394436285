/* eslint-disable require-yield */
import { takeLatest, fork } from 'redux-saga/effects'
import { updateTimeZoneMoment } from '~/utils/datetime'
import { AUTH_ACTION } from '~/modules/auth/redux/action'
import { mapSettingData } from '~/utils/user'
import { AUTH_TYPE } from '~/constants/auth'

export function* updateTimeZone(action) {
  const userSettings = action.type === AUTH_ACTION.SIGN_IN_SUCCESS ? mapSettingData(action?.payload) : action?.payload?.user.data.user_settings
  if (userSettings) {
    updateTimeZoneMoment(userSettings.tz_setting, userSettings.tz_timezone)
  } else {
    updateTimeZoneMoment()
  }
}

// export function* reloadWindow() {
//   window.location.reload()
// }

export function* redriectSigup({ payload: { email } }) {
  const whiteList = Object.values(AUTH_TYPE).filter((url) => url !== AUTH_TYPE.SIGN_IN)
  if (whiteList.every((url) => url !== window.location.pathname)) { window.location.replace(`/sign-up?email=${email}`) }
}

export function* ssoRedirectSignUp({ payload: { email } }) {
  const whiteList = Object.values(AUTH_TYPE)
  if (whiteList.every((url) => url !== window.location.pathname)) { window.location.replace(`/sign-up?email=${email}`) }
}

/*
export function* handlePersist() {
  // Handle action persist
}
*/

export function* watchGetDashboardData() {
  yield takeLatest('persist/REHYDRATE', updateTimeZone)
  yield takeLatest(AUTH_ACTION.SIGN_IN_SUCCESS, updateTimeZone)
  // yield takeLatest(AUTH_ACTION.SIGN_IN_WITH_TOKEN_SUCCESS, reloadWindow)
  yield takeLatest(AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN_MISSING_CLAIM, ssoRedirectSignUp)
  yield takeLatest(AUTH_ACTION.SIGN_IN_WITH_TOKEN_MISSING_CLAIM, redriectSigup)
  // yield takeLatest('persist/PERSIST', handlePersist)
}

export default function* persist() {
  yield fork(watchGetDashboardData)
}
