export const goToTop = (id) => {
  const element = document.getElementById(id)
  // eslint-disable-next-line no-unused-expressions
  element && element.scrollTo({ top: 0, behavior: 'smooth' })
}

export const goToLeft = (id) => {
  const element = document.getElementById(id)
  // eslint-disable-next-line no-unused-expressions
  element && element.scrollTo({ left: 0, behavior: 'smooth' })
}
