import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  WEBPUSH: 'stores/woocommerce/webpush/',
}

const read = (params) => httpClient.json.post(
  url.WEBPUSH,
  () => ({ ...params, action: ACTIONS.READ }),
)

const subscribe = (params) => httpClient.json.post(
  url.WEBPUSH,
  () => ({ ...params, action: ACTIONS.SUBSCRIBE }),
)

const unsubscribe = (params) => httpClient.json.post(
  url.WEBPUSH,
  () => ({ ...params, action: ACTIONS.UNSUBSCRIBE }),
)

const webpushApi = {
  read,
  subscribe,
  unsubscribe,
}

export default webpushApi
