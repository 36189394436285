import {
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'
import userApi from '~/services/apis/user'
import organizationApi from '~/services/apis/organization'
import storeApi from '~/services/apis/store'
import warehouseApi from '~/services/apis/warehouse'
import { mapUpdateSettingData, mapSettingData } from '~/utils/user'
import {
  USER_ACTION,
  updateSettingFail, updateSettingSuccess,
  updateOrganizationSuccess, updateOrganizationFailed,
  getOrgSuccess, getOrgFailed,
  getStoresSuccess, getStoresFailed,
  removeStoreSuccess, removeStoreFailed,
  linkStoreSuccess, linkStoreFailed, updateStoreFailed, updateStoreSuccess,
  removeWarehouseSuccess, removeWarehouseFailed,
  updateWarehouseSuccess, updateWarehouseFailed,
  createWarehouseSuccess, createWarehouseFailed,
  linkCovaStoreSuccess,
  linkCovaStoreFailed,
} from '../actions/user'
import { updateTimeZoneMoment } from '~/utils/datetime'
import { DELETED_STORE_KEY } from '~/constants/localStorage'
import { set as setLocalStorage, clear as clearLocalStorage } from '~/utils/storage/localStorage'
import covaApi from '~/services/apis/cova'
import { STORE_TYPES } from '~/constants/store'

export function* updateSettingData({ payload }) {
  try {
    const res = yield call(userApi.updateSetting, mapUpdateSettingData(payload))
    const newSetting = mapSettingData(res)
    updateTimeZoneMoment(newSetting.tz_setting, newSetting.tz_timezone)
    showDialog(dialogParams.changesSaved)
    yield put(updateSettingSuccess(newSetting))
  } catch (e) {
    yield put(updateSettingFail(e))
  }
}

export function* updateOrganization({ payload }) {
  try {
    const response = yield call(organizationApi.update, payload)
    showDialog(dialogParams.changesSaved)
    yield put(updateOrganizationSuccess(response))
  } catch (e) {
    yield put(updateOrganizationFailed(e))
  }
}

export function* getOrg({ payload }) {
  try {
    const response = yield call(organizationApi.get, payload)
    yield put(getOrgSuccess(response))
  } catch (e) {
    yield put(getOrgFailed(e))
  }
}

export function* getStores({ payload }) {
  try {
    const response = yield call(storeApi.get, payload)
    yield put(getStoresSuccess(response))
  } catch (e) {
    yield put(getStoresFailed(e))
  }
}

export function* removeStore({ payload }) {
  try {
    const response = yield call(storeApi.remove, payload)
    yield put(removeStoreSuccess(response))
    showDialog(dialogParams.storeDeleted)

    const { organization_id, store_id } = response
    setLocalStorage(DELETED_STORE_KEY, { organization_id, store_id })
    clearLocalStorage(DELETED_STORE_KEY)
  } catch (e) {
    yield put(removeStoreFailed(e))
  }
}

function* linkStore({ payload, onSuccess }) {
  try {
    const { warehouse, ...rest } = payload
    let warehouseRes = warehouse
    if (!rest.manage_inventory) {
      warehouseRes = yield call(warehouseApi.create, { ...warehouse, organization_id: payload.organization_id })
    }
    const response = yield call(storeApi.create, {
      ...rest,
      ...(!rest.manage_inventory && warehouseRes.warehouse_id ? {
        linked_warehouses: [warehouseRes],
      } : {}),
    })
    if (onSuccess) {
      onSuccess(response)
    }
    yield put(linkStoreSuccess({ ...payload, ...response, authenticated: false }))
  } catch (e) {
    yield put(linkStoreFailed(e))
  }
}

function* linkWooStore({ payload, onSuccess }) {
  try {
    const { warehouse, ...rest } = payload
    let warehouseRes = warehouse
    if (!rest.manage_inventory) {
      warehouseRes = yield call(warehouseApi.create, { ...warehouse, organization_id: payload.organization_id })
    }
    const response = yield call(storeApi.create, {
      ...rest,
      ...(!rest.manage_inventory && warehouseRes.warehouse_id ? {
        linked_warehouses: [warehouseRes],
      } : {}),
    })
    if (onSuccess) {
      onSuccess(response)
    }
    yield put(linkStoreSuccess({
      ...payload, store_type: STORE_TYPES.WOO, ...response, authenticated: false,
    }))
  } catch (e) {
    yield put(linkStoreFailed(e))
  }
}

function* updateStore({ payload, onSuccess, onError }) {
  try {
    const response = yield call(storeApi.update, payload)
    yield put(updateStoreSuccess({ ...payload, ...response }))
    if (onSuccess) {
      onSuccess()
    }
    showDialog({ ...dialogParams.storeSaved })
  } catch (e) {
    yield put(updateStoreFailed(e))
    showDialog({ ...dialogParams.error(e), onOk: onError || (() => { }) })
  }
}

export function* removeWarehouse({ payload }) {
  try {
    const response = yield call(warehouseApi.remove, payload)
    yield put(removeWarehouseSuccess(response))
    showDialog(dialogParams.warehouseDeleted)
  } catch (e) {
    yield put(removeWarehouseFailed(e))
  }
}

export function* updateWarehouse({ payload }) {
  try {
    const { params, backToList } = payload
    const response = yield call(warehouseApi.update, params)
    yield put(updateWarehouseSuccess(response))
    showDialog(dialogParams.warehouseUpdated)
    backToList(response)
  } catch (e) {
    yield put(updateWarehouseFailed(e))
  }
}

export function* createWarehouse({ payload }) {
  const { params, backToList } = payload
  try {
    const response = yield call(warehouseApi.create, params)
    yield put(createWarehouseSuccess(response))
    showDialog(dialogParams.warehouseCreated)
    backToList(response)
  } catch (e) {
    yield put(createWarehouseFailed(e))
  }
}

function* linkCovaStore({ payload, onSuccess }) {
  try {
    const response = yield call(covaApi.create, payload)
    if (onSuccess) {
      onSuccess(response)
    }
    yield put(linkCovaStoreSuccess({
      ...payload, store_type: STORE_TYPES.COVA, ...response, authenticated: false,
    }))
  } catch (e) {
    yield put(linkCovaStoreFailed(e))
  }
}

export function* watchUpdateSettingData() {
  yield takeLatest(USER_ACTION.UPDATE_SETTINGS, updateSettingData)
}

export function* watchUpdateOrganization() {
  yield takeLatest(USER_ACTION.UPDATE_ORGANIZATION, updateOrganization)
}

export function* watchGetOrg() {
  yield takeLatest(USER_ACTION.GET_ORG, getOrg)
}

export function* watchGetStores() {
  yield takeLatest(USER_ACTION.GET_STORES, getStores)
}

export function* watchLinkStore() {
  yield takeLatest(USER_ACTION.LINK_STORE, linkStore)
}

export function* watchLinkWooStore() {
  yield takeLatest(USER_ACTION.LINK_WOO_STORE, linkWooStore)
}

export function* watchLinkCovaStore() {
  yield takeLatest(USER_ACTION.LINK_COVA_STORE, linkCovaStore)
}

export function* watchUpdateStore() {
  yield takeLatest(USER_ACTION.UPDATE_STORE, updateStore)
}

export function* watchRemoveStore() {
  yield takeLatest(USER_ACTION.REMOVE_STORE, removeStore)
}

export function* watchRemoveWarehouse() {
  yield takeLatest(USER_ACTION.REMOVE_WAREHOUSE, removeWarehouse)
}

export function* watchUpdateWarehouse() {
  yield takeLatest(USER_ACTION.UPDATE_WAREHOUSE, updateWarehouse)
}

export function* watchCreateWarehouse() {
  yield takeLatest(USER_ACTION.CREATE_WAREHOUSE, createWarehouse)
}

export default function* share() {
  yield fork(watchUpdateSettingData)
  yield fork(watchUpdateOrganization)
  yield fork(watchGetOrg)
  yield fork(watchGetStores)
  yield fork(watchLinkStore)
  yield fork(watchLinkWooStore)
  yield fork(watchUpdateStore)
  yield fork(watchRemoveStore)
  yield fork(watchRemoveWarehouse)
  yield fork(watchUpdateWarehouse)
  yield fork(watchCreateWarehouse)
  yield fork(watchLinkCovaStore)
}
