import { BASE_URL_CANTEC } from '~/constants/environment'
import httpClient from '~/modules/core/httpClient'
import { basePath, addHeader, jwtHeader } from '~/modules/core/httpClient/interceptors'

const cantecClient = httpClient.json.create()
  .ejectReq('basePath')
  .useReq('basePath', basePath(BASE_URL_CANTEC))
  .ejectReq('defaultHeader')
  .useReq('defaultHeader', addHeader(
    () => ({ 'Content-Type': 'multipart/form-data' }),
    jwtHeader,
  ))
  .ejectRes('defaultResponseHandler')
  .useRes('defaultResponseHandler', (res) => res.data)
// eslint-disable-next-line
const get = async (url, cfg) => {
  return cantecClient.get(url, cfg)
}
// eslint-disable-next-line
const post = async (url, data, cfg) => {
  return cantecClient.post(url, data, cfg)
}

export default {
  get,
  post,
}
