export const ONBOARDING_LINK_STORE_STEPS = {
  GENERAL: 'general',
  WAREHOUSE: 'warehouse',
  CATEGORY: 'category',
  VERIFICATION: 'verification',
}

export const ONBOARDING_LINK_STORE_COVA_STEPS = {
  GENERAL: 'general',
  WAREHOUSE: 'warehouse',
  VERIFICATION: 'verification',
  CATEGORIES: 'categories',
}

export const ONBOARDING_LINK_STORE_GREENLINE_STEPS = {
  GENERAL: 'general',
  WAREHOUSE: 'warehouse',
  VERIFICATION: 'verification',
  CATEGORIES: 'categories',
}

export const ONBOARDING_TYPES = {
  SIGN_UP: 'sign-up',
  USER_INFO: 'user-info',
  ORGANIZATION: 'organization',
  NEW_ORGANIZATION: 'new-organization',
  STORE_WOO: 'store-woo',
  STORE_COVA: 'store-cova',
  SYNC: 'sync',
  STORE: 'store',
  STORE_GREENLINE: 'store-greenline',
}

export const AUTH_TYPE = {
  SIGN_IN: '/login',
  SIGN_UP: '/sign-up',
  SIGN_UP_EMAIL_SENT: '/verification-email-sent',
  SIGN_UP_EMAIL_VERIFIED: '/email-verified',
  SIGN_UP_EMAIL_NOT_VERIFIED: '/email-not-verified',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password/email-sent',
  SET_PASSWORD: '/reset-password/new-password',
  SET_PASSWORD_SUCCESS: '/reset-password/new-password/success',
  REDIRECT_USER: '/redirect-user',
  INVITATION_EXPIRED: '/invitation-expired',
  SHOPIFY_OA: '/shopify-oauth',
}

export const EXTENDED_AUTH_TYPE = {
  ONBOARDING: '/legacy/onboarding',
  ONBOARDING_USER_INFO: `/legacy/onboarding/${ONBOARDING_TYPES.USER_INFO}`,
  ONBOARDING_ORG: `/legacy/onboarding/${ONBOARDING_TYPES.ORGANIZATION}`,
  ONBOARDING_NEW_ORG: `/legacy/onboarding/${ONBOARDING_TYPES.NEW_ORGANIZATION}`,
  ONBOARDING_SYNC: `/legacy/onboarding/${ONBOARDING_TYPES.SYNC}`,
  ONBOARDING_STORE_WOO: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_WOO}`,
  ONBOARDING_STORE_COVA: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_COVA}`,
  ONBOARDING_STORE_WAREHOUSE: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_WOO}/${ONBOARDING_LINK_STORE_STEPS.WAREHOUSE}`,
  ONBOARDING_STORE_CATEGORY: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_WOO}/${ONBOARDING_LINK_STORE_STEPS.CATEGORY}`,
  ONBOARDING_STORE_VERIFICATION: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_WOO}/${ONBOARDING_LINK_STORE_STEPS.VERIFICATION}`,
  ONBOARDING_STORE_COVA_CATEGORIES: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_COVA}/${ONBOARDING_LINK_STORE_COVA_STEPS.CATEGORIES}`,
  ONBOARDING_STORE_COVA_WAREHOUSE: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_COVA}/${ONBOARDING_LINK_STORE_COVA_STEPS.WAREHOUSE}`,
  ONBOARDING_STORE_COVA_VERIFICATION: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_COVA}/${ONBOARDING_LINK_STORE_COVA_STEPS.VERIFICATION}`,
  ONBOARDING_STORE_GREENLINE: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_GREENLINE}`,
  ONBOARDING_STORE_GREENLINE_CATEGORIES: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_GREENLINE}/${ONBOARDING_LINK_STORE_GREENLINE_STEPS.CATEGORIES}`,
  ONBOARDING_STORE_GREENLINE_WAREHOUSE: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_GREENLINE}/${ONBOARDING_LINK_STORE_GREENLINE_STEPS.WAREHOUSE}`,
  ONBOARDING_STORE_GREENLINE_VERIFICATION: `/legacy/onboarding/${ONBOARDING_TYPES.STORE_GREENLINE}/${ONBOARDING_LINK_STORE_GREENLINE_STEPS.VERIFICATION}`,
}

export const AUTH_IMAGE = {
  [AUTH_TYPE.SIGN_IN]: 'sign_in',
  [AUTH_TYPE.SIGN_UP]: 'sign_up',
  [AUTH_TYPE.SIGN_UP_EMAIL_SENT]: 'sign_success',
  [AUTH_TYPE.SIGN_UP_EMAIL_VERIFIED]: 'mail_success',
  [AUTH_TYPE.SIGN_UP_EMAIL_NOT_VERIFIED]: 'mail_fail',
  [AUTH_TYPE.RESET_PASSWORD]: 'forgot_password',
  [AUTH_TYPE.RESET_PASSWORD_SUCCESS]: 'mail',
  [AUTH_TYPE.SET_PASSWORD]: 'forgot_password',
  [AUTH_TYPE.SET_PASSWORD_SUCCESS]: 'reset_success',
  [AUTH_TYPE.INVITATION_EXPIRED]: 'invitation_expired',
  [EXTENDED_AUTH_TYPE.ONBOARDING]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_ORG]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_NEW_ORG]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_WOO]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_COVA]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_WAREHOUSE]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_COVA_WAREHOUSE]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_CATEGORY]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_VERIFICATION]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_GREENLINE]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_GREENLINE_WAREHOUSE]: 'onboard',
  [EXTENDED_AUTH_TYPE.ONBOARDING_STORE_GREENLINE_CATEGORIES]: '',
  [EXTENDED_AUTH_TYPE.ONBOARDING_SYNC]: 'sync',
  [EXTENDED_AUTH_TYPE.ONBOARDING_USER_INFO]: 'sign_up',
}

export const ONBOARDING_LINK_STORE_OPTIONS = [
  { value: ONBOARDING_LINK_STORE_STEPS.GENERAL, label: 'General Store Info' },
  { value: ONBOARDING_LINK_STORE_STEPS.WAREHOUSE, label: 'Inventory & Address' },
  { value: ONBOARDING_LINK_STORE_STEPS.CATEGORY, label: 'Store Category' },
  { value: ONBOARDING_LINK_STORE_STEPS.VERIFICATION, label: 'WooCommerce Verification' },
]

export const ONBOARDING_LINK_STORE_COVA_OPTIONS = [
  { value: ONBOARDING_LINK_STORE_COVA_STEPS.GENERAL, label: 'General Store Info' },
  { value: ONBOARDING_LINK_STORE_COVA_STEPS.WAREHOUSE, label: 'Warehouse Info' },
  { value: ONBOARDING_LINK_STORE_COVA_STEPS.CATEGORIES, label: 'Match Categories' },
  { value: ONBOARDING_LINK_STORE_COVA_STEPS.VERIFICATION, label: 'Store Theme' },
]

export const ONBOARDING_LINK_STORE_GREENLINE_OPTIONS = [
  { value: ONBOARDING_LINK_STORE_GREENLINE_STEPS.GENERAL, label: 'General Store Info' },
  { value: ONBOARDING_LINK_STORE_GREENLINE_STEPS.WAREHOUSE, label: 'Warehouse Info' },
  { value: ONBOARDING_LINK_STORE_GREENLINE_STEPS.CATEGORIES, label: 'Match Categories' },
  { value: ONBOARDING_LINK_STORE_GREENLINE_STEPS.VERIFICATION, label: 'Store Theme' },
]

export const ONBOARDING_TYPE_OPTIONS = [
  { value: ONBOARDING_TYPES.ORGANIZATION, label: '1. NAME ORGANIZATION' },
  { value: ONBOARDING_TYPES.STORE, label: '2. LINK STORE' },
]

export const LINK_STORE_OPTION = [
  {
    value: `${ONBOARDING_TYPES.STORE_WOO}`,
    label: 'WooCommerce',
  },
  {
    value: `${ONBOARDING_TYPES.STORE_COVA}`,
    label: 'Cova',
  },
  {
    value: `${ONBOARDING_TYPES.STORE_GREENLINE}`,
    label: 'Greenline',
  },
]

export const MARK_SCREEN_ID = {
  [ONBOARDING_TYPES.STORE_WOO]: 'wooGeneral__store--type',
  [ONBOARDING_TYPES.STORE_COVA]: 'covaGeneral__store--type',
  [ONBOARDING_TYPES.STORE_GREENLINE]: 'greenlineGeneral__store--type',
}

export const TIME_COOKIE_TOKEN_EXPIRATION = 3

export const COOKIE_KEYS = {
  CUSTOM_TOKEN: 'customToken',
  REFRESH_TOKEN: 'refreshToken',
  DATA_TOKEN: 'dataToken',
}
