const style = () => ({
  container: {
    display: 'flex',
    width: '100vw',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default style
