import produce from 'immer'

import { SKU_ACTIONS } from '../actions/sku'
import { DEFAULT_SKU_LIST_DATA, DEFAULT_SKU_DETAIL } from '../default/sku'

const initialState = {
  data: DEFAULT_SKU_LIST_DATA,
  loading: false,
  error: null,

  detail: DEFAULT_SKU_DETAIL,
  loadingDetail: false,
  errorDetail: null,
  loadingForm: false,
  errorForm: null,
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case SKU_ACTIONS.GET_SKU_LIST:
      draft.loading = true
      draft.error = null
      break
    case SKU_ACTIONS.GET_SKU_LIST_OK:
      draft.data = payload
      draft.loading = false
      break
    case SKU_ACTIONS.GET_SKU_LIST_ERR:
      draft.loading = false
      draft.error = payload
      break

    case SKU_ACTIONS.GET_SKU_DETAIL:
      draft.loadingDetail = true
      draft.errorDetail = null
      break
    case SKU_ACTIONS.GET_SKU_DETAIL_OK:
      draft.detail = payload
      draft.loadingDetail = false
      break
    case SKU_ACTIONS.GET_SKU_DETAIL_ERR:
      draft.loadingDetail = false
      draft.errorDetail = payload
      break

    case SKU_ACTIONS.CREATE_SKU:
      draft.loadingForm = true
      break
    case SKU_ACTIONS.CREATE_SKU_OK:
      draft.loadingForm = false
      draft.detail = payload
      break
    case SKU_ACTIONS.CREATE_SKU_FAIL:
      draft.loadingForm = false
      draft.errorDetail = payload
      break

    case SKU_ACTIONS.UPDATE_SKU:
      draft.loadingForm = false
      // draft.detail = payload
      break
    case SKU_ACTIONS.UPDATE_SKU_FAIL:
      draft.loadingForm = false
      draft.errorDetail = payload
      break
    case SKU_ACTIONS.UPDATE_SKU_OK:
      draft.loadingForm = false
      draft.detail = payload
      break
    case SKU_ACTIONS.CLEAR_SKU_DETAIL:
      draft.loadingForm = false
      draft.detail = payload
      break

    default:
      break
  }
})

export default reducer
