import produce from 'immer'
import { CREDENTIAL_ACTION } from '~/redux/actions/credential'

const initialState = {
  loadingOrg: false,
  loadingStore: false,
  error: '',
  data: {},
  updatingOrg: false,
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case CREDENTIAL_ACTION.FETCH_LIST: {
      draft.loadingOrg = true
      draft.loadingStore = true
      draft.error = ''
      break
    }
    case CREDENTIAL_ACTION.SET_LIST_DATA: {
      draft.loadingOrg = false
      draft.loadingStore = false
      draft.data = action.payload
      draft.error = ''
      break
    }
    case CREDENTIAL_ACTION.SET_LIST_ERROR: {
      draft.loadingOrg = false
      draft.loadingStore = false
      draft.error = action.payload
      break
    }
    case CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS: {
      draft.updatingOrg = true
      draft.error = ''
      break
    }
    case CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS_SUCCESS: {
      draft.updatingOrg = false
      draft.error = ''
      break
    }
    case CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS_ERROR: {
      draft.updatingOrg = false
      draft.error = action.payload
      break
    }
    case CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS: {
      draft.loadingStore = true
      draft.error = ''
      break
    }
    case CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS_SUCCESS: {
      draft.loadingStore = false
      draft.data.stores = draft.data.stores.map((store) => store._id === action.payload.store_id ? { ...store, ...action.payload.data } : store)
      draft.error = ''
      break
    }
    case CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS_ERROR: {
      draft.loadingStore = false
      draft.error = action.payload
      break
    }
    default:
      break
  }
})

export default reducer
