import { ONBOARDING_STEP, ROLES } from '../constants'
import { CANTEC_TYPE, CANTEC_TYPE_NAME, STORE_TYPES } from '~/constants/store'
import { ONBOARDING_TYPES } from '~/constants/auth'

export const isLoggedIn = (u) => Boolean(u)

export const getRole = (storeId) => (u) => {
  if (!isLoggedIn(u)) return null
  return u.stores[storeId]?.roleId
}
export const isOwner = (storeId) => (u) => getRole(storeId)(u) === ROLES.OWNER
export const isAdministrator = (storeId) => (u) => getRole(storeId)(u) === ROLES.ADMIN
export const isManager = (storeId) => (u) => [ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER].includes(getRole(storeId)(u))
export const isServiceMember = (storeId) => (u) => [ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER].includes(getRole(storeId)(u))
export const isFulfillmentAssociate = (storeId) => (u) => getRole(storeId)(u) === ROLES.FULFILLMENT_ASSOCIATE

export const hasVerifiedEmail = (u) => isLoggedIn(u) && u.emailVerified
export const hasNotVerifiedEmail = (u) => isLoggedIn(u) && !u.emailVerified
export const inOopsStatus = (u) => hasVerifiedEmail(u) && !Object.values(u.orgs).some((org) => org?.role?.isOwner) && u.deactivated

export const inOnBoardingOrgPhase = (u) => hasVerifiedEmail(u) && Object.keys(u.orgs).length === 0 && !u.deactivated

export const inOnBoardingUpdateUserPhase = (u) => hasVerifiedEmail(u) && !u.deactivated && !u.firstName && !u.lastName

export const inOnBoardingSyncPhase = (u, orgId) => {
  if (orgId) {
    const stores = Object.values(u?.stores || []).filter((s) => s.orgId === orgId)
    return hasVerifiedEmail(u) && Object.keys(u.orgs).length > 0 && u.orgs[orgId] && u.orgs[orgId].role?.isOwner
      && stores.length > 0 && stores.some((s) => s.authenticated) && !stores.some((s) => s.syncCompleted)
  }
  return hasVerifiedEmail(u) && Object.keys(u.orgs).length > 0
    && Object.keys(u.stores).length > 0 && Object.values(u.stores).some((s) => s.authenticated) && !Object.values(u.stores).some((s) => s.syncCompleted)
}

export const inOnBoardingStorePhase = (u, orgId) => {
  if (orgId) {
    const stores = Object.values(u?.stores || []).filter((s) => s.orgId === orgId)
    return hasVerifiedEmail(u) && Object.keys(u.orgs).length > 0 && u.orgs[orgId] && u.orgs[orgId].role?.isOwner
      && (stores.length === 0 || (stores.length > 0 && !stores.some((s) => s.authenticated) && u.orgs[orgId]?.assignedStores?.length === 0))
  }
  return hasVerifiedEmail(u) && Object.keys(u.orgs).length > 0 && Object.values(u.orgs).some((org) => org?.role?.isOwner)
    && (Object.keys(u.stores).length === 0 || (Object.keys(u.stores).length > 0 && !Object.values(u.stores).some((s) => s.authenticated) && Object.values(u.orgs).find((org) => org?.role?.isOwner)?.assignedStores?.length === 0))
}
export const inOnBoardingVerifyPhase = (u, orgId) => {
  if (orgId) {
    const stores = Object.values(u?.stores || []).filter((s) => s.orgId === orgId)
    return hasVerifiedEmail(u) && Object.keys(u.orgs).length > 0 && u.orgs[orgId] && u.orgs[orgId].role?.isOwner
      && u.orgs[orgId]?.assignedStores?.length > 0 && !stores.some((s) => s.authenticated)
  }
  return hasVerifiedEmail(u) && Object.keys(u.orgs).length > 0 && Object.values(u.orgs).some((org) => org?.role?.isOwner)
    && Object.values(u.orgs).find((org) => org?.role?.isOwner)?.assignedStores?.length > 0 && !Object.values(u.stores).some((s) => s.authenticated)
}

export const inOnBoardingNewOrgPhase = (u, type, orgId) => hasVerifiedEmail(u) && type === ONBOARDING_TYPES.NEW_ORGANIZATION && (!orgId || !u.orgs[orgId])

const findOnboardingStore = (u, orgId) => {
  if (!u) return null
  if (hasNotVerifiedEmail(u)) return null

  const ownedOrg = orgId ? (u.orgs[orgId]?.role?.isOwner ? u.orgs[orgId] : null) : Object.values(u.orgs).find((org) => org?.role?.isOwner)
  if (!ownedOrg) return null
  const stores = orgId ? Object.values(u.stores).filter((s) => s.orgId === orgId) : Object.values(u.stores)

  if (!stores.length || stores.some((s) => s.authenticated)) return null

  const { assignedStores } = ownedOrg

  if (!assignedStores?.length) return null

  const storeId = assignedStores[assignedStores.length - 1]

  return u.stores[storeId]
}

export const inOnBoardingVerifyWooPhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.WOO)

export const inOnBoardingCategoriesCovaPhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.COVA && (!findOnboardingStore(u, orgId)?.storeId || !findOnboardingStore(u, orgId)?.covaMappingSettings?.classification_mapping?.length))

export const inOnBoardingWarehouseCovaPhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.COVA && (!findOnboardingStore(u, orgId)?.storeUrl || findOnboardingStore(u, orgId)?.storeUrl === findOnboardingStore(u, orgId)?.storeId))

export const inOnBoardingVerifyCovaPhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.COVA && findOnboardingStore(u, orgId)?.storeUrl && !!findOnboardingStore(u, orgId)?.covaMappingSettings?.classification_mapping?.length)

// export const inOnBoardingVerifyCovaPhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.COVA && findOnboardingStore(u, orgId)?.storeUrl && findOnboardingStore(u, orgId)?.storeUrl !== findOnboardingStore(u, orgId)?.storeId)

export const inOnBoardingCategoriesGreenlinePhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.GREENLINE && findOnboardingStore(u, orgId)?.onboardingStep === ONBOARDING_STEP.SELECTED_LOCATION)

export const inOnBoardingWarehouseGreenlinePhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.GREENLINE && findOnboardingStore(u, orgId)?.onboardingStep === ONBOARDING_STEP.AUTHENTICATED)

export const inOnBoardingVerifyGreenlinePhase = (u, orgId) => !!(findOnboardingStore(u, orgId)?.storeType === STORE_TYPES.GREENLINE && findOnboardingStore(u, orgId)?.onboardingStep === ONBOARDING_STEP.MAPPED_CATEGORY)

// export const inOnBoarding = (u) => inOnBoardingOrgPhase(u) || inOnBoardingSyncPhase(u) || inOnBoardingStorePhase(u) || inOnBoardingVerifyPhase(u)
export const inOnBoarding = (u) => hasVerifiedEmail(u) && (Object.keys(u?.orgs)?.length === 0 || Object.keys(u?.stores)?.length === 0) && !u.deactivated

export const isValidUser = (u) => hasVerifiedEmail(u) && !inOnBoarding(u) && !inOopsStatus(u)

export const not = (cond) => (u) => !cond(u)
export const anyOf = (...conds) => (u) => conds.some((c) => c(u))
export const allOf = (...conds) => (u) => conds.every((c) => c(u))

export const inRole = (r) => (storeId) => (u) => {
  if (!isLoggedIn(u)) return null
  const role = getRole(storeId)(u)
  return role && (r || []).includes(role)
}
export const isActivePointReward = () => (u) => {
  const validPlugins = u && u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}
  return !!validPlugins[CANTEC_TYPE_NAME[CANTEC_TYPE.POINT_INTEGRATION]]
}

export const hasOnfleetPlugin = () => (u) => {
  const validPlugins = u && u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}
  return !!validPlugins['Breadstack Onfleet Local Delivery']
}

export const isActiveUserApproval = () => (u) => {
  const validPlugins = u && u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}
  return !!validPlugins[CANTEC_TYPE_NAME[CANTEC_TYPE.USER_APPROVAL]]
}

export const isActiveShippingTracking = () => (u) => {
  const validPlugins = u && u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}
  return !!validPlugins[CANTEC_TYPE_NAME[CANTEC_TYPE.SHIPPING_TRACKING]]
}

export const isActiveCantecDelivery = () => (u) => {
  const validPlugins = u && u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}
  return !!validPlugins[CANTEC_TYPE_NAME[CANTEC_TYPE.CANTEC_DELIVERY]]
}

export const isActiveCantecLocalPickup = () => (u) => {
  const validPlugins = u && u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}
  return !!validPlugins[CANTEC_TYPE_NAME[CANTEC_TYPE.CANTEC_LOCAL_PICKUP]]
}

export const notCovaStore = (storeId) => (u) => (u.stores[storeId]?.storeType !== STORE_TYPES.COVA)
export const notGreenlineStore = (storeId) => (u) => (u.stores[storeId]?.storeType !== STORE_TYPES.GREENLINE)
export const isCovaStore = (storeId) => (u) => (u.stores[storeId]?.storeType === STORE_TYPES.COVA)
export const isGreenlineStore = (storeId) => (u) => (u.stores[storeId]?.storeType === STORE_TYPES.GREENLINE)
export const isWooStore = (storeId) => (u) => (u.stores[storeId]?.storeType === STORE_TYPES.WOO)

export const adminRoles = inRole([ROLES.OWNER, ROLES.ADMIN])
export const dashboardRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const productRolesEditable = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const productRolesView = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const loyaltyRolesEditable = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const loyaltyRolesView = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER])
export const couponRolesEditable = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER])
export const couponRolesView = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER])
export const orderRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER, ROLES.FULFILLMENT_ASSOCIATE])
export const customerRolesEditable = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER])
export const customerSendEmail = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER])
export const customerRolesView = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER, ROLES.FULFILLMENT_ASSOCIATE])
export const fulfillmentRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.FULFILLMENT_ASSOCIATE])
export const onfleetRoles = (storeId) => allOf(fulfillmentRoles(storeId), hasOnfleetPlugin())
export const inventoryRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.FULFILLMENT_ASSOCIATE])
export const inventoryRolesEditableTransferAdjust = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER, ROLES.FULFILLMENT_ASSOCIATE])
export const inventoryRolesEditableAll = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const mediaRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const pointRewardRoles = (storeId) => allOf(loyaltyRolesView(storeId), isActivePointReward())
export const pointRewardRolesEditable = (storeId) => allOf(loyaltyRolesEditable(storeId), isActivePointReward())
export const analyticRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const adminOptionRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const wooSettingRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const productCreationRoles = (storeId) => allOf(productRolesEditable(storeId), notCovaStore(storeId))
export const notCovaProductRoles = (storeId) => allOf(productRolesEditable(storeId), notCovaStore(storeId))
export const onlyWooProductRoles = (storeId) => allOf(productRolesEditable(storeId), isWooStore(storeId))
export const notGreenlineProductRoles = (storeId) => allOf(productRolesEditable(storeId), notGreenlineStore(storeId))
export const notCovaOrderRoles = (storeId) => allOf(orderRoles(storeId), notCovaStore(storeId))
export const notGreenlineOrderRoles = (storeId) => allOf(orderRoles(storeId), notGreenlineStore(storeId))
export const notCovaInventoryRoles = (storeId) => allOf(inventoryRoles(storeId), notCovaStore(storeId))
export const onlyWooInventoryRoles = (storeId) => allOf(inventoryRoles(storeId), isWooStore(storeId))
export const notCovaInventoryRolesEditableTransferAdjust = (storeId) => allOf(inventoryRolesEditableTransferAdjust(storeId), notCovaStore(storeId))
export const onlyWooInventoryRolesEditableTransferAdjust = (storeId) => allOf(inventoryRolesEditableTransferAdjust(storeId), isWooStore(storeId))
export const notCovaInventoryRolesEditableAll = (storeId) => allOf(inventoryRolesEditableAll(storeId), notCovaStore(storeId))
export const onlyWooInventoryRolesEditableAll = (storeId) => allOf(inventoryRolesEditableAll(storeId), isWooStore(storeId))
export const settingManagerRoles = inRole([ROLES.OWNER, ROLES.ADMIN, ROLES.GENERAL_MANAGER, ROLES.MARKETING_MANAGER])
export const notCovaCustomerRolesEditable = (storeId) => allOf(customerRolesEditable(storeId), notCovaStore(storeId))
export const onlyWooCustomerRolesEditable = (storeId) => allOf(customerRolesEditable(storeId), isWooStore(storeId))
export const notGreenlineCustomerRolesEditable = (storeId) => allOf(customerRolesEditable(storeId), notGreenlineStore(storeId))
