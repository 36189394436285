import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, MEDIA_ROUTES } from '~/constants/router'
import { mediaRoles } from '~/modules/auth/conditions'

const NewMediaLibrary = lazyRetry(() => import('~/pages/NewMediaLibrary'))

const MediaRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${MEDIA_ROUTES.MEDIA_LIBRARY}/:folderId?/:fileMode?`,
    component: NewMediaLibrary,
    layout: 'admin',
    exact: true,
    roles: mediaRoles,
    routeName: 'Media',
  },
]

export default MediaRouters
