import React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

const LoadingLinear = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <LinearProgress />
  </Box>
)

export default LoadingLinear
