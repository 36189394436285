import { GrowthBook } from '@growthbook/growthbook-react'
import { useEffect } from 'react'
import { REACT_APP_ENV, REACT_APP_FEATURE_FLAG_KEY, REACT_APP_FEATURE_FLAG_URL } from '~/constants/environment'

const growthbook = new GrowthBook({
  apiHost: REACT_APP_FEATURE_FLAG_URL,
  clientKey: REACT_APP_FEATURE_FLAG_KEY,
  enableDevMode: REACT_APP_ENV !== 'production',
})

export const useLoadFeatures = () => {
  useEffect(() => {
    growthbook.loadFeatures({ autoRefresh: true })
  }, [])
}
export default growthbook
