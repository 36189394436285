import { DEFAULT_SKU_TAGS_DETAIL } from '../default/skuTags'

export const SKU_TAGS_ACTIONS = {
  GET_SKU_TAGS_LIST: 'skuTags.GET_SKU_LIST',
  GET_SKU_TAGS_LIST_OK: 'skuTags.GET_SKU_LIST_OK',
  GET_SKU_TAGS_LIST_ERR: 'skuTags.GET_SKU_LIST_ERR',

  GET_SKU_TAGS_DETAIL: 'skuTags.GET_SKU_DETAIL',
  GET_SKU_TAGS_DETAIL_OK: 'skuTags.GET_SKU_DETAIL_OK',
  GET_SKU_TAGS_DETAIL_ERR: 'skuTags.GET_SKU_DETAIL_ERR',
  CLEAR_SKU_TAGS_DETAIL: 'skuTags.CLEAR_SKU_TAGS_DETAIL',

  UPDATE_SKU_TAGS: 'skuTags.UPDATE_SKU_TAGS',
  UPDATE_SKU_TAGS_OK: 'skuTags.UPDATE_SKU_TAGS_OK',
  UPDATE_SKU_TAGS_FAIL: 'skuTags.UPDATE_SKU_TAGS_FAIL',

  CREATE_SKU_TAGS: 'skuTags.CREATE_SKU_TAGS',
  CREATE_SKU_TAGS_OK: 'skuTags.CREATE_SKU_TAGS_OK',
  CREATE_SKU_TAGS_FAIL: 'skuTags.CREATE_SKU_TAGS_FAIL',

}

export const getSkuTagsList = (payload) => ({
  type: SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST,
  payload,
})

export const getSkuTagsListSuccess = (payload) => ({
  type: SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST_OK,
  payload,
})

export const getSkuTagsListFail = (payload) => ({
  type: SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST_ERR,
  payload,
})

export const getSkuTagDetail = (payload) => ({
  type: SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL,
  payload,
})

export const getSkuTagDetailSuccess = (payload) => ({
  type: SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL_OK,
  payload,
})

export const getSkuTagDetailFail = (payload) => ({
  type: SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL_ERR,
  payload,
})

export const updateSkuTags = (payload) => ({
  type: SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS,
  payload,
})

export const updateSkuTagsOk = (payload) => ({
  type: SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS_OK,
  payload,
})

export const updateSkuTagsFail = (payload) => ({
  type: SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS_FAIL,
  payload,
})

export const createSkuTag = (payload) => ({
  type: SKU_TAGS_ACTIONS.CREATE_SKU_TAGS,
  payload,
})

export const createSkuTagOk = (payload) => ({
  type: SKU_TAGS_ACTIONS.CREATE_SKU_TAGS_FAIL,
  payload,
})

export const createSkuTagFail = (payload) => ({
  type: SKU_TAGS_ACTIONS.CREATE_SKU_TAGS,
  payload,
})

export const clearSkuTagsDetail = () => ({
  type: SKU_TAGS_ACTIONS.CLEAR_SKU_TAGS_DETAIL,
  payload: DEFAULT_SKU_TAGS_DETAIL,
})

export default {
  getSkuTagsList,
  getSkuTagsListSuccess,
  getSkuTagsListFail,

  getSkuTagDetail,
  getSkuTagDetailSuccess,
  getSkuTagDetailFail,
  clearSkuTagsDetail,

  updateSkuTags,
  updateSkuTagsOk,
  updateSkuTagsFail,

  createSkuTag,
  createSkuTagOk,
  createSkuTagFail,
}
