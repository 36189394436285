export const SHARE_ACTION = Object.freeze({
  GET_CATEGORY_LIST: 'share.GET_CATEGORY_LIST',
  GET_CATEGORY_LIST_OK: 'share.GET_CATEGORY_LIST_OK',
  GET_CATEGORY_LIST_ERR: 'share.GET_CATEGORY_LIST_ERR',

  GET_LOCATIONS: 'share.GET_LOCATIONS',
  GET_LOCATIONS_SUCCESS: 'share.GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILED: 'share.GET_LOCATIONS_FAILED',

  GET_FILTER_LIST: 'share.GET_FILTER_LIST',
  GET_FILTER_LIST_OK: 'share.SET_FILTER_LIST_OK',
  GET_FILTER_LIST_ERR: 'share.SET_FILTER_LIST_ERR',

  ADD_FILTER: 'share.ADD_FILTER',
  ADD_FILTER_OK: 'share.ADD_FILTER_OK',
  ADD_FILTER_ERR: 'share.ADD_FILTER_ERR',

  UPDATE_FILTER: 'share.UPDATE_FILTER',
  UPDATE_FILTER_OK: 'share.UPDATE_FILTER_OK',
  UPDATE_FILTER_ERR: 'share.UPDATE_FILTER_ERR',

  DELETE_FILTER: 'share.DELETE_FILTER',
  DELETE_FILTER_OK: 'share.DELETE_FILTER_OK',
  DELETE_FILTER_ERR: 'share.DELETE_FILTER_ERR',

})

export const getCategoryList = (payload) => ({
  type: SHARE_ACTION.GET_CATEGORY_LIST,
  payload,
})

export const getCategoryListSuccess = (payload) => ({
  type: SHARE_ACTION.GET_CATEGORY_LIST_OK,
  payload,
})

export const getCategoryListFail = (payload) => ({
  type: SHARE_ACTION.GET_CATEGORY_LIST_ERR,
  payload,
})

export const getLocations = (payload) => ({
  type: SHARE_ACTION.GET_LOCATIONS,
  payload,
})

export const getLocationsSuccess = (payload) => ({
  type: SHARE_ACTION.GET_LOCATIONS_SUCCESS,
  payload,
})

export const getLocationsFailed = (payload) => ({
  type: SHARE_ACTION.GET_LOCATIONS_FAILED,
  payload,
})

// filter
export const getFilterList = (payload) => ({
  type: SHARE_ACTION.GET_FILTER_LIST,
  payload,
})

export const getFilterListSuccess = (payload) => ({
  type: SHARE_ACTION.GET_FILTER_LIST_OK,
  payload,
})

export const getFilterListFail = (payload) => ({
  type: SHARE_ACTION.GET_FILTER_LIST_ERR,
  payload,
})

export const addFilter = (payload, callbacks = { onSuccess() {}, onError() {} }) => ({
  type: SHARE_ACTION.ADD_FILTER,
  payload,
  callbacks,
})

export const addFilterSuccess = (payload) => ({
  type: SHARE_ACTION.ADD_FILTER_OK,
  payload,
})

export const addFilterFail = (payload) => ({
  type: SHARE_ACTION.ADD_FILTER_ERR,
  payload,
})

export const updateFilter = (payload) => ({
  type: SHARE_ACTION.UPDATE_FILTER,
  payload,
})

export const updateFilterSuccess = (payload) => ({
  type: SHARE_ACTION.UPDATE_FILTER_OK,
  payload,
})

export const updateFilterFail = (payload) => ({
  type: SHARE_ACTION.UPDATE_FILTER_ERR,
  payload,
})

export const deleteFilter = (payload, callbacks = { onSuccess() {}, onError() {} }) => ({
  type: SHARE_ACTION.DELETE_FILTER,
  payload,
  callbacks,
})

export const deleteFilterSuccess = (payload) => ({
  type: SHARE_ACTION.DELETE_FILTER_OK,
  payload,
})

export const deleteFilterFail = (payload) => ({
  type: SHARE_ACTION.DELETE_FILTER_ERR,
  payload,
})

export default {
  getCategoryList,
  getLocations,

  getFilterList,
  getFilterListSuccess,
  getFilterListFail,
  addFilter,
  addFilterSuccess,
  addFilterFail,
  updateFilter,
  updateFilterSuccess,
  updateFilterFail,
  deleteFilter,
  deleteFilterSuccess,
  deleteFilterFail,
}
