import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
  READ_V2: 'read_v2',
}

const url = {
  CALENDAR: '/stores/woocommerce/calendar',
}

const getCalendarData = (params) => httpClient.post(url.CALENDAR, {
  action: ACTIONS.READ_V2,
  parameters: params,
})

const countOrders = (params) => httpClient.post(url.CALENDAR, {
  action: ACTIONS.COUNT_ORDERS,
  parameters: params,
})

const categoryApi = {
  getCalendarData,
  countOrders,
}

export default categoryApi
