import {
  takeLatest, all, fork, call, put,
} from 'redux-saga/effects'
import calendarApi from '~/services/apis/calendar'
import Actions, { CALENDAR_ACTION } from '~/redux/actions/calendar'

export function* fetchCalendarDetail(action) {
  try {
    const resp = yield call(calendarApi.getCalendarData, action.payload)
    yield put(Actions.setCalendarData(resp))
  } catch (e) {
    yield put(Actions.setCalendarDataError(e))
  }
}

export function* watchCalendar() {
  yield all([
    takeLatest(CALENDAR_ACTION.FETCH_DATA, fetchCalendarDetail),
  ])
}

export default function* calendar() {
  yield fork(watchCalendar)
}
