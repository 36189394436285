import moment from 'moment-timezone'
import { TIMEZONE_SETTING } from '~/constants/settings'

export const USER_DEVICE_ZONE = Object.freeze(moment.tz.guess())

export const mappingTimezone = (tz) => {
  switch (tz) {
    case '-3.5':
      return 'Canada/Newfoundland'
    case '-4':
      return 'Canada/Atlantic'
    case '-5':
      return 'Canada/Eastern'
    case '-6':
      return 'Canada/Central'
    case '-7':
      return 'Canada/Mountain'
    case '-8':
      return 'Canada/Pacific'
    case '-9':
      return 'US/Alaska'
    case '-10':
      return 'US/Aleutian'
    case '+7':
      return 'Asia/Ho_Chi_Minh'
    default:
      return tz
  }
}

export const mappingTimezoneToOffset = (tz) => {
  const timezone = moment().tz(mappingTimezone(tz)).utcOffset() / 60

  return timezone
}

export const convertLocalToGmtTime = (time, outPutformat = 'YYYY-MM-DDTHH:mm:ss', inputFormat = 'YYYY-MM-DDTHH:mm:ss', fallback) => {
  if (!time) return fallback || ''
  return moment(time, inputFormat).utc().format(outPutformat)
}

export const convertGmtToLocalTime = (time, outputFormat = 'MMM DD, YYYY hh:mm a', inputFormat = 'YYYY-MM-DDTHH:mm:ss', fallback) => {
  if (!time) return fallback || ''
  return moment(`${moment(time, inputFormat).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format(outputFormat)
}

export const getDeviceTime = () => moment().tz(USER_DEVICE_ZONE)
export const updateTimeZoneMoment = (tz_setting, tz_timezone) => {
  if (tz_setting === TIMEZONE_SETTING.MANUALLY && mappingTimezone(tz_timezone)) {
    moment.tz.setDefault(mappingTimezone(tz_timezone))
  } else {
    moment.tz.setDefault()
  }
}

export const convertToUTC0 = (date = moment().format('YYYY-MM-DD')) => {
  const startDate = moment(date, 'YYYY-MM-DD').startOf('day').utcOffset(0).format('YYYY-MM-DDTHH:mm:ss')
  const endDate = moment(date, 'YYYY-MM-DD').add(1, 'days').startOf('day').utcOffset(0)
    .format('YYYY-MM-DDTHH:mm:ss')

  return [startDate, endDate]
}

// TODO: REMOVE FUNCTIONS
export const getLocalTimeMoment = () => moment()
export const makeTodayMomentWithTz = (timeZone) => {
  if (timeZone) {
    return moment().tz(timeZone)
  }
  return moment()
}
export const convertTimeTzToLocaltime = (timeWithTimezone) => moment(timeWithTimezone)
export const makeMomentWithLocalTz = (datetime, inputFormat) => moment(datetime, inputFormat)

export const formatTimeString = (timeString) => typeof timeString === 'string' ? timeString.replace('AM', 'a.m').replace('PM', 'p.m') : ''

// This function will work incorrect if local time include timezone
export const convertDateToMomentWithTz = (localTime, formatLocalTime = 'MM/DD/YYYY', timeZone = 'America/Vancouver') => {
  const convertTime = moment(localTime, formatLocalTime)
  return moment().tz(timeZone).set({
    year: convertTime.year,
    month: convertTime.month,
    date: convertTime.date,
    hour: 0,
    minute: 0,
    second: 0,
  })
}

// TODO: Re-check again
export const convertTime = (time, format = 'L') => moment(time).format(format)
export const getSimpleDate = (date, format) => date ? moment(date).format(format || 'YYYY-MM-DDTHH:mm:ss') : null
export const getStartOfDay = (date) => moment(date).startOf('day')
export const getEndOfDay = (date) => moment(date).endOf('day')
export const convertH2M = (timeInHour) => {
  if (!timeInHour) return null
  const timeParts = timeInHour.split(':')
  return Number(timeParts[0]) * 60 + Number(timeParts[1])
}
