import { CHATSO_MEDIA_URL } from '~/constants/environment'

import { HttpClient } from '../httpClient/client'
import {
  addCsWsIdHeader,
  addHeader, basePath, defaultResponseErrorHandler, handleDefaultChatsoResponse, jwtHeader, timeout,
} from '../httpClient/interceptors'

class HttpFormData extends HttpClient {
  static create() {
    const httpFormData = new HttpFormData()
    httpFormData.useReq('basePath', basePath(CHATSO_MEDIA_URL))
    httpFormData.useReq('timeout', timeout())
    httpFormData.useReq('defaultHeader', addHeader(
      () => ({ 'Content-Type': 'multipart/form-data' }),
      jwtHeader,
      addCsWsIdHeader,
    ))
    httpFormData.useRes('handleDefaultChatsoResponse', handleDefaultChatsoResponse)
    httpFormData.useRes('defaultResponseErrorHandler', undefined, defaultResponseErrorHandler)
    return httpFormData
  }

  post(url, data, cfg) {
    return this._client.post(url, data, cfg)
  }
}

export default HttpFormData
