import httpClient from '~/modules/core/httpClient'
import countryData from '~/assets/data/country.json'
import { ACTIONS } from './common'

const url = {
  FILTER: '/users/filters/',
  CATEGORY: '/stores/categories/read/',
}

const getCategory = () => httpClient.post(url.CATEGORY, (() => ({})))

const getLocations = () => {
  // eslint-disable-next-line no-useless-catch
  try {
    return countryData
  } catch (e) {
    throw e
  }
}

// CRUD filters
export const getFilterList = (userId, params = {}) => httpClient.post(url.FILTER, (defaultParams) => ({
  action: ACTIONS.LIST_ALL,
  ...defaultParams,
  user_id: userId,
  parameters: params,
}))

export const addFilter = (userId, params) => httpClient.post(url.FILTER, (defaultParams) => ({
  action: ACTIONS.CREATE,
  ...defaultParams,
  user_id: userId,
  parameters: params,
}))

export const updateFilter = (userId, params) => httpClient.post(url.FILTER, (defaultParams) => ({
  action: ACTIONS.UPDATE,
  ...defaultParams,
  user_id: userId,
  parameters: params,
}))

export const deleteFilter = (userId, params) => httpClient.post(url.FILTER, (defaultParams) => ({
  action: ACTIONS.DELETE,
  ...defaultParams,
  user_id: userId,
  parameters: params,
}))

const shareApi = {
  getCategory,
  getLocations,

  getFilterList,
  addFilter,
  updateFilter,
  deleteFilter,
}

export default shareApi
