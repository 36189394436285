export const AUTH_STORAGE_KEYS = {
  INVITATION_CODE: 'INVITATION_CODE',
  INVITATION_EMAIL: 'INVITATION_EMAIL',
  IS_SHOWED_INSTRUCTION: 'IS_SHOWED_INSTRUCTION',
  NOTIFICATION_TIME_FLAG: 'NOTIFICATION_TIME_FLAG',
}

export const DELETED_STORE_KEY = 'DELETED_STORE'

export const SIDEBAR_LOCK_STATE = 'SIDEBAR_LOCK_STATE'

export const RETRY_LAZY_REFRESHED = 'RETRY_LAZY_REFRESHED'

export const IS_NOT_SHOW_TUTORIAL_PANEL_HOME_AGAIN = 'IS_NOT_SHOW_TUTORIAL_PANEL_HOME_AGAIN'
