import produce from 'immer'
import { REGULAR_SHIPPING_FULFILLMENT_ACTION } from '../actions/regularShipping'
import { DEFAULT_LIST_DATA } from '../default/share'
import { isNumeric } from '~/utils/utility'

const initialState = {
  pickingData: DEFAULT_LIST_DATA,
  pickingLoading: false,
  pickingError: '',
  pendingDeliveringData: DEFAULT_LIST_DATA,
  pendingDeliveringLoading: false,
  pendingDeliveringError: '',
  trackingOrders: {
    data: [],
    loading: false,
    error: '',
    total: 0,
  },
  statistic: {
    picking_and_waybill_count: 0,
    pending_delivering_count: 0,
    tracking_count: 0,
  },
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PICKING_LIST:
      draft.pickingLoading = true
      draft.pickingError = ''
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PICKING_LIST:
      draft.pickingData = payload
      draft.pickingLoading = false
      draft.pickingError = ''
      draft.statistic.picking_and_waybill_count = payload.items.length
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PICKING_LIST_ERROR:
      draft.pickingLoading = false
      draft.pickingError = payload
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PENDING_DELIVERING_LIST:
      draft.pendingDeliveringLoading = true
      draft.pendingDeliveringError = ''
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_DELIVERING_LIST:
      draft.pendingDeliveringData = payload
      draft.pendingDeliveringLoading = false
      draft.pendingDeliveringError = ''
      draft.statistic.pending_delivering_count = payload.items.length
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_DELIVERING_LIST_ERROR:
      draft.pendingDeliveringLoading = false
      draft.pendingDeliveringError = payload
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_TRACKING_LIST:
      draft.trackingOrders.loading = true
      draft.trackingOrders.error = ''
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_LIST:
      if (action.extraParams.isLoadingMore) {
        draft.trackingOrders.data = [
          ...draft.trackingOrders.data,
          ...payload.items.filter((item) => !draft.trackingOrders.data.find((currentItem) => currentItem.id === item.id)),
        ]
      } else {
        draft.trackingOrders.data = payload.items
      }
      draft.trackingOrders.total = payload.total_items
      draft.trackingOrders.loading = false
      draft.trackingOrders.error = ''
      draft.statistic.tracking_count = payload.total_items
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_LIST_ERROR:
      draft.trackingOrders.loading = false
      draft.trackingOrders.error = payload
      break
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_COUNT_ORDERS: {
      draft.statistic = {
        ...draft.statistic,
        picking_and_waybill_count: isNumeric(payload.pending_picking) ? payload.pending_picking : draft.statistic.picking_and_waybill_count,
        pending_delivering_count: isNumeric(payload.pending_pickup) ? payload.pending_pickup : draft.statistic.pending_delivering_count,
      }
      break
    }
    case REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_COUNT_ORDERS: {
      draft.statistic = {
        ...draft.statistic,
        tracking_count: isNumeric(payload.tracking) ? payload.tracking : draft.statistic.tracking_count,
      }
      break
    }
    default:
      break
  }
})

export default reducer
