import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
  REGULAR_PENDING: 'regular_pending',
  REGULAR_TRACKING: 'regular_tracking',
  COUNT_REGULAR_ORDERS: 'count_regular_orders',
  CREATE_LABELS: 'create_labels',
  VOID_LABELS: 'void_labels',
  READ_LABELS: 'read_labels',
  GET_TRACKING_DETAIL: 'get_tracking_detail',
}
const url = {
  ORDERS: '/stores/woocommerce/orders/',
  FULFILLMENT: '/fulfillment',
}

const getPendingOrders = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.REGULAR_PENDING,
  parameters,
})

const getTrackingOrders = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.REGULAR_TRACKING,
  parameters,
})

const countOrders = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.COUNT_REGULAR_ORDERS,
  parameters,
})

const createLabels = (parameters) => httpClient.create()
  .ejectRes('defaultResponseErrorHandler')
  .useRes('defaultResponseErrorHandler', undefined, (error) => {
    if (error.response && error.response.data) {
      throw error.response.data
    }
    throw error.message
  })
  .post(url.FULFILLMENT, {
    action: ACTIONS.CREATE_LABELS,
    parameters,
  })

const voidLabels = (parameters) => httpClient.create()
  .ejectRes('defaultResponseErrorHandler')
  .useRes('defaultResponseErrorHandler', undefined, (error) => {
    if (error.response && error.response.data) {
      throw error.response.data
    }
    throw error.message
  }).post(url.FULFILLMENT, {
    action: ACTIONS.VOID_LABELS,
    parameters,
  })

const readLabels = (parameters) => httpClient.post(url.FULFILLMENT, {
  action: ACTIONS.READ_LABELS,
  parameters,
})

const getTrackingDetail = (parameters) => httpClient.post(url.FULFILLMENT, {
  action: ACTIONS.GET_TRACKING_DETAIL,
  parameters,
})

export default {
  getPendingOrders,
  getTrackingOrders,
  countOrders,
  createLabels,
  voidLabels,
  readLabels,
  getTrackingDetail,
}
