import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { withStyles } from '~/themes/useStyles'
import { useAllStores } from '~/redux/hooks/store'
import {
  inOnBoarding, inOopsStatus, isLoggedIn,
} from '~/modules/auth/conditions'
import { useUser } from '~/redux/hooks/user'
import { useAuth } from '~/modules/auth/redux/hook'
import LoadingLinear from '~/components/common/LoadingLinear'
import styles from './style'
import { AUTH_STATUS } from '~/modules/auth/redux/reducer'
import { ONBOARDING_STEPS } from '~/constants/onboarding'

const NoMatch = ({ classes }) => {
  const stores = useAllStores()
  const { authState } = useAuth()
  const { user } = useUser()
  const navigate = useNavigate()

  const activeStore = useMemo(() => {
    if (user && user.user_settings && user.user_settings.default_store) {
      const tempActiveStore = (stores || []).find((s) => s.storeId === user.user_settings.default_store)
      return tempActiveStore ? user.user_settings.default_store : stores?.[0]?.storeId
    }
    if (stores && (stores || []).length > 0) {
      return stores[Object.keys(stores)[0]]?.storeId
    }
    return null
  }, [user, stores])

  const isExistStore = useCallback(() => {
    if (activeStore) return true
    return false
  }, [activeStore])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (authState.status === AUTH_STATUS.PROCESSING) { return () => { } }

    if (!isLoggedIn(authState.data)) {
      navigate('/login', { replace: true })
      return () => { }
    }

    if (inOopsStatus(authState.data)) {
      navigate('/oops', { replace: true })
      return () => { }
    }

    if (inOnBoarding(authState.data)) {
      navigate(ONBOARDING_STEPS.ONBOARDING_USER_INFO, { replace: true })
      return () => { }
    }

    if (!isExistStore(authState.data)) {
      navigate('/404', { replace: true })
      return () => { }
    }
    navigate(`/s/${activeStore}/home`, { replace: true })
  }, [authState.data, activeStore])

  return (
    <div className={classes.container}>
      <LoadingLinear />
    </div>
  )
}

NoMatch.propTypes = {
  classes: PropTypes.shape().isRequired,
}

export default withStyles(styles)(NoMatch)
