const observable = {
  listeners: {},

  subscribe(evt, listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected the listener to be a function.')
    }

    if (typeof evt !== 'string') {
      throw new Error('Expected the event to be a string.')
    }

    if (!(evt in this.listeners)) this.listeners[evt] = []

    this.listeners[evt].push(listener)
  },

  unSubscribe(evt, listener) {
    if (!(evt in this.listeners)) return

    this.listeners[evt] = this.listeners[evt].filter((l) => l !== listener)
  },

  broadcast(evt, payload) {
    if (!(evt in this.listeners)) return

    this.listeners[evt].forEach((listener) => {
      listener(payload)
    })
  },
}

export default {
  emit: (evt, payload) => {
    observable.broadcast(evt, payload)
  },
  on: (evt, cb) => {
    observable.subscribe(evt, cb)
  },
  off: (evt, cb) => {
    observable.unSubscribe(evt, cb)
  },
}
