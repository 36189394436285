import countryData from '~/assets/data/country.json'

const getList = () => {
  // eslint-disable-next-line no-useless-catch
  try {
    return countryData
  } catch (e) {
    throw e
  }
}
export default {
  getList,
}
