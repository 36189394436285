import { COLORS } from '~/themes/common'

export default {
  notificationContainer: {
    position: 'fixed',
    zIndex: 90,
    top: 80,
    right: 0,
    width: 380,
    maxHeight: 'calc(100vh - 80px)',
    overflow: 'auto',
  },
  notificationTrack: {
    overflow: 'hidden',
    padding: 5,
  },
  notification: {
    background: '#FFF',
    width: 350,
    boxShadow: '0px 3px 6px #2933C533',
    borderRadius: 10,
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    marginBottom: 10,
    overflow: 'hidden',
    fontSize: 14,

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between',
    background: COLORS.palette.secondaryBlue3,
    padding: '6px 10px',
    lineHeight: '18px',
    fontWeight: 'normal',
  },
  message: {
    display: 'inline-block',
    color: COLORS.gray.textGray1,
    letterSpacing: 0.28,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&.highlight': {
      background: COLORS.gray.midGray,
      borderRadius: 10,
      padding: '0 10px',
      color: '#fff',
    },
  },
  time: {
    color: COLORS.gray.textGray1,
    textTransform: 'uppercase',
    marginLeft: 10,
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'flex',
    padding: '8px 0 8px 10px',
    alignItems: 'center',
  },
  notificationOnfleetContent: {
    padding: '8px 10px 8px 10px',
  },
  icon: {
    flex: '0 0 40px',
    marginRight: 20,
    '& img': {
      width: '100%',
    },
  },
  info: {
    flex: 1,
    overflow: 'hidden',
  },
  title: {
    margin: 0,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: COLORS.gray.textGray1,
    wordBreak: 'break-word',
  },
  desc: {
    margin: '1px 0 0',
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: COLORS.gray.textGray1,
    wordBreak: 'break-word',
  },
  showTime: {
    margin: '1px 0 0',
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: COLORS.gray.textGray1,
    wordBreak: 'break-word',
    textAlign: 'right',
    minWidth: 100,
  },
  closeButtonContainer: {
    padding: '9px 15px',
  },
  appUpdatedLabel: {
    padding: '13px 15px',
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    transition: 'all .2s ease-in-out',

    '& svg': {
      width: 14,
      color: COLORS.gray.textGray1,
    },

    '&:hover': {
      background: '#DDDDDD',
      '& svg': {
        color: '#000',
      },
    },
  },
  primary: {
    color: '#fff',
    backgroundColor: COLORS.palette.primaryBlue,
  },
  descRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: COLORS.gray.textGray1,
    fontSize: 12,
    lineHeight: '20px',
  },
  shownTime: {
    minWidth: 60,
  },
  importStore: {
    flex: 1,
    padding: '0px 8px 0px 8px',
  },
  closeButtonBig: {
    fontSize: 16,
  },
}
