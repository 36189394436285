import { lazyRetry } from '~/utils/lazyRetry'
import { AUTH_TYPE } from '~/constants/auth'

const Authentication = lazyRetry(() => import('~/pages/Authentication'))
const SignUp = lazyRetry(() => import('~/pages/Authentication/SignUp'))
const Public = lazyRetry(() => import('~/layouts/Public'))
const SignUpLayout = lazyRetry(() => import('~/layouts/SignUpLayout'))

const SignUpLayoutWhiteList = [
  AUTH_TYPE.INVITATION_EXPIRED,
  AUTH_TYPE.SIGN_UP_EMAIL_SENT,
  AUTH_TYPE.SIGN_UP_EMAIL_NOT_VERIFIED,
  AUTH_TYPE.SIGN_UP_EMAIL_VERIFIED,
  AUTH_TYPE.REDIRECT_USER,
]

const AuthRouters = [
  ...Object.values(AUTH_TYPE).map((path) => ({
    path: path === AUTH_TYPE.SIGN_UP ? '/register' : path,
    layout: SignUpLayoutWhiteList.includes(path) ? SignUpLayout : Public,
    component: Authentication,
    exact: true,
    routeName: path === AUTH_TYPE.SIGN_UP ? '/register' : path.slice(1),
  })),
  {
    path: '/sign-up',
    layout: SignUpLayout,
    component: SignUp,
    exact: true,
    routeName: '/sign-up',
  },
]

export default AuthRouters
