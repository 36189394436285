import isNil from 'lodash/isNil'

export const isNullOrUndefined = (value) => value === null || value === undefined

/* eslint-disable */
export function mapAuthState(data, oldData) {
  const authState = {
    userEmail: data.user_email,
    emailVerified: data.email_verified,
    firstName: data.first_name,
    lastName: data.last_name,
    fullName: data.full_name,
    userFirebaseId: data.user_firebase_id,
    userId: data.user_id,
    defaultOrg: data.default_org,
    defaultStore: data.default_store,
    deactivated: data.deactivated || false,
    userPhoto: data.photo || '',
    userPhotoType: data.photo_type || '',
    jobTitle: data.job_title,
    phone: data.phone || '',
    serviceDisplayName: data.service_display_name || '',
    photoUrl: data.photo_url || '',
    enable_mfa: data.enable_mfa || false,
  }

  // check if user_organizatiosn exists?
  const orgs = data.user_organizations.reduce((orgs, o) => {
    const { organization_id } = o
    orgs[organization_id] = {
      name: o.organization_name,
      id: o.organization_id,
      role: {
        isOwner: o.organization_role.is_owner,
        isGa: o.organization_role.is_ga,
      },
      assignedStores: o.organization_role.assigned_stores.map((s) => s.store_id),
      assignedWarehouses: o.organization_role.assigned_warehouses.map((w) => w.warehouse_id),
      profileType: o.profile_type,
    }

    return orgs
  }, {})
  authState.orgs = orgs

  // TODO: is store_id unique between orgs
  const stores = data.user_organizations.reduce((stores, o) => {
    o.organization_role.assigned_stores.reduce((storeOfAnOrg, s) => {
      storeOfAnOrg[s.store_id] = {
        storeId: s.store_id,
        storeType: s.store_type,
        roleId: s.role_id,
        storeName: s.store_name,
        storeUrl: s.store_url,
        syncCompleted: s.sync_completed,
        syncStatusFails: s.sync_status_fails,
        authenticated: s.authenticated,
        orgId: o.organization_id,
        manageInventory: s.manage_inventory,
        covaMappingSettings: s.cova_mapping_settings,
        companyId: s.company_id,
        greenlineCategoryMapping: s.greenline_category_mapping,
        onboardingStep: s.onboarding_step,
        csWsId: o.cs_ws_id,
        defaultStoreForEmptyOrg: s?.default_store_for_empty_org || false,
        screenshotUrl: s?.screenshot_url || '',
        /**
        * Checks if the `invited_team_member` is `null` or `undefined` (org without this field, invited_team_member defaults to true).
        */ 
        invitedTeamMember: isNil(o?.invited_team_member) ? true : o?.invited_team_member,
        csStoreId: s.cs_store_id,
        addWarehouseStatus: s.add_warehouse_status,
      }
      return storeOfAnOrg
    }, stores)

    return stores
  }, {})
  authState.stores = stores

  // TODO: is warehouse_id unique between orgs
  const whs = data.user_organizations.reduce((whs, o) => {
    o.organization_role.assigned_warehouses.reduce((whOfAnOrg, wh) => {
      whOfAnOrg[wh.warehouse_id] = {
        warehouseId: wh.warehouse_id,
        orgId: o.organization_id,
      }
      return whOfAnOrg
    }, whs)

    return whs
  }, {})
  authState.warehouses = whs

  return authState
}
