/* eslint-disable no-param-reassign */
import produce from 'immer'
import { inventoryWarehousesAction } from '~/redux/actions/inventoryWarehouses'
import { DEFAULT_INVENTORY_PRODUCT_DETAIL, DEFAULT_INVENTORY_PRODUCT_LIST } from '~/constants/inventory'
import { convertInventoryTags } from '~/utils/inventoryWarehouses'

const initialState = {
  warehouses: DEFAULT_INVENTORY_PRODUCT_LIST,
  warehouseDetail: {
    data: DEFAULT_INVENTORY_PRODUCT_DETAIL,
    carryingStoreDetail: [],
    error: null,
    loading: false,
    loadingCarryingStoreDetail: false,
    errorCarryingStoreDetail: '',
  },
  tags: [],
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload = {} } = action

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES) {
    const { tab } = action.payload
    draft.warehouses[tab].loading = true
  }

  if (type === inventoryWarehousesAction.CLEAR_INVENTORY_WAREHOUSE_DETAIL) {
    draft.warehouseDetail.carryingStoreDetail = []
    draft.warehouseDetail.data = DEFAULT_INVENTORY_PRODUCT_DETAIL
    draft.warehouseDetail.loading = false
    // Clear all existing errors
    draft.warehouseDetail.error = null
  }

  if (type === inventoryWarehousesAction.CLEAR_INVENTORY_WAREHOUSE_LIST) {
    draft.warehouses = DEFAULT_INVENTORY_PRODUCT_LIST
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES_OK) {
    const { tab } = action
    draft.warehouses[tab].items = payload.items
    draft.warehouses[tab].total_items = payload.total_items
    draft.warehouses[tab].total_pages = payload.total_pages
    draft.warehouses[tab].loading = false
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES_ERR) {
    const { tab } = action
    draft.warehouses[tab].loading = false
    draft.warehouses[tab].error = payload
  }

  if (type === inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT) {
    draft.warehouses.loading = true
  }

  if (type === inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT_OK) {
    draft.warehouses.loading = false
    draft.warehouses.error = null
  }
  if (type === inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT_ERR) {
    draft.warehouses.loading = false
    draft.warehouses.error = payload
  }
  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL) {
    draft.warehouseDetail.loading = true
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL_OK) {
    draft.warehouseDetail.data = payload
    draft.warehouseDetail.loading = false
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL_ERR) {
    draft.warehouseDetail.loading = false
    draft.warehouseDetail.error = payload
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE) {
    draft.warehouseDetail.loadingCarryingStoreDetail = true
    draft.warehouseDetail.errorCarryingStoreDetail = ''
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE_OK) {
    draft.warehouseDetail.carryingStoreDetail = payload
    draft.warehouseDetail.loadingCarryingStoreDetail = false
  }

  if (type === inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE_ERR) {
    draft.warehouseDetail.errorCarryingStoreDetail = payload
    draft.warehouseDetail.loadingCarryingStoreDetail = false
  }

  if (type === inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT) {
    draft.warehouses.loading = true
  }

  if (type === inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT_OK) {
    if (payload.tags && payload.tags.find((x) => !(draft.tags || []).includes(x.value))) {
      draft.tags = draft.tags.concat(
        convertInventoryTags(
          payload.tags.filter((x) => !(draft.tags || []).includes(x.value)),
        ),
      )
    }
    draft.warehouses.loading = false
    draft.warehouseDetail.data = payload
  }

  if (type === inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT_ERR) {
    draft.warehouses.loading = false
  }

  if (type === inventoryWarehousesAction.GET_ALL_TAGS) {
    draft.tags = []
  }
  if (type === inventoryWarehousesAction.GET_ALL_TAGS_OK) {
    draft.tags = payload
  }
  if (type === inventoryWarehousesAction.GET_ALL_TAGS_ERR) {
    draft.tags = []
  }
})

export default reducer
