import httpClient from '~/modules/core/httpClient'
import { basePath, addHeader, jwtHeader } from '~/modules/core/httpClient/interceptors'

const client = httpClient.json.create()
  .ejectReq('basePath')
  .useReq('basePath', basePath(window.location.origin))
  .ejectReq('defaultHeader')
  .useReq('defaultHeader', addHeader(
    () => ({ 'Content-Type': 'multipart/form-data' }),
    jwtHeader,
  ))
  .ejectRes('defaultResponseHandler')
  .useRes('defaultResponseHandler', (res) => res.data)
// eslint-disable-next-line
const getHash = async () => {
  return client.get('/version.json', {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
}

export default {
  getHash,
}
