import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAuthState, getAuthStatus } from './selector'
import authActions from './action'

export const useAuth = () => {
  const authState = useSelector(getAuthState)
  const authStatus = useSelector(getAuthStatus)

  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(authActions, dispatch), [dispatch])

  return useMemo(() => ({
    actions,
    authState,
    authStatus,
  }), [actions, authState, authStatus])
}

export const useAuthState = () => {
  const authState = useSelector(getAuthState)
  return useMemo(() => authState, [authState])
}

export const useUserEmail = () => {
  const authState = useSelector(getAuthState)
  return useMemo(() => authState?.data?.userEmail, [authState])
}

export const useAuthData = () => {
  const authState = useSelector(getAuthState)
  return useMemo(() => authState.data, [authState.data])
}

export const useAuthPlugins = () => {
  const u = useSelector(getAuthState)
  return useMemo(() => u.plugins && u.plugins.active_plugins ? u.plugins.active_plugins[0] : {}, [u.plugins])
}
