import { createSelector } from 'reselect'

export const getStores = (state) => state?.auth?.data?.stores
export const getOrganizations = (state) => state?.auth?.data?.orgs
export const getWarehouses = (state) => state?.auth?.data?.warehouses

export const allStoresSelector = createSelector(getStores, (stores) => Object.values(stores || {}))

export const syncedStoresSelector = createSelector(
  getStores,
  (stores) => Object.values(stores || {}).filter((st) => st.authenticated && st.syncCompleted),
)

export const authenticatedStoresSelector = createSelector(
  getStores,
  (stores) => Object.values(stores || {}).filter((st) => st.authenticated),
)

export const organizationsSelector = createSelector(
  getOrganizations,
  (orgs) => Object.values(orgs || {}),
)

export const selectWarehouseByOrgId = (orgId) => createSelector(
  getWarehouses,
  (warehouses) => Object.values(warehouses || {}).filter((w) => w.orgId === orgId),
)

export const selectStoreByStoreId = (storeId) => createSelector(
  getStores,
  (stores) => Object.values(stores || {}).find((s) => s.storeId === storeId),
)

export const syncedStoresByOrgIdSelector = (orgId) => createSelector(
  getStores,
  (stores) => Object.values(stores || {}).filter((st) => st.authenticated && st.syncCompleted && orgId === st.orgId),
)
