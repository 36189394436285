export const paymentAction = Object.freeze({
  GET_PAYMENT: 'GET_PAYMENT',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_ERR: 'GET_PAYMENT_ERR',

  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_ERR: 'UPDATE_PAYMENT_ERR',

  REORDER_PAYMENT: 'REORDER_PAYMENT',
  REORDER_PAYMENT_SUCCESS: 'REORDER_PAYMENT_SUCCESS',
  REORDER_PAYMENT_ERR: 'REORDER_PAYMENT_ERR',

  SYNC_WCM_PAYMENT: 'SYNC_WCM_PAYMENT',
  SYNC_WCM_PAYMENT_SUCCESS: 'SYNC_WCM_PAYMENT_SUCCESS',
  SYNC_WCM_PAYMENT_ERR: 'SYNC_WCM_PAYMENT_ERR',
})

const getPayment = (params) => ({
  type: paymentAction.GET_PAYMENT,
  payload: params,
})

const updatePayment = (params) => ({
  type: paymentAction.UPDATE_PAYMENT,
  payload: params,
})

const reorderPayment = (params) => ({
  type: paymentAction.REORDER_PAYMENT,
  payload: params,
})

const syncWoocPayment = () => ({
  type: paymentAction.SYNC_WCM_PAYMENT,
  payload: {},
})

export default {
  getPayment,
  updatePayment,
  reorderPayment,
  syncWoocPayment,
}
