/* eslint-disable no-console */

import { isDebugMode } from './debugUtils'

const info = (...args) => isDebugMode() ? console.log(...args) : null
const error = (...args) => isDebugMode() ? console.error(...args) : null
const warn = (...args) => isDebugMode() ? console.warn(...args) : null

const logger = {
  info,
  error,
  warn,
}

export default logger
