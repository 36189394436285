/* eslint-disable no-param-reassign */
import produce from 'immer'
import { customerAction } from '../actions/customer'
import { DEFAULT_PAGE_SIZE } from '~/constants/settings'
import { DEFAULT_CUSTOMER_DETAIL_STATE, DEFAULT_COVA_CUSTOMER_DETAIL } from '~/constants/customer'

const initialState = {
  info: {
    loading: false,
    data: DEFAULT_CUSTOMER_DETAIL_STATE,
    error: '',
  },
  list: {
    loading: false,
    loadingStatus: false,
    data: {
      items: [],
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      limit: 10 || DEFAULT_PAGE_SIZE,
    },
    error: '',
  },
  covaList: {
    loading: false,
    loadingStatus: false,
    data: {
      items: [],
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      limit: 10 || DEFAULT_PAGE_SIZE,
    },
  },
  covaCustomerInfo: {
    loading: false,
    data: DEFAULT_COVA_CUSTOMER_DETAIL,
    error: '',
  },
  notes: [],
  loadingNotes: false,
  getNoteError: '',
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload = {} } = action
  if (type === customerAction.GET_CUSTOMER_DATA_REQUEST) {
    draft.info.loading = true
  }

  if (type === customerAction.GET_CUSTOMER_DATA_SUCCESS) {
    draft.info.data = payload
    draft.info.loading = false
  }

  if (type === customerAction.GET_CUSTOMER_DATA_FAILURE) {
    draft.info.loading = false
    draft.error = ''
  }

  if (type === customerAction.UPDATE_CUSTOMER_STATUS_SUCCESS) {
    draft.info.data.meta_data = payload.meta_data
  }

  if (type === customerAction.UPDATE_CUSTOMER_INFO_SUCCESS) {
    draft.info.data.first_name = payload.first_name
    draft.info.data.last_name = payload.last_name
    draft.info.data.email = payload.email
    draft.info.data.billing = payload.billing
    draft.info.data.shipping = payload.shipping
  }

  if (type === customerAction.GET_CUSTOMER_LIST_DATA_REQUEST) {
    draft.list.loading = true
    draft.list.error = ''
  }
  if (type === customerAction.GET_CUSTOMER_LIST_DATA_REQUEST_SUCCESS) {
    draft.list.loading = false
    draft.list.data = {
      ...payload,
      current_page: payload.page,
    }
    draft.list.error = ''
  }
  if (type === customerAction.GET_CUSTOMER_LIST_DATA_REQUEST_FAILURE) {
    draft.list.loading = false
    draft.list.error = payload
    draft.list.data.items = []
  }

  if (type === customerAction.APPROVE_CUSTOMER) {
    draft.list.loadingStatus = true
    draft.list.error = ''
  }

  if (type === customerAction.APPROVE_CUSTOMER_SUCCESS) {
    draft.list.loadingStatus = false
    draft.list.error = ''
  }
  if (type === customerAction.APPROVE_CUSTOMER_FAILURE) {
    draft.list.loadingStatus = false
    draft.data.error = payload
  }

  if (type === customerAction.DENY_CUSTOMER) {
    draft.list.loadingStatus = true
    draft.list.error = ''
  }

  if (type === customerAction.DENY_CUSTOMER_SUCCESS) {
    draft.list.loadingStatus = false
    draft.list.error = ''
  }
  if (type === customerAction.DENY_CUSTOMER_FAILURE) {
    draft.list.loadingStatus = false
    draft.list.error = payload
  }
  if (type === customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST) {
    draft.covaList.loading = true
    draft.covaList.error = ''
  }
  if (type === customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST_SUCCESS) {
    draft.covaList.loading = false
    draft.covaList.data = {
      ...payload,
      current_page: payload.page,
    }
    draft.list.error = ''
  }
  if (type === customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST_FAILURE) {
    draft.covaList.loading = false
    draft.covaList.error = payload
    draft.covaList.data.items = []
  }
  if (type === customerAction.GET_COVA_CUSTOMER_DATA_REQUEST) {
    draft.covaCustomerInfo.loading = true
  }

  if (type === customerAction.GET_COVA_CUSTOMER_DATA_SUCCESS) {
    draft.covaCustomerInfo.data = payload
    draft.covaCustomerInfo.loading = false
  }

  if (type === customerAction.GET_COVA_CUSTOMER_DATA_FAILED) {
    draft.covaCustomerInfo.loading = false
    draft.covaCustomerInfo.error = ''
  }

  if (type === customerAction.FETCH_CUSTOMER_NOTES) {
    draft.loadingNotes = true
    draft.getNoteError = ''
  }
  if (type === customerAction.SET_CUSTOMER_NOTES) {
    draft.loadingNotes = false
    draft.notes = payload
    draft.getNoteError = ''
  }
  if (type === customerAction.SET_CUSTOMER_NOTES_ERROR) {
    draft.loadingNotes = false
    draft.notes = []
    draft.getNoteError = payload
  }
})

export default reducer
