import React from 'react'
import NotificationSubscription from './NotificationSubscription'
import NotificationController from './NotificationController'
import NotificationPresenter from './NotificationPresenter'

const PushNotification = () => (
  <>
    <NotificationSubscription />
    <NotificationController />
    <NotificationPresenter />
  </>
)

export default PushNotification
