/* eslint-disable no-param-reassign */
import produce from 'immer'
import { AUTH_ACTION } from '~/modules/auth/redux/action'
import { mapSettingData, mapOrganizationData } from '~/utils/user'
import { USER_ACTION } from '../actions/user'

const initialState = {
  data: {
    user_settings: {},
    organization: {},
    stores: [],
    warehouses: [],
    activeUsers: [],
    organization_stores: [],
  },
  error: '',
  loading: false,
  storeLoading: false,
  orgLoading: false,
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  let arr
  let index

  switch (type) {
    case AUTH_ACTION.SIGN_IN_SUCCESS:
    case AUTH_ACTION.SIGN_IN_WITH_TOKEN_SUCCESS:
    case AUTH_ACTION.SSO_SIGN_IN_WITH_TOKEN_SUCCESS:
      draft.data.user_settings = mapSettingData(payload)
      draft.loading = false
      break

    case AUTH_ACTION.SIGN_OUT_SUCCESS:
      draft.data = initialState.data
      draft.error = ''
      draft.loading = false
      break
    case USER_ACTION.GET_ORG:
      draft.loading = true
      draft.orgLoading = true
      draft.error = ''
      break

    case USER_ACTION.UPDATE_SETTINGS:
    case USER_ACTION.UPDATE_ORGANIZATION:
    case USER_ACTION.GET_STORES:
    case USER_ACTION.REMOVE_STORE:
    case USER_ACTION.REMOVE_WAREHOUSE:
    case USER_ACTION.UPDATE_WAREHOUSE:
    case USER_ACTION.CREATE_WAREHOUSE:
      draft.loading = true
      draft.error = ''
      break

    case USER_ACTION.UPDATE_SETTINGS_OK:
      draft.data.user_settings = payload
      draft.loading = false
      break

    case USER_ACTION.UPDATE_ORGANIZATION_SUCCESS:
      draft.data.organization = mapOrganizationData(payload)
      draft.loading = false
      break

    case USER_ACTION.GET_ORG_SUCCESS:
      const org = payload.find((o) => o.organization_id)
      draft.data.organization = org ? mapOrganizationData(org) : {}
      draft.data.warehouses = org?.linked_warehouses || []
      draft.data.activeUsers = org?.organization_users || []
      draft.data.organization_stores = org?.organization_stores || []
      draft.loading = false
      draft.orgLoading = false
      break

    case USER_ACTION.GET_STORES_SUCCESS:
      draft.data.stores = payload.filter((s) => s.store_id)
      draft.loading = false
      break

    case USER_ACTION.REMOVE_STORE_SUCCESS:
      const { store_id: removedStore } = payload
      const { default_store } = draft.data.user_settings
      const { stores } = draft.data

      if (removedStore === default_store) {
        const authenticatedStores = stores.filter((s) => s.authenticated && s.syncCompleted && s.store_id !== removedStore)
        draft.data.user_settings.default_store = authenticatedStores?.[0]
      }

      draft.data.stores = draft.data.stores.filter((s) => s.store_id !== removedStore)
      draft.loading = false
      break

    case USER_ACTION.LINK_STORE:
    case USER_ACTION.LINK_WOO_STORE:
      draft.loading = true
      draft.error = ''
      break
    case USER_ACTION.LINK_COVA_STORE:
      draft.loading = true
      draft.error = ''
      break
    case USER_ACTION.LINK_STORE_SUCCESS:
    case USER_ACTION.LINK_COVA_STORE_SUCCESS:
      draft.data.stores = draft.data.stores.concat(payload)
      draft.loading = false
      break

    case USER_ACTION.UPDATE_STORE:
      draft.storeLoading = true
      break
    case USER_ACTION.UPDATE_STORE_SUCCESS:
      draft.storeLoading = false
      draft.data.stores[draft.data.stores.findIndex((x) => x.store_id === payload.store_id)] = payload
      break
    case USER_ACTION.REMOVE_WAREHOUSE_SUCCESS:
      draft.data.warehouses = (draft.data.warehouses || []).filter((w) => w.warehouse_id !== payload?.warehouse_id)
      draft.loading = false
      break

    case USER_ACTION.UPDATE_WAREHOUSE_SUCCESS:
      arr = [...draft.data.warehouses]
      index = arr.findIndex((x) => x.warehouse_id === payload.warehouse_id)
      if (index !== -1) {
        arr[index] = { ...arr[index], ...payload }
        draft.data.warehouses = arr
      }
      draft.loading = false
      break
    case USER_ACTION.UPDATE_WAREHOUSE_ZONE_COUNTING:
      arr = [...draft.data.warehouses]
      index = arr.findIndex((x) => x.warehouse_id === payload.warehouse_id)
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          ...(payload.area_type === arr[index].area_type
            ? { delivery_area_counting: payload.delivery_area_counting } : {}),
        }
        draft.data.warehouses = arr
      }
      break

    case USER_ACTION.CREATE_WAREHOUSE_SUCCESS:
      draft.data.warehouses = [...draft.data.warehouses, payload]
      draft.loading = false
      break

    case USER_ACTION.UPDATE_STORE_FAILED:
      draft.storeLoading = false
      break

    case USER_ACTION.GET_ORG_FAILED:
      draft.loading = false
      draft.orgLoading = false
      draft.error = payload
      break

    case USER_ACTION.UPDATE_SETTINGS_ERR:
    case USER_ACTION.UPDATE_ORGANIZATION_FAILED:
    case USER_ACTION.GET_STORES_FAILED:
    case USER_ACTION.REMOVE_STORE_FAILED:
    case USER_ACTION.LINK_STORE_FAILED:
    case USER_ACTION.LINK_COVA_STORE_FAILED:
    case USER_ACTION.REMOVE_WAREHOUSE_FAILED:
    case USER_ACTION.UPDATE_WAREHOUSE_FAILED:
    case USER_ACTION.CREATE_WAREHOUSE_FAILED:
      draft.loading = false
      draft.error = payload
      break

    default:
      break
  }
})

export default reducer
