import { DEFAULT_TAX_CLASS } from '~/constants/settings'
import { capitalize } from '~/utils/utility'

export const sortTaxClasses = (arr) => {
  arr.sort((a, b) => {
    if (!a.name || !b.name) { return 0 }
    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1 }
    return 0
  })
  arr.forEach((item, i) => {
    if (item.slug === DEFAULT_TAX_CLASS) {
      arr.splice(i, 1)
      arr.unshift(item)
    }
  })

  return arr
}

export const convertTaxClassName = (taxClass) => {
  let name = ''
  if (taxClass.slug === DEFAULT_TAX_CLASS) {
    name = `${taxClass.name}s`
  } else {
    name = `${taxClass.name} rates`
  }

  return capitalize(name)
}

export const mappingTaxClasseOptions = (taxClasses) => (taxClasses || []).map((item) => ({ value: item.slug, label: convertTaxClassName(item) }))

function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.mozRequestFullScreen) { // Firefox
    elem.mozRequestFullScreen()
  } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
    elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) { // IE/Edge
    elem.msRequestFullscreen()
  }
}

export function createFullScreenControl(controlDiv, mapId) {
  const fullScreenButton = document.createElement('div')

  const fullscreenIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M16 3H22V9H20V5H16V3ZM2 3H8V5H4V9H2V3ZM20 19V15H22V21H16V19H20ZM4 19H8V21H2V15H4V19Z"></path>
    </svg>`

  const exitFullscreenIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18 7H22V9H16V3H18V7ZM8 9H2V7H6V3H8V9ZM18 17V21H16V15H22V17H18ZM8 15V21H6V17H2V15H8Z"></path>
    </svg>`

  fullScreenButton.innerHTML = fullscreenIcon

  fullScreenButton.style.cssText = `
      background-color: white; font-size: 20px; margin: 8px;
      cursor: pointer; display: flex; align-items: center;
      justify-content: center; width: 32px; height: 32px;
      line-height: 20px; user-select: none;`

  controlDiv.appendChild(fullScreenButton)

  let isFullScreen = false

  fullScreenButton.addEventListener('click', () => {
    if (!isFullScreen) {
      openFullscreen(document.getElementById(mapId))
      fullScreenButton.innerHTML = exitFullscreenIcon
      isFullScreen = true
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen()
      }
      fullScreenButton.innerHTML = fullscreenIcon
      isFullScreen = false
    }
  })
}

export function createZoomControls(zoomControlDiv, map) {
  const zoomInButton = document.createElement('div')
  zoomInButton.classList.add('zoom-button')
  zoomInButton.innerHTML = '+'
  zoomInButton.style.backgroundColor = 'white'
  zoomInButton.style.marginRight = '8px'
  zoomInButton.style.fontSize = '20px'
  zoomInButton.style.cursor = 'pointer'
  zoomInButton.style.display = 'flex'
  zoomInButton.style.alignItems = 'center'
  zoomInButton.style.justifyContent = 'center'
  zoomInButton.style.width = '32px'
  zoomInButton.style.height = '32px'
  zoomInButton.style.userSelect = 'none'
  zoomControlDiv.appendChild(zoomInButton)

  const zoomOutButton = document.createElement('div')
  zoomOutButton.classList.add('zoom-button')
  zoomOutButton.innerHTML = '-'
  zoomOutButton.style.backgroundColor = 'white'
  zoomOutButton.style.fontSize = '30px'
  zoomOutButton.style.cursor = 'pointer'
  zoomOutButton.style.display = 'flex'
  zoomOutButton.style.alignItems = 'center'
  zoomOutButton.style.justifyContent = 'center'
  zoomOutButton.style.width = '32px'
  zoomOutButton.style.height = '32px'
  zoomOutButton.style.userSelect = 'none'
  zoomControlDiv.appendChild(zoomOutButton)

  zoomInButton.addEventListener('click', () => {
    map.setZoom(map.getZoom() + 1)
  })

  zoomOutButton.addEventListener('click', () => {
    map.setZoom(map.getZoom() - 1)
  })
}
