import {
  takeLatest, all, fork, call, put,
} from 'redux-saga/effects'
import tagApi from '~/services/apis/tag'

import Actions, { TAG_ACTION } from '~/redux/actions/tag'

export function* fetchTagList(action) {
  try {
    const resp = yield call(tagApi.getList, action.payload)
    yield put(Actions.setTagListData(resp))
  } catch (e) {
    yield put(Actions.setTagListError(e))
  }
}
export function* fetchAllTagList(action) {
  try {
    const resp = yield call(tagApi.getAllList, action.payload)
    yield put(Actions.setTagAllListData(resp))
  } catch (e) {
    yield put(Actions.setAllTagListError(e))
  }
}

export function* fetchTagDetail(action) {
  try {
    const resp = yield call(tagApi.findOne, action.payload)
    yield put(Actions.setTagDetail(resp))
  } catch (e) {
    yield put(Actions.setTagDetailError(e))
  }
}

export function* watchTag() {
  yield all([
    takeLatest(TAG_ACTION.FETCH_LIST, fetchTagList),
    takeLatest(TAG_ACTION.FETCH_ALL_LIST, fetchAllTagList),
    takeLatest(TAG_ACTION.FETCH_DETAIL, fetchTagDetail),
  ])
}

export default function* tag() {
  yield fork(watchTag)
}
