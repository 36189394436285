export const notificationCenterAction = Object.freeze({
  GET_NOTIFICATION_CENTER: 'GET_NOTIFICATION_CENTER',
  GET_NOTIFICATION_CENTER_SUCCESS: 'GET_NOTIFICATION_CENTER_SUCCESS',
  GET_NOTIFICATION_CENTER_ERR: 'GET_NOTIFICATION_CENTER_ERR',
})

const getNotificationCenter = (params) => ({
  type: notificationCenterAction.GET_NOTIFICATION_CENTER,
  payload: params,
})

export default {
  getNotificationCenter,
}
