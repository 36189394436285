import produce from 'immer'
import { CALENDAR_ACTION } from '../actions/calendar'

const initialState = {
  data: {},
  loading: false,
  error: '',
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case CALENDAR_ACTION.FETCH_DATA:
      draft.loading = true
      draft.error = ''
      break
    case CALENDAR_ACTION.SET_CALENDAR_DATA:
      draft.data = payload
      draft.loading = false
      draft.error = ''
      break
    case CALENDAR_ACTION.SET_CALENDAR_DATA_ERROR:
      draft.loading = false
      draft.error = payload
      break
    default:
      break
  }
})

export default reducer
