import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  NOTIFICATION: 'stores/woocommerce/notification-settings/',
  TAX: '/stores/woocommerce/taxes/',
  TAX_CLASSES: '/stores/woocommerce/taxes/classes/',
}

const getNotificationSettings = (params) => httpClient.json.post(url.NOTIFICATION, {
  action: ACTIONS.READ,
  parameters: {
    store_ids: params,
  },
})

const updateNotificationSettings = (params) => httpClient.json.post(url.NOTIFICATION, {
  action: ACTIONS.UPDATE,
  parameters: params,
})

const getTaxClasses = (params) => httpClient.post(url.TAX_CLASSES, {
  action: ACTIONS.PAGINATE,
  parameters: {
    limit: 100,
    page: 0,
    sort: { name: 1 },
    ...params,
  },
})

const createTaxClass = (item_data) => httpClient.post(url.TAX_CLASSES, {
  action: ACTIONS.CREATE,
  parameters: { item_data },
})

const removeTaxClass = (slug) => httpClient.post(url.TAX_CLASSES, {
  action: ACTIONS.DELETE,
  parameters: slug,
})

const getTaxRates = ({ slug, ...params }) => httpClient.post(url.TAX, {
  action: ACTIONS.PAGINATE,
  parameters: {
    limit: 100,
    page: 0,
    ...params,
    filters: { class: slug },
  },
})

const createTaxRate = (item_data) => httpClient.post(url.TAX, {
  action: ACTIONS.CREATE,
  parameters: { item_data },
})

const updateTaxRate = (item_data) => httpClient.post(url.TAX, {
  action: ACTIONS.UPDATE,
  parameters: { item_data },
})

const removeTaxRate = (id) => httpClient.post(url.TAX, {
  action: ACTIONS.DELETE,
  parameters: { id },
})

const syncTax = () => Promise.all([
  httpClient.post(url.TAX_CLASSES, {
    action: ACTIONS.SYNC,
    parameters: {},
  }),
  httpClient.post(url.TAX, {
    action: ACTIONS.SYNC,
    parameters: {},
  }),
])
  .then((responses) => responses)
  .catch((error) => ({ error }))

const settingsApi = {
  getNotificationSettings,
  updateNotificationSettings,
  getTaxClasses,
  getTaxRates,
  createTaxClass,
  removeTaxClass,
  createTaxRate,
  updateTaxRate,
  removeTaxRate,
  syncTax,
}

export default settingsApi
