import { useQuery } from '@tanstack/react-query'
import ssoClient from '~/modules/core/ssoClient'
import { queryFnWrapper } from '~/utils/utility'

const checkSession = () => ssoClient.json.post('check-session', {})

export const useCheckSession = ({
  onSuccess, onError, onSettled, enabled = true, onStart,
}) => useQuery({
  queryKey: ['checkSession'],
  queryFn: queryFnWrapper({
    queryFn: () => checkSession(),
    onStart,
    onSuccess,
    onError,
    onSettled,
  }),
  initialData: null,
  refetchInterval: 50 * 60 * 1000,
  refetchIntervalInBackground: false,
  refetchOnWindowFocus: true,
  enabled,
  select: (res) => res || {},
})
