import { DEFAULT_CATEGORY_DETAIL } from '../default/category'

export const CATEGORY_ACTION = {
  FETCH_LIST: 'category.FETCH_LIST',
  SET_LIST_DATA: 'category.SET_LIST_DATA',
  SET_LIST_ERROR: 'category.SET_LIST_ERROR',
  MAP_CATEGORY_WITH_LEVEL: 'category.MAP_CATEGORY_WITH_LEVEL',
  SORT_CATEGORY_LIST: 'category.SORT_CATEGORY_LIST',

  FETCH_DETAIL: 'category.FETCH_DETAIL',
  SET_DETAIL_DATA_ERROR: 'category.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'category.SET_DETAIL_DATA',
}

export const fetchCategoryList = (params) => ({
  type: CATEGORY_ACTION.FETCH_LIST,
  payload: params,
})

export const setCategoryListData = (listData) => ({
  type: CATEGORY_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setCategoryListError = (error) => ({
  type: CATEGORY_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const sortCategoryList = (params) => ({
  type: CATEGORY_ACTION.SORT_CATEGORY_LIST,
  payload: params,
})

export const fetchCategoryDetail = (id) => ({
  type: CATEGORY_ACTION.FETCH_DETAIL,
  payload: id,
})

export const setCategoryDetail = (category) => ({
  type: CATEGORY_ACTION.SET_DETAIL_DATA,
  payload: category,
})

export const setCategoryDetailError = (error) => ({
  type: CATEGORY_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const clearCategoryDetail = () => ({
  type: CATEGORY_ACTION.SET_DETAIL_DATA,
  payload: DEFAULT_CATEGORY_DETAIL,
})

export default {
  fetchCategoryList,
  setCategoryListData,
  setCategoryListError,
  sortCategoryList,

  fetchCategoryDetail,
  setCategoryDetail,
  setCategoryDetailError,
  clearCategoryDetail,
}
