export const DEFAULT_ORDER_LIST_DATA = Object.freeze({
  items: [],
  total_items: 0,
  total_pages: 0,
  total: 0,
})

export const DEFAULT_ORDER_DETAIL = {
  date_created: null,
  created_date: null,
  created_time: null,
  order_updates: null,
  customer: {},
  customer_id: null,
  customer_email: '',
  customer_phone: '',
  customer_note: '',
  billing: {},
  tmp_billing: {},
  payment_method: '',
  transaction_id: '',
  shipping: {},
  tmp_shipping: {},
  shipping_note: '',
  line_items: [],
  shipping_lines: [],
  tax_lines: [],
  fee_lines: [],
  coupon_lines: [],
  custom_fields: [],
  meta_data: [],
  selecting_add_ship: null,
  selecting_added_fee: null,
  status: 'pending',
  order_action: null,
  order_notes: [],
  deleting_note: null,
  adding_note: null,
  action: 'none',
}

export const ORDER_DETAIL_READONLY = [
  'id',
  'number',
  'order_key',
  'created_via',
  'version',
  'date_created',
  'date_created_gmt',
  'date_modified',
  'date_modified_gmt',
  'discount_total',
  'discount_tax',
  'shipping_total',
  'shipping_tax',
  'cart_tax',
  'total',
  'total_tax',
  'prices_include_tax',
  'customer_ip_address',
  'customer_user_agent',
  'date_paid',
  'date_paid_gmt',
  'date_completed',
  'date_completed_gmt',
  'cart_hash',
  'tax_lines',
  'refunds',
]

export const ORDER_DETAIL_UPDATED = [
  'parent_id',
  'status',
  'currency',
  'customer_id',
  'customer_note',
  'billing',
  'shipping',
  'payment_method',
  'payment_method_id',
  'payment_method_title',
  'transaction_id',
  'meta_data',
  'line_items',
  'shipping_lines',
  'fee_lines',
  'coupon_lines',
  'set_paid',
]

export const ORDER_DETAIL_IGNORE = [
  'store_id',
  '_created',
  '_links',
  '_updated',
  'currency_symbol',
  'date_completed_from_str',
  'date_completed_gmt_from_str',
  'date_created_from_str',
  'date_created_gmt_from_str',
  'date_modified_from_str',
  'date_modified_gmt_from_str',
  'date_paid_from_str',
  'date_paid_gmt_from_str',
  'post_process_queue_count',
  'total_items',
  'total_value',
  'failed_note',
  'inventory_id_of_line_items',
  'categories',
  'post_process_queue',
  'promotions',
]
