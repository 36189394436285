export const whAdjustmentActions = {
  GET_LIST_WH_ADJUSTMENTS: 'warehouseAdjustments.GET_LIST_WH_ADJUSTMENTS',
  GET_LIST_WH_ADJUSTMENTS_OK: 'warehouseAdjustments.GET_LIST_WH_ADJUSTMENTS_OK',
  GET_LIST_WH_ADJUSTMENTS_ERR: 'warehouseAdjustments.GET_LIST_WH_ADJUSTMENTS_ERR',
}

const getWarehouseAdjustments = (
  params,
  warehouseId,
  callbacks = {
    onSuccess: () => {},
    onError: () => {},
  },
) => ({
  type: whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS,
  payload: {
    params,
  },
  warehouseId,
  callbacks,
})

export default {
  getWarehouseAdjustments,
}
