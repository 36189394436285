export const PAGE_ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
}

export const INTERCEPTOR_ERROR_CODES = {
  CANCEL_ORDER: 'cannot_cancel_order_in_breadstack',
  COMPLETE_ORDER: 'cannot_complete_unpaid_order',
  CHANGE_STATUS_ORDER: 'order_cannot_be_modified',
  PROCESSING_ORDER: 'cannot_processing_unpaid_order',
  UNABLE_RETRIEVE_ORDER_INFORMATION: 'unable_retrieve_order_information',
  LOCATION_IS_DELETED: 'location_is_deleted',
}

export const SPECIAL_CHAR = '!"#$%&\'()*+,-./:;<=>?@^_{|}~'

export const regexValidPassword = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${SPECIAL_CHAR}])[A-Za-z\\d${SPECIAL_CHAR}]{8,}$`)

export const ALL_TIME = 'all_time'

export const ALL_VALUE = 'all'
