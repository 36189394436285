import {
  takeLatest, all, fork, call, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import union from 'lodash/union'
import productApi from '~/services/apis/product'
import Actions, { PRODUCT_ACTION } from '~/redux/actions/product'
import { selectStoreByStoreId } from '~/redux/selectors/store'
import { hasSupportedCompositeVersion } from '~/redux/selectors/product'
import { PRODUCT_TYPE } from '~/constants/product'
import { STORE_TYPES } from '~/constants/store'

export function* fetchProductList(action) {
  try {
    const resp = yield call(productApi.getList, action.payload)
    yield put(Actions.setProductListData(resp))
  } catch (e) {
    yield put(Actions.setProductListError(e))
  }
}

export function* fetchProductDetail(action) {
  try {
    const resp = yield call(productApi.findOne, action.payload)
    yield put(Actions.setProductDetail(resp))
  } catch (e) {
    yield put(Actions.setProductDetailError(e))
  }
}

export function* fetchDuplicateData(action) {
  try {
    let resp
    let store
    if (action.payload.storeId) {
      store = yield select(selectStoreByStoreId(action.payload.storeId))
      resp = yield call(productApi.findOne, action.payload.id, store.orgId, action.payload.storeId)
    } else {
      resp = yield call(productApi.findOne, action.payload.id)
    }

    let duplicatingProduct = {
      ...resp,
      id: null,
      ...((resp.type === PRODUCT_TYPE.SIMPLE && store.storeType === STORE_TYPES.WOO && action.payload.isSameOrg && !action.payload.isSameStore) ? {
        sku: resp.sku,
        linked_inventory: resp.linked_inventory,
        ...(resp.linked_inventory ? { sku_status: 'need_check' } : {}),
      } : {
        linked_inventory: false,
        sku: resp.sku,
      }),
      status: 'draft',
      name: `${resp.name} (Copy)`,
      slug: `${resp.slug}-copy`,
      date_created_gmt: moment(moment.utc()).format('YYYY-MM-DDTHH:mm:ss'),
      inventory_product_cost: null,
      inventory_product_id: null,
      inventory_product_name: null,
      inventory_quantity: null,
      variations: [],
      images: [],
    }

    if (!action.payload.isSameStore) {
      duplicatingProduct.categories = []
      duplicatingProduct.images = duplicatingProduct.images ? duplicatingProduct.images.map(({ id, src, ...x }) => ({
        ...x,
      })) : null
      duplicatingProduct.attributes = duplicatingProduct.attributes ? duplicatingProduct.attributes.map((x) => ({ ...x, id: 0 })) : null
      duplicatingProduct.variationContainers = []
      duplicatingProduct.tags = []
      duplicatingProduct.upsell_ids = []
      duplicatingProduct.cross_sell_ids = []
      duplicatingProduct.tax_class = ''
      duplicatingProduct.shipping_class = ''
      duplicatingProduct.related_ids = []
      duplicatingProduct.composite_components = []
      duplicatingProduct.composite_scenarios = []
    } else {
      if (resp.type === PRODUCT_TYPE.COMPOSITE) {
        duplicatingProduct.composite_components = (resp.composite_components || []).map((c) => ({
          ...c,
          id: `component_${c.id}`,
        }))

        const supportScenarios = yield select(hasSupportedCompositeVersion)
        duplicatingProduct.composite_scenarios = supportScenarios ? (resp.composite_scenarios || []).map((s) => ({
          ...s,
          id: `scenario_${s.id}`,
          configuration: (s.configuration || []).map((c) => ({
            ...c,
            component_id: `component_${c.component_id}`,
          })),
        })) : []
      }

      let upsell_ids = []
      let cross_sell_ids = []
      if (resp.upsell_ids && resp.upsell_ids.length > 0) {
        upsell_ids = yield call(productApi.findMulti, resp.upsell_ids)
      }
      if (resp.cross_sell_ids && resp.cross_sell_ids.length > 0) {
        cross_sell_ids = yield call(productApi.findMulti, resp.cross_sell_ids)
      }
      duplicatingProduct = { ...duplicatingProduct, upsell_ids, cross_sell_ids }

      let linkedIds = (resp.upsell_ids || []).concat(resp.cross_sell_ids || [])
      if (resp.type === PRODUCT_TYPE.COMPOSITE) {
        linkedIds = linkedIds.concat((resp.composite_components || [])
          .map((c) => (c.default_option_id ? [c.default_option_id] : [])
            .concat(c.query_type === 'product_ids' ? (c.query_ids || []) : [])))
          .reduce((acc, item) => acc.concat(item), [])
        linkedIds = linkedIds.concat((resp.composite_scenarios || [])
          .reduce((acc, item) => acc.concat(item.configuration || []), [])
          .reduce((acc, item) => acc.concat(item.component_options || []), [])
          .filter((pId) => pId && pId > 0))
        linkedIds = linkedIds.concat((resp.composite_scenarios || [])
          .reduce((acc, item) => item.actions && item.actions.length > 2 ? acc.concat(item.actions[2]?.hidden_options || []) : acc, [])
          .reduce((acc, item) => acc.concat(item.component_options || []), [])
          .filter((pId) => pId && pId > 0))
      }
      yield put({
        type: PRODUCT_ACTION.FETCH_CACHED_PRODUCTS,
        payload: union(linkedIds),
      })
    }
    yield put(Actions.setProductDetail(duplicatingProduct))
  } catch (e) {
    yield put(Actions.setProductDetailError(e))
  }
}

export function* fetchProductFullDetail(action) {
  try {
    const resp = yield call(productApi.findOne, action.payload)
    let upsell_ids = []
    let cross_sell_ids = []
    if (resp.upsell_ids && resp.upsell_ids.length > 0) {
      upsell_ids = yield call(productApi.findMulti, resp.upsell_ids)
    }
    if (resp.cross_sell_ids && resp.cross_sell_ids.length > 0) {
      cross_sell_ids = yield call(productApi.findMulti, resp.cross_sell_ids)
    }
    let linkedIds = (resp.upsell_ids || []).concat(resp.cross_sell_ids || [])
    if (resp.type === PRODUCT_TYPE.COMPOSITE) {
      linkedIds = linkedIds.concat((resp.composite_components || [])
        .map((c) => (c.default_option_id ? [c.default_option_id] : [])
          .concat(c.query_type === 'product_ids' ? (c.query_ids || []) : [])))
        .reduce((acc, item) => acc.concat(item), [])
      linkedIds = linkedIds.concat((resp.composite_scenarios || [])
        .reduce((acc, item) => acc.concat(item.configuration || []), [])
        .reduce((acc, item) => acc.concat(item.component_options || []), [])
        .filter((pId) => pId && pId > 0))
      linkedIds = linkedIds.concat((resp.composite_scenarios || [])
        .reduce((acc, item) => item.actions && item.actions.length > 2 ? acc.concat(item.actions[2]?.hidden_options || []) : acc, [])
        .reduce((acc, item) => acc.concat(item.component_options || []), [])
        .filter((pId) => pId && pId > 0))
    }
    yield put({
      type: PRODUCT_ACTION.FETCH_CACHED_PRODUCTS,
      payload: union(linkedIds),
    })
    yield put(Actions.setProductDetail({
      ...resp,
      upsell_ids,
      cross_sell_ids,
    }))
  } catch (e) {
    yield put(Actions.setProductDetailError(e))
  }
}

export function* fetchCachedProducts(action) {
  try {
    if (action.payload?.length > 0) {
      const linkedProducts = yield call(productApi.findMulti, action.payload, ['id', 'name', 'type', 'status', 'sku', 'variation_name', 'attributes', 'variations', 'variations_data'])
      yield put({
        type: PRODUCT_ACTION.SET_CACHED_PRODUCTS,
        payload: (linkedProducts || []).reduce((acc, item) => {
          // eslint-disable-next-line no-param-reassign
          acc[item.id] = {
            ...acc[item.id],
            ...item,
            ...(item.type === 'variation' && !item.variation_name ? {
              variation_name: item.name,
            } : {}),
          }
          if (item.type === PRODUCT_TYPE.VARIABLE && item.variations_data && item.variations_data.length > 0) {
            for (let i = 0; i < item.variations_data.length; i += 1) {
              const variation = item.variations_data[i]
              // eslint-disable-next-line no-param-reassign
              acc[variation.id] = {
                ...acc[variation.id],
                ...variation,
                variation_name: variation.variation_name || `${item.name} - ${(variation.attributes || []).map((attr) => attr.option).join('/')}`,
                type: PRODUCT_TYPE.VARIATION,
              }
            }
          }
          return acc
        }, {}),
      })
    }
  } catch (e) {
    yield put({
      type: PRODUCT_ACTION.FETCH_CACHED_PRODUCTS_ERROR,
    })
  }
}

export function* watchProduct() {
  yield all([
    takeLatest(PRODUCT_ACTION.FETCH_PRODUCT_LIST, fetchProductList),
    takeLatest(PRODUCT_ACTION.FETCH_DETAIL, fetchProductDetail),
    takeLatest(PRODUCT_ACTION.FETCH_DUPLICATE_DATA, fetchDuplicateData),
    takeLatest(PRODUCT_ACTION.FETCH_FULL_DETAIL, fetchProductFullDetail),
    takeLatest(PRODUCT_ACTION.FETCH_CACHED_PRODUCTS, fetchCachedProducts),
  ])
}

export default function* product() {
  yield fork(watchProduct)
}
