import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  READ: '/stores/woocommerce/widgets/dashboard/',
}

const get = (startDate, endDate) => httpClient.post(
  url.READ,
  { start_date: startDate, end_date: endDate, action: ACTIONS.READ },
)

const dashboardApi = {
  get,
}

export default dashboardApi
