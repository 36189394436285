export const SETTING_ACTION = {
  GET_NOTIFICATION_SETTING: 'settings.GET_NOTIFICATION_SETTING',
  GET_NOTIFICATION_SETTING_OK:
           'settings.GET_NOTIFICATION_SETTING_OK',
  GET_NOTIFICATION_SETTING_ERR:
           'settings.GET_NOTIFICATION_SETTING_ERR',

  UPDATE_NOTIFICATION_SETTING: 'settings.UPDATE_NOTIFICATION_SETTING',
  UPDATE_NOTIFICATION_SETTING_OK:
           'settings.UPDATE_NOTIFICATION_SETTING_OK',
  UPDATE_NOTIFICATION_SETTING_ERR:
           'settings.UPDATE_NOTIFICATION_SETTING_ERR',

  GET_TAX_CLASSES: 'settings.GET_TAX_CLASSES',
  GET_TAX_CLASSES_SUCCESS: 'settings.GET_TAX_CLASSES_SUCCESS',
  GET_TAX_CLASSES_FAILED: 'settings.GET_TAX_CLASSES_FAILED',
  CREATE_TAX_CLASS: 'settings.CREATE_TAX_CLASS',
  CREATE_TAX_CLASS_SUCCESS: 'settings.CREATE_TAX_CLASS_SUCCESS',
  CREATE_TAX_CLASS_FAILED: 'settings.CREATE_TAX_CLASS_FAILED',
  REMOVE_TAX_CLASS: 'settings.REMOVE_TAX_CLASS',
  REMOVE_TAX_CLASS_SUCCESS: 'settings.REMOVE_TAX_CLASS_SUCCESS',
  REMOVE_TAX_CLASS_FAILED: 'settings.REMOVE_TAX_CLASS_FAILED',
}

const getNotificationSettings = (
  params,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: SETTING_ACTION.GET_NOTIFICATION_SETTING,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const updateNotificationSettings = (
  params,
  {
    onSuccess = () => {},
    onError = () => {},
  },
) => ({
  type: SETTING_ACTION.UPDATE_NOTIFICATION_SETTING,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

export const getTaxClasses = (payload) => ({
  type: SETTING_ACTION.GET_TAX_CLASSES,
  payload,
})

export const getTaxClassesSuccess = (payload) => ({
  type: SETTING_ACTION.GET_TAX_CLASSES_SUCCESS,
  payload,
})

export const getTaxClassesFailed = (payload) => ({
  type: SETTING_ACTION.GET_TAX_CLASSES_FAILED,
  payload,
})

export const createTaxClass = (payload) => ({
  type: SETTING_ACTION.CREATE_TAX_CLASS,
  payload,
})

export const createTaxClassSuccess = (payload) => ({
  type: SETTING_ACTION.CREATE_TAX_CLASS_SUCCESS,
  payload,
})

export const createTaxClassFailed = (payload) => ({
  type: SETTING_ACTION.CREATE_TAX_CLASS_FAILED,
  payload,
})

export const removeTaxClass = (payload) => ({
  type: SETTING_ACTION.REMOVE_TAX_CLASS,
  payload,
})

export const removeTaxClassSuccess = (payload) => ({
  type: SETTING_ACTION.REMOVE_TAX_CLASS_SUCCESS,
  payload,
})

export const removeTaxClassFailed = (payload) => ({
  type: SETTING_ACTION.REMOVE_TAX_CLASS_FAILED,
  payload,
})

export default {
  getNotificationSettings,
  updateNotificationSettings,
  getTaxClasses,
  createTaxClass,
  removeTaxClass,
}
