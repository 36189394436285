export const REGULAR_SHIPPING_FULFILLMENT_ACTION = {
  FETCH_PICKING_LIST: 'regularShipping.FETCH_PICKING_LIST',
  SET_PICKING_LIST: 'regularShipping.SET_PICKING_LIST',
  SET_PICKING_LIST_ERROR: 'regularShipping.SET_PICKING_LIST_ERROR',
  FETCH_PENDING_DELIVERING_LIST: 'regularShipping.FETCH_PENDING_DELIVERING_LIST',
  SET_PENDING_DELIVERING_LIST: 'regularShipping.SET_PENDING_DELIVERING_LIST',
  SET_PENDING_DELIVERING_LIST_ERROR: 'regularShipping.SET_PENDING_DELIVERING_LIST_ERROR',
  FETCH_TRACKING_LIST: 'regularShipping.FETCH_TRACKING_LIST',
  SET_TRACKING_LIST: 'regularShipping.SET_TRACKING_LIST',
  SET_TRACKING_LIST_ERROR: 'regularShipping.SET_TRACKING_LIST_ERROR',
  FETCH_PENDING_COUNT_ORDERS: 'regularShipping.FETCH_PENDING_COUNT_ORDERS',
  SET_PENDING_COUNT_ORDERS: 'regularShipping.SET_PENDING_COUNT_ORDERS',
  SET_PENDING_COUNT_ORDER_ERROR: 'regularShipping.SET_PENDING_COUNT_ORDER_ERROR',
  FETCH_TRACKING_COUNT_ORDERS: 'regularShipping.FETCH_TRACKING_COUNT_ORDERS',
  SET_TRACKING_COUNT_ORDERS: 'regularShipping.SET_TRACKING_COUNT_ORDERS',
  SET_TRACKING_COUNT_ORDER_ERROR: 'regularShipping.SET_TRACKING_COUNT_ORDER_ERROR',
}

const fetchPickingList = (params, {
  onSuccess = () => {},
  onError = () => {},
} = {}) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PICKING_LIST,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

const setPickingListData = (data) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PICKING_LIST,
  payload: data,
})

const setPickingListError = (error) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PICKING_LIST_ERROR,
  payload: error,
})

const fetchPendingDeliveringList = (params, {
  onSuccess = () => {},
  onError = () => {},
} = {}) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PENDING_DELIVERING_LIST,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

const setPendingDeliveringListData = (data) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_DELIVERING_LIST,
  payload: data,
})

const setPendingDeliveringListError = (error) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_DELIVERING_LIST_ERROR,
  payload: error,
})

const fetchTrackingList = (params, isLoadingMore = false, {
  onSuccess = () => {},
  onError = () => {},
}) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_TRACKING_LIST,
  payload: params,
  extraParams: {
    isLoadingMore,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const setTrackingListData = (data, isLoadingMore = false) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_LIST,
  payload: data,
  extraParams: {
    isLoadingMore,
  },
})

const setTrackingListError = (error) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_LIST_ERROR,
  payload: error,
})

const fetchPendingCountOrders = (params) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PENDING_COUNT_ORDERS,
  payload: params,
})

const setPendingCountOrders = (resp) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_COUNT_ORDERS,
  payload: resp,
})

const setPendingCountOrderError = (error) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_PENDING_COUNT_ORDER_ERROR,
  payload: error,
})

const fetchTrackingCountOrders = (params) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_TRACKING_COUNT_ORDERS,
  payload: params,
})

const setTrackingCountOrders = (resp) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_COUNT_ORDERS,
  payload: resp,
})

const setTrackingCountOrderError = (error) => ({
  type: REGULAR_SHIPPING_FULFILLMENT_ACTION.SET_TRACKING_COUNT_ORDER_ERROR,
  payload: error,
})

export default {
  fetchPickingList,
  setPickingListData,
  setPickingListError,
  fetchPendingDeliveringList,
  setPendingDeliveringListData,
  setPendingDeliveringListError,
  fetchTrackingList,
  setTrackingListData,
  setTrackingListError,
  fetchPendingCountOrders,
  setPendingCountOrders,
  setPendingCountOrderError,
  fetchTrackingCountOrders,
  setTrackingCountOrders,
  setTrackingCountOrderError,
}
