import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  READ: '/warehouses/inventory_products/',
  GET_ALL: '/warehouses/get_all/',
}

const get = ({ params, warehouseId }) => httpClient.post(
  url.READ,
  {
    warehouse_id: warehouseId,
    action: ACTIONS.PAGINATE,
    parameters: params,
  },
)

const getCova = ({ params, warehouseId }) => httpClient.post(
  url.READ,
  {
    warehouse_id: warehouseId,
    action: ACTIONS.READ_COVA,
    parameters: params,
  },
)

const getDetail = ({ params, warehouseId, action = ACTIONS.READ }) => httpClient.post(
  url.READ,
  {
    warehouse_id: warehouseId,
    action,
    parameters: params,
  },
)

const create = ({ params, warehouseId }) => httpClient.post(
  url.READ,
  {
    warehouse_id: warehouseId,
    action: ACTIONS.CREATE,
    parameters: params,
  },
)

const update = ({ params, warehouseId }) => httpClient.post(
  url.READ,
  {
    warehouse_id: warehouseId,
    action: ACTIONS.UPDATE,
    parameters: params,
  },
)

const deleteDetail = ({ params, warehouseId }) => httpClient.post(
  url.READ,
  {
    warehouse_id: warehouseId,
    action: ACTIONS.DELETE,
    parameters: params,
  },
)

const exportCSVInventory = ({ params, warehouseId }) => httpClient.post(url.READ, {
  warehouse_id: warehouseId,
  action: ACTIONS.EXPORT,
  parameters: params,
})

const getAll = () => httpClient.post(
  url.GET_ALL,
)

const inventoryWarehousesApi = {
  get,
  getDetail,
  create,
  update,
  deleteDetail,
  getAll,
  getCova,
  exportCSVInventory,
}

export default inventoryWarehousesApi
