export const SKU_ACTIONS = {
  GET_SKU_LIST: 'sku.GET_SKU_LIST',
  GET_SKU_LIST_OK: 'sku.GET_SKU_LIST_OK',
  GET_SKU_LIST_ERR: 'sku.GET_SKU_LIST_ERR',

  GET_SKU_DETAIL: 'sku.GET_SKU_DETAIL',
  GET_SKU_DETAIL_OK: 'sku.GET_SKU_DETAIL_OK',
  GET_SKU_DETAIL_ERR: 'sku.GET_SKU_DETAIL_ERR',

  CREATE_SKU: 'sku.CREATE_SKU',
  CREATE_SKU_OK: 'sku.CREATE_SKU_OK',
  CREATE_SKU_FAIL: 'sku.CREATE_SKU_FAIL',

  UPDATE_SKU: 'sku.UPDATE_SKU',
  UPDATE_SKU_OK: 'sku.UPDATE_SKU_OK',
  UPDATE_SKU_FAIL: 'sku.UPDATE_SKU_FAIL',
  CLEAR_SKU_DETAIL: 'sku.CLEAR_SKU_DETAIL',
}
const defaultSku = {}
const defaultFunc = (a) => a
export const createSku = (payload, callback = {}) => ({
  type: SKU_ACTIONS.CREATE_SKU,
  payload,
  onSuccess: (res) => callback.onSuccess(res) || defaultFunc,
  onFail: (err) => callback.onFail(err) || defaultFunc,
})
export const createSkuFail = (payload) => ({
  type: SKU_ACTIONS.CREATE_SKU_FAIL,
  payload,
})
export const createSkuOk = (payload) => ({
  type: SKU_ACTIONS.CREATE_SKU_OK,
  payload,
})
export const updateSku = (payload, callback = {}) => ({
  type: SKU_ACTIONS.UPDATE_SKU,
  payload,
  onSuccess: (res) => callback.onSuccess(res) || defaultFunc,
  onFail: (err) => callback.onFail(err) || defaultFunc,
})
export const updateSkuFail = (payload) => ({
  type: SKU_ACTIONS.UPDATE_SKU_FAIL,
  payload,
})
export const updateSkuOk = (payload) => ({
  type: SKU_ACTIONS.UPDATE_SKU_OK,
  payload,
})

export const getSKUList = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_LIST,
  payload,
})

export const getSKUListSuccess = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_LIST_OK,
  payload,
})

export const getSKUListFail = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_LIST_ERR,
  payload,
})

export const getSKUDetail = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_DETAIL,
  payload,
})

export const getSKUDetailSuccess = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_DETAIL_OK,
  payload,
})

export const getSKUDetailFail = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_DETAIL_ERR,
  payload,
})

export const resetState = (payload) => ({
  type: SKU_ACTIONS.GET_SKU_DETAIL_ERR,
  payload,
})

export const clearSkuDetail = () => ({
  type: SKU_ACTIONS.CLEAR_SKU_DETAIL,
  payload: defaultSku,
})

export default {
  getSKUList,
  getSKUListSuccess,
  getSKUListFail,

  getSKUDetail,
  getSKUDetailSuccess,
  getSKUDetailFail,

  createSku,
  createSkuOk,
  createSkuFail,

  updateSku,
  updateSkuOk,
  updateSkuFail,

  clearSkuDetail,
}
