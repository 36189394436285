import React, {
  useState, useEffect, useCallback, useReducer,
} from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import moment from 'moment'
import { withStyles } from '~/themes/useStyles'
import pubsub from '~/utils/pushNotification/pubsub'
import NotificationItem from './NotificationItem'
import Downloading from '~/components/common/Downloading'
import style from './style'
import { handleExportType } from '~/utils/helpers/csvDownload'
import { NOTIFICATION_SHIPPING_METHODS } from '~/constants/settings'

const DOMAIN_NAME = 'Breadstack'

const reducer = (notifications, action) => {
  const shownTime = moment().format('hh:mm A')
  switch (action.type) {
    case 'add':
      if (notifications.find((item) => item.id === action.payload.id)) return notifications
      return [{ ...action.payload, shownTime }, ...notifications]
    case 'remove':
      return notifications.filter((item) => item.id !== action.payload)
    default:
      return notifications
  }
}

const NotificationPresenter = ({ classes }) => {
  const navigate = useNavigate()
  const [progress, setProgress] = useState(0)
  const [display, setDisplay] = useState(false)

  const [notifications, dispatch] = useReducer(reducer, [])
  const showNotification = useCallback(
    (evt) => {
      switch (true) {
        case evt.type === 'app_updated': {
          dispatch({
            type: 'add',
            payload: {
              type: 'app_updated',
              id: 'app_updated',
              data: {
                message: 'App updated',
                title: 'A new version has just released!',
              },
            },
          })
          return
        }
        case evt.type === 'notify_private_product': {
          dispatch({
            type: 'add',
            payload: {
              type: 'notify_private_product',
              id: 'notify_private_product',
              data: evt.data,
            },
          })
          return
        }
        default: {
          if (
            !handleExportType(evt.type)
            && ![
              NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING,
              NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY,
              NOTIFICATION_SHIPPING_METHODS.LOCAL_PICKUP,
              'import_store',
              NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP,
              NOTIFICATION_SHIPPING_METHODS.ONFLEET,
              NOTIFICATION_SHIPPING_METHODS.BS_CANFLEET_LOCAL_SHIPPING,
            ].includes(evt.type)
          ) return

          dispatch({
            type: 'add',
            payload: {
              type: evt.type,
              data: evt.data,
              id: evt.data.orderId || evt.data.exportId || `${evt.data.status}${evt.data.storeId}`,
            },
          })
        }
      }
    },
    [],
  )

  const closeNotification = useCallback((id) => {
    dispatch({
      type: 'remove',
      payload: id,
    })
  }, [])

  useEffect(() => {
    const count = notifications.length

    if (count) {
      document.title = `(${count}) ${DOMAIN_NAME}`
    } else {
      document.title = DOMAIN_NAME
    }
  }, [notifications])

  useEffect(() => {
    pubsub.on('message', showNotification)

    return () => pubsub.off('message', showNotification)
  }, [showNotification])

  const handleDisplay = (b) => {
    setDisplay(b)
    setProgress(0)
  }

  const handleProgress = (p) => {
    setProgress(p)
  }
  return (
    <>
      {Boolean(notifications && notifications.length) && (
      <div className={classes.notificationContainer}>
        <div className={classes.notificationTrack}>
          { notifications.map((noti, idx) => (
            <NotificationItem
              key={`${noti.id}${idx}`}
              noti={noti}
              onClick={() => {
                if (noti.type === 'app_updated') {
                  closeNotification('app_updated')
                  return
                }
                if (noti.data.path) {
                  navigate(noti.data.path)
                }
                closeNotification(noti.id)
              }}
              onClose={closeNotification}
              onDisplay={(b) => handleDisplay(b)}
              onProgress={(p) => handleProgress(p)}
              index={idx}
            />
          ))}
        </div>
      </div>
      )}

      <Downloading display={display} loading={progress} />
    </>
  )
}

NotificationPresenter.propTypes = {
  classes: PropTypes.shape().isRequired,
}

export default withStyles(style)(NotificationPresenter)
