/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useRouteError,
} from 'react-router-dom'
import { theme as bsTheme } from 'bs-unified-ui'
import { Provider } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { FeaturesReady, GrowthBookProvider } from '@growthbook/growthbook-react'
import { createRoot } from 'react-dom/client'
import configureStore from '~/redux/store'
import AdminLayout from './layouts/Admin'
import SystemRouters from './router/SystemRouters'
import '~/assets/css/style.scss'
import { PromptProvider } from '~/modules/Prompt'
import NoMatch from './pages/NoMatch'
import AuthRouters from './router/AuthRouters'
import CustomRouters from './router/CustomRouters'
import '~/utils/datetime'
import RouteWithStore from '~/components/main/routers/RouteWithStore'
import 'react-datetime/css/react-datetime.css'
import DataLoading from './components/common/DataLoading'
import { appendPendo } from './utils/pendo'
import { appendGtag } from './utils/gaTag'
import { REACT_APP_ENV, REACT_APP_SENTRY_DNS, APP_SHA_VERSION } from './constants/environment'
import growthbook, { useLoadFeatures } from './configs/growthBook'
import theme from './themes/theme'
import WrapSSOProvider from './providers/WrapSSOProvider'

const { store, persistor } = configureStore()

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })

function FallbackComponent() {
  const error = useRouteError()
  if (error?.message?.includes('Failed to fetch dynamically imported module')) {
    window.location.reload()
  }
  return <div />
}

const myFallback = <FallbackComponent />

const App = () => {
  useEffect(() => {
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))|Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i.test(navigator?.userAgent)) {
      document.body.classList.add('mobile-tablet-device')
    }
  }, [])

  useLoadFeatures()

  const appRouter = createBrowserRouter(
    createRoutesFromElements(
      <>
        {CustomRouters.map((router) => (
          <Route
            key={router.path}
            exact={router.exact}
            path={router.path}
            element={(
              <React.Suspense fallback={<DataLoading loading />}>
                {
                  router.layout
                    ? (
                      <router.layout>
                        <router.component {...router.xProps} />
                      </router.layout>
                    )
                    : <router.component {...router.xProps} />
                }

              </React.Suspense>
            )}
            errorElement={myFallback}
          />
        ))}
        {AuthRouters.map((router) => (
          <Route
            key={router.path}
            exact={router.exact}
            path={router.path}
            element={(
              <React.Suspense fallback={<DataLoading loading />}>
                <router.layout>
                  <router.component {...router.xProps} />
                </router.layout>
              </React.Suspense>
            )}
            errorElement={myFallback}
          />
        ))}
        {SystemRouters.filter(({ layout }) => layout === 'admin').map((router) => (
          <Route
            key={router.path}
            exact={router.exact}
            path={`${router.path}`}
            element={(
              <AdminLayout>
                <React.Suspense fallback={<DataLoading loading />}>
                  <ThemeProvider theme={router.bsTheme ? bsTheme : theme}>
                    <RouteWithStore
                      key={router.path}
                      exact={router.exact}
                      path={`${router.path}`}
                      routeConfig={router}
                    />
                  </ThemeProvider>
                </React.Suspense>
              </AdminLayout>
            )}
            errorElement={myFallback}
          />
        ))}
        <Route path="*" element={<NoMatch />} />
      </>,
    ),
  )

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog={false}>
      <GrowthBookProvider growthbook={growthbook}>
        <StyledEngineProvider injectFirst>
          <FeaturesReady fallback={null}>
            <ThemeProvider theme={bsTheme}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <QueryClientProvider client={queryClient}>
                    <WrapSSOProvider>
                      <PromptProvider>
                        <RouterProvider router={appRouter} />
                      </PromptProvider>
                    </WrapSSOProvider>
                  </QueryClientProvider>
                </PersistGate>
              </Provider>
              <div id="errorMessage" />
              <div id="toast" />
              <div id="app-version" style={{ display: 'none' }}>{APP_SHA_VERSION}</div>
            </ThemeProvider>
          </FeaturesReady>
        </StyledEngineProvider>
      </GrowthBookProvider>
    </Sentry.ErrorBoundary>
  )
}

appendGtag()
appendPendo()

Sentry.init({
  dsn: REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  environment: REACT_APP_ENV,
  tracesSampleRate: REACT_APP_ENV !== 'production' ? 0 : 0.5,
  release: {
    name: APP_SHA_VERSION,
  },
})

const root = createRoot(document.getElementById('root'))
root.render(<App />)
