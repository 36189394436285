import { PENDO_API_KEY } from '~/constants/environment'

export const appendPendo = () => {
  if (!PENDO_API_KEY) return
  //   const pendoScript = document.createElement('script')
  //   pendoScript.type = 'text/javascript'
  //   pendoScript.text = `(function(apiKey){
  //     (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
  //     v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
  //         o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
  //         y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
  //         z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  // })('${PENDO_API_KEY}');`
  //   document.head.appendChild(pendoScript)
  window.PENDO_API_KEY = PENDO_API_KEY

  const pendoScript = document.createElement('script')
  pendoScript.type = 'text/javascript'
  pendoScript.src = '/pendoLoader.js'
  document.head.appendChild(pendoScript)
}
