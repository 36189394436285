import produce from 'immer'
import { WAREHOUSE_OPERATION_STATUS } from '~/constants/inventory'
import { warehouseTransferActions } from '../actions/warehouseTransfer'

const initialState = {
  warehouseTransfer: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
    loadingWarehouse: false,
    warehouses: [],
  },
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload = {} } = action

  switch (type) {
    case warehouseTransferActions.GET_TRANSFER_REQUEST:
      draft.warehouseTransfer.loading = true
      break
    case warehouseTransferActions.GET_TRANSFER_OK:
      draft.warehouseTransfer.loading = false
      draft.warehouseTransfer.items = payload.items
      draft.warehouseTransfer.total_items = payload.total_items
      draft.warehouseTransfer.total_pages = payload.total_pages
      break
    case warehouseTransferActions.GET_TRANSFER_ERR:
      draft.warehouseTransfer.loading = false
      draft.warehouseTransfer.error = 'Error occurred'
      break
    case warehouseTransferActions.UNLOCK_TRANSFER_ITEM:
      draft.warehouseTransfer.loading = true
      break
    case warehouseTransferActions.UNLOCK_TRANSFER_ITEM_SUCCESS:
      draft.warehouseTransfer.loading = false
      if (payload.success && draft.warehouseTransfer.items.findIndex((x) => x.id === payload.id) !== -1) {
        draft.warehouseTransfer.items[draft.warehouseTransfer.items.findIndex((x) => x.id === payload.id)].current_user = null
      }
      break
    case warehouseTransferActions.DELETE_TRANSFER_ITEM:
      draft.warehouseTransfer.loading = true
      break
    case warehouseTransferActions.DELETE_TRANSFER_ITEM_SUCCESS:
      draft.warehouseTransfer.loading = false
      if (draft.warehouseTransfer.items.findIndex((x) => x.id === payload.id) !== -1) {
        draft.warehouseTransfer.items[draft.warehouseTransfer.items.findIndex((x) => x.id === payload.id)].status = WAREHOUSE_OPERATION_STATUS.CANCELLED
      }
      break
    case warehouseTransferActions.DELETE_TRANSFER_ITEM_ERROR:
      draft.warehouseTransfer.loading = false
      break

    case warehouseTransferActions.GET_ALL_WAREHOUSES:
      draft.warehouseTransfer.loadingWarehouse = true
      break
    case warehouseTransferActions.GET_ALL_WAREHOUSES_SUCCESS:
      draft.warehouseTransfer.loadingWarehouse = false
      draft.warehouseTransfer.warehouses = payload
      break
    case warehouseTransferActions.GET_ALL_WAREHOUSES_ERROR:
      draft.warehouseTransfer.loadingWarehouse = false
      break
    default:
      break
  }
})

export default reducer
