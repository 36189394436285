import { COMPOSITE_LAYOUT, COMPOSITE_SHIPPINGS, PRODUCT_TYPE } from '~/constants/product'

export const DEFAULT_PRODUCT_LIST_DATA = Object.freeze({
  items: [],
  total_items: 0,
  total_pages: 0,
})

export const DEFAULT_PRODUCT_DETAIL = {
  // General:
  type: 'simple', // 'simple', // 'variable',
  name: '',
  slug: '',
  regular_price: '',
  sale_price: '',
  // virtual: false,
  downloadable: false,
  description: '',
  short_description: '',
  date_on_sale_from: null,
  date_on_sale_from_gmt: null,
  date_on_sale_to: null,
  date_on_sale_to_gmt: null,
  tax_status: 'none',
  tax_class: '',
  linked_inventory: true,
  manage_stock: false,
  stock_status: 'instock',
  // Inventory:
  sku: '',
  sold_individually: false,
  backorders: 'no',
  cogs: 0,
  shipping_class: '',
  upsell_ids: [],
  cross_sell_ids: [],
  attributes: [],
  categories: [],
  tags: [],
  images: [],

  // variations
  variations: [],
  variationContainers: [],
  oldVariationContainer: [],

  // Publish Setting
  status: 'draft',
  featured: false,
  catalog_visibility: 'visible',
  date_created_gmt: null,

  // composite
  composite_layout: COMPOSITE_LAYOUT.STACKED,
  composite_add_to_cart_form_location: 'default',
  composite_shop_price_calc: 'defaults',
  shipping_type: COMPOSITE_SHIPPINGS.UNASSIGNED,
  composite_components: [],
  composite_scenarios: [],

  // Shipping:
  dimensions: {
    length: '',
    width: '',
    height: '',
  },
  weight: '',
}

export const makeDefaultProduct = (type) => ({
  ...DEFAULT_PRODUCT_DETAIL,
  date_created_gmt: null,
  ...(type === PRODUCT_TYPE.COMPOSITE ? {
    virtual: true,
    shipping_required: false,
    shipping_taxable: false,
  } : {}),
})

export const COMPOSITE_COMPONENT = {
  title: '',
  query_type: 'product_ids',
  query_ids: [],
  options_style: 'dropdowns',
  display_prices: 'absolute',
  product_title_visible: true,
  product_descr_visible: true,
  product_thumb_visible: true,
  product_price_visible: true,
  subtotal_visible_product: true,
  subtotal_visible_cart: true,
  subtotal_visible_orders: true,
  quantity_min: 1,
  quantity_max: 1,
}

export const COMPOSITE_SCENARIO = {
  name: '',
  description: '',
  configuration: [],
  configuration_map: {},
  actions: [
    { action_data: [], action_id: 'compat_group', is_active: false },
    { hidden_components: [], action_id: 'conditional_components', is_active: false },
    { hidden_options: [], action_id: 'conditional_options', is_active: false },
  ],
  enabled: true,
}
