import {
  all,
  takeLatest,
  put,
  fork,
  call,
  select,
} from 'redux-saga/effects'

import shareApi from '~/services/apis/share'
import { convertCategoryData, convertLocationListName } from '~/utils/share'
import {
  SHARE_ACTION,
  getCategoryListSuccess,
  getCategoryListFail,
  getLocationsSuccess,
  getLocationsFailed,

  getFilterListSuccess,
  getFilterListFail,
  addFilterSuccess,
  addFilterFail,
  updateFilterSuccess,
  updateFilterFail,
  deleteFilterSuccess,
  deleteFilterFail,
} from '../actions/share'

export function* getCategoryData() {
  try {
    const data = yield call(shareApi.getCategory)
    yield put(getCategoryListSuccess(convertCategoryData(data)))
  } catch (e) {
    yield put(getCategoryListFail(e))
  }
}

export function* getLocations() {
  try {
    const data = yield call(shareApi.getLocations)
    yield put(getLocationsSuccess(convertLocationListName(data)))
  } catch (e) {
    yield put(getLocationsFailed(e))
  }
}

// filter
const getUserId = (state) => state.auth?.data?.userId
export function* getFilterList(action) {
  const userId = yield select(getUserId)
  try {
    const resp = yield call(shareApi.getFilterList, userId, action.payload)
    yield put(getFilterListSuccess({
      data: resp,
      type: action.payload.type,
    }))
  } catch (e) {
    yield put(getFilterListFail(e))
  }
}

export function* addFilter(action) {
  const userId = yield select(getUserId)
  try {
    const resp = yield call(shareApi.addFilter, userId, action.payload)
    yield put(addFilterSuccess(resp))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(addFilterFail(e))
    action.callbacks.onError(e)
  }
}

export function* updateFilter(action) {
  const userId = yield select(getUserId)
  try {
    yield call(shareApi.updateFilter, userId, action.payload)
    yield put(updateFilterSuccess(action.payload))
  } catch (e) {
    yield put(updateFilterFail(e))
  }
}

export function* deleteFilter(action) {
  const userId = yield select(getUserId)
  try {
    yield call(shareApi.deleteFilter, userId, action.payload)
    yield put(deleteFilterSuccess(action.payload))
    action.callbacks.onSuccess()
  } catch (e) {
    yield put(deleteFilterFail(e))
    action.callbacks.onError(e)
  }
}

// watch
export function* watchCategoryData() {
  yield takeLatest(SHARE_ACTION.GET_CATEGORY_LIST, getCategoryData)
}

export function* watchGetLocations() {
  yield takeLatest(SHARE_ACTION.GET_LOCATIONS, getLocations)
}

export function* watchGetFilterList() {
  yield all([
    takeLatest(SHARE_ACTION.GET_FILTER_LIST, getFilterList),
    takeLatest(SHARE_ACTION.ADD_FILTER, addFilter),
    takeLatest(SHARE_ACTION.UPDATE_FILTER, updateFilter),
    takeLatest(SHARE_ACTION.DELETE_FILTER, deleteFilter),
  ])
}

export default function* share() {
  yield fork(watchCategoryData)
  yield fork(watchGetLocations)
  yield fork(watchGetFilterList)
}
