export const APPS = Object.freeze({
  BREADSTACK: 'breadstack',
  CHATSO: 'chatso',
  CANFLEET: 'canfleet',
})

export const MENU_ITEM_TYPE = {
  HORIZONTAL_LINE: 'horizontal_line',
  HEADER: 'header',
  SELECTION: 'selection',
  LABEL: 'label',
}

export const POPUP_ID_ITEMS = {
  MY_ACCOUNT_POP_UP: 'my_account_pop_up',
  LOG_OUT: 'log_out',
  SWITCH_WORKSPACE: 'switch_workspace',
}

/**
 * Object containing exception hidden IDs
 * ( Used in cases where menu items need to be hidden but cannot backend processed in be and must be hidden on frontend )
 * Need optimize
 */
export const EXCEPTION_HIDDEN_IDS = {
  PLAN_AND_BILLING: 'plan_and_billing',
  POS: 'pos',
}
