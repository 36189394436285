import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import theme from '~/themes/theme'
import CustomDialog from './CustomDialog'

const DialogContent = (props) => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(true)
  }, [props])

  const close = () => {
    setOpen(false)
  }

  return <StyledEngineProvider injectFirst><ThemeProvider theme={theme}><CustomDialog open={open} onClose={close} {...props} /></ThemeProvider></StyledEngineProvider>
}

const showDialog = (props) => {
  const root = createRoot(document.getElementById('errorMessage'))
  root.render(<DialogContent {...props} />)
}

export {
  showDialog,
}
