/* eslint-disable no-param-reassign */
import produce from 'immer'
import { mapOrderList, mapOrderDetail } from '~/utils/order'
import { ORDER_ACTION } from '../actions/order'
import {
  DEFAULT_ORDER_LIST_DATA,
  DEFAULT_ORDER_DETAIL,
} from '../default/order'

const initialState = {
  data: DEFAULT_ORDER_LIST_DATA,
  covaData: DEFAULT_ORDER_LIST_DATA,
  posData: DEFAULT_ORDER_LIST_DATA,
  loading: false,
  detail: DEFAULT_ORDER_DETAIL,
  loadingDetail: false,
  preview: DEFAULT_ORDER_DETAIL,
  loadingPreview: false,
  getPreviewError: '',
  error: '',
  notes: [],
  loadingNotes: false,
  getNoteError: '',
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case ORDER_ACTION.FETCH_ORDER_LIST:
      draft.loading = true
      draft.error = ''
      break
    case ORDER_ACTION.SET_LIST_DATA:
      draft.data = {
        ...payload,
        items: mapOrderList(payload.items),
      }
      draft.loading = false
      draft.error = ''
      break
    case ORDER_ACTION.SET_LIST_ERROR:
      draft.loading = false
      draft.error = payload
      break

    case ORDER_ACTION.FETCH_DETAIL:
      draft.detail = DEFAULT_ORDER_DETAIL
      draft.loadingDetail = true
      draft.error = ''
      break
    case ORDER_ACTION.SET_DETAIL_DATA_ERROR:
      draft.loadingDetail = false
      draft.error = payload
      break
    case ORDER_ACTION.SET_DETAIL_DATA:
      draft.loadingDetail = false
      draft.error = ''
      draft.detail = payload
      break
    case ORDER_ACTION.FETCH_ORDER_NOTES:
      draft.loadingNotes = true
      draft.getNoteError = ''
      break
    case ORDER_ACTION.SET_ORDER_NOTES:
      draft.loadingNotes = false
      draft.notes = payload
      draft.getNoteError = ''
      break
    case ORDER_ACTION.SET_ORDER_NOTES_ERROR:
      draft.loadingNotes = false
      draft.notes = []
      draft.getNoteError = payload
      break
    case ORDER_ACTION.FETCH_PREVIEW:
      draft.preview = DEFAULT_ORDER_DETAIL
      draft.loadingPreview = true
      draft.getPreviewError = ''
      break
    case ORDER_ACTION.SET_PREVIEW_ERROR:
      draft.loadingPreview = false
      draft.getPreviewError = payload
      break
    case ORDER_ACTION.SET_PREVIEW_DATA:
      draft.loadingPreview = false
      draft.getPreviewError = ''
      draft.preview = mapOrderDetail(payload)
      break
    case ORDER_ACTION.REMOVE_ORDER_ITEMS_REDUX:
      draft.data = {
        ...draft.data,
        items: [],
        total_items: 0,
      }
      break
    case ORDER_ACTION.FETCH_COVA_ORDER_LIST:
      draft.loading = true
      draft.error = ''
      break
    case ORDER_ACTION.SET_COVA_ORDER_LIST_DATA:
      draft.covaData = {
        ...payload,
        items: mapOrderList(payload.items),
      }
      draft.loading = false
      draft.error = ''
      break
    case ORDER_ACTION.FETCH_POS_ORDER_LIST:
      draft.loading = true
      draft.error = ''
      break
    case ORDER_ACTION.SET_POS_LIST_DATA:
      draft.posData = {
        ...payload,
        items: mapOrderList(payload.items),
      }
      draft.loading = false
      draft.error = ''
      break
    case ORDER_ACTION.SET_POS_LIST_ERROR:
      draft.loading = false
      draft.error = payload
      break
    default:
      break
  }
})

export default reducer
