import React, { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  syncedStoresSelector, organizationsSelector, selectWarehouseByOrgId, allStoresSelector, authenticatedStoresSelector,
  selectStoreByStoreId,
  syncedStoresByOrgIdSelector,
} from '../selectors/store'
import storeApi from '~/services/apis/store'
import { CANTEC_TYPE } from '~/constants/store'
import { useUser } from '~/redux/hooks/user'
import { getMapUserStores, getStoreInfo } from '../selectors/user'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'
import PluginInstruction from '~/components/main/PluginInstruction'
import { isActiveCantecDelivery, isActiveCantecLocalPickup } from '~/modules/auth/conditions'

export const useAllStores = () => useSelector(allStoresSelector)

export const useSyncedStores = () => useSelector(syncedStoresSelector)
export const useAuthenticatedStores = () => useSelector(authenticatedStoresSelector)

export const useOrganizations = () => useSelector(organizationsSelector)

export const useStoresInWorkspace = (orgId) => {
  const stores = useSelector(allStoresSelector)
  return useMemo(() => (stores || []).filter((s) => s.orgId === orgId), [stores, orgId])
}

export const useActiveStore = () => {
  const { storeId } = useParams()
  const stores = useSyncedStores()
  const store = useMemo(() => (stores || []).find((s) => s.storeId === storeId), [storeId, stores])
  const activeStore = storeId ? store : null
  // console.log({ activeStore })
  return activeStore
}

export const useSelectedStore = () => {
  const { storeId } = useParams()
  const stores = useAllStores()
  const store = useMemo(() => (stores || []).find((s) => s.storeId === storeId), [storeId, stores])
  const activeStore = storeId ? store : null
  return activeStore
}

export const useIsSyncedStore = () => !!useActiveStore()

export const useGetStore = () => {
  const { storeId } = useParams()
  const stores = useAllStores()
  const store = useMemo(() => (stores || []).find((s) => s.storeId === storeId) || {}, [storeId, stores])
  return storeId ? store : {}
}

export const useActiveStoreType = () => useActiveStore()?.storeType

export const useStoreInfo = (storeId) => {
  if (!storeId) return null
  const stores = useSyncedStores()
  return useMemo(() => (stores || []).find((s) => s.storeId === storeId), [storeId, stores])
}

export const useStoreType = (storeId) => {
  const stores = useSyncedStores()
  return useMemo(() => storeId ? (stores || []).find((s) => s.storeId === storeId)?.storeType : null, [storeId, stores])
}

export const useDefaultStore = () => {
  const stores = useSyncedStores()
  const { user } = useUser()
  const activeStore = useMemo(() => {
    if (user && user.user_settings && user.user_settings.default_store) {
      return user.user_settings.default_store
    }
    if (stores && (stores || []).length > 0) {
      return stores[Object.keys(stores)[0]]?.storeId
    }
    return null
  }, [user, stores])
  return activeStore
}

export const useWarehouses = () => {
  const activeStore = useActiveStore()
  if (!activeStore) return []
  return useSelector(selectWarehouseByOrgId(activeStore.orgId))
}

const checkCantecStatus = async (storeURL, type) => {
  try {
    const { data: { enabled } } = await storeApi.checkCantecStatus({ storeURL, type })
    return enabled
  } catch (err) {
    return false
  }
}

const updateCantecStatus = async (storeURL, type, status) => {
  try {
    const { data: { enabled }, status: responseStatus, message } = await storeApi.changeCantecStatus({ storeURL, type, status })

    return {
      enabled: enabled || false,
      error: responseStatus === 'success' ? '' : message,
    }
  } catch (err) {
    return {
      enabled: !status,
      error: err,
    }
  }
}

export const useCantecDelivery = (values) => {
  const [isCantecDelivery, setIsCantecDelivery] = useState(false)
  const [loadingCantecDelivery, setLoadingCantecDelivery] = useState(false)

  const changeCantecDelivery = async (status) => {
    if (!isActiveCantecDelivery()({ plugins: { active_plugins: [{ ...values.active_plugins }] } })) {
      showDialog({
        ...dialogParams.pluginNotInstalled,
        onOk: () => showDialog({
          maxWidth: 'lg',
          children: (cb) => <PluginInstruction type={CANTEC_TYPE.CANTEC_DELIVERY} onClose={cb.onClose} />,
        }),
      })

      return
    }

    setLoadingCantecDelivery(true)
    const res = await updateCantecStatus(values.store_url, CANTEC_TYPE.CANTEC_DELIVERY, status)
    setIsCantecDelivery(res.enabled)
    setLoadingCantecDelivery(false)

    if (res.error) {
      showDialog(dialogParams.error(res.error))
    }
  }
  useEffect(() => {
    if (values.store_id && isActiveCantecDelivery()({ plugins: { active_plugins: [{ ...values.active_plugins }] } })) {
      const checkCantecStatusAsync = async () => {
        setLoadingCantecDelivery(true)
        const res = await checkCantecStatus(values.store_url, CANTEC_TYPE.CANTEC_DELIVERY)
        setIsCantecDelivery(res)
        setLoadingCantecDelivery(false)
      }
      checkCantecStatusAsync()
    }
    return () => {
      setIsCantecDelivery(false)
    }
  }, [values.store_id])

  return { isCantecDelivery, loadingCantecDelivery, changeCantecDelivery }
}

export const useLocalPickUp = (values) => {
  const [isLocalPickUp, setIsLocalPickUp] = useState(false)
  const [loadingLocalPickUp, setLoadingLocalPickUp] = useState(false)

  const changeLocalPickup = async (status) => {
    if (!isActiveCantecLocalPickup()({ plugins: { active_plugins: [{ ...values.active_plugins }] } })) {
      showDialog({
        ...dialogParams.pluginNotInstalled,
        onOk: () => showDialog({
          maxWidth: 'lg',
          children: (cb) => <PluginInstruction type={CANTEC_TYPE.CANTEC_LOCAL_PICKUP} onClose={cb.onClose} />,
        }),
      })

      return
    }

    setLoadingLocalPickUp(true)
    const res = await updateCantecStatus(values.store_url, CANTEC_TYPE.CANTEC_LOCAL_PICKUP, status)
    setIsLocalPickUp(res.enabled)
    setLoadingLocalPickUp(false)

    if (res.error) {
      showDialog(dialogParams.error(res.error))
    }
  }

  useEffect(() => {
    if (values.store_id && isActiveCantecLocalPickup()({ plugins: { active_plugins: [{ ...values.active_plugins }] } })) {
      const checkCantecStatusAsync = async () => {
        setLoadingLocalPickUp(true)
        const res = await checkCantecStatus(values.store_url, CANTEC_TYPE.CANTEC_LOCAL_PICKUP)
        setIsLocalPickUp(res)
        setLoadingLocalPickUp(false)
      }
      checkCantecStatusAsync()
    }
    return () => {
      setIsLocalPickUp(false)
    }
  }, [values.store_id])

  return { isLocalPickUp, loadingLocalPickUp, changeLocalPickup }
}

export const useGetMapUserStores = () => useSelector(getMapUserStores)

export const useActiveStoreFullyInfo = () => {
  const { storeId } = useParams()
  return useSelector(getStoreInfo(storeId))
}

export const useStore = (storeId) => {
  if (storeId) { return useSelector(selectStoreByStoreId(storeId)) }
  return null
}

export const useSyncedStoresByOrgId = (orgId) => useSelector(syncedStoresByOrgIdSelector(orgId))

export const useExistSyncedStores = (orgId) => {
  if (orgId) return useSyncedStoresByOrgId(orgId).length > 0
  return useSyncedStores().length > 0
}
