import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
  SYNC: 'sync',
  GET_SHIPPING_ZONE_METHODS: 'get_shipping_zone_methods',
}
const url = {
  SHIPPING_ZONES: '/stores/woocommerce/shipping/zones/',
  CREATE_SHIPPING_ZONE_METHODS: '/stores/woocommerce/shipping/zones/methods/',
  SHIPPING_CLASSES: 'stores/woocommerce/products/shipping_classes/',
  SHIPPING_METHOD: '/stores/woocommerce/shipping/methods/',
  SHIPPING_ZONES_LOCATION: '/stores/woocommerce/shipping/zones/locations/',
  SHIPPING_ZONES_METHOD: '/stores/woocommerce/shipping/zones/methods/',
}

const getShippingZoneMethods = async () => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.GET_SHIPPING_ZONE_METHODS,
    parameters: {},
  })
  return resp
}

const getMethods = async () => {
  try {
    const resp = await httpClient.post(url.SHIPPING_METHOD, {
      action: ACTIONS.READ_DIRECT_PAGINATE,
      parameters: {},
    })
    return resp
    // eslint-disable-next-line
  } catch (err) {
    return []
  }
}

const getShippingZones = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.READ_DIRECT_PAGINATE,
    parameters: params || { limit: 100, page: 1 },
  })
  return resp
}

const getShippingClasses = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_CLASSES, {
    action: ACTIONS.PAGINATE,
    parameters: params || { limit: 100, page: 1 },
  })
  return resp
}

const addShippingClass = async (data) => {
  const resp = await httpClient.post(url.SHIPPING_CLASSES, {
    action: ACTIONS.CREATE,
    parameters: {
      item_data: data,
    },
  })
  return resp
}

const updateShippingClass = async (data) => {
  const resp = await httpClient.post(url.SHIPPING_CLASSES, {
    action: ACTIONS.UPDATE,
    parameters: {
      item_data: data,
    },
  })
  return resp
}

const deleteShippingClass = async (data) => {
  const resp = await httpClient.post(url.SHIPPING_CLASSES, {
    action: ACTIONS.DELETE,
    parameters: data,
  })
  return resp
}

const updateShippingZones = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.UPDATE,
    parameters: params,
  })
  return resp
}

const createShippingZone = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.CREATE,
    parameters: params,
  })
  return resp
}

const updateZoneLocation = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES_LOCATION, {
    action: ACTIONS.UPDATE,
    parameters: params,
  })
  return resp
}

const editShippingZone = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.UPDATE,
    parameters: params,
  })
  return resp
}

const createShippingMethod = async (params) => {
  const resp = await httpClient.post(url.CREATE_SHIPPING_ZONE_METHODS, {
    action: ACTIONS.CREATE,
    parameters: params,
  })
  return resp
}

const findZoneMethod = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES_METHOD, {
    action: ACTIONS.READ_DIRECT_PAGINATE,
    parameters: { id: params.id },
  })
  return resp
}

const updateZoneMethod = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES_METHOD, {
    action: ACTIONS.UPDATE,
    parameters: params,
  })
  return resp
}

const deleteZoneMethod = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES_METHOD, {
    action: ACTIONS.DELETE,
    parameters: params,
  })
  return resp
}

const deleteShippingMethod = async (params) => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.DELETE,
    parameters: params,
  })
  return resp
}

const syncZoneWithWoo = async () => {
  const resp = await httpClient.post(url.SHIPPING_ZONES, {
    action: ACTIONS.SYNC,
    parameters: {},
  })
  return resp
}

const syncClassWithWoo = async () => {
  const resp = await httpClient.post(url.SHIPPING_CLASSES, {
    action: ACTIONS.SYNC,
    parameters: {},
  })
  return resp
}

const syncMethodWithWoo = async () => {
  const resp = await httpClient.post(url.SHIPPING_METHOD, {
    action: ACTIONS.SYNC,
    parameters: {},
  })
  return resp
}

const shippingApi = {
  getShippingZoneMethods,
  getMethods,
  getShippingZones,
  getShippingClasses,
  addShippingClass,
  updateShippingClass,
  deleteShippingClass,
  updateShippingZones,
  createShippingMethod,
  deleteShippingMethod,
  createShippingZone,
  updateZoneLocation,
  editShippingZone,
  findZoneMethod,
  updateZoneMethod,
  deleteZoneMethod,
  syncZoneWithWoo,
  syncClassWithWoo,
  syncMethodWithWoo,
}

export default shippingApi
