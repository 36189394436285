import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
}
const url = {
  NOTIFICATION_CENTER: '/users/notifications/',
}

const getNotificationCenter = async (params) => {
  const resp = await httpClient.post(url.NOTIFICATION_CENTER, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })
  return resp
}

const notificationCenterApi = {
  getNotificationCenter,
}

export default notificationCenterApi
