import moment from 'moment'
import { isEqual } from 'lodash'
import { formatCurrency } from '~/utils/common'
import { VANCOUVER_TZ } from '~/constants/share'

/* eslint-disable no-unused-vars */

export const generateRandomCode = (length = 5) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const isNullOrUndefined = (value) => value === null || value === undefined

export const isNullOrUndefinedOrEmptyString = (value) => isNullOrUndefined(value) || value === ''

export const isNumeric = (value) => !isNullOrUndefinedOrEmptyString(value)

export const priceFormatter = (num, len = 2) => {
  const n = Number.parseFloat(num).toFixed(len)
  const int_part = n.split('.')[0]
  const float_part = n.split('.')[1]
  return `${new Intl.NumberFormat().format(Number(int_part))}.${float_part}`
}

export const formatNumber = (num, len = 2) => {
  const n = Number.parseFloat(num).toFixed(len)
  const int_part = n.split('.')[0]
  const float_part = n.split('.')[1]
  return Number.isInteger(Number(num)) ? new Intl.NumberFormat().format(Number(num)) : `${int_part}.${float_part}`
}

export const humanize = (x) => x.toFixed(2).replace(/\.?0*$/, '')
// TODO: Remove this function getTime()
// Please not use this function, you can use function convertGmtToLocalTime in ~/utils/datetime to replace
export const getTime = (gmtTime, formatDate) => moment().utc(gmtTime).format(formatDate || 'MM/DD/YYYY')

export const checkFilterOn = (values, defaultList) => !!Object.keys(defaultList).find((defaultKey) => Object.keys(values).find((key) => key === defaultKey && values[key] !== defaultList[defaultKey]))

export const getBillingAddress = (locationList, billing) => {
  if (!billing) return ''

  const billingAddress = { ...billing }

  const address = `${billing.address_1 || ''}${billing.address_2 ? `, ${billing.address_2}` : ''}${billing.city ? `, ${billing.city}` : ''}`
  billingAddress.address = address

  let country
  let state

  if (billing.country) {
    country = locationList.find((x) => x.value === billing.country)
    billingAddress.country = country ? country.label : ''
  }

  if (country && billing.state) {
    state = country.states.find((x) => x.value === billing.state)
    billingAddress.state = state ? state.label : ''
  }
  return billingAddress
}

export const convertLocationListName = (list) => list.map(({ code, name, states }) => ({ label: name, value: code, states: states.map(({ name: stateName, code: stateCode }) => ({ label: stateName, value: stateCode })) }))

export const deepCleanObject = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      newObj[key] = deepCleanObject(obj[key]) // recurse
    } else if (obj[key] != null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key] // copy value
    }
  })
  return newObj
}

export const filterObject = (data, not_allowed) => Object.keys(data)
  .filter((key) => !not_allowed.includes(key))
  .reduce((obj, key) => {
    // eslint-disable-next-line no-param-reassign
    obj[key] = data[key]
    return obj
  }, {})

export const capitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return ''
}

export const capitalizeAllBySpace = (str) => {
  if (!isNullOrUndefined(str) && str.length > 0) {
    return str.split(' ').map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  }
  return str
}

export const formatPrice = (num) => (num < 0 ? `-$${Math.abs(Number(num)).toFixed(2)}` : `$${Number(num).toFixed(2)}`)

export const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

const endpoint = ['M', 'B', 'T', 'P']
const convertToText = (num, pointnums) => {
  let formatNum = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(num)
  let int_part = +parseFloat(formatNum.split('.')[0].replace(/,/g, ''))
  if (int_part === 1000 && pointnums < 2) {
    return `1${endpoint[pointnums + 1]}`
  }
  if (int_part.toString().length > 3) {
    int_part = new Intl.NumberFormat().format(Number(int_part))
  }
  const float_part = +formatNum.split('.')[1]
  if (+float_part === 0) {
    formatNum = int_part
  }
  return `${formatNum}${endpoint[pointnums]}`
}

export const convertNumberWithSISymbol = (num, digits) => {
  if (!num) return '0'
  const n = Number.parseFloat(num).toFixed(2)
  let int_part = +n.split('.')[0]
  let float_part = +n.split('.')[1]
  let val
  if (+int_part < 1000000) {
    if (float_part >= 50 && int_part < 999999) {
      int_part += 1
      float_part = ''
    } else if (float_part >= 50 && int_part === 999999) {
      return '1M'
    } else {
      float_part = ''
    }
    const dot = float_part ? '.' : ''
    val = `${new Intl.NumberFormat().format(
      Number(int_part),
    )}${dot}${float_part}`
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0)
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1)
  } else {
    val = convertToText(+int_part / 1000000000000, 2)
  }
  return `${val}`
}

export const getNumberWithCurrency = (num, defaultValue = '$0.00') => {
  if (!num) {
    return {
      display: defaultValue,
      tooltip: '',
    }
  }
  const minus = +num >= 0 ? '' : '-'
  const n = Number.parseFloat(num).toFixed(2)
  const int_part = minus ? Math.abs(+n.split('.')[0]) : +n.split('.')[0]
  let float_part = n.split('.')[1]
  if (int_part < 1000) {
    return {
      display: formatCurrency(`${minus}${new Intl.NumberFormat().format(
        Number(int_part),
      )}.${float_part}`),
      tooltip: '',
    }
  }
  let val
  if (!float_part) {
    float_part = '00'
  }
  if (+int_part < 1000000) {
    val = `${new Intl.NumberFormat().format(
      Number(int_part),
    )}`
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0)
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1)
  } else if (+int_part >= 1000000000000 && +int_part < 1000000000000000) {
    val = convertToText(+int_part / 1000000000000, 2)
  } else {
    val = convertToText(+int_part / 1000000000000000, 3)
  }
  return {
    display: formatCurrency(`${minus}${val}`),
    tooltip: formatCurrency(
      `${minus}${new Intl.NumberFormat().format(
        Number(int_part),
      )}.${float_part}`,
    ),
  }
}

export const printReceiptNumberCurrency = (num) => getNumberWithCurrency(num).tooltip || getNumberWithCurrency(num).display

export const getNumberNormal = (num, defaultValue = '0.00') => {
  if (!num) {
    return {
      display: defaultValue,
      tooltip: '',
    }
  }
  const minus = +num >= 0 ? '' : '-'
  const n = Number.parseFloat(num).toFixed(2)
  const int_part = minus ? Math.abs(+n.split('.')[0]) : +n.split('.')[0]
  let float_part = n.split('.')[1]
  if (int_part < 1000) {
    return {
      display: `${minus}${new Intl.NumberFormat().format(
        Number(int_part),
      )}.${float_part}`,
      tooltip: '',
    }
  }
  let val
  if (!float_part) {
    float_part = '00'
  }
  if (+int_part < 1000000) {
    val = `${new Intl.NumberFormat().format(Number(int_part))}`
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0)
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1)
  } else if (+int_part >= 1000000000000 && +int_part < 1000000000000000) {
    val = convertToText(+int_part / 1000000000000, 2)
  } else {
    val = convertToText(+int_part / 1000000000000000, 3)
  }
  return {
    display: `${minus}${val}`,
    tooltip: `${minus}${new Intl.NumberFormat().format(
      Number(int_part),
    )}.${float_part}`,
  }
}

export const getTimeFromSeconds = (
  seconds,
  format = {
    second: 's',
    minutes: 'm',
    hours: 'h',
    days: 'd',
  },
) => {
  const days = Math.floor(moment.duration(seconds, 'seconds').asDays())
  const daysText = days ? `${days}${format.days}` : ''

  const hours = Math.floor(moment.duration(seconds, 'seconds').asHours())
  const hoursText = hours ? `${hours}${format.hours}` : ''

  const minutes = moment.duration(seconds, 'seconds').minutes()
  const minutesText = minutes ? `${minutes}${format.minutes}` : ''

  const second = moment.duration(seconds, 'seconds').seconds()
  const secondText = second ? `${second}${format.second}` : ''
  return `${daysText} ${hoursText} ${minutesText} ${secondText}`
}

export const diffObj = (
  target = {},
  origin = {},
  map = (_, v) => v,
  ignoreKeys = [],
  rejectKeys = [],
) => Object.entries(target).reduce((acc, item) => {
  const newVal = map(item[0], item[1])
  const originVal = map(item[0], origin[item[0]])
  const rejectKey = rejectKeys && rejectKeys.length > 0 && rejectKeys.includes(item[0])
  if (!rejectKey && (ignoreKeys.includes(item[0]) || !isEqual(originVal, newVal))) {
    return {
      ...acc,
      [item[0]]: newVal,
    }
  }
  return acc
}, {})

export function isNewerVersion(oldVer, newVer) {
  const oldParts = (oldVer || '').split('.')
  const newParts = (newVer || '').split('.')
  for (let i = 0; i < newParts.length; i += 1) {
    const a = parseInt(newParts[i] || '0', 10)
    const b = parseInt(oldParts[i] || '0', 10)
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

export function enchantPriceFormatter(num, defaultValue = '$0.00') {
  if (!num) {
    return {
      display: defaultValue,
      tooltip: '',
    }
  }
  const minus = +num >= 0 ? '' : '-'
  const n = Number.parseFloat(num).toFixed(2)
  const int_part = minus ? Math.abs(+n.split('.')[0]) : +n.split('.')[0]
  let float_part = n.split('.')[1]
  if (int_part < 1000) {
    return {
      display: formatCurrency(`${minus}${new Intl.NumberFormat().format(
        Number(int_part),
      )}.${float_part}`),
      tooltip: formatCurrency(
        `${minus}${new Intl.NumberFormat().format(Number(int_part))}.${float_part}`,
      ),
    }
  }
  let val
  if (!float_part) {
    float_part = '00'
  }
  if (+int_part < 1000000) {
    val = `${new Intl.NumberFormat().format(
      Number(int_part),
    )}`
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0)
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1)
  } else if (+int_part >= 1000000000000 && +int_part < 1000000000000000) {
    val = convertToText(+int_part / 1000000000000, 2)
  } else {
    val = convertToText(+int_part / 1000000000000000, 3)
  }
  return {
    display: formatCurrency(`${minus}${val}`),
    tooltip: formatCurrency(
      `${minus}${new Intl.NumberFormat().format(
        Number(int_part),
      )}.${float_part}`,
    ),
  }
}

export function enchantNumberFormatter(num) {
  if (!num) return 0
  const n = Number.parseFloat(num).toFixed(2)
  const int_part = +n.split('.')[0]
  let val
  if (+int_part < 1000000) {
    val = `${new Intl.NumberFormat().format(
      Number(int_part),
    )}`
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0)
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1)
  } else {
    val = convertToText(+int_part / 1000000000000, 2)
  }
  return `${val}`
}

export function enchantNumberChartFormatter(num) {
  if (!num) return 0
  const n = Number.parseFloat(num).toFixed(2)
  const int_part = +n.split('.')[0]
  let val
  if (+int_part < 1000000) {
    val = `${new Intl.NumberFormat().format(
      num,
    )}`
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0)
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1)
  } else {
    val = convertToText(+int_part / 1000000000000, 2)
  }
  return `${val}`
}

export function sortObjAlphabetically(a, b, key = 'label') {
  const aName = (a[key] || '').toLowerCase()
  const bName = (b[key] || '').toLowerCase()
  if (aName < bName) return -1
  if (aName > bName) return 1
  return 0
}

export function capitalizeFirstLetter(string = '') {
  const lower = (string || '').toLowerCase()
  return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const momentVancouver = (dateTime, format) => dateTime ? moment(dateTime, format).tz(VANCOUVER_TZ) : moment().tz(VANCOUVER_TZ)

/**
 *
 * @param {string} tz
 * @param {string} inputTime
 * @param {string} formatter
 * @returns string
 */
export const roundTimeFifteen = (tz, inputTime = '', formatter = 'hh:mm A') => {
  const time = inputTime
    ? tz ? moment(inputTime).tz(tz) : moment(inputTime)
    : tz ? moment().tz(tz) : moment()
  const remainder = 15 - (time.minute() % 15)
  return time.add(remainder, 'minutes').format(formatter)
}

export const queryFnWrapper = ({
  queryFn, onSuccess, onError, onSettled, onStart,
}) => async () => {
  try {
    if (onStart) await onStart()
    const data = await queryFn()
    if (onSuccess) {
      onSuccess(data)
    }
    return data
  } catch (e) {
    if (onError) {
      onError(e)
    }
    return null
  } finally {
    if (onSettled) {
      onSettled()
    }
  }
}
