// import moment from 'moment'

export const DEFAULT_COUPON_LIST_DATA = Object.freeze({
  items: [],
  total_items: 0,
  total_pages: 0,
})

export const DEFAULT_COUPON_DETAIL = {
  code: '',
  amount: '',
  all_product: {
    type: 'specific',
    products: {},
  },
  discount_type: 'percent',
  applied_type: 'specific',
  date_expires: '',
  hour_expires: '',
  description: '',
  free_shipping: false,
  minimum_amount: '',
  maximum_amount: '',
  individual_use: false, // It's "Sold Individualy". If true, the coupon can only be used individually. Other applied coupons will be removed from the cart.
  exclude_sale_items: false, // It's "Individual Use Only". If true, this coupon will not be applied to items that have sale prices.
  products: [],
  excluded_products: [],
  product_categories: [],
  excluded_product_categories: [],
  email_restrictions: [],
  usage_limit: '',
  usage_limit_per_user: '',
  limit_usage_to_x_items: '',

  adding_exclude_product: null,
  adding_product_category: null,
  adding_allowed_email: '',
  adding_product: null,
  adding_excluded_category: null,
  expiry_date: '',

  // setting
  status: 'draft',
  visibility: 'public',
  published: 'immediately',
}
