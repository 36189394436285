export const DASHBOARD_ACTION = {
  GET_DATA: 'dashboard.GET_DATA',
  GET_DATA_RESOLVED: 'dashboard.GET_DATA_RESOLVED',
  GET_DATA_REJECTED: 'dashboard.GET_DATA_REJECTED',
}

export const getDashboardData = (payload) => ({
  type: DASHBOARD_ACTION.GET_DATA,
  payload,
})

export const getDashboardDataResolved = (payload) => ({
  type: DASHBOARD_ACTION.GET_DATA_RESOLVED,
  payload,
})

export const getDashboardDataRejected = (payload) => ({
  type: DASHBOARD_ACTION.GET_DATA_REJECTED,
  payload,
})

export default {
  getDashboardData,
  getDashboardDataResolved,
  getDashboardDataRejected,
}
