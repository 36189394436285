export const DEFAULT_BILL_LIST_DATA = {
  items: [],
  pageItems: [],
  page: 1,
  total_items: 0,
  total_pages: 1,
}

export const DEFAULT_BILL_DETAIL = {
  id: '',
}
