import {
  all,
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'

import billApi from '~/services/apis/bill'

import Actions, { BILL_ACTIONS } from '~/redux/actions/bill'

export function* getBillList(action) {
  try {
    const resp = yield call(billApi.getList, action.payload)
    yield put(Actions.getBillListSuccess(resp))
  } catch (e) {
    yield put(Actions.getBillListFail(e))
  }
}

export function* getBillDetail(action) {
  try {
    const resp = yield call(billApi.findOne, action.payload)
    yield put(Actions.getBillDetailSuccess(resp))
  } catch (e) {
    yield put(Actions.getBillDetailFail(e))
  }
}

export function* getBillStores(action) {
  try {
    const resp = yield call(billApi.getStores, action.payload)
    yield put(Actions.getBillStoresSuccess(resp))
  } catch (e) {
    yield put(Actions.getBillStoresFail(e))
  }
}

export function* watchBill() {
  yield all([
    takeLatest(BILL_ACTIONS.GET_BILL_LIST, getBillList),
    takeLatest(BILL_ACTIONS.GET_BILL_DETAIL, getBillDetail),
    takeLatest(BILL_ACTIONS.GET_BILL_STORES, getBillStores),
  ])
}

export default function* bill() {
  yield fork(watchBill)
}
