/* eslint-disable no-underscore-dangle */
import { WAREHOUSE_OPERATION_TYPES } from '~/constants/inventory'
import { getSimpleDate } from '~/utils/datetime'

export const parseTransferData = ({ items, ...data }) => ({
  items: items.map(({
    _updated, _id, _created, ...props
  }) => ({
    date_updated: _updated,
    date_created: _created,
    id: _id,
    ...props,
  })),
  ...data,
})

export const parseAdjustmentData = ({ items, ...data }) => ({
  items: items.map(({
    _updated, _id, _created, ...props
  }) => ({
    date_updated: _updated,
    date_created: _created,
    id: _id,
    _created,
    ...props,
  })),
  ...data,
})
// TRANSFER IN DETAIL
export const getNewLineItems = (lineItems) => lineItems.map(({
  quantity, inventory_product_id, name, sku,
}, index) => ({
  quantity,
  inventory_product_id,
  name,
  sku,
  check_in_logs: [],
  index,
}))

export const mapTransferInDetailData = ({
  _created, _id, ...data
}, index) => ({
  ...data,
  index,
  id: _id,
  created: getSimpleDate(_created, 'MM/DD/YYYY - hh:mm A'),
})

export const mapTransferInDetailForm = ({ _id, new_line_items }) => ({
  _id,
  check_in_logs: new_line_items
    .map(({ inventory_product_id, check_in_logs }) => ({
      inventory_product_id,
      logs: check_in_logs.map(({ status, quantity, note }) => ({ status, quantity, note })),
    })),
})

export const convertCheckInLogProducts = (list) => list.map(({ name, inventory_product_id, sku }) => ({
  label: name, value: inventory_product_id, sku,
}))

export const mapTransferOutFormData = (items) => items.map(({ _id, quantity }) => ({
  inventory_product_id: _id,
  quantity,
}))

export const mapTransferOutDetailData = ({
  to_warehouse, line_items, from_warehouse, operator_name, _created, status, description,
}) => {
  const { _id: toWarehouseId, name: toWarehouseName } = to_warehouse
  const { _id: fromWarehouseId, name: fromWarehouseName } = from_warehouse
  const checkInLogs = line_items.map(({
    name, check_in_logs, inventory_product_id, quantity,
  }) => {
    if (check_in_logs?.length !== 0) {
      return {
        name,
        logs: check_in_logs,
        inventory_product_id,
        quantity,
      }
    }
    return null
  }).filter((i) => i)

  return {
    to_warehouse: { toWarehouseId, toWarehouseName },
    from_warehouse: { fromWarehouseId, fromWarehouseName },
    line_items,
    check_in_logs: checkInLogs,
    operator_name,
    status,
    description,
    date_created: getSimpleDate(_created, 'MM/DD/YYYY - hh:mm A', ''),
  }
}

export const convertTransferList = (list, currentWarehouse) => list.map(({ from_warehouse, to_warehouse, ...data }) => ({
  ...data,
  from_warehouse,
  to_warehouse,
  from: from_warehouse.name,
  to: to_warehouse.name,
  type: from_warehouse._id === currentWarehouse ? WAREHOUSE_OPERATION_TYPES.TRANSFER_OUT : WAREHOUSE_OPERATION_TYPES.TRANSFER_IN,
}))

export const convertGetListTransferParams = ({
  filters: {
    transfer_type, from, to, ...filters
  }, ...data
}) => ({
  ...data,
  filters: {
    ...filters,
    ...(transfer_type === WAREHOUSE_OPERATION_TYPES.TRANSFER_IN
      ? { from: [] }
      : (
        transfer_type === WAREHOUSE_OPERATION_TYPES.TRANSFER_OUT
          ? { to: [] }
          : {}
      )),
    ...(from && from.length > 0 ? { from } : {}),
    ...(to && to.length > 0 ? { to } : {}),
  },
})

export const convertWarehouseList = (list) => list.map((e) => ({ ...e, label: e.name, value: e._id }))

export const convertInventoryTags = (tags) => tags.map((tag) => ({ value: tag, label: tag }))
