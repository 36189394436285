import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
}
const url = {
  ORDER_NOTES: '/stores/woocommerce/orders/notes/',
}

const findOne = (orderId) => httpClient.post(url.ORDER_NOTES, {
  action: ACTIONS.READ_DIRECT_PAGINATE,
  parameters: {
    order_id: Number(orderId),
  },
})

const create = (orderId, noteData, isEnableCustomerNote = false) => {
  const params = {
    order_id: Number(orderId),
    item_data: {
      note: noteData.content,
      added_by_user: true,
      customer_note: isEnableCustomerNote ? noteData.type : false,
    },
  }
  return httpClient.post(url.ORDER_NOTES, {
    action: ACTIONS.CREATE,
    parameters: params,
  })
}

const remove = async (orderId, id) => {
  const params = {
    order_id: Number(orderId),
    id,
  }
  return httpClient.post(url.ORDER_NOTES, {
    action: ACTIONS.DELETE,
    parameters: params,
  })
}

export default {
  findOne,
  create,
  remove,
}
