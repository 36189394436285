import { fork } from 'redux-saga/effects'
import dashboard from './dashboard'
import auth from '~/modules/auth/redux/saga'
import product from './product'
import media from './media'
import share from './share'
import user from './user'
import category from './category'
import order from './order'
import reward from './reward'
import coupon from './coupon'
import tag from './tag'
import customer from './customer'
import attribute from './attribute'
import comment from './comment'
import location from './location'
import calendar from './calendar'
import cantecFulfillment from './cantecFulfillment'
import regularShipping from './regularShipping'
import settings from './settings'
import shipping from './shipping'
import payment from './payment'
import inventoryWarehouses from './inventoryWarehouses'
import warehouseTransfer from './warehouseTransfer'
import warehouseAdjustments from './warehouseAdjustments'
import bill from './bill'
import sku from './sku'
import skuTags from './skuTags'
import reduxPersist from './reduxPersist'
import notification from './notificationCenter'
import credential from './credential'
import onfleet from './onfleet'
import analytic from './analytic'

export default function* root() {
  yield fork(dashboard)
  yield fork(auth)
  yield fork(product)
  yield fork(media)
  yield fork(share)
  yield fork(user)
  yield fork(category)
  yield fork(order)
  yield fork(reward)
  yield fork(coupon)
  yield fork(tag)
  yield fork(customer)
  yield fork(attribute)
  yield fork(comment)
  yield fork(location)
  yield fork(calendar)
  yield fork(cantecFulfillment)
  yield fork(settings)
  yield fork(shipping)
  yield fork(inventoryWarehouses)
  yield fork(regularShipping)
  yield fork(warehouseTransfer)
  yield fork(payment)
  yield fork(warehouseAdjustments)
  yield fork(sku)
  yield fork(skuTags)
  yield fork(reduxPersist)
  yield fork(bill)
  yield fork(notification)
  yield fork(credential)
  yield fork(onfleet)
  yield fork(analytic)
}
