import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import rootReducer from '../reducers'
import rootSaga from '~/redux/sagas'

const authBlacklist = createBlacklistFilter('auth', ['status', 'error'])
const userBlacklist = createBlacklistFilter('user', ['loading'])

const authPersistConfig = {
  key: '__auth',
  storage,
  whitelist: ['auth', 'user'],
  transforms: [authBlacklist, userBlacklist],
}

export default function configureStore() {
  const persistedReducer = persistReducer(authPersistConfig, rootReducer)
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  )
  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  return { store, persistor }
}
