export const ACTIONS = {
  CREATE: 'create',
  READ: 'read',
  READ_LOG: 'read_log',
  READ_CARRY_STORES: 'read_carry_stores',
  READ_MULTI: 'read_multi',
  READ_MANY: 'read_many',
  READ_DIRECT_PAGINATE: 'read_direct_paginate',
  READ_DIRECT_ALL: 'read_direct_all',
  READ_DIRECT_LOG_PAGINATE: 'read_direct_log_paginate',
  READ_COVA: 'read_cova',
  DELETE: 'delete',
  DELETE_MANY: 'delete_many',
  SEARCH: 'search',
  EXPORT: 'export',
  EXPORT_V2: 'export_v2',
  UPDATE: 'update',
  UPDATE_POINTS: 'update_points',
  UPDATE_MANY: 'update_many',
  UPDATE_ORDERS: 'update_orders',
  BATCH_UPDATE: 'batch_update',
  PAGINATE: 'paginate',
  QUERY: 'query',
  FILTER: 'filter',
  TEXT_SEARCH: 'text_search',
  LIST_ALL_ONLY_NAME: 'list_all_only_name',
  LIST_ALL: 'list_all',
  BULK_UPDATE: 'bulk_update',
  BULK_DELETE: 'bulk_delete',
  SYNC: 'sync',
  RESTORE: 'restore',
  COUNT_ORDERS: 'count_orders',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  SET_STATUS: 'set_status',
  GET_ALL: 'get_all',
  ADD_COVA_LOCATIONS: 'add',
}
