import { COLORS } from '~/themes/common'

export default {
  notificationContainer: {
    position: 'fixed',
    zIndex: 90,
    bottom: 25,
    right: 0,
    width: 380,
    maxHeight: 'calc(100vh - 80px)',
    overflow: 'auto',
  },
  notificationTrack: {
    overflow: 'hidden',
    padding: 5,
  },
  notification: {
    background: '#FFF',
    width: 350,
    boxShadow: '0px 3px 6px #2933C533',
    borderRadius: 10,
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    marginBottom: 10,
    overflow: 'hidden',
    fontSize: 14,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between',
    background: COLORS.palette.secondaryBlue3,
    padding: '6px 10px',
    lineHeight: '18px',
    fontWeight: 'normal',
  },
  content: {
    display: 'flex',
    padding: '8px 10px 8px 10px',
  },
}
