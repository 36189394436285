import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, CUSTOMER_ROUTES } from '~/constants/router'
import { customerRolesEditable, customerRolesView, customerSendEmail } from '~/modules/auth/conditions'

const CustomerForm = lazyRetry(() => import('~/pages/Customer/'))
const CustomerList = lazyRetry(() => import('~/pages/Customer/'))
const CustomerDetail = lazyRetry(() => import('~/pages/Customer'))
const CovaCustomerForm = lazyRetry(() => import('~/pages/Customer/CovaCustomerForm'))
const CovaCustomerDetail = lazyRetry(() => import('~/pages/Customer/CovaCustomerDetail'))
const CovaCustomerList = lazyRetry(() => import('~/pages/Customer/CovaCustomerList'))
const GreenlineCustomerList = lazyRetry(() => import('~/pages/Customer/GreenlineCustomerList'))
const GreenlineCustomerDetail = lazyRetry(() => import('~/pages/Customer/GreenlineCustomerDetail'))
const ShopifyCustomerList = lazyRetry(() => import('~/pages/Customer/ShopifyCustomerList'))
const ShopifyCustomerDetail = lazyRetry(() => import('~/pages/Customer/ShopifyCustomerDetail'))
const SendEmail = lazyRetry(() => import('~/pages/Customer/SendEmail'))

const CustomerRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.LIST}`,
    component: CustomerList,
    covaComponent: CovaCustomerList,
    greenlineComponent: GreenlineCustomerList,
    shopifyComponent: ShopifyCustomerList,
    layout: 'admin',
    exact: true,
    roles: customerRolesView,
    editRoles: customerRolesEditable,
    routeName: 'Customers List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.SERVICE}`,
    component: CustomerList,
    covaComponent: CovaCustomerList,
    greenlineComponent: GreenlineCustomerList,
    shopifyComponent: ShopifyCustomerList,
    layout: 'admin',
    exact: true,
    roles: customerRolesView,
    editRoles: customerRolesEditable,
    routeName: 'Customers List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.MARKETING}`,
    component: CustomerList,
    covaComponent: CovaCustomerList,
    greenlineComponent: GreenlineCustomerList,
    shopifyComponent: ShopifyCustomerList,
    layout: 'admin',
    exact: true,
    roles: customerRolesView,
    editRoles: customerRolesEditable,
    routeName: 'Customers List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.DETAIL}`,
    component: CustomerDetail,
    covaComponent: CovaCustomerDetail,
    greenlineComponent: GreenlineCustomerDetail,
    shopifyComponent: ShopifyCustomerDetail,
    layout: 'admin',
    exact: true,
    // roles: customerRolesView,
    // editRoles: customerRolesEditable,
    routeName: 'Customer Detail',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.CREATE}`,
    component: CustomerForm,
    covaComponent: CovaCustomerForm,
    layout: 'admin',
    exact: true,
    roles: customerRolesEditable,
    routeName: 'Customer Create',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.SEND_EMAIL}`,
    component: SendEmail,
    covaComponent: SendEmail,
    layout: 'admin',
    exact: true,
    roles: customerSendEmail,
    routeName: 'Customer Send Email',
  },
]

export default CustomerRouters
