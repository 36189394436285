import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'
import { convertCustomerForm, convertCustomerListParams } from '~/utils/customer'

const url = {
  READ: '/stores/woocommerce/customers/',
  DOCUMENT: '/stores/woocommerce/customers/documents',
}

const covaUrl = {
  READ: '/cova/customers/',
}

const get = (customerId, timeZone) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.READ,
    parameters: timeZone === undefined ? { id: customerId } : { id: customerId, time_zone: timeZone },
  },
)

const create = (params) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.CREATE,
    parameters: { item_data: convertCustomerForm(params) },
  },
)

const updateCustomerInfo = (params) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.UPDATE,
    parameters: params,
  },
)

const removeOne = (customerId) => httpClient.post(
  url.READ,
  {
    action: ACTIONS.DELETE,
    parameters: { id: Number(customerId) },
  },
)

const getList = (params) => httpClient.post(url.READ, {
  action: ACTIONS.PAGINATE,
  parameters: convertCustomerListParams(params),
})

const getListWithFields = (params) => httpClient.post(url.READ, {
  action: ACTIONS.READ_MANY,
  parameters: params,
})

const batchUpdate = (params) => httpClient.post(url.READ, {
  action: ACTIONS.BATCH_UPDATE,
  parameters: params,
})
const exportList = (params) => httpClient.post(url.READ, {
  action: ACTIONS.EXPORT_V2,
  parameters: params,
})

const getADocument = (params) => httpClient.create()
  .ejectRes('defaultResponseHandler')
  .post(url.DOCUMENT, {
    organization_id: params.orgId,
    store_id: params.storeId,
    action: ACTIONS.READ,
    parameters: {
      customer_id: params.customerId,
      document_id: params.documentId,
    },
  }, {
    responseType: 'blob',
  })

const deleteDocument = (params) => httpClient.post(url.DOCUMENT, {
  organization_id: params.orgId,
  store_id: params.storeId,
  action: ACTIONS.DELETE,
  parameters: {
    customer_id: params.customerId,
    document_id: params.documentId,
  },
})

const updateDocument = (params) => httpClient.post(url.DOCUMENT, {
  organization_id: params.orgId,
  store_id: params.storeId,
  action: ACTIONS.UPDATE,
  parameters: {
    customer_id: params.customerId,
    document_id: params.documentId,
    title: params.title,
    description: params.desc,
  },
})

const uploadDocuments = (params) => httpClient.json.post(url.DOCUMENT, () => (params))

const getCovaCustomer = (customerId) => httpClient.json.post(
  covaUrl.READ,
  {
    action: ACTIONS.READ,
    parameters: { id: customerId },
  },
)

const getCovaList = (params) => httpClient.post(covaUrl.READ, {
  action: ACTIONS.PAGINATE,
  parameters: convertCustomerListParams(params),
})

const customerApi = {
  get,
  updateCustomerInfo,
  removeOne,
  getList,
  getListWithFields,
  batchUpdate,
  create,
  exportList,
  getADocument,
  deleteDocument,
  updateDocument,
  uploadDocuments,

  getCovaCustomer,
  getCovaList,
}

export default customerApi
