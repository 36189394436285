import exportApi from '~/services/apis/export'

export const getCSVLink = (id) => exportApi.getLink(id)

export function handleExportType(type) {
  switch (type) {
    case 'export_orders':
    case 'export_transfers':
    case 'export_adjustments':
    case 'export_inventory':
    case 'export_products':
    case 'export_customers':
    case 'export_product_performance':
      return true
    default:
      return false
  }
}

export const exportTypePrefix = {
  export_orders: 'Order',
  export_transfers: 'Transfers',
  export_adjustments: 'Adjustments',
  export_inventory: 'Inventory',
  export_products: 'Product',
  export_customers: 'Customer',
  export_product_performance: 'Product',
}
