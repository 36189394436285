const style = () => ({
  container: {
    zIndex: 21,
    '& .bs-unified__side-bar-container-minimal': {
      zIndex: 1,
    },
  },
  bottomMenuApp: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: 10,
  },
  menuItemPopup: {
    '& .menu-icon': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  rightIcon: {
    marginLeft: 5,
  },
  posIcon: {
    marginLeft: 10,
    marginBottom: -2,
  },
})

export default style
