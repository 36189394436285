/* eslint-disable no-underscore-dangle */
import {
  takeLatest,
  put,
  fork,
  call,
} from 'redux-saga/effects'
import { mediaAction } from '~/redux/actions/media'
import mediaApis from '~/services/apis/media'
import { getFolderId } from '~/utils/helpers/media'

export function* getListFolder(action) {
  try {
    const response = yield call(mediaApis.getFolders, {})
    yield put({
      type: mediaAction.GET_LIST_FOLDER_SUCCESS,
      payload: {
        folders: response.children,
        unsortedFileCount: response.count,
      },
    })
    action.callbacks.onSuccess()
  } catch (error) {
    yield put({
      type: mediaAction.GET_LIST_FOLDER_FAILURE,
      payload: error,
    })
  }
}

export function* getListFile(action) {
  const params = { ...action.payload, ...{ folder_id: getFolderId(action.payload.folder_id) } }
  try {
    const response = yield call(mediaApis.getFiles, params)
    yield put({
      type: mediaAction.GET_LIST_FILE_SUCCESS,
      payload: {
        files: response.items.map((file) => ({ ...file, ...{ id: file._id, src: file.public_url } })),
        limit: response.limit,
        page: response.page,
        total_items: response.total_items,
        total_pages: response.total_pages,
      },
    })
    action.callbacks.onSuccess()
  } catch (error) {
    yield put({
      type: mediaAction.GET_LIST_FILE_FAILURE,
      payload: error,
    })
  }
}

export function* deleteFile(action) {
  const params = {
    file_id: action.payload.fileId,
  }

  try {
    yield call(mediaApis.deleteFile, params)
    yield put({
      type: mediaAction.DELETE_FILE_SUCCESS,
      payload: action.payload,
    })
    action.callbacks.onSuccess()
  } catch (error) {
    yield put({
      type: mediaAction.DELETE_FILE_FAILURE,
      payload: {
        ...error,
        ...action.payload,
      },
    })
  }
}

export function* addNewFolder(action) {
  const params = {
    parent_id: action.payload.parentId,
    folder_name: action.payload.folderName,
  }
  try {
    const response = yield call(mediaApis.createFolder, params)
    action.callbacks.onSuccess()
    yield put({
      type: mediaAction.ADD_NEW_FOLDER_SUCCESS,
      payload: {
        id: response._id,
        name: response.name,
        parent_id: response.parent_id,
      },
    })
  } catch (error) {
    action.callbacks.onError(error)
    yield put({
      type: mediaAction.ADD_NEW_FOLDER_FAILURE,
      payload: {
        parentId: action.payload.parentId,
        ...error,
      },
    })
  }
}

export function* deleteFolder(action) {
  const { folderId } = action.payload
  const params = {
    folder_id: folderId,
  }
  try {
    yield call(mediaApis.deleteFolder, params)
    action.callbacks.onSuccess()
    yield put({
      type: mediaAction.DELETE_FOLDER_SUCCESS,
      payload: action.payload,
    })
  } catch (error) {
    yield put({
      type: mediaAction.DELETE_FOLDER_FAILURE,
      payload: {
        ...error,
      },
    })
  }
}

export function* editFolder(action) {
  const { folderId, folderName } = action.payload
  const params = {
    folder_id: folderId,
    folder_name: folderName,
  }
  try {
    yield call(mediaApis.editFolder, params)
    action.callbacks.onSuccess()
    yield put({
      type: mediaAction.EDIT_FOLDER_SUCCESS,
      payload: action.payload,
    })
  } catch (error) {
    action.callbacks.onError(error)
    yield put({
      type: mediaAction.EDIT_FOLDER_FAILURE,
      payload: {
        ...error,
      },
    })
  }
}

export function* moveFile(action) {
  const { parentId, mediaIdList } = action.payload
  const params = {
    parent_id: getFolderId(parentId),
    media_id_list: mediaIdList,
  }
  try {
    yield call(mediaApis.moveFileToFolder, params)
    action.callbacks.onSuccess()
    yield put({
      type: mediaAction.MOVE_FILE_TO_FOLDER_SUCCESS,
      payload: action.payload,
    })
  } catch (error) {
    action.callbacks.onError(error)
    yield put({
      type: mediaAction.MOVE_FILE_TO_FOLDER_FAILURE,
      payload: {
        ...error,
      },
    })
  }
}

export function* watchGetListFolder() {
  yield takeLatest(mediaAction.GET_LIST_FOLDER_REQUEST, getListFolder)
}

export function* watchDeleteFile() {
  yield takeLatest(mediaAction.DELETE_FILE_REQUEST, deleteFile)
}

export function* watchAddNewFolder() {
  yield takeLatest(mediaAction.ADD_NEW_FOLDER_REQUEST, addNewFolder)
}

export function* watchDeleteFolder() {
  yield takeLatest(mediaAction.DELETE_FOLDER_REQUEST, deleteFolder)
}

export function* watchEditFolder() {
  yield takeLatest(mediaAction.EDIT_FOLDER_REQUEST, editFolder)
}

export function* watchGetListFile() {
  yield takeLatest(mediaAction.GET_LIST_FILE_REQUEST, getListFile)
}

export function* watchMoveFileToFolder() {
  yield takeLatest(mediaAction.MOVE_FILE_TO_FOLDER_REQUEST, moveFile)
}
export default function* media() {
  yield fork(watchGetListFolder)
  yield fork(watchDeleteFile)
  yield fork(watchAddNewFolder)
  yield fork(watchDeleteFolder)
  yield fork(watchEditFolder)
  yield fork(watchGetListFile)
  yield fork(watchMoveFileToFolder)
}
