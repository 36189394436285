import Dexie from 'dexie'

const SETTINGS = 'settings'

const db = new Dexie('notification')
db.version(5).stores({
  [SETTINGS]: '',
})

export const saveNotificationSettingsIDB = (data) => db.table(SETTINGS).put(data, 0)

export const getNotificationSettingsIDB = () => db.table('settings').get(0)

export const clearNotificationSettingsIDB = () => {
  db.table(SETTINGS).clear()
}

// @TODO: put clearNotificationSettingsIDB func into somewhere?

export default db
