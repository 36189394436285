import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, PROMOTION_ROUTES } from '~/constants/router'
import { loyaltyRolesEditable, loyaltyRolesView } from '~/modules/auth/conditions'

const Promotion = lazyRetry(() => import('~/pages/Promotion'))

const PromotionRouters = [{
  path: `${ADMIN_ROUTES.STORE}${PROMOTION_ROUTES.PROMOTION}`,
  component: Promotion,
  layout: 'admin',
  exact: 'true',
  roles: loyaltyRolesView,
  editRoles: loyaltyRolesEditable,
  routeName: 'Promotion',
}, {
  path: `${ADMIN_ROUTES.STORE}${PROMOTION_ROUTES.SERVICE_SALE}`,
  component: Promotion,
  layout: 'admin',
  exact: 'true',
  roles: loyaltyRolesView,
  editRoles: loyaltyRolesEditable,
  routeName: 'Promotion - Service',
}, {
  path: `${ADMIN_ROUTES.STORE}${PROMOTION_ROUTES.MARKETING_SALE}`,
  component: Promotion,
  layout: 'admin',
  exact: 'true',
  roles: loyaltyRolesView,
  editRoles: loyaltyRolesEditable,
  routeName: 'Promotion - Marketing',
}]

export default PromotionRouters
