import {
  takeLatest,
  put,
  call,
  fork,
} from 'redux-saga/effects'
import { warehouseTransferActions } from '../actions/warehouseTransfer'
import warehouseTransferApis from '~/services/apis/warehouseTransfer'
import { convertWarehouseList, parseTransferData } from '~/utils/inventoryWarehouses'
import inventoryWarehousesApi from '~/services/apis/inventoryWarehouses'

export function* getWarehouseTransfer(action) {
  try {
    const response = yield call(warehouseTransferApis.getList, action.payload)

    yield put({
      type: warehouseTransferActions.GET_TRANSFER_OK,
      payload: parseTransferData(response),
    })
  } catch (error) {
    yield put({
      type: warehouseTransferActions.GET_TRANSFER_ERR,
      payload: error,
    })
  }
}

export function* getAllWarehouses(action) {
  try {
    const response = yield call(inventoryWarehousesApi.getAll, action.payload)

    yield put({
      type: warehouseTransferActions.GET_ALL_WAREHOUSES_SUCCESS,
      payload: convertWarehouseList(response),
    })
  } catch (error) {
    yield put({
      type: warehouseTransferActions.GET_ALL_WAREHOUSES_ERROR,
      payload: error,
    })
  }
}

export function* watchGetWarehouseTransferData() {
  yield takeLatest(warehouseTransferActions.GET_TRANSFER_REQUEST, getWarehouseTransfer)
}

export function* watchGetAllWarehouses() {
  yield takeLatest(warehouseTransferActions.GET_ALL_WAREHOUSES, getAllWarehouses)
}

export default function* warehouseTransfer() {
  yield fork(watchGetWarehouseTransferData)
  yield fork(watchGetAllWarehouses)
}
