import { DEFAULT_ORDER_DETAIL } from '~/redux/default/order'

export const ORDER_ACTION = {
  FETCH_DETAIL: 'order.FETCH_DETAIL',
  SET_DETAIL_DATA_ERROR: 'order.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'order.SET_DETAIL_DATA',
  FETCH_ORDER_LIST: 'order.GET_LIST',
  SET_LIST_DATA: 'order.SET_LIST_DATA',
  SET_LIST_ERROR: 'order.SET_LIST_ERROR',
  FETCH_PREVIEW: 'order.GET_PREVIEW',
  SET_PREVIEW_DATA: 'order.SET_PREVIEW_DATA',
  SET_PREVIEW_ERROR: 'order.SET_PREVIEW_ERROR',
  FETCH_ORDER_NOTES: 'order.FETCH_ORDER_NOTES',
  SET_ORDER_NOTES: 'order.SET_ORDER_NOTES',
  SET_ORDER_NOTES_ERROR: 'order.SET_ORDER_NOTES_ERROR',
  REMOVE_ORDER_ITEMS_REDUX: 'order.REMOVE_ORDER_ITEMS_REDUX',
  FETCH_COVA_ORDER_LIST: 'order.GET_COVA_ORDER_LIST',
  SET_COVA_ORDER_LIST_DATA: 'order.SET_COVA_ORDER_LIST_DATA',
  FETCH_POS_ORDER_LIST: 'order.GET_POS_ORDER_LIST',
  SET_POS_LIST_DATA: 'order.SET_POS_LIST_DATA',
  SET_POS_LIST_ERROR: 'order.SET_POS_LIST_ERROR',
}

export const fetchOrderList = (params, {
  onSuccess = () => {},
  onError = () => {},
} = {}) => ({
  type: ORDER_ACTION.FETCH_ORDER_LIST,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

export const setOrderListData = (listData) => ({
  type: ORDER_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setOrderListError = (error) => ({
  type: ORDER_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const fetchOrderDetail = (orderId, {
  onError = () => {},
}) => ({
  type: ORDER_ACTION.FETCH_DETAIL,
  payload: orderId,
  callbacks: {
    onError,
  },
})

export const setOrderDetail = (order) => ({
  type: ORDER_ACTION.SET_DETAIL_DATA,
  payload: order,
})

export const setOrderDetailError = (error) => ({
  type: ORDER_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const fetchOrderPreview = (orderMdbId) => ({
  type: ORDER_ACTION.FETCH_PREVIEW,
  payload: orderMdbId,
})

export const setOrderPreview = (order) => ({
  type: ORDER_ACTION.SET_PREVIEW_DATA,
  payload: order,
})

export const setOrderPreviewError = (error) => ({
  type: ORDER_ACTION.SET_PREVIEW_ERROR,
  payload: error,
})

export const clearOrderDetail = () => ({
  type: ORDER_ACTION.SET_DETAIL_DATA,
  payload: DEFAULT_ORDER_DETAIL,
})

export const fetchOrderNotes = (orderId) => ({
  type: ORDER_ACTION.FETCH_ORDER_NOTES,
  payload: orderId,
})

export const setOrderNotes = (notes) => ({
  type: ORDER_ACTION.SET_ORDER_NOTES,
  payload: notes,
})

export const setOrderNotesError = (error) => ({
  type: ORDER_ACTION.SET_ORDER_NOTES_ERROR,
  payload: error,
})

export const removeOrderItemsRedux = () => ({
  type: ORDER_ACTION.REMOVE_ORDER_ITEMS_REDUX,
})

export const fetchCovaOrderList = (params) => ({
  type: ORDER_ACTION.FETCH_COVA_ORDER_LIST,
  payload: params,
})

export const setCovaOrderListData = (listData) => ({
  type: ORDER_ACTION.SET_COVA_ORDER_LIST_DATA,
  payload: listData,
})

export const fetchPOSOrderList = (params) => ({
  type: ORDER_ACTION.FETCH_POS_ORDER_LIST,
  payload: params,
})

export const setPOSOrderList = (listData) => ({
  type: ORDER_ACTION.SET_POS_LIST_DATA,
  payload: listData,
})

export const setPOSListError = (error) => ({
  type: ORDER_ACTION.SET_POS_LIST_ERROR,
  payload: error,
})

export default {
  fetchOrderList,
  setOrderListData,
  setOrderListError,
  fetchOrderDetail,
  setOrderDetail,
  setOrderDetailError,
  clearOrderDetail,
  fetchOrderNotes,
  setOrderNotes,
  setOrderNotesError,
  fetchOrderPreview,
  setOrderPreview,
  setOrderPreviewError,
  removeOrderItemsRedux,
  fetchCovaOrderList,
  setCovaOrderListData,
  fetchPOSOrderList,
  setPOSOrderList,
  setPOSListError,
}
