import {
  takeLatest, all, fork, call, put,
} from 'redux-saga/effects'
import categoryApi from '~/services/apis/category'

import Actions, { CATEGORY_ACTION } from '~/redux/actions/category'

export function* fetchCategoryList() {
  try {
    const resp = yield call(categoryApi.getList, { limit: -1, page: 1 })
    yield put(Actions.setCategoryListData(resp))
  } catch (e) {
    yield put(Actions.setCategoryListError(e))
  }
}

export function* fetchCategoryDetail(action) {
  try {
    const resp = yield call(categoryApi.findOne, action.payload)
    yield put(Actions.setCategoryDetail(resp))
  } catch (e) {
    yield put(Actions.setCategoryDetailError)
  }
}

export function* watchCategory() {
  yield all([
    takeLatest(CATEGORY_ACTION.FETCH_LIST, fetchCategoryList),
    takeLatest(CATEGORY_ACTION.FETCH_DETAIL, fetchCategoryDetail),
  ])
}

export default function* category() {
  yield fork(watchCategory)
}
