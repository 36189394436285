import { useQuery } from '@tanstack/react-query'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import qs from 'querystring'
import httpClient from '~/modules/core/httpClient'
import { MOCK_NAVIGATION_DATA } from './constant'
import { set as setLocalStorage, get as getLocalStorage } from '~/utils/storage/localStorage'
import { queryFnWrapper } from '~/utils/utility'
import csClient from '~/modules/core/csClient'
import { FLAGS } from '~/constants/flags'

const getNavigationList = ({ storeId = '', isShowNewUISetting = false, isShowNewUILocationSetting = false }) => {
  const params = {
    bs_store_id: storeId,
    flag_profile_3_new_setting: isShowNewUISetting,
    flag_new_locations: isShowNewUILocationSetting,
  }
  return httpClient.json.get(`/unification/bs_navigation?${qs.stringify(params)}`)
}

const getCachedNav = () => getLocalStorage('cached_nav_bar') || MOCK_NAVIGATION_DATA

const setCachedNav = (data) => {
  setLocalStorage('cached_nav_bar', data)
}

export const useNavigationConfig = (storeId, enabled = true) => {
  const placeholderData = getCachedNav()
  const isShowNewUISetting = useFeatureIsOn(FLAGS.BS_SETTING_UI_FACELIFTING)
  const isShowNewUILocationSetting = useFeatureIsOn(FLAGS.BS_LOCATION_FACELIFTING)

  return useQuery({
    queryKey: ['getNavigationList', storeId, isShowNewUISetting, isShowNewUILocationSetting],
    queryFn: queryFnWrapper({
      queryFn: () => getNavigationList({ storeId, isShowNewUISetting, isShowNewUILocationSetting }),
      onError: (e) => {
        console.error(e) // eslint-disable-line
      },
    }),
    placeholderData,
    enabled,
    select: (data) => {
      if (!data) return {}
      setCachedNav(data)
      return data
    },
    retry: 10,
  })
}

const getPlanTier = () => csClient.json.get('payments/get-plan-tier')

export const useGetPlanTier = ({ enabled = true }) => useQuery({
  queryKey: ['getPlanTier'],
  queryFn: () => getPlanTier(),
  initialData: null,
  enabled,
  select: (res) => res?.data || {},
})
