/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  useBlocker,
  useBeforeUnload,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import CustomDialog from '~/components/common/Dialog/CustomDialog'
import { COOKIE_KEYS } from '~/constants/auth'
import { get as getMemoryStorage } from '~/utils/storage/memoryStorage'

const defaultMsg = {
  description: 'If you leave this page, unsaved changes will be lost.\nAre you sure you want to continue?',
  icon: 'warn',
  title: 'Warning',
  cancelText: 'Cancel',
  okText: 'Yes, Leave',
}

const Prompt = ({
  isBlocking, isExceptionBlocking, confirmParams, beforeConfirm, beforeCancel, afterConfirm, afterCancel,
}) => {
  const when = useMemo(() => {
    if (isExceptionBlocking) return false
    return isBlocking
  }, [isBlocking, isExceptionBlocking])

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => when && currentLocation.pathname !== nextLocation.pathname,
  )
  const isBlockedRoute = useMemo(() => blocker.state === 'blocked', [blocker.state])

  const dialogParams = useMemo(() => ({
    ...defaultMsg,
    ...confirmParams,
  }), [confirmParams])

  const confirmDialogHandler = useCallback(async () => {
    if (beforeConfirm) await beforeConfirm()
    if (isBlockedRoute) blocker.proceed()
    if (afterConfirm) await afterConfirm()
  }, [beforeConfirm, afterConfirm, blocker, isBlockedRoute])

  const cancelDialogHandler = useCallback(async () => {
    if (beforeCancel) await beforeCancel()
    if (isBlockedRoute) blocker.reset()
    if (afterCancel) await afterCancel()
  }, [beforeCancel, afterCancel, blocker, isBlockedRoute])

  useEffect(() => {
    if (isBlockedRoute && !when) {
      blocker.reset()
    }
  }, [blocker, when, isBlockedRoute])

  useBeforeUnload(
    useCallback((event) => {
      if (when && (!!Cookies.get(COOKIE_KEYS.CUSTOM_TOKEN) || !!getMemoryStorage(COOKIE_KEYS.DATA_TOKEN))) {
        const msg = dialogParams?.description
        event.preventDefault()
        event.returnValue = msg
        return msg
      }
    }, [when, dialogParams]),
    { capture: true },
  )

  return (
    <CustomDialog
      open={isBlockedRoute}
      closeOnClick
      {...dialogParams}
      onCancel={cancelDialogHandler}
      onOk={confirmDialogHandler}
    />
  )
}

Prompt.defaultProps = {
  isBlocking: false,
  isExceptionBlocking: false,
  confirmParams: {},
  beforeConfirm: null,
  beforeCancel: null,
  afterConfirm: null,
  afterCancel: null,
}

Prompt.propTypes = {
  isBlocking: PropTypes.bool,
  isExceptionBlocking: PropTypes.bool,
  confirmParams: PropTypes.shape(),
  beforeConfirm: PropTypes.func,
  beforeCancel: PropTypes.func,
  afterConfirm: PropTypes.func,
  afterCancel: PropTypes.func,
}

export default Prompt
