import { AuthSessionKey } from './sessionStorageKey'

export function storeSessionWithKey(key, value) {
  window.sessionStorage.setItem(key, value)
}

export function getSessionWithKey(key) {
  return window.sessionStorage.getItem(key)
}

export function clearSessionItemByKey(key) {
  window.sessionStorage.removeItem(key)
}

export function clearAllSession() {
  window.sessionStorage.clear()
}

export function shouldUseSession(key) {
  return Boolean(AuthSessionKey[key])
}
