import React, { useEffect, useState } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FLAGS } from '~/constants/flags'
import CheckingValidProvider from '../CheckingValidProvider'
import SSOProvider from '../SSOProvider'
import { WrapSSOContext } from './hook'
import { AUTH_ACTION } from '~/modules/auth/redux/action'

const WrapSSOProvider = ({ children }) => {
  const isSSO = useFeatureIsOn(FLAGS.ACD_11071_COOKIE_ATTRIBUTES_NOT_SET_OPTIMAL_SECURITY)
  const [isHandlingSSO, setIsHandlingSSO] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: AUTH_ACTION.SET_IS_SSO, payload: isSSO })
  }, [isSSO, dispatch])

  const Wrap = isSSO ? SSOProvider : CheckingValidProvider

  return (
    <WrapSSOContext.Provider value={{ isHandlingSSO, setIsHandlingSSO }}>
      <Wrap>
        {children}
      </Wrap>
    </WrapSSOContext.Provider>
  )
}

WrapSSOProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WrapSSOProvider
