import { DEFAULT_ATTRIBUTE_DETAIL } from '~/redux/default/attribute'

export const ATTRIBUTE_ACTION = {
  FETCH_LIST: 'attribute.GET_LIST',
  SET_LIST_DATA: 'attribute.SET_LIST_DATA',
  SET_LIST_ERROR: 'attribute.SET_LIST_ERROR',
  FETCH_DETAIL: 'attribute.FETCH_DETAIL',
  SET_DETAIL_DATA_ERROR: 'attribute.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'attribute.SET_DETAIL_DATA',
}

export const fetchAttributeList = (params) => ({
  type: ATTRIBUTE_ACTION.FETCH_LIST,
  payload: params,
})

export const setAttributeListData = (listData) => ({
  type: ATTRIBUTE_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setAttributeListError = (error) => ({
  type: ATTRIBUTE_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const fetchAttributeDetail = (attributeId) => ({
  type: ATTRIBUTE_ACTION.FETCH_DETAIL,
  payload: attributeId,
})

export const setAttributeDetail = (attribute) => ({
  type: ATTRIBUTE_ACTION.SET_DETAIL_DATA,
  payload: attribute,
})

export const setAttributeDetailError = (error) => ({
  type: ATTRIBUTE_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const clearAttributeDetail = () => ({
  type: ATTRIBUTE_ACTION.SET_DETAIL_DATA,
  payload: DEFAULT_ATTRIBUTE_DETAIL,
})

export default {
  fetchAttributeList,
  setAttributeListData,
  setAttributeListError,
  fetchAttributeDetail,
  setAttributeDetail,
  setAttributeDetailError,
  clearAttributeDetail,
}
