import { lazyRetry } from '~/utils/lazyRetry'
import {
  ADMIN_ROUTES,
  ATTRIBUTE_ROUTES,
} from '~/constants/router'

import { onlyWooProductRoles } from '~/modules/auth/conditions'

const AttributeList = lazyRetry(() => import('~/pages/Attribute/List'))
const AttributeForm = lazyRetry(() => import('~/pages/Attribute/Form'))

const AttributeRouters = [{
  path: `${ADMIN_ROUTES.STORE}${ATTRIBUTE_ROUTES.ATTRIBUTES}`,
  component: AttributeList,
  layout: 'admin',
  exact: true,
  roles: onlyWooProductRoles,
  routeName: 'Attributes List',
},
{
  path: `${ADMIN_ROUTES.STORE}${ATTRIBUTE_ROUTES.CREATE}`,
  component: AttributeForm,
  layout: 'admin',
  exact: true,
  roles: onlyWooProductRoles,
  routeName: 'Attribute Create',
},
{
  path: `${ADMIN_ROUTES.STORE}${ATTRIBUTE_ROUTES.EDIT}`,
  component: AttributeForm,
  layout: 'admin',
  exact: true,
  roles: onlyWooProductRoles,
  xProps: {
    isEditing: true,
  },
  routeName: 'Attribute Edit',
},
]

export default AttributeRouters
