import moment from 'moment'

export const convertStoreData = ({
  linked_warehouses,
  organization_name,
  warehouse_id,
  ...data
}) => ({
  ...data,
  ...(linked_warehouses ? { linked_warehouses: linked_warehouses.map((x) => x.warehouse_id) || [] } : {}),
})

export const isStoreImportFailed = (s) => {
  if (!s) return false
  const timeCreated = moment(s._updated || s._created) // eslint-disable-line
  const timeNow = moment.utc()
  return timeNow.diff(timeCreated, 'hours') > 23 && !s.sync_completed
}
