export const ONBOARDING_TYPES = {
  USER_INFO: 'user-info',
  SYNC: 'sync',
  ADD_SCRIPT: 'add-script',
  INVITE: 'invite',
  LAUNCH: 'launch',
}

export const ONBOARDING_PAGE_TYPES = {
  ONBOARDING: 'onboarding',
  CREATE_STORE: 'create-store',
  CREATE_WORKSPACE: 'create-workspace',
}

export const CREATE_STORE_STEPS = {
  SYNC: 'sync',
  ADD_SCRIPT: 'add-script',
  INVITE: 'invite',
}
export const CREATE_STORE_STEPS_LIST = [
  ONBOARDING_TYPES.SYNC,
  ONBOARDING_TYPES.ADD_SCRIPT,
  ONBOARDING_TYPES.INVITE,
]

export const CREATE_STORE_ROUTER = {
  SYNC: (orgId) => `/${ONBOARDING_PAGE_TYPES.CREATE_STORE}/${orgId}/${ONBOARDING_TYPES.SYNC}`,
  ADD_SCRIPT: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_STORE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.ADD_SCRIPT}`,
  INVITE: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_STORE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.INVITE}`,
  LAUNCH: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_STORE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.LAUNCH}`,
}

export const CREATE_WORKSPACE_STEPS = {
  USER_INFO: 'user-info',
  SYNC: 'sync',
  ADD_SCRIPT: 'add-script',
  INVITE: 'invite',
}

export const CREATE_WORKSPACE_ROUTER = {
  USER_INFO: `/${ONBOARDING_PAGE_TYPES.CREATE_WORKSPACE}/${ONBOARDING_TYPES.USER_INFO}`,
  SYNC: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_WORKSPACE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.SYNC}`,
  ADD_SCRIPT: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_WORKSPACE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.ADD_SCRIPT}`,
  INVITE: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_WORKSPACE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.INVITE}`,
  LAUNCH: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.CREATE_WORKSPACE}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.LAUNCH}`,
}

export const ONBOARDING_STEPS_LIST = [
  ONBOARDING_TYPES.USER_INFO,
  ONBOARDING_TYPES.SYNC,
  ONBOARDING_TYPES.ADD_SCRIPT,
  ONBOARDING_TYPES.INVITE,
  ONBOARDING_TYPES.LAUNCH,
]

export const ONBOARDING_STEPS = {
  ONBOARDING_USER_INFO: `/${ONBOARDING_PAGE_TYPES.ONBOARDING}/${ONBOARDING_TYPES.USER_INFO}`,
  ONBOARDING_SYNC: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.ONBOARDING}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.SYNC}`,
  ONBOARDING_ADD_SCRIPT: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.ONBOARDING}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.ADD_SCRIPT}`,
  ONBOARDING_INVITE: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.ONBOARDING}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.INVITE}`,
  ONBOARDING_LAUNCH: (orgId, storeId) => `/${ONBOARDING_PAGE_TYPES.ONBOARDING}/${orgId}/s/${storeId}/${ONBOARDING_TYPES.LAUNCH}`,
}

export const ONBOARDING_STORE_TYPES = {
  WOOCOMMERCE: 'woocommerce',
  SHOPIFY: 'shopify',
}
