import produce from 'immer'
import { COMMENT_ACTION } from '../actions/comment'
import { DEFAULT_COMMENT_LIST_DATA, DEFAULT_COMMENT_DETAIL } from '../default/comment'

const initialState = {
  data: DEFAULT_COMMENT_LIST_DATA,
  loading: false,
  detail: DEFAULT_COMMENT_DETAIL,
  loadingDetail: false,
  error: '',
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case COMMENT_ACTION.FETCH_LIST:
      draft.loading = true
      draft.error = ''
      break
    case COMMENT_ACTION.SET_LIST_DATA:
      draft.data = payload
      draft.loading = false
      draft.error = ''
      break
    case COMMENT_ACTION.SET_LIST_ERROR:
      draft.loading = false
      draft.error = payload
      break
    case COMMENT_ACTION.FETCH_DETAIL:
      draft.detail = DEFAULT_COMMENT_DETAIL
      draft.loadingDetail = true
      draft.error = ''
      break
    case COMMENT_ACTION.SET_DETAIL_DATA_ERROR:
      draft.loadingDetail = false
      draft.error = payload
      break
    case COMMENT_ACTION.SET_DETAIL_DATA:
      draft.loadingDetail = false
      draft.error = ''
      draft.detail = payload
      break
    default:
      break
  }
})

export default reducer
