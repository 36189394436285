export const CANTEC_FULFILLMENT_ACTION = {
  FETCH_PENDING_PICKING_LIST: 'cantecFulfillment.FETCH_PENDING_PICKING_LIST',
  SET_PENDING_PICKING_LIST: 'cantecFulfillment.SET_PENDING_PICKING_LIST',
  SET_PENDING_PICKING_LIST_ERROR: 'cantecFulfillment.SET_PENDING_PICKING_LIST_ERROR',
  FETCH_PENDING_PICKUP_LIST: 'cantecFulfillment.FETCH_PENDING_PICK_UP_LIST',
  SET_PENDING_PICKUP_LIST: 'cantecFulfillment.SET_PENDING_PICKUP_LIST',
  SET_PENDING_PICKUP_LIST_ERROR: 'cantecFulfillment.SET_PENDING_PICKUP_LIST_ERROR',
  FETCH_PICKED_UP_LIST: 'cantecFulfillment.FETCH_PICKED_UP_LIST',
  SET_PICKED_UP_LIST: 'cantecFulfillment.SET_PICKED_UP_LIST',
  SET_PICKED_UP_LIST_ERROR: 'cantecFulfillment.SET_PICKED_UP_LIST_ERROR',
  FETCH_LINK_WAREHOUSES: 'cantecFulfillment.FETCH_LINK_WAREHOUSES',
  SET_LINK_WAREHOUSE_LIST: 'cantecFulfillment.SET_LINK_WAREHOUSE_LIST',
  SET_LINK_WAREHOUSE_LIST_ERROR: 'cantecFulfillment.SET_LINK_WAREHOUSE_LIST_ERROR',
  FETCH_ONLINE_DRIVERS: 'cantecFulfillment.FETCH_ONLINE_DRIVERS',
  SET_ONLINE_DRIVER_LIST: 'cantecFulfillment.SET_ONLINE_DRIVER_LIST',
  SET_ONLINE_DRIVER_LIST_ERROR: 'cantecFulfillment.SET_ONLINE_DRIVER_LIST_ERROR',
  FETCH_COUNT_ORDERS: 'cantecFulfillment.FETCH_COUNT_ORDERS',
  SET_COUNT_ORDERS: 'cantecFulfillment.SET_COUNT_ORDERS',
  SET_COUNT_ORDER_ERROR: 'cantecFulfillment.SET_COUNT_ORDER_ERROR',
  FETCH_INCOMPLETED_ORDERS: 'cantecFulfillment.FETCH_INCOMPLETED_ORDERS',
  SET_INCOMPLETED_ORDERS: 'cantecFulfillment.SET_INCOMPLETED_ORDERS',
  SET_INCOMPLETED_ORDERS_ERROR: 'cantecFulfillment.SET_INCOMPLETED_ORDERS_ERROR',
}

const fetchPendingPicking = (params, {
  onSuccess = () => {},
  onError = () => {},
} = {}) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_PENDING_PICKING_LIST,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

const setPendingPicking = (listData) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKING_LIST,
  payload: listData,
})

const setPendingPickingError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKING_LIST_ERROR,
  payload: error,
})

const fetchPendingPickup = (params, {
  onSuccess = () => {},
  onError = () => {},
} = {}) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_PENDING_PICKUP_LIST,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

const setPendingPickup = (listData) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKUP_LIST,
  payload: listData,
})

const setPendingPickupError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKUP_LIST_ERROR,
  payload: error,
})

const fetchPickedUp = (params, isLoadingMore = false, {
  onSuccess = () => {},
  onError = () => {},
}) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_PICKED_UP_LIST,
  payload: params,
  extraParams: {
    isLoadingMore,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const setPickedUp = (listData, isLoadingMore = false) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_PICKED_UP_LIST,
  payload: listData,
  extraParams: {
    isLoadingMore,
  },
})

const setPickedUpError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_PICKED_UP_LIST_ERROR,
  payload: error,
})

const fetchLinkWarehouses = (params) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_LINK_WAREHOUSES,
  payload: params,
})

const setLinkWarehouses = (listData) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_LINK_WAREHOUSE_LIST,
  payload: listData,
})

const setLinkWarehousError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_LINK_WAREHOUSE_LIST_ERROR,
  payload: error,
})

const fetchOnlineDrivers = (params) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_ONLINE_DRIVERS,
  payload: params,
})

const setOnlineDrivers = (listData) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_ONLINE_DRIVER_LIST,
  payload: listData,
})

const setOnlineDriverError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_ONLINE_DRIVER_LIST_ERROR,
  payload: error,
})

const fetchCountOrders = (params) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_COUNT_ORDERS,
  payload: params,
})

const setCountOrders = (resp) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_COUNT_ORDERS,
  payload: resp,
})

const setCountOrderError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_COUNT_ORDER_ERROR,
  payload: error,
})

const fetchInCompletedOrders = (params) => ({
  type: CANTEC_FULFILLMENT_ACTION.FETCH_INCOMPLETED_ORDERS,
  payload: params,
})

const setIncompletedOrders = (resp) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_INCOMPLETED_ORDERS,
  payload: resp,
})

const setIncompletedOrderError = (error) => ({
  type: CANTEC_FULFILLMENT_ACTION.SET_INCOMPLETED_ORDERS_ERROR,
  payload: error,
})

export default {
  fetchPendingPicking,
  setPendingPicking,
  setPendingPickingError,
  fetchPendingPickup,
  setPendingPickup,
  setPendingPickupError,
  fetchPickedUp,
  setPickedUp,
  setPickedUpError,
  fetchLinkWarehouses,
  setLinkWarehouses,
  setLinkWarehousError,
  fetchOnlineDrivers,
  setOnlineDrivers,
  setOnlineDriverError,
  fetchCountOrders,
  setCountOrders,
  setCountOrderError,
  fetchInCompletedOrders,
  setIncompletedOrders,
  setIncompletedOrderError,
}
