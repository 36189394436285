import { DEFAULT_COUPON_DETAIL } from '~/redux/default/coupon'

export const COUPON_ACTION = {
  FETCH_DETAIL: 'coupon.FETCH_DETAIL',
  SET_DETAIL_DATA_ERROR: 'coupon.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'coupon.SET_DETAIL_DATA',
  FETCH_COUPON_LIST: 'coupon.GET_LIST',
  SET_LIST_DATA: 'coupon.SET_LIST_DATA',
  SET_LIST_ERROR: 'coupon.SET_LIST_ERROR',
}

export const fetchCouponList = (params) => ({
  type: COUPON_ACTION.FETCH_COUPON_LIST,
  payload: params,
})

export const setCouponListData = (listData) => ({
  type: COUPON_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setCouponListError = (error) => ({
  type: COUPON_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const fetchCouponDetail = (id, {
  onError = () => {},
}) => ({
  type: COUPON_ACTION.FETCH_DETAIL,
  payload: id,
  callbacks: {
    onError,
  },
})

export const setCouponDetail = (coupon) => ({
  type: COUPON_ACTION.SET_DETAIL_DATA,
  payload: coupon,
})

export const setCouponDetailError = (error) => ({
  type: COUPON_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const clearCouponDetail = () => ({
  type: COUPON_ACTION.SET_DETAIL_DATA,
  payload: DEFAULT_COUPON_DETAIL,
})

export default {
  fetchCouponList,
  setCouponListData,
  setCouponListError,
  fetchCouponDetail,
  setCouponDetail,
  setCouponDetailError,
  clearCouponDetail,
}
