import { DEFAULT_TAG_DETAIL } from '../default/tag'

export const TAG_ACTION = {
  FETCH_DETAIL: 'tag.FETCH_DETAIL',
  SET_DETAIL_DATA_ERROR: 'tag.SET_DETAIL_DATA_ERROR',
  SET_DETAIL_DATA: 'tag.SET_DETAIL_DATA',
  FETCH_LIST: 'tag.GET_LIST',
  SET_LIST_DATA: 'tag.SET_LIST_DATA',
  SET_LIST_ERROR: 'tag.SET_LIST_ERROR',
  FETCH_ALL_LIST: 'tag.FETCH_ALL_LIST',
  SET_ALL_LIST_DATA: 'tag.SET_ALL_LIST_DATA',
  SET_ALL_LIST_ERROR: 'tag.SET_ALL_LIST_ERROR',
}

export const fetchTagList = (params) => ({
  type: TAG_ACTION.FETCH_LIST,
  payload: params,
})
export const fetchAllTagList = (params = {}) => ({
  type: TAG_ACTION.FETCH_ALL_LIST,
  payload: params,
})

export const setTagAllListData = (listData) => ({
  type: TAG_ACTION.SET_ALL_LIST_DATA,
  payload: listData,
})

export const setAllTagListError = (error) => ({
  type: TAG_ACTION.SET_ALL_LIST_ERROR,
  payload: error,
})

export const setTagListData = (listData) => ({
  type: TAG_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setTagListError = (error) => ({
  type: TAG_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const fetchTagDetail = (id) => ({
  type: TAG_ACTION.FETCH_DETAIL,
  payload: id,
})

export const setTagDetail = (tag) => ({
  type: TAG_ACTION.SET_DETAIL_DATA,
  payload: tag,
})

export const setTagDetailError = (error) => ({
  type: TAG_ACTION.SET_DETAIL_DATA_ERROR,
  payload: error,
})

export const clearTagDetail = () => ({
  type: TAG_ACTION.SET_DETAIL_DATA,
  payload: DEFAULT_TAG_DETAIL,
})

export default {
  fetchTagList,
  fetchAllTagList,
  setTagAllListData,
  setAllTagListError,
  setTagListData,
  setTagListError,
  fetchTagDetail,
  setTagDetail,
  setTagDetailError,
  clearTagDetail,
}
