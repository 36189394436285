import { createTheme } from '@mui/material/styles'
import typography from './typography'
import palette from './palette'
import components from './components'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography,
  palette,
  components,
})

export default theme
