export const DEFAULT_CATEGORY_LIST_DATA = {
  items: [],
  total_items: 0,
}

export const DEFAULT_CATEGORY_DETAIL = {
  name: '',
  slug: '',
  description: '',

  image: null,
}
