/**
 * USAGE:
 *   `httpClient` is a minimalist wrapper library that supports ajax request in browsers.
 *   Underhood this library uses `axios` to make ajax request.
 *   But this library is organized the way that makes you free to change `axios` to any library you're comfortable (i.e. `superagent`)
 *   without breaking any thing from the app.
 *   As long as you keep the public APIs stay same you're good to go.
 *
 *   Right now, this library supports 2 `Content-Type`.
 *     - multipart/form-data
 *     - application/json
 *   You can add more custom types/handlers as you wish.
 *
 *   1. multipart/form-data: Using `formDataRequest` for this type of request
 *
 *      By default, `httpClient` methods are tied to this type.
 *
 * Use it like this
 * ```
 * get : http.get(url: string, queryString = { queryKey: queryVal }),
 * post: http.post(url: string, params = {paramKey: paramVal}) // pass `params` as object
 * post: http.post(url: string, params: () => {}) // pass `params` as function so that you can add/remove/edit params whatever you like
 * ```
 *
 * or explicit like this
 * ```
 * get : http.formData.get(url: string, queryString = { queryKey: queryVal }),
 * post: http.formData.post(url: string, params = {paramKey: paramVal}) // pass `params` as object
 * post: http.formData.post(url: string, params: () => {}) // pass `params` as function so that you can add/remove/edit params whatever you like

 * ```
 *
 *   2. application/json: Using `jsonRequest` for this type of request
 *
 * ```
 * get : http.json.get(url: string, queryString = { queryKey: queryVal }),
 * post: http.json.post(url: string, params = {paramKey: paramVal}) // pass `params` as object
 * post: http.json.post(url: string, params: () => {}) // pass `params` as function so that you can add/remove/edit params whatever you like
 * ```
 */
import { HttpFormData, HttpJson } from './client'

const httpClient = {}

// Create default httpFormData
httpClient.formData = HttpFormData.create()
// httpClient.formData.create = HttpFormData.create

// create default httpJson
httpClient.json = HttpJson.create()
httpClient.json.create = HttpJson.create

// By default, `httpClient` methods are tied to `formData` request
// coz this type is used more often
httpClient.create = HttpFormData.create
httpClient.post = httpClient.formData.post.bind(httpClient.formData)
httpClient.put = httpClient.formData.put.bind(httpClient.formData)
httpClient.patch = httpClient.formData.patch.bind(httpClient.formData)
httpClient.delete = httpClient.formData.delete.bind(httpClient.formData)
httpClient.get = httpClient.formData.get.bind(httpClient.formData)

export default httpClient
