import {
  takeLatest, all, fork, call, put,
} from 'redux-saga/effects'
import regularShippingApi from '~/services/apis/regularShipping'
import Actions, { REGULAR_SHIPPING_FULFILLMENT_ACTION } from '~/redux/actions/regularShipping'

export function* fetchPickingList(action) {
  try {
    const resp = yield call(regularShippingApi.getPendingOrders, action.payload)
    yield put(Actions.setPickingListData(resp))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setPickingListError(e))
  }
}

export function* fetchPendingDeliveringList(action) {
  try {
    const resp = yield call(regularShippingApi.getPendingOrders, action.payload)
    yield put(Actions.setPendingDeliveringListData(resp))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setPendingDeliveringListError(e))
  }
}

export function* fetchTrackingList(action) {
  try {
    const resp = yield call(regularShippingApi.getTrackingOrders, action.payload)
    yield put(Actions.setTrackingListData(resp, action.extraParams.isLoadingMore))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setTrackingListError(e))
  }
}

export function* fetchPendingCountOrders(action) {
  try {
    const resp = yield call(regularShippingApi.countOrders, action.payload)
    yield put(Actions.setPendingCountOrders(resp))
  } catch (e) {
    yield put(Actions.setPendingCountOrderError(e))
  }
}

export function* fetchTrackingCountOrders(action) {
  try {
    const resp = yield call(regularShippingApi.countOrders, action.payload)
    yield put(Actions.setTrackingCountOrders(resp))
  } catch (e) {
    yield put(Actions.setTrackingCountOrderError(e))
  }
}

export function* watchRegularShippingFullfilment() {
  yield all([
    takeLatest(REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PICKING_LIST, fetchPickingList),
    takeLatest(REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PENDING_DELIVERING_LIST, fetchPendingDeliveringList),
    takeLatest(REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_TRACKING_LIST, fetchTrackingList),
    takeLatest(REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_PENDING_COUNT_ORDERS, fetchPendingCountOrders),
    takeLatest(REGULAR_SHIPPING_FULFILLMENT_ACTION.FETCH_TRACKING_COUNT_ORDERS, fetchTrackingCountOrders),
  ])
}

export default function* regularShippingFulfillment() {
  yield fork(watchRegularShippingFullfilment)
}
