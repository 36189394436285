import produce from 'immer'
import { notificationCenterAction } from '../actions/notificationCenter'

const initialState = {
  data: {},
  error: '',

}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload } = action
  switch (type) {
    case notificationCenterAction.GET_NOTIFICATION_CENTER:
      draft.error = ''
      break

    case notificationCenterAction.GET_NOTIFICATION_CENTER_SUCCESS:
      draft.data = payload
      break

    case notificationCenterAction.GET_NOTIFICATION_CENTER_ERR:
      draft.error = payload
      break
    default:
      break
  }
})

export default reducer
