import { useSelector, useDispatch } from 'react-redux'
import { useMemo, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import {
  getPendingPickingList,
  getPendingPickingListLoading,
  getPendingPickingListError,
  getPendingPickupList,
  getPendingPickupListLoading,
  getPendingPickupListError,
  getPickedUpList,
  getPickedUpListLoading,
  getPickedUpListError,
  getLinkWarehouseList,
  getLinkWarehouseLoading,
  getLinkWarehouseError,
  getOnlineDriverList,
  getOnlineDriverLoading,
  getOnlineDriverError,
  getCantecStatistic,
  getIncompletedOrders,
} from '../selectors/cantecFulfillment'
import cantecFulfillmentActions from '../actions/cantecFulfillment'
import { mapPendingPickingCantecOrders, mapPendingPickupCantecOrders, mapPickedUpCantecOrders } from '~/utils/fulfillment'

const sortedFulfillmentList = (list = []) => {
  const { withoutScheduleList, withScheduleList } = list.reduce(
    (acc, cur) => {
      if (cur?.schedule_completion) {
        acc.withScheduleList.push(cur)
      } else {
        acc.withoutScheduleList.push(cur)
      }
      return acc
    }, { withoutScheduleList: [], withScheduleList: [] },
  )

  return [...withoutScheduleList, ...withScheduleList]
}

export const usePendingPickingList = () => {
  const pendingPickingListData = useSelector(getPendingPickingList)
  const loading = useSelector(getPendingPickingListLoading)
  const error = useSelector(getPendingPickingListError)
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])

  return useMemo(() => ({
    actions,
    pendingPickingListData: {
      ...pendingPickingListData,
      items: sortedFulfillmentList(mapPendingPickingCantecOrders(pendingPickingListData.items)),
    },
    loading,
    error,
  }), [actions, pendingPickingListData, loading, error])
}

export const usePendingPickupList = () => {
  const pendingPickupListData = useSelector(getPendingPickupList)
  const loading = useSelector(getPendingPickupListLoading)
  const error = useSelector(getPendingPickupListError)
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])

  return useMemo(() => ({
    actions,
    pendingPickupListData: {
      ...pendingPickupListData,
      items: sortedFulfillmentList(mapPendingPickupCantecOrders(pendingPickupListData.items)),
    },
    loading,
    error,
  }), [actions, pendingPickupListData, loading, error])
}

export const usePickedUpList = () => {
  const pickedUpListData = useSelector(getPickedUpList)
  const loading = useSelector(getPickedUpListLoading)
  const error = useSelector(getPickedUpListError)
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])

  return useMemo(() => ({
    actions,
    pickedUpListData: {
      ...pickedUpListData,
      items: sortedFulfillmentList(mapPickedUpCantecOrders(pickedUpListData.items)),
    },
    loading,
    error,
  }), [actions, pickedUpListData, loading, error])
}

export const useLinkWarehouseList = () => {
  const linkWarehouses = useSelector(getLinkWarehouseList)
  const loading = useSelector(getLinkWarehouseLoading)
  const error = useSelector(getLinkWarehouseError)
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])

  return useMemo(() => ({
    actions,
    linkWarehouses,
    loading,
    error,
  }), [actions, linkWarehouses, loading, error])
}

export const useOnlineDriverList = () => {
  const onlineDrivers = useSelector(getOnlineDriverList)
  const loading = useSelector(getOnlineDriverLoading)
  const error = useSelector(getOnlineDriverError)
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])

  return useMemo(() => ({
    actions,
    onlineDrivers,
    loading,
    error,
  }), [actions, onlineDrivers, loading, error])
}

export const useCantecStatistic = () => {
  const cantecStatistic = useSelector(getCantecStatistic)
  return useMemo(() => ({
    cantecStatistic,
  }), [cantecStatistic])
}

export const useIncompletedOrdersOfSpecificStore = (storeId) => {
  const incompletedOrders = useSelector(getIncompletedOrders)
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])
  const incompletedOrdersOfSpecificStore = incompletedOrders.find((item) => item.store_id === storeId)
  return useMemo(() => ({
    actions,
    incompletedOrdersOfSpecificStore,
  }), [incompletedOrders])
}

export const useCountIncompletedOrdersOfSpecificStore = (storeId) => {
  const incompletedOrders = useSelector(getIncompletedOrders)
  const incompletedOrdersOfSpecificStore = incompletedOrders.find((item) => item.store_id === storeId)
  const countCantecDelivery = _get(incompletedOrdersOfSpecificStore, 'on-hold.cantec_delivery', []).length + _get(incompletedOrdersOfSpecificStore, 'processing.cantec_delivery', []).length
  const countCantecLocalPickup = _get(incompletedOrdersOfSpecificStore, 'on-hold.local_pickup', []).length + _get(incompletedOrdersOfSpecificStore, 'processing.local_pickup', []).length
  const countCurbsidePickup = _get(incompletedOrdersOfSpecificStore, 'on-hold.curbside_pickup', []).length + _get(incompletedOrdersOfSpecificStore, 'processing.curbside_pickup', []).length

  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])
  return useMemo(() => ({
    actions,
    countCantecDelivery,
    countCantecLocalPickup,
    countCurbsidePickup,
  }), [countCantecDelivery, countCantecLocalPickup, countCurbsidePickup])
}

export const useHasUnpickedOrders = (storeId) => {
  const incompletedOrders = useSelector(getIncompletedOrders)
  const incompletedOrdersOfSpecificStore = incompletedOrders.find((item) => item.store_id === storeId)
  const countCantecDelivery = _get(incompletedOrdersOfSpecificStore, 'on-hold.cantec_delivery', []).length + _get(incompletedOrdersOfSpecificStore, 'processing.cantec_delivery', []).length
  const countCantecLocalPickup = _get(incompletedOrdersOfSpecificStore, 'on-hold.local_pickup', []).length + _get(incompletedOrdersOfSpecificStore, 'processing.local_pickup', []).length
  const countCurbsidePickup = _get(incompletedOrdersOfSpecificStore, 'on-hold.curbside_pickup', []).length + _get(incompletedOrdersOfSpecificStore, 'processing.curbside_pickup', []).length

  return useMemo(() => Number(countCantecDelivery) + Number(countCantecLocalPickup) + Number(countCurbsidePickup) > 0, [countCantecDelivery, countCantecLocalPickup, countCurbsidePickup, storeId])
}

export const useFetchingIncompletedOrders = (storeId) => {
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(cantecFulfillmentActions, dispatch), [dispatch])
  useEffect(() => {
    const fetchIncompletedOrders = () => actions.fetchInCompletedOrders({
      store_id_list: [storeId],
    })

    let intervalId
    if (storeId) {
      fetchIncompletedOrders()
      intervalId = setInterval(() => {
        fetchIncompletedOrders()
      }, 60000 * 5)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [storeId])
}
