import {
  takeLatest,
  all,
  put,
  fork,
  call,
} from 'redux-saga/effects'
import shippingRequest from '~/services/apis/shipping'
import { shippingAction } from '~/redux/actions/shipping'

export function* getShippingZones(action) {
  try {
    const response = yield call(shippingRequest.getShippingZones, action.payload)
    yield put({
      type: shippingAction.GET_SHIPPING_ZONES_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: shippingAction.GET_SHIPPING_ZONES_ERR,
      payload: error,
    })
    action.callbacks.onSuccess(error)
  }
}

export function* getShippingClasses(action) {
  try {
    const response = yield call(shippingRequest.getShippingClasses, action.payload)
    yield put({
      type: shippingAction.GET_SHIPPING_CLASSES_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: shippingAction.GET_SHIPPING_CLASSES_ERR,
      payload: error,
    })
    action.callbacks.onSuccess(error)
  }
}

export function* addShippingClass(action) {
  try {
    const response = yield call(shippingRequest.addShippingClass, action.payload)
    yield put({
      type: shippingAction.ADD_SHIPPING_CLASS_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: shippingAction.ADD_SHIPPING_CLASS_ERR,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* updateShippingClass(action) {
  try {
    const response = yield call(shippingRequest.updateShippingClass, action.payload)
    yield put({
      type: shippingAction.UPDATE_SHIPPING_CLASS_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: shippingAction.UPDATE_SHIPPING_CLASS_ERR,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* deleteShippingClass(action) {
  try {
    const response = yield call(shippingRequest.deleteShippingClass, action.payload)
    yield put({
      type: shippingAction.DELETE_SHIPPING_CLASS_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: shippingAction.DELETE_SHIPPING_CLASS_ERR,
      payload: error,
    })
    action.callbacks.onError(error)
  }
}

export function* watchGetListShippingZones() {
  yield all([
    takeLatest(shippingAction.GET_SHIPPING_ZONES, getShippingZones),
    takeLatest(shippingAction.GET_SHIPPING_CLASSES, getShippingClasses),
    takeLatest(shippingAction.ADD_SHIPPING_CLASS, addShippingClass),
    takeLatest(shippingAction.UPDATE_SHIPPING_CLASS, updateShippingClass),
    takeLatest(shippingAction.DELETE_SHIPPING_CLASS, deleteShippingClass),
  ])
}

export default function* reward() {
  yield fork(watchGetListShippingZones)
}
