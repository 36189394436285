/* eslint-disable no-param-reassign */
import produce from 'immer'

import { STATUS } from '~/constants/status'
import { DASHBOARD_ACTION } from '../actions/dashboard'

const initialState = {
  data: {
    grossSales: null,
    netSales: null,
    totalOrders: null,
    newUsers: null,
    topCategories: null,
    customerTypes: null,
    topCoupons: null,
    recentReviews: null,
    bestSellers: null,
  },
  error: null,
  status: STATUS.IDLE,
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
  const { type, payload = {} } = action

  if (type === DASHBOARD_ACTION.GET_DATA) {
    draft.status = STATUS.PROCESSING
  }

  if (type === DASHBOARD_ACTION.GET_DATA_RESOLVED) {
    draft.data = { ...payload }
    draft.error = null
    draft.status = STATUS.RESOLVED
  }

  if (type === DASHBOARD_ACTION.GET_DATA_REJECTED) {
    draft.error = payload
    draft.status = STATUS.REJECTED
  }
})

export default reducer
