import moment from 'moment'
import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import { isNullOrUndefined } from '~/utils/utility'
import { convertLocalToGmtTime, getEndOfDay, getStartOfDay } from '~/utils/datetime'
import { CUSTOMER_STATUS } from '~/constants/customer'

export const handleCustomerAnalyticsCount = (data) => [
  { label: 'Completed', value: data?.completed_orders || 0 },
  { label: 'Processing', value: data?.processing_orders || 0 },
  { label: 'Non-complete', value: (data?.failed_orders + data?.processing_orders + data?.refunded_orders + data?.cancelled_orders + data?.pending_orders + data?.['on-hold_orders']) || 0 },
  { label: 'Cancelled', value: data?.cancelled_orders || 0 },
  { label: 'Total Refunds', value: data?.refunded_orders || 0 },
  { label: 'Avg. Orders ($)', value: parseFloat(data?.average_order_amount || 0).toFixed(2) },
  {
    label: 'Last 3 month\nOrder Frequency\n(day)',
    value: parseFloat(data?.last_90_average_order_frequency || 0).toFixed(2),
  },
  {
    label: 'Lifetime\nOrder Frequency\n(day)',
    value: parseFloat(data?.average_order_frequency || 0).toFixed(2),
  },
  { label: 'Points', value: data?.points || 0 },
]

export const getCustomerInfo = (customerData) => {
  const avatarUrl = customerData.avatar_url
  const { username, meta_data } = customerData
  const name = [customerData.first_name, customerData.last_name].join(' ')
  const createdDate = new Date(customerData.date_created)
  const lastLoginDateText = customerData.last_active_date_gmt ? moment(customerData.last_active_date_gmt).format('L') : '-'
  const createdDateText = [`${createdDate.getMonth() + 1}`.padStart(2, '0'), createdDate.getFullYear()].join('/')
  return {
    avatarUrl,
    name,
    username,
    createdDateText,
    lastLoginDateText,
    points: customerData.points,
    score: customerData.score && customerData.score.toFixed(2),
    clv: customerData.clv && customerData.clv.toFixed(2),
    meta_data,
  }
}

export const getBillingInfo = (billing) => {
  const name = [billing.first_name, billing.last_name].join(' ')
  const address = [billing.address_1, billing.city, billing.state].join(', ')
  const postcode = [billing.postcode, billing.country].join(', ')
  const { email } = billing
  const { phone } = billing
  return {
    name, address, email, phone, postcode,
  }
}

export const getShippingInfo = (shipping) => {
  const name = [shipping.first_name, shipping.last_name].join(' ')
  const address = [shipping.address_1, shipping.city, shipping.state].join(', ')
  const postcode = [shipping.postcode, shipping.country].join(', ')
  return { name, address, postcode }
}

export const getCustomerStatus = (customerInfo) => {
  const metaData = customerInfo.meta_data ? customerInfo.meta_data.find((md) => md.key === 'pw_user_status') : null
  if (metaData && metaData.value) {
    return metaData.value
  }
  return CUSTOMER_STATUS.APPROVED
}

export const mapCustomerList = (customers) => {
  if (isNullOrUndefined(customers)) {
    return []
  }
  const items = customers.map((customer) => {
    const item = { id: customer.id }
    item.fullname = `${customer.first_name} ${customer.last_name}`
    item.email = customer.email
    item.phone = customer.customer_phone
      ? customer.customer_phone
      : customer.billing.phone
    // const clvStr = customer.clv ? `$${customer.clv.toFixed(2)}` : '__'
    item.clv = customer.clv

    item.completed_orders = customer.completed_orders || 0
    item.total_orders = customer.total_orders || 0
    item.average_order_amount = customer.average_order_amount || 0

    item.points = !Number.isNaN(Number(customer.points))
      ? customer.points
      : '__'
    item.country = customer.billing.country
    item.state = customer.billing.state
    item.city = customer.billing.city
    item.order_history = customer.order_history?.join(', ')
    item.last_order_date = customer.last_order_date
      ? moment(customer.last_order_date).format('MM/DD/YYYY')
      : '__'
    item.username = customer.username
    item.selectInfo = { id: customer.id, isSelected: false }
    item.meta_data = customer.meta_data

    item.on_hold_orders = customers['on-hold_orders']

    // Data for updating customer in list (Approve/Deny)
    item.customer = customer

    return item
  })
  return items
}

export const mapCovaCustomerList = (customers) => {
  if (isNullOrUndefined(customers)) {
    return []
  }
  const items = customers.map((customer) => {
    const item = { id: customer.Id }
    item.fullname = `${customer.PrimaryName} ${customer.FamilyName}`
    // const clvStr = customer.CLV ? `$${customer.CLV.toFixed(2)}` : '__'
    item.clv = customer.CLV

    // item.completed_orders = customer.completed_orders || 0
    item.total_orders = customer.TotalOrders || 0
    item.average_order_amount = customer.average_order_amount || 0

    item.points = !Number.isNaN(Number(customer.points))
      ? customer.points
      : '__'
    const checkAddress = customer.Addresses && customer.Addresses.length > 0
    item.country = checkAddress ? customer.Addresses[0].Country : ''
    item.state = checkAddress ? customer.Addresses[0].State : ''
    item.city = checkAddress ? customer.Addresses[0].Locality : ''

    const contactMethods = customer.ContactMethods
    if (contactMethods && contactMethods.length > 0) {
      contactMethods.forEach((contactMethod) => {
        if (contactMethod.ContactMethodCategory === 'Phone') item.phone = contactMethod.Value
        else if (contactMethod.ContactMethodCategory === 'Email') item.email = contactMethod.Value
      })
    } else {
      item.email = ''
      item.phone = ''
    }
    // item.order_history = customer.order_history?.join(', ')
    item.last_order_date = customer.LastOrderDate
      ? moment(customer.LastOrderDate).format('MM/DD/YYYY')
      : '__'
    item.username = customer.UniqueIdentifier
    item.bs_customer_type = customer.BSCustomerType
    item.woo_customer_id = customer.WooCustomerId

    // Data for updating customer in list (Approve/Deny)
    item.customer = customer

    return item
  })
  return items
}

export const getStateList = (countryId, locationList) => {
  const countryIdx = countryId ? locationList.findIndex((c) => c.value === countryId) : -1
  if (countryIdx > 0) {
    return locationList[countryIdx].states
  }
  return []
}

export const mapCustomerFilters = (params) => params

export const mapHeaderCSVCustomer = (showColumns) => {
  const csvHeader = []
  if (showColumns.username) csvHeader.push('Username')
  if (showColumns.fullname) csvHeader.push('Customer Name')
  if (showColumns.phone) csvHeader.push('Phone')
  if (showColumns.email) csvHeader.push('Email')
  if (showColumns.clv) csvHeader.push('CLV')
  if (showColumns.total_orders) csvHeader.push('Total Orders')
  if (showColumns.points) csvHeader.push('Point')
  if (showColumns.city) csvHeader.push('City')
  if (showColumns.state) csvHeader.push('Province')
  if (showColumns.country) csvHeader.push('Country')
  csvHeader.push('Order History')
  if (showColumns.last_order_date) csvHeader.push('Last Order Date')
  return csvHeader
}

export const mapExportCustomer = (items, showColumns) => items.map(
  ({
    username,
    fullname,
    phone,
    email,
    clv,
    total_orders,
    points,
    city,
    state,
    country,
    order_history,
    last_order_date,
  }) => {
    const contentCSV = []
    if (showColumns.username) contentCSV.push(username)
    if (showColumns.fullname) contentCSV.push(fullname)
    if (showColumns.phone) contentCSV.push(phone)
    if (showColumns.email) contentCSV.push(email)
    if (showColumns.clv) contentCSV.push(clv)
    if (showColumns.total_orders) contentCSV.push(total_orders)
    if (showColumns.points) contentCSV.push(points)
    if (showColumns.city) contentCSV.push(city)
    if (showColumns.state) contentCSV.push(state)
    if (showColumns.country) contentCSV.push(country)
    if (order_history) contentCSV.push(order_history)
    if (showColumns.last_order_date) contentCSV.push(last_order_date)
    return contentCSV
  },

)

export const displayCustomerAddress = (line1, line2, sign = ',') => (line1 || line2) ? `${line1 || ''}${line2 ? `${(line1 && sign) ? `${sign} ` : ' '}${line2}` : ''}` : ''

export const getCustomerAddress = (addressLine1, addressLine2, city) => {
  let address = ''
  if (addressLine1 && addressLine2) {
    address = `${addressLine1}, ${addressLine2}`
  } else if (addressLine1 && !addressLine2) {
    address = `${addressLine1}`
  } else if (!addressLine1 && addressLine2) {
    address = `${addressLine2}`
  }
  if (city) {
    return `${address}, ${city}`
  }
  return `${address}`
}

export const convertCustomerForm = ({
  email, billing: { email: billingEmail, ...billing }, shipping: { email: shippingEmail, ...shipping }, ...data
}) => ({
  email: email.toLowerCase(),
  billing: {
    email: billingEmail.toLowerCase(),
    ...billing,
  },
  shipping: {
    email: shippingEmail.toLowerCase(),
    ...shipping,
  },
  ...data,
})

export const convertCustomerListParams = ({
  filters,
  ...params
}) => {
  const {
    start_date, end_date, last_login_date, registered_date, ...xFilters
  } = (filters || {})
  return {
    filters: {
      ...(start_date ? { start_date: convertLocalToGmtTime(getStartOfDay(start_date)) } : {}),
      ...(end_date ? { end_date: convertLocalToGmtTime(getEndOfDay(end_date)) } : {}),
      ...(last_login_date ? {
        last_login_date: {
          from: last_login_date.from ? convertLocalToGmtTime(getStartOfDay(last_login_date.from)) : null,
          to: last_login_date.to ? convertLocalToGmtTime(getEndOfDay(last_login_date.to)) : null,
        },
      } : {}),
      ...(registered_date ? {
        registered_date: {
          from: registered_date.from ? convertLocalToGmtTime(getStartOfDay(registered_date.from)) : null,
          to: registered_date.to ? convertLocalToGmtTime(getEndOfDay(registered_date.to)) : null,
        },
      } : {}),
      ...xFilters,
    },
    ...params,
  }
}

export const trimObjValues = (obj) => Object.keys(obj).reduce((acc, curr) => {
  // eslint-disable-next-line no-param-reassign
  acc[curr] = obj[curr].trim()
  return acc
}, {})

export const trimObject = (obj) => {
  if (typeof obj === 'string') {
    return obj.trim()
  }

  if (Array.isArray(obj)) {
    return obj.map(trimObject)
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, cur) => {
      // eslint-disable-next-line no-param-reassign
      acc[cur] = trimObject(obj[cur])
      return acc
    }, {})
  }

  return obj
}

// all keys of Simple Filter will start with the character 's_' different advance filter
export const clearObjectSimpleFilter = (obj = {}) => pickBy(obj, (_, key) => !startsWith(key, 's_'))

export const clearObjectAdvanceFilter = (obj = {}) => pickBy(obj, (_, key) => startsWith(key, 's_'))

export const clearObjectFilterPrefix = (obj = {}, prefix = '') => pickBy((obj), (_, key) => !startsWith(key, prefix))
