import {
  takeLatest, all, fork, call, put,
} from 'redux-saga/effects'
import locationApi from '~/services/apis/country'

import Actions, { LOCATION_ACTION } from '~/redux/actions/location'

export function* fetchLocationList(action) {
  try {
    const resp = yield call(locationApi.getList, action.payload)
    yield put(Actions.setLocationListData(resp))
  } catch (e) {
    yield put(Actions.setLocationListError(e))
  }
}

export function* watchLocation() {
  yield all([
    takeLatest(LOCATION_ACTION.FETCH_LIST, fetchLocationList),
  ])
}

export default function* tag() {
  yield fork(watchLocation)
}
