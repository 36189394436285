export const ONFLEET_ACTION = {
  FETCH_LIST: 'onfleet.FETCH_LIST',
  SET_LIST_DATA: 'onfleet.SET_LIST_DATA',
  SET_LIST_ERROR: 'onfleet.SET_LIST_ERROR',

  GET_ONFLEET_STORE_DETAIL: 'onfleet.GET_SKU_DETAIL',
  GET_ONFLEET_STORE_DETAIL_OK: 'onfleet.GET_SKU_DETAIL_OK',
  GET_ONFLEET_STORE_DETAIL_ERR: 'onfleet.GET_SKU_DETAIL_ERR',
  CLEAR_ONFLEET_STORE_DETAIL: 'onfleet.CLEAR_SKU_TAGS_DETAIL',

  UPDATE_ONFLEET_STORE: 'onfleet.UPDATE_ONFLEET_STORE',
  UPDATE_ONFLEET_STORE_OK: 'onfleet.UPDATE_ONFLEET_STORE_OK',
  UPDATE_ONFLEET_STORE_FAIL: 'onfleet.UPDATE_ONFLEET_STORE_FAIL',
  FETCH_ONFLEET_PROCESSING: 'onfleet.FETCH_ONFLEET_PROCESSING',
  REFETCH_ONFLEET_PROCESSING: 'onfleet.REFETCH_ONFLEET_PROCESSING',
  SET_ONFLEET_PROCESSING_DATA: 'onfleet.SET_ONFLEET_PROCESSING_DATA',
  SET_ONFLEET_PROCESSING_ERROR: 'onfleet.SET_ONFLEET_PROCESSING_ERROR',
}

export const fetchOnfleetStoreList = (params) => ({
  type: ONFLEET_ACTION.FETCH_LIST,
  payload: params,
})

export const setOnfleetStoreListData = (params) => ({
  type: ONFLEET_ACTION.SET_LIST_DATA,
  payload: params,
})

export const setOnfleetStoreListError = (error) => ({
  type: ONFLEET_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const getOnfleetStoreDetail = (payload) => ({
  type: ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL,
  payload,
})

export const getOnfleetStoreDetailSuccess = (payload) => ({
  type: ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL_OK,
  payload,
})

export const getOnfleetStoreDetailFail = (payload) => ({
  type: ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL_ERR,
  payload,
})

export const updateOnfleetStore = (payload) => ({
  type: ONFLEET_ACTION.UPDATE_ONFLEET_STORE,
  payload,
})

export const updateOnfleetStoreOk = (payload) => ({
  type: ONFLEET_ACTION.UPDATE_ONFLEET_STORE_OK,
  payload,
})

export const updateOnfleetStoreFail = (payload) => ({
  type: ONFLEET_ACTION.UPDATE_ONFLEET_STORE_FAIL,
  payload,
})

export const fetchOnfleetFulfillment = (params) => ({
  type: ONFLEET_ACTION.FETCH_ONFLEET_PROCESSING,
  payload: params,
})

export const refetchOnfleetFulfillment = (params) => ({
  type: ONFLEET_ACTION.REFETCH_ONFLEET_PROCESSING,
  payload: params,
})

export const setOnfleetProcessingData = (params) => ({
  type: ONFLEET_ACTION.SET_ONFLEET_PROCESSING_DATA,
  payload: params,
})

export const setOnfleetProcessingError = (error) => ({
  type: ONFLEET_ACTION.SET_ONFLEET_PROCESSING_ERROR,
  payload: error,
})

export default {
  fetchOnfleetStoreList,
  setOnfleetStoreListData,
  setOnfleetStoreListError,
  getOnfleetStoreDetail,
  getOnfleetStoreDetailSuccess,
  getOnfleetStoreDetailFail,
  updateOnfleetStore,
  updateOnfleetStoreOk,
  updateOnfleetStoreFail,

  fetchOnfleetFulfillment,
  refetchOnfleetFulfillment,
  setOnfleetProcessingData,
  setOnfleetProcessingError,
}
