import moment from 'moment'
import httpClient from '~/modules/core/httpClient'
import * as Common from './common'
import { isNullOrUndefined } from '~/utils/utility'
import { mapOrderFilters } from '~/utils/order'
import cantecClient from '~/services/apis/cantec'

const ACTIONS = {
  ...Common.ACTIONS,
  READ_ORDER_PREVIEW: 'read_order_preview',
  PAGINATE_ORDER: 'paginate_order',
}
const url = {
  ORDERS: '/stores/woocommerce/orders/',
  COVA_ORDERS: '/cova/orders/',
  PRODUCTS: '/stores/woocommerce/products/',
  REFUND: '/stores/woocommerce/orders/refunds/',
  TASK: '/tasks/timeline',
  ORDER_PICKING_DELIVERY: '/tasks/pending',
  ORDER_PICKING_PICKED_UP: '/tasks/pickup',
  ORDER_PICKING_NOTIFY_DRIVER: '/tasks/batch',
}

const isNewCompositeChildren = (item) => item.composite_parent && `${item.composite_parent}`.startsWith('new_item_')

const isHavingNewChilddComponent = (compositeParent, lineItems) => lineItems.filter((i) => i.composite_parent === compositeParent.id).find((i) => `${i.id}`.startsWith('new_item_'))

const isNewCompositeItem = (item) => item.composite_components && `${item.id}`.startsWith('new_item_')

const isHavingCompositeParent = (item, lineItems) => lineItems.find((lineItem) => `${lineItem.id}` === `${item.composite_parent}` && lineItem.product_id)

const buildCompositeConfiguration = (item) => item.composite_components.map((component) => {
  if (component.selectedProduct && component.selectedProduct.variation) {
    return ({
      component_id: component.id,
      product_id: component.selectedProduct.id,
      quantity: component.selectedProduct.quantity,
      variation_id: component.selectedProduct.variation.id,
      attributes: component.selectedProduct.variation.attributes,
    })
  }
  return {
    component_id: component.id,
    product_id: component.selectedProduct?.id || null,
    quantity: component.selectedProduct?.quantity || 0,
  }
})

const mapOrderParams = (orderData) => {
  let itemData = {
    billing: {},
    shipping: {},
    line_items: [],
    meta_data: [],
    shipping_lines: [],
    coupon_lines: [],
    // Note: date_created is READ-ONLY, so we can't update it to backend
  }
  if (orderData) {
    itemData = {
      ...orderData,
      ...(orderData.custom_fields ? { meta_data: orderData.custom_fields } : {}),
      ...(orderData.coupon_lines && !orderData.coupon_lines.find((coupon) => coupon.code.startsWith('wc_points_redemption'))
        ? { coupon_lines: (orderData.coupon_lines || []).map(({ code }) => ({ code })) } : { coupon_lines: undefined }),
      ...(orderData.refunds ? { refunds: orderData.refunds } : {}),
    }

    if (orderData.billing) {
      itemData.billing = {
        ...orderData.billing,
        email: orderData.billing && orderData.billing.email ? orderData.billing.email : undefined,
      }
    }

    if (!isNullOrUndefined(orderData.line_items)) {
      const extraLineItems = []
      itemData.line_items = orderData.line_items.map((item) => {
        const {
          id,
        } = item
        let newItem
        if (item.composite_children?.length > 0) {
          let composite_configuration
          if (isNewCompositeItem(item)) {
            composite_configuration = buildCompositeConfiguration(item)
          }
          newItem = {
            product_id: item.product_id,
            quantity: item.quantity,
            total: item.total && String(item.total),
            subtotal: item.subtotal && String(item.subtotal),
            composite_configuration,
          }
          if (isHavingNewChilddComponent(item, orderData.line_items)) {
            composite_configuration = buildCompositeConfiguration(item)
            extraLineItems.push({ ...newItem, composite_configuration })
            newItem = {
              product_id: null,
            }
          }
        } else {
          newItem = {
            product_id: item.product_id,
            quantity: item.quantity,
            variation_id: item.variation_id || item.variation?.id,
            total: item.total && String(item.total),
            subtotal: item.subtotal && String(item.subtotal),
            composite_parent: item.composite_parent,
          }
        }
        return (`${id}`).startsWith('new_item_') ? newItem : {
          ...newItem,
          id,
        }
      })
      itemData.line_items = itemData.line_items.map((item) => item.composite_parent && !isHavingCompositeParent(item, itemData.line_items) ? { product_id: null, id: item.id } : item)
      itemData.line_items = itemData.line_items.filter((item) => !item.composite_parent || !isNewCompositeChildren(item))
      itemData.line_items = itemData.line_items.concat(extraLineItems)
    }

    if (!isNullOrUndefined(orderData.customer) && !isNullOrUndefined(orderData.customer.id)) {
      itemData.customer_id = orderData.customer.id
    }
  }
  if (itemData.shipping && itemData.shipping.phone) {
    let meta_data = [...(itemData.meta_data || [])]
    const phoneIndex = meta_data.findIndex((x) => x.key === '_shipping_phone')
    if (phoneIndex !== -1) {
      meta_data[phoneIndex] = {
        ...meta_data[phoneIndex],
        value: itemData.shipping.phone,
      }
    } else {
      meta_data = [...meta_data, {
        key: '_shipping_phone',
        value: itemData.shipping.phone,
      }]
    }
    itemData.meta_data = meta_data
  }
  let meta_data = [...(itemData.meta_data || [])]
  const shipmentTrackingIndex = meta_data.findIndex((x) => x.key === '_wc_shipment_tracking_items')
  if (shipmentTrackingIndex !== -1) {
    meta_data[shipmentTrackingIndex] = {
      ...meta_data[shipmentTrackingIndex],
      value: [...[...itemData.shipment_trackings_from_meta, ...itemData.tmp_shipment_trackings].map((tracking) => ({
        tracking_provider: tracking.shipment_provider,
        custom_tracking_provider: '',
        custom_tracking_link: '',
        tracking_number: tracking.tracking_number,
        date_shipped: typeof tracking.date_shipped === 'string' ? moment(tracking.date_shipped, 'MM/DD/YYYY').unix() : moment(tracking.date_shipped).unix(),
        tracking_id: '',
      }))],
    }
  } else if (shipmentTrackingIndex === -1 && itemData.tmp_shipment_trackings) {
    meta_data = [...meta_data, {
      key: '_wc_shipment_tracking_items',
      value: itemData.tmp_shipment_trackings?.map((tracking) => ({
        tracking_provider: tracking.shipment_provider,
        custom_tracking_provider: '',
        custom_tracking_link: '',
        tracking_number: tracking.tracking_number,
        date_shipped: typeof tracking.date_shipped === 'string' ? moment(tracking.date_shipped, 'MM/DD/YYYY').unix() : moment(tracking.date_shipped).unix(),
        tracking_id: '',
      })),
    }]
  }
  itemData.meta_data = meta_data

  return {
    item_data: itemData,
  }
}

const create = (orderData) => httpClient.post(url.ORDERS, {
  action: ACTIONS.CREATE,
  parameters: mapOrderParams(orderData),
})

const preview = (params) => httpClient.post(url.ORDERS, {
  action: ACTIONS.READ_ORDER_PREVIEW,
  parameters: params,
})

const readOne = async (organization_id, store_id, orderId) => httpClient.post(url.ORDERS, () => ({
  organization_id,
  store_id,
  action: ACTIONS.READ,
  parameters: { id: Number(orderId) },
}))

const findOne = async (orderId) => {
  const parameters = { id: Number(orderId) }
  // eslint-disable-next-line no-useless-catch
  try {
    const order = await httpClient.post(url.ORDERS, {
      action: ACTIONS.READ,
      parameters,
    })
    if (order.line_items && order.line_items.length) {
      const pIds = order.line_items.map((p) => p.product_id).filter((pId) => !!pId)
      let products = []
      if (pIds.length) {
        products = await httpClient.post(url.PRODUCTS, {
          action: ACTIONS.READ_MULTI,
          parameters: { ids: order.line_items.map((p) => p.product_id).filter((pId) => !!pId) },
        })
      }
      const variationIds = []
      order.line_items.forEach((p) => {
        const found = products.find((item) => item.id === p.product_id)
        if (found) {
          // eslint-disable-next-line no-param-reassign
          p.image = found.images && found.images.length ? found.images[0].src : null
          // eslint-disable-next-line no-param-reassign
          p.sale_price = found.sale_price
          // eslint-disable-next-line no-param-reassign
          p.regular_price = found.regular_price
          // eslint-disable-next-line no-param-reassign
          p.name = found.name
        }
        if (p.variation_id) {
          variationIds.push(p.variation_id)
        }
      })
      if (variationIds.length) {
        let variations = []
        const vIds = (variationIds || []).filter((pId) => !!pId)
        if (vIds.length) {
          variations = await httpClient.post(url.PRODUCTS, {
            action: ACTIONS.READ_MULTI,
            parameters: { ids: vIds },
          })
        }
        order.line_items.forEach((p) => {
          const found = variations.find((item) => item.id === p.variation_id)
          if (found) {
            let subTitle = ''
            found.attributes.forEach((att) => {
              if (subTitle.length > 0) {
                subTitle += ' | '
              }
              subTitle += `${att.name}: ${att.option}`
            })
            // eslint-disable-next-line no-param-reassign
            p.variation = {
              id: found.id,
              value: found.id,
              label: subTitle,
              slug: found.slug,
              attributes: found.attributes,
              name: found.name,
              sku: found.sku,
              price: found.price,
              regular_price: found.regular_price,
              sale_price: found.sale_price,
            }
            if (found.attributes) {
            // eslint-disable-next-line no-param-reassign
              p.attributes = found.attributes
            }
          }
        })
      }
    }
    return {
      ...order,
      shipping: {
        ...order.shipping,
        phone: ((order.meta_data || []).find((x) => x.key === '_shipping_phone') || {}).value,
      },
    }
  } catch (e) {
    throw e
  }
}

const removeOne = (orderId) => httpClient.post(url.ORDERS, {
  action: ACTIONS.DELETE,
  parameters: {
    id: Number(orderId),
  },
})

const removeMulti = (orderIds) => {
  const parameters = {
    data: { delete: orderIds },
  }
  return httpClient.post(url.ORDERS, {
    action: ACTIONS.BATCH_UPDATE,
    parameters,
  })
}

const update = async (orderData) => {
  const parameters = mapOrderParams(orderData)
  const order = await httpClient.post(url.ORDERS, {
    action: ACTIONS.UPDATE,
    parameters,
  })
  if (order.line_items && order.line_items.length) {
    order.line_items.forEach((p) => {
      const found = (orderData.line_items || []).find((item) => item.product_id === p.product_id)
      if (found) {
        // eslint-disable-next-line no-param-reassign
        p.image = found.image
      }
    })
    order.line_items.forEach((p) => {
      const found = (orderData.line_items || []).find((item) => item.variation && item.variation.id === p.variation_id)
      if (found) {
        // eslint-disable-next-line no-param-reassign
        p.variation = found.variation
      }
    })
  }
  return order
}

const recalculate = async (orderData) => {
  const parameters = mapOrderParams(orderData)
  const order = await httpClient.post(url.ORDERS, {
    action: ACTIONS.UPDATE,
    parameters,
  })
  if (order.line_items && order.line_items.length) {
    order.line_items.forEach((p) => {
      const found = orderData.line_items.find((item) => item.product_id === p.product_id)
      if (found) {
        // eslint-disable-next-line no-param-reassign
        p.image = found.image
      }
    })
    order.line_items.forEach((p) => {
      const found = orderData.line_items.find((item) => item.variation && item.variation.id === p.variation_id)
      if (found) {
        // eslint-disable-next-line no-param-reassign
        p.variation = found.variation
      }
    })
  }
  return order
}

const recalculateDraft = async (orderData) => {
  const parameters = mapOrderParams(orderData)
  const order = await httpClient.post(url.ORDERS, {
    action: 'create_draft',
    parameters,
  })
  if (order.line_items && order.line_items.length) {
    order.line_items.forEach((p) => {
      const found = orderData.line_items.find((item) => item.product_id === p.product_id)
      if (found) {
        // eslint-disable-next-line no-param-reassign
        p.image = found.image
      }
    })
    order.line_items.forEach((p) => {
      const found = orderData.line_items.find((item) => item.variation && item.variation.id === p.variation_id)
      if (found) {
        // eslint-disable-next-line no-param-reassign
        p.variation = found.variation
      }
    })
  }
  return order
}

const bulkUpdate = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.BATCH_UPDATE,
  parameters,
})

const getList = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.PAGINATE_ORDER,
  parameters: {
    ...parameters,
    page: parameters.page || 1,
    filters: mapOrderFilters(parameters.filters),
  },
})

const exportList = (parameters) => httpClient.post(url.ORDERS, {
  action: ACTIONS.EXPORT_V2,
  parameters: {
    ...parameters,
    filters: mapOrderFilters(parameters.filters),
  },
})

const exportPOSList = (params) => httpClient.post(url.COVA_ORDERS, {
  action: ACTIONS.EXPORT,
  parameters: {
    ...params,
  },
})

export const findAllRefunds = (orderId, limit = 25, offset = 0) => {
  const params = {
    order_id: orderId,
    limit,
    offset,
  }
  return httpClient.post(url.REFUND, {
    action: ACTIONS.READ_DIRECT_PAGINATE,
    parameters: params,
  })
}

export const createRefund = (orderId, refundObject) => {
  const params = {
    order_id: orderId,
    item_data: refundObject,
  }
  return httpClient.post(url.REFUND, {
    action: ACTIONS.CREATE,
    parameters: params,
  })
}

export const removeRefund = (orderId, refundId) => {
  const params = {
    order_id: orderId,
    id: refundId,
  }
  return httpClient.post(url.REFUND, {
    action: ACTIONS.DELETE,
    parameters: params,
  })
}

export const getTask = ({ order_id, store_url }) => cantecClient.get(url.TASK, { params: { order_id, store_url } })

const getCovaList = (parameters) => httpClient.post(url.COVA_ORDERS, {
  action: ACTIONS.PAGINATE_ORDER,
  parameters: {
    ...parameters,
    page: parameters.page || 1,
  },
})

const getPOSList = (parameters) => httpClient.post(url.COVA_ORDERS, {
  action: ACTIONS.PAGINATE,
  parameters: {
    ...parameters,
    page: parameters.page || 1,
  },
})

const covaPreview = (payload) => httpClient.post(url.COVA_ORDERS, {
  action: ACTIONS.PAGINATE_ORDER,
  parameters: payload,
})

const orderApi = {
  create,
  preview,
  findOne,
  removeOne,
  removeMulti,
  update,
  recalculate,
  bulkUpdate,
  getList,
  exportList,
  exportPOSList,
  findAllRefunds,
  createRefund,
  removeRefund,
  getTask,
  getCovaList,
  covaPreview,
  readOne,
  recalculateDraft,
  getPOSList,
}

export default orderApi
